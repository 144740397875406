import { type FC } from 'react';
import { Badge, type BadgeProps } from '../Badge';

export interface NumberBadgeProps extends Omit<BadgeProps, 'badge'> {
  value?: number;
}

const NumberBadge: FC<NumberBadgeProps> = (props) => {
  const { value, ...rest } = props;

  let label: string | undefined;
  if (value && value > 0) {
    label = value >= 10 ? '9+' : value.toString(10);
  }

  return (
    <Badge
      {...rest}
      badge={label}
    />
  );
};

export default NumberBadge;
