import { type FromURLMapper, type ToURLMapper } from '../types';

export const sortFromURLMapperFactory =
  <T extends number>(
    searchParam: string,
    defaultValue: T,
    validValues: T[]
  ): FromURLMapper<T> =>
  (input) => {
    const sort = input.get(searchParam);
    const parsed = sort ? Number.parseInt(sort, 10) : null;
    const result: T =
      parsed !== null &&
      !Number.isNaN(parsed) &&
      validValues.includes(parsed as T)
        ? (parsed as T)
        : defaultValue;

    return result;
  };

export const sortToURLMapperFactory =
  <T extends number>(searchParam: string, defaultValue: T): ToURLMapper<T> =>
  (input) => {
    const { value, accumulator } = input;

    accumulator.delete(searchParam);
    if (value !== undefined && value !== defaultValue) {
      const converted = value.toString(10);
      accumulator.set(searchParam, converted);
    }

    return accumulator;
  };
