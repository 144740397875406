import { memo, type FC } from 'react';
import classNames from 'classnames';

import classes from './RoomSkeletonLoadingPlaceholder.module.css';

import { StyledBlock } from 'src/components/Styled/Block/StyledBlock';

export interface RoomSkeletonLoadingPlaceholderProps {
  className?: string;
}

const RoomSkeletonLoadingPlaceholder: FC<
  RoomSkeletonLoadingPlaceholderProps
> = (props) => {
  const { className } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <StyledBlock className={classes.styledBlock}>
        <div className={classes.wrapper}>
          <div className={classes.header} />
          <div className={classes.contentWrapper}>
            <div className={classes.content}>
              <div className={classes.avatarContainer}>
                <div className={classes.avatar} />
              </div>
              <div className={classes.dataContainer}>
                <div className={classes.name} />
                <div className={classes.attributeList}>
                  <div className={classes.attribute} />
                  <div className={classes.attribute} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledBlock>
    </div>
  );
};

export default memo(RoomSkeletonLoadingPlaceholder);
