import {
  type PropsWithRef,
  type DetailedHTMLProps,
  type FC,
  forwardRef,
} from 'react';
import { Link, type LinkProps } from 'react-router-dom';
import {
  type ButtonLikeClasses,
  type ButtonLikeBaseProps,
  ButtonLike,
} from 'src/components/Button/ButtonLike';

import classes from './ButtonLink.module.css';

export {
  BUTTON_COLOR_VARIANT,
  type ButtonColorVariant,
  BUTTON_SIZE_VARIANT,
  type ButtonSizeVariant,
  BUTTON_VARIANT,
  type ButtonVariant,
} from 'src/components/Button/ButtonLike';
export interface ButtonLinkProps
  extends PropsWithRef<DetailedHTMLProps<LinkProps, HTMLAnchorElement>>,
    ButtonLikeBaseProps {}

const BUTTON_LINK_CLASSES: Partial<ButtonLikeClasses> = {
  colorVariantDanger: classes.danger,
  colorVariantPrimary: classes.primary,
  colorVariantSecondary: classes.secondary,
  root: classes.root,
  variantContained: classes.contained,
  variantOutlined: classes.outlined,
};

const ButtonLink: FC<ButtonLinkProps> = forwardRef<
  HTMLAnchorElement,
  ButtonLinkProps
>((props, ref) => {
  return (
    <ButtonLike<PropsWithRef<DetailedHTMLProps<LinkProps, HTMLAnchorElement>>>
      as={Link}
      classes={BUTTON_LINK_CLASSES}
      ref={ref}
      {...props}
    ></ButtonLike>
  );
});

export default ButtonLink;
