import { forwardRef, type FC } from 'react';
import classNames from 'classnames';

import classes from './EllipsisButton.module.css';
import {
  Button,
  type ButtonProps,
  BUTTON_VARIANT,
} from 'src/components/Button/Button2';
import { IconDotsVertical } from 'src/assets/svg';

export interface EllipsisButtonProps
  extends Omit<ButtonProps, 'variant' | 'icon'> {
  className?: string;
}

const EllipsisButton: FC<EllipsisButtonProps> = forwardRef<
  HTMLButtonElement,
  EllipsisButtonProps
>((props, ref) => {
  const { className, ...rest } = props;

  return (
    <Button
      {...rest}
      className={classNames(classes.root, className)}
      icon={IconDotsVertical}
      ref={ref}
      variant={BUTTON_VARIANT.text}
    ></Button>
  );
});

export default EllipsisButton;
