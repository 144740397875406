import { useCallback } from 'react';
import type { FieldValues } from 'react-hook-form';

import type { UseFormControllerReturn } from 'src/components/Form/Form';
import type { SyntheticValidatorResult, KeyType } from './types';

export type UseManualValidationResultSetterReturn = (
  result?: SyntheticValidatorResult | null,
  shouldFocus?: boolean
) => void;

export function useManualValidationResultSetter<T extends FieldValues>(
  controller: UseFormControllerReturn<T>,
  key: KeyType<T>
): UseManualValidationResultSetterReturn {
  const { clearErrors, setError } = controller;

  // возвращает стабильную функцию
  return useCallback(
    (result?: SyntheticValidatorResult | null, shouldFocus?: boolean) => {
      if (!result || result.result) {
        clearErrors(key);
      } else {
        setError(
          key,
          { type: result.type },
          { shouldFocus: Boolean(shouldFocus) }
        );
      }
    },
    [
      key,
      clearErrors,
      setError,
    ]
  );
}
