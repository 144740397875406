import classNames from 'classnames';
import { memo, type FC, type ReactNode } from 'react';

import { type ValueOf } from 'src/types/utils';

import classes from './RoleChip.module.css';

export const ROLE_CHIP_SIZE_VARIANT = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export type RoleChipSizeVariant = ValueOf<typeof ROLE_CHIP_SIZE_VARIANT>;

export interface RoleChipClasses {
  dot: string;
  label: string;
  root: string;
}

export interface RoleChipProps {
  children?: ReactNode;
  className?: string;
  classes?: Partial<RoleChipClasses>;
  sizeVariant?: RoleChipSizeVariant;
}

const RoleChip: FC<RoleChipProps> = (props) => {
  const { children, className, classes: classesProp = {}, sizeVariant } = props;

  const sizeVariantClassName =
    sizeVariant === ROLE_CHIP_SIZE_VARIANT.large
      ? classes.rootLarge
      : sizeVariant === ROLE_CHIP_SIZE_VARIANT.small
      ? classes.rootSmall
      : null;

  return (
    <div
      className={classNames(
        classes.root,
        sizeVariantClassName,
        className,
        classesProp.root
      )}
    >
      <div className={classes.dotWrapper}>
        <div className={classNames(classes.dot, classesProp.dot)}></div>
      </div>
      <div className={classes.label}>{children}</div>
    </div>
  );
};

export default RoleChip;
