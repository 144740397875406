import type { FieldValues } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { FormItemWithTypedError } from 'src/components/Form/FormItemWithTypedError';
import { TextInput } from 'src/components/Input/TextInput';
import { StyledFormItemHint } from 'src/components/Styled/Forms/StyledFormItemHint';
import { USER_NAME_MAX_LENGTH } from 'src/constants/users';

import * as T from './types';
import classes from './UserNameFormItem.module.css';

const DEFAULT_INPUT_ID = 'user_form_name_input';
const DEFAULT_INPUT_NAME = 'name';

const UserNameFormItem = <T extends FieldValues = FieldValues>(
  props: T.UserNameFormItemProps<T>
) => {
  const {
    className,
    defaultValue,
    disabled,
    id = DEFAULT_INPUT_ID,
    name = DEFAULT_INPUT_NAME,
    maxLength = USER_NAME_MAX_LENGTH,
    validation,
  } = props;

  const { t } = useTranslation();

  return (
    <FormItemWithTypedError<HTMLInputElement, string, T>
      className={className}
      defaultValue={defaultValue}
      name={name}
      label={t('user.form.labelName')}
      labelFor={id}
      required
      slots={{
        hint: () => (
          <StyledFormItemHint>
            <Trans
              i18nKey="user.form.hintName"
              t={t}
            >
              <strong>0</strong>
              <strong>1</strong>
              <strong>2</strong>
            </Trans>
          </StyledFormItemHint>
        ),

        input: ({ field: { onChange, onBlur, value, ref }, valid }) => {
          return (
            <TextInput
              className={classes.input}
              colorVariant={valid ? 'secondary' : 'danger'}
              disabled={disabled}
              id={id}
              maxLength={maxLength}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={t('user.form.placeholderName')}
              ref={ref}
              type="text"
              sizeVariant="medium"
              value={value}
            />
          );
        },
      }}
      validation={validation}
    />
  );
};

export default UserNameFormItem;
