import { type UserListSort, USER_LIST_SORT_LIST } from 'src/constants/users';
import { type PageRequest } from 'src/services/api';
import { type Mapper } from 'src/utils/mappers/types';
import { type InternalPageRequest } from 'src/utils/pageable';

export const userListInternalPageRequestToPageRequestMapper: Mapper<
  InternalPageRequest<UserListSort>,
  PageRequest
> = (input) => {
  const { page, pageSize, sort: internalSort } = input;

  let sort: any;
  if (internalSort) {
    switch (internalSort) {
      case USER_LIST_SORT_LIST.ALPHABETICAL_ASC: {
        sort = 'name,asc';
        break;
      }

      case USER_LIST_SORT_LIST.ALPHABETICAL_DESC: {
        sort = 'name,desc';
        break;
      }
    }
  }

  return {
    page,
    size: pageSize,
    sort,
  };
};
