import { useState, useEffect, useCallback } from 'react';
import type { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type {
  UserId,
  InternalUserView,
  ApiUpdateUserForm,
} from 'src/services/api';
import { userApiService } from 'src/services/api';
import { PATHS } from 'src/constants/paths';
import { type UseRequestDataSupplier } from 'src/hooks/request/useRequest';
import { useOnlyLastRequest } from 'src/hooks/request/implmentations/useOnlyLastRequest';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { AsyncContainer } from 'src/components/AsyncContainer/AsyncContainer';
import { UserView } from 'src/containers/UserView';
import type {
  UserViewBlockHandler,
  UserViewEditHandler,
  UserViewResendHandler,
} from 'src/containers/UserView';
import {
  is404Error,
  maybeGetErrorDataFromBackend,
} from 'src/services/api/functions';
import { defaultErrorToast, toast } from 'src/components/Toast/functions';
import { useReadonlyLazyRef } from 'src/hooks';
import { UserNotFoundPlaceholder } from 'src/pages/UserPage/components/UserNotFoundPlaceholder';
import { UserErrorPlaceholder } from 'src/pages/UserPage/components/UserErrorPlaceholder';
import { UserSkeletonLoadingPlaceholder } from 'src/pages/UserPage/components/UserSkeletonLoadingPlaceholder';

import classes from './UserPage.module.css';
import { errorDataToDisplayErrorData } from './functions';
import { ToastErrorContent } from 'src/components/Toast/ToastErrorContent';

type DataType = InternalUserView | null;
type IdType = UserId | undefined;

const UserPage: FC = () => {
  const { [PATHS.user.param]: id } = useParams();
  const { t } = useTranslation();

  const [
    data,
    setData,
  ] = useState<DataType>(null);

  const dataSupplier: UseRequestDataSupplier<DataType, IdType> = useCallback(
    async (id) => {
      if (id) {
        try {
          const { data } = await userApiService.getUser(id);
          return data;
        } catch (e: any) {
          if (is404Error(e)) {
            // ловим 404 ошибку
            return null;
          } else {
            throw e;
          }
        }
      }

      return null;
    },
    []
  );

  // через dataSetter все делается в едином рендере
  const { isLoading, isError, getData } = useOnlyLastRequest<DataType, IdType>({
    dataSetter: setData,
    dataSupplier,
  });

  useEffect(() => {
    getData(id);
  }, [
    getData,
    id,
  ]);

  const retry = () => {
    getData(id);
  };

  const hasData = Boolean(data);

  const processError = useReadonlyLazyRef(() => (e: any) => {
    const errorData = maybeGetErrorDataFromBackend(e);
    if (errorData.status !== 401) {
      defaultErrorToast(errorDataToDisplayErrorData(errorData, t));
    }
  });

  const onBlock: UserViewBlockHandler | undefined = id
    ? async () => {
        try {
          const response = await userApiService.blockUser(id);
          setData(response.data);
        } catch (e) {
          processError(e);
          throw e;
        }
      }
    : undefined;

  const onUnblock: UserViewBlockHandler | undefined = id
    ? async () => {
        try {
          const response = await userApiService.unblockUser(id);
          setData(response.data);
        } catch (e) {
          processError(e);
        }
      }
    : undefined;

  const onEdit: UserViewEditHandler | undefined = id
    ? async (data) => {
        try {
          const form: ApiUpdateUserForm = {
            avatar: data.avatar,
            email: data.email,
            name: data.name,
            role: {
              id: data.role,
            },
          };

          const response = await userApiService.editUser(id, form);
          setData(response.data);
        } catch (e) {
          processError(e);
          throw e;
        }
      }
    : undefined;

  const onResend: UserViewResendHandler | undefined = id
    ? async () => {
        try {
          const response = await userApiService.resendTokenMail(id);
          const {
            data: { sendMail },
          } = response;

          if (sendMail) {
            toast(
              <ToastErrorContent title={t('user.dialog.resendOkTitle')} />,
              {
                type: 'success',
              }
            );
          } else {
            defaultErrorToast({
              title: t('user.dialog.resendErrorTitle'),
              message: t('user.dialog.resendErrorDescription'),
            });
          }
        } catch (e) {
          processError(e);
        }
      }
    : undefined;

  return (
    <ContentWrapper className={classes.root}>
      <AsyncContainer
        className={classes.content}
        hasData={hasData}
        isError={isError}
        isRequesting={isLoading}
        slots={{
          content: hasData ? (
            <UserView
              data={data!}
              onBlock={onBlock}
              onEdit={onEdit}
              onResend={onResend}
              onUnblock={onUnblock}
            />
          ) : null,
          errorPlaceholder: <UserErrorPlaceholder onRetry={retry} />,
          loadingPlaceholder: <UserSkeletonLoadingPlaceholder />,
          noDataPlaceholder: <UserNotFoundPlaceholder />,
        }}
      ></AsyncContainer>
    </ContentWrapper>
  );
};

export default UserPage;
