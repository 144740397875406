import type { ReactElement, ElementType, ReactNode, ForwardedRef } from 'react';
import { forwardRef } from 'react';
import classNames, { type Argument as ClassNamesArgument } from 'classnames';

import classes from './ButtonLike.module.css';
import { type ReactSVGComponent } from 'src/types/svg';
import { type ValueOf } from 'src/types/utils';

export const BUTTON_VARIANT = {
  contained: 'contained',
  outlined: 'outlined',
  // пока пустой
  text: 'text',
  // дефолтный, никакие стили по цветам и виду не применяются,
  // только размеры
  blank: 'blank',
} as const;

export type ButtonVariant = ValueOf<typeof BUTTON_VARIANT>;

export const BUTTON_COLOR_VARIANT = {
  // красные
  danger: 'danger',
  // синие оттенки, дефолтный вариант
  primary: 'primary',
  primaryLight: 'primary-light',
  // зеленые
  secondary: 'secondary',
  secondaryLight: 'secondary-light',
} as const;

export type ButtonColorVariant = ValueOf<typeof BUTTON_COLOR_VARIANT>;

export const BUTTON_SIZE_VARIANT = {
  smaller: 'smaller',
  small: 'small',
  // дефолтный вариант
  medium: 'medium',
  large: 'large',
} as const;

export type ButtonSizeVariant = ValueOf<typeof BUTTON_SIZE_VARIANT>;

export interface ButtonLikeClasses {
  colorVariantDanger: string;
  colorVariantPrimary: string;
  colorVariantPrimaryLight: string;
  colorVariantSecondary: string;
  colorVariantSecondaryLight: string;
  icon: string;
  iconed: string;
  root: string;
  variantContained: string;
  variantOutlined: string;
  variantText: string;
}

export interface ButtonLikeBaseProps {
  children?: ReactNode;
  className?: string;
  classes?: Partial<ButtonLikeClasses>;

  /**
   * Цветовая тема, определяет цвет фона, заливки и шрифта.
   */
  colorVariant?: ButtonColorVariant;

  /**
   * Компонент иконки.
   * Если указан, использует спецальные стили для кнопок с иконками.
   * Рендерит children после иконки, так что не рекомендуется
   * указывать эту опцию вместе с children.
   */
  icon?: ReactSVGComponent;

  prefixIcon?: ReactSVGComponent;
  sizeVariant?: ButtonSizeVariant;
  suffixIcon?: ReactSVGComponent;
  uppercased?: boolean;

  /**
   * Тема внешнего вида
   */
  variant?: ButtonVariant;
}

export type ButtonLikeComponentProps<P> = {
  as: ElementType<P>;
} & ButtonLikeBaseProps &
  P;

const ButtonLike = <P,>(
  props: ButtonLikeComponentProps<P>,
  ref: ForwardedRef<P>
): ReactElement<any, any> | null => {
  const {
    as,
    children,
    className: classNameProp,
    classes: classesProp = {},
    colorVariant = BUTTON_COLOR_VARIANT.primary,
    icon: IconComponent,
    prefixIcon: PrefixIconComponent,
    sizeVariant = BUTTON_SIZE_VARIANT.medium,
    suffixIcon: SuffixIconComponent,
    variant = BUTTON_VARIANT.blank,
    uppercased = false,
    ...rest
  } = props;

  const Component: ElementType = as;

  const variantClassName =
    variant === BUTTON_VARIANT.blank
      ? null
      : variant === BUTTON_VARIANT.outlined
      ? [
          classes.outlined,
          classesProp.variantOutlined,
        ]
      : variant === BUTTON_VARIANT.text
      ? [
          classes.text,
          classesProp.variantText,
        ]
      : [
          classes.contained,
          classesProp.variantContained,
        ];

  let colorVariantClassName: ClassNamesArgument | null;
  if (variant !== BUTTON_VARIANT.blank) {
    switch (colorVariant) {
      case BUTTON_COLOR_VARIANT.danger: {
        colorVariantClassName = [
          classes.danger,
          classesProp.colorVariantDanger,
        ];
        break;
      }

      case BUTTON_COLOR_VARIANT.secondary: {
        colorVariantClassName = [
          classes.secondary,
          classesProp.colorVariantSecondary,
        ];
        break;
      }

      case BUTTON_COLOR_VARIANT.secondaryLight: {
        colorVariantClassName = [
          classes.secondaryLight,
          classesProp.colorVariantSecondaryLight,
        ];
        break;
      }

      case BUTTON_COLOR_VARIANT.primaryLight: {
        colorVariantClassName = [
          classes.primaryLight,
          classesProp.colorVariantPrimaryLight,
        ];
        break;
      }

      case BUTTON_COLOR_VARIANT.primary:
      default: {
        colorVariantClassName = [
          classes.primary,
          classesProp.colorVariantPrimary,
        ];
        break;
      }
    }
  }

  const sizeVariantClassName =
    sizeVariant === BUTTON_SIZE_VARIANT.smaller
      ? classes.smaller
      : sizeVariant === BUTTON_SIZE_VARIANT.small
      ? classes.small
      : sizeVariant === BUTTON_SIZE_VARIANT.large
      ? classes.large
      : classes.medium;

  const commonIconedClassName =
    IconComponent || ((PrefixIconComponent || SuffixIconComponent) && !children)
      ? [
          classes.iconed,
          classesProp.iconed,
        ]
      : null;
  const iconedClassName = PrefixIconComponent
    ? classes.hasPrefix
    : SuffixIconComponent
    ? classes.hasSuffix
    : null;

  const iconClassName = [
    classes.icon,
    classesProp.icon,
  ];

  const className = classNames(
    classes.root,
    classesProp.root,
    variantClassName,
    colorVariantClassName,
    sizeVariantClassName,
    commonIconedClassName,
    iconedClassName,
    uppercased && classes.uppercased,
    classNameProp
  );

  return (
    <Component
      className={className}
      ref={ref}
      {...rest}
    >
      {IconComponent ? (
        <IconComponent className={classNames(iconClassName)} />
      ) : (
        <>
          {PrefixIconComponent && (
            <PrefixIconComponent
              className={classNames(iconClassName, classes.prefix)}
            />
          )}
          {children && <div className={classes.wrapper}>{children}</div>}
          {SuffixIconComponent && (
            <SuffixIconComponent
              className={classNames(iconClassName, classes.suffix)}
            />
          )}
        </>
      )}
    </Component>
  );
};

export default forwardRef(ButtonLike) as <T>(
  props: ButtonLikeComponentProps<T>,
  ref: ForwardedRef<T>
) => ReturnType<typeof ButtonLike<T>>;
