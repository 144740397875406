import classNames from 'classnames';
import { type FC } from 'react';

import { Link, type LinkProps } from 'react-router-dom';
import type { ValueOf } from 'src/types/utils';

import classes from './SimpleLink.module.css';

export const SIMPLE_LINK_SIZE_VARIANT = {
  smaller: 'smaller',
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export type SimpleLinkSizeVariant = ValueOf<typeof SIMPLE_LINK_SIZE_VARIANT>;

export interface SimpleLinkClasses {
  link: string;
  root: string;
  rootSmaller: string;
  rootSmall: string;
  rootLarge: string;
}

export interface BaseSimpleProps {
  classes?: Partial<SimpleLinkClasses>;
  noWrap?: boolean;
  sizeVariant?: SimpleLinkSizeVariant;
}

export interface SimpleLinkProps extends LinkProps, BaseSimpleProps {
  className?: string;
}

const SimpleLink: FC<SimpleLinkProps> = (props) => {
  const {
    className,
    classes: classesProp = {},
    noWrap,
    sizeVariant,
    ...rest
  } = props;

  const sizeVariantClassName =
    sizeVariant === SIMPLE_LINK_SIZE_VARIANT.small
      ? [
          classes.small,
          classesProp.rootSmall,
        ]
      : sizeVariant === SIMPLE_LINK_SIZE_VARIANT.large
      ? [
          classes.large,
          classesProp.rootLarge,
        ]
      : sizeVariant === SIMPLE_LINK_SIZE_VARIANT.smaller
      ? [
          classes.smaller,
          classesProp.rootSmaller,
        ]
      : null;

  return (
    <span
      className={classNames(
        classes.root,
        sizeVariantClassName,
        noWrap && classes.noWrap,
        classesProp.root,
        className
      )}
    >
      <Link
        className={classNames(classes.link, classesProp.link)}
        {...rest}
      />
    </span>
  );
};

export default SimpleLink;
