import type { RequestDisplayErrorData } from 'src/types/common';
import { maybeGetErrorDataFromBackend } from 'src/services/api/functions';

export function defaultErrorParser(e: any): RequestDisplayErrorData {
  const errorData = maybeGetErrorDataFromBackend(e);

  return {
    message: errorData.message,
    status: errorData.status,
  };
}
