import { type FromURLMapper, type ToURLMapper } from '../types';

export function pageFromURLMapperFactory(
  searchParam: string,
  defaultValue: number
): FromURLMapper<number> {
  return (input) => {
    const param = input.get(searchParam);
    const parsed = param ? Number.parseInt(param, 10) : null;
    const result: number =
      parsed !== null && !Number.isNaN(parsed) && parsed > defaultValue
        ? parsed
        : defaultValue;

    return result;
  };
}

export function pageToURLMapperFactory(
  searchParam: string,
  defaultValue: number
): ToURLMapper<number> {
  return (input) => {
    const { value, accumulator } = input;

    accumulator.delete(searchParam);
    if (typeof value === 'number' && value !== defaultValue) {
      accumulator.set(searchParam, value.toString(10));
    }

    return accumulator;
  };
}
