import { type FromURLMapper, type ToURLMapper } from '../types';

export const pageSizeFromURLMapperFactory =
  (
    searchParam: string,
    defaultValue: number,
    validValues: number[]
  ): FromURLMapper<number> =>
  (input) => {
    const param = input.get(searchParam);
    const parsed = param ? Number.parseInt(param, 10) : null;
    const result: number =
      parsed !== null && !Number.isNaN(parsed) && validValues.includes(parsed)
        ? parsed
        : defaultValue;

    return result;
  };

export const pageSizeToURLMapperFactory =
  (searchParam: string, defaultValue: number): ToURLMapper<number> =>
  (input) => {
    const { value, accumulator } = input;

    accumulator.delete(searchParam);
    if (typeof value === 'number' && value !== defaultValue) {
      accumulator.set(searchParam, value.toString(10));
    }

    return accumulator;
  };
