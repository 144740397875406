import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IconLink } from 'src/assets/svg';
import { Attribute } from 'src/components/AttributeList';
import { SimpleExternalLink } from 'src/components/Link/SimpleLink';
import { RoomAttributeIconDescriptor } from 'src/containers/Room/RoomAttributeIconDescriptor';
import { RoomLinkAttributeValue } from 'src/containers/Room/RoomLinkAttributeValue';
import { type RoomExternalLink } from 'src/services/api';

export interface RoomLinkAttributeValueProps {
  className?: string;
  externalLink?: RoomExternalLink;
  sizeVariant?: 'small' | 'medium';
}

const RoomLinkAttribute: FC<RoomLinkAttributeValueProps> = (props) => {
  const { className, externalLink, sizeVariant } = props;
  const { t } = useTranslation();

  const { link, expires } = externalLink || {};

  return link ? (
    <Attribute
      className={className}
      descriptor={
        <RoomAttributeIconDescriptor
          icon={IconLink}
          sizeVariant={sizeVariant}
        />
      }
    >
      <RoomLinkAttributeValue hint={t('room.link.externalLink')}>
        <SimpleExternalLink
          href={link}
          noWrap
          rel="noopener"
          sizeVariant={sizeVariant === 'small' ? 'smaller' : 'small'}
        >
          {link}
        </SimpleExternalLink>
      </RoomLinkAttributeValue>
    </Attribute>
  ) : null;
};

export default RoomLinkAttribute;
