import classNames from 'classnames';
import {
  type FC,
  type ReactNode,
  type ReactElement,
  useCallback,
  type MouseEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';
import type { FieldValues } from 'react-hook-form';

import {
  Button,
  type ButtonColorVariant,
  BUTTON_SIZE_VARIANT,
  BUTTON_VARIANT,
  BUTTON_COLOR_VARIANT,
} from 'src/components/Button/Button2';
import { Form } from 'src/components/Form/Form';
import type {
  FormProps,
  FormSubmitHandler,
  UseFormControllerReturn,
} from 'src/components/Form/Form';
import { type ValueOf } from 'src/types/utils';

import classes from './FilterForm.module.css';

export const FILTER_FORM_COLOR_VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
} as const;

export type FilterFormColorVariant = ValueOf<typeof FILTER_FORM_COLOR_VARIANT>;

export type FilterFormSubmitHandler<T extends FieldValues = FieldValues> =
  FormSubmitHandler<T>;

export interface FilterFormProps<T extends FieldValues = FieldValues> {
  children?: ReactNode;
  className?: string;
  colorVariant?: FilterFormColorVariant;
  formController: UseFormControllerReturn<T>;
  onReset: MouseEventHandler<HTMLButtonElement>;
  onSubmit: FilterFormSubmitHandler<T>;
}

const FilterForm = <T extends FieldValues = FieldValues>(
  props: FilterFormProps<T>
): ReactElement<any, any> | null => {
  const {
    children,
    className,
    colorVariant = FILTER_FORM_COLOR_VARIANT.primary,
    formController,
    onReset,
    onSubmit,
  } = props;

  const { t } = useTranslation();

  const { handleSubmit } = formController;

  const buttonColorVariant: ButtonColorVariant | undefined =
    colorVariant === FILTER_FORM_COLOR_VARIANT.secondary
      ? BUTTON_COLOR_VARIANT.secondary
      : BUTTON_COLOR_VARIANT.primary;

  return (
    <div className={classNames(classes.root, className)}>
      <Form<T>
        controller={formController}
        onSubmit={handleSubmit(onSubmit)}
      >
        {children}
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            colorVariant={buttonColorVariant}
            onClick={onReset}
            sizeVariant={BUTTON_SIZE_VARIANT.small}
            variant={BUTTON_VARIANT.outlined}
          >
            {t('filters.actionReset')}
          </Button>
          <Button
            className={classes.button}
            colorVariant={buttonColorVariant}
            type="submit"
            sizeVariant={BUTTON_SIZE_VARIANT.small}
            variant={BUTTON_VARIANT.contained}
          >
            {t('filters.actionSubmit')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FilterForm;
