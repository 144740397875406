import { ApiService } from '../ApiService';
import { type ApiBackendResponse } from '../ApiBackend';
import type {
  ApiChangePasswordForm,
  ApiPersonalForm,
  InternalPersonalView,
} from 'src/services/api/types';
import {
  type ApiPersonalView,
  type ApiSetPasswordForm,
  type ApiCheckTokenResult,
} from 'src/services/api/types';
import { mapToInternalType } from 'src/services/api/functions';
import { userViewToInternalTypeMapper } from 'src/services/api/mappers';

export const API_PASSWORD_TOKEN_VERIFIER_VALUE = {
  timeout: 'REQUEST_TIMEOUT',
  invalid: 'BAD_REQUEST',
} as const;

export class AuthApiService extends ApiService {
  async authenticate(
    username: string,
    password: string
  ): Promise<ApiBackendResponse<void>> {
    return this.backend.post<void>('/authenticate', { username, password });
  }

  async logout(): Promise<ApiBackendResponse<void>> {
    return this.backend.post<void>('/logout');
  }

  async changePassword(
    form: ApiChangePasswordForm
  ): Promise<ApiBackendResponse<void>> {
    return this.backend.post<void>('/personal/changePassword', form);
  }

  async getProfileData(): Promise<ApiBackendResponse<InternalPersonalView>> {
    const response = await this.backend.get<ApiPersonalView>('/personal');
    return mapToInternalType<ApiPersonalView, InternalPersonalView>(
      response,
      userViewToInternalTypeMapper
    );
    /*
    this.backend.get<UserView>('/personal');
    return new Promise<ApiBackendResponse<UserView>>((resolve, reject) => {
      setTimeout(() => {
        resolve({ data: TEST_USER } as ApiBackendResponse<UserView>);
        // reject('getCurrentUserInfo reject');
      }, 3000);
    });
    */
  }

  async forgetPassword(label: string): Promise<ApiBackendResponse<void>> {
    return this.backend.post<void>(`/personal/forgotPassword/${label}`);
  }

  async setPassword(
    token: string,
    form: ApiSetPasswordForm
  ): Promise<ApiBackendResponse<void>> {
    return this.backend.post<void>('/personal/setPassword', form, {
      params: {
        token,
      },
    });
  }

  async updateProfileData(
    form: ApiPersonalForm
  ): Promise<ApiBackendResponse<InternalPersonalView>> {
    const response = await this.backend.post<ApiPersonalView>(
      '/personal',
      form
    );
    return mapToInternalType<ApiPersonalView, InternalPersonalView>(
      response,
      userViewToInternalTypeMapper
    );
  }

  async verifyPasswordToken(
    token: string
  ): Promise<ApiBackendResponse<ApiCheckTokenResult>> {
    return this.backend.get<ApiCheckTokenResult>(`/check/token/${token}`);
  }
}
