import classNames from 'classnames';
import { type FC, type ReactNode } from 'react';

import classes from './Grid.module.css';

export interface GridProps {
  children?: ReactNode;
  className?: string;
}

const Grid: FC<GridProps> = (props) => {
  const { children, className } = props;

  return <div className={classNames(classes.root, className)}>{children}</div>;
};

export default Grid;
