import { useEffect, type FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContentWrapper } from 'src/components/ContentWrapper';
import { PATHS } from 'src/constants/paths';
import { ProfileView } from 'src/containers/ProfileView';
import { useAuth } from 'src/contexts/auth';
import { type InternalUserView, type UserId } from 'src/services/api';

import classes from './ProfilePage.module.css';

const ProfilePage: FC = () => {
  const [
    { user },
    { getPersonalData: getUserData, logout },
  ] = useAuth();

  const navigate = useNavigate();

  // запускаем эффект один раз, чтобы обновить данные
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUserData();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onLogout = async () => {
    await logout();
    navigate(PATHS.login.path);
  };

  return user ? (
    <ContentWrapper className={classes.root}>
      <ProfileView
        data={user}
        onLogout={onLogout}
      ></ProfileView>
    </ContentWrapper>
  ) : null;
};

export default ProfilePage;
