import { useCallback, useRef, type FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Avatar } from 'src/components/Avatar';
import { HeaderProfileCard } from 'src/containers/HeaderProfileCard';
import { Menu, MenuItem } from 'src/components/Menu';
import { useDropdown } from 'src/components/Dropdown';
import type { InternalPersonalView } from 'src/services/api';
import { useAuthActions } from 'src/contexts/auth';
import { IconizedContent } from 'src/components/IconizedContent';
import { StyledLink } from 'src/components/StyledLink';
import { PATHS } from 'src/constants/paths';
import { IconLogout } from 'src/assets/svg';

import classes from './InternalHeaderProfileButton.module.css';
import { useNavigate } from 'react-router-dom';

export interface InternalHeaderProfileButtonProps {
  className?: string;
  user: InternalPersonalView;
}

const InternalHeaderProfileButton: FC<InternalHeaderProfileButtonProps> = (
  props
) => {
  const { t } = useTranslation();
  const { logout } = useAuthActions();
  const { className, user } = props;
  const { avatar, name } = user;

  const anchor = useRef<HTMLButtonElement>(null);
  const [
    open,
    close,
    opened,
  ] = useDropdown();

  const navigate = useNavigate();
  const onLogoutClick = useCallback(() => {
    const asyncHandler = async () => {
      await logout();
      close();
      navigate(PATHS.login.path);
    };

    asyncHandler();
  }, [
    logout,
    close,
  ]);

  return (
    <div className={classNames(classes.root, className)}>
      <button
        className={classes.button}
        onClick={open}
        ref={anchor}
      >
        <Avatar
          alt={name}
          className={classes.avatar}
          fallbackLabelSource={name}
          sizeVariant="medium"
          src={avatar}
        />
      </button>
      <Menu
        anchorEl={anchor.current}
        open={opened}
        onCloseRequested={close}
      >
        <HeaderProfileCard user={user} />
        <MenuItem
          className={classes.linkMenuItem}
          onClick={close}
        >
          <StyledLink
            to={PATHS.profile.path}
            variant="menuItem"
          >
            <IconizedContent keepIconSpace>
              {t('profileButton.profile')}
            </IconizedContent>
          </StyledLink>
        </MenuItem>
        <MenuItem onClick={onLogoutClick}>
          <IconizedContent
            classes={{ icon: classes.menuItemIcon }}
            icon={IconLogout}
          >
            {t('profileButton.logout')}
          </IconizedContent>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default InternalHeaderProfileButton;
