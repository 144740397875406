import { useState } from 'react';
import type { FC } from 'react';
import classNames from 'classnames';

import { NonAuthorizedHeader } from 'src/containers/Header/NonAuthorizedHeader';
import { ContentWrapper } from 'src/components/ContentWrapper';

import { useTranslation } from 'react-i18next';
import { ForgotPasswordForm } from 'src/pages/ForgotPasswordPage/components/ForgotPasswordForm';
import type { ForgotPasswordFormSuccessHandler } from 'src/pages/ForgotPasswordPage/components/ForgotPasswordForm';
import { FullscreenLayout } from 'src/containers/FullscreenLayout';
import { SimpleLink } from 'src/components/Link/SimpleLink';
import { PATHS } from 'src/constants/paths';

import passwordFormsClasses from 'src/styles/common/passwordForms.module.css';
import classes from './ForgotPasswordPage.module.css';

const STAGE_START_ID = 1;
const STAGE_SUCCESS_ID = 2;

const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation();

  const [
    stage,
    setStage,
  ] = useState<number>(STAGE_START_ID);

  const onSubmitSuccess: ForgotPasswordFormSuccessHandler = () => {
    setStage(STAGE_SUCCESS_ID);
  };

  return (
    <FullscreenLayout
      className={classes.root}
      classes={{ content: classes.mainLayoutContent }}
      header={<NonAuthorizedHeader loginButtonHidden />}
    >
      <div className={classes.content}>
        <ContentWrapper className={classes.contentInnerWrapper}>
          {stage === STAGE_START_ID && (
            <>
              <p
                className={classNames(
                  passwordFormsClasses.description,
                  classes.description
                )}
              >
                {t('forgotPasswordPage.descriptionMain')}
              </p>
              <div className={classes.formBlock}>
                <div className={classes.formBlockInnerWrapper}>
                  <ForgotPasswordForm onSuccess={onSubmitSuccess} />
                </div>
              </div>
            </>
          )}

          {stage === STAGE_SUCCESS_ID && (
            <>
              <p
                className={classNames(
                  passwordFormsClasses.description,
                  classes.description
                )}
              >
                {t('forgotPasswordPage.descriptionSuccess')}
              </p>
              <div className={classes.linkBlock}>
                <SimpleLink
                  className={passwordFormsClasses.link}
                  sizeVariant="small"
                  to={PATHS.login.path}
                >
                  {t('links.toLoginPage')}
                </SimpleLink>
              </div>
            </>
          )}
        </ContentWrapper>
      </div>
    </FullscreenLayout>
  );
};

export default ForgotPasswordPage;
