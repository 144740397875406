import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { IconPlus } from 'src/assets/svg';
import {
  ButtonLink,
  BUTTON_COLOR_VARIANT,
} from 'src/components/Button/ButtonLink';
import { PATHS } from 'src/constants/paths';

import linkClasses from 'src/styles/common/link.module.css';
import classes from './CreateUserButton.module.css';

export interface CreateUserButtonProps {
  className?: string;
}

const CreateUserButton: FC<CreateUserButtonProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();

  return (
    <ButtonLink
      className={classNames(
        linkClasses.createButtonLink,
        classes.root,
        className
      )}
      colorVariant={BUTTON_COLOR_VARIANT.secondary}
      rel="noopener"
      to={PATHS.newUser.path}
      variant="contained"
    >
      <div className={linkClasses.createButtonLinkContent}>
        {t('users.add')}
      </div>
    </ButtonLink>
  );
};

export default CreateUserButton;
