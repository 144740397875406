import classNames from 'classnames';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from 'src/components/Button/LoadingButton';
import { Form } from 'src/components/Form/Form';
import type { FormSubmitHandler } from 'src/components/Form/Form';
import type { FormItemValidationRule } from 'src/components/Form/FormItem';
import { stringRequiredValidatorFactory } from 'src/components/Form/validation/validators';
import { useFormController } from 'src/components/Form/Form/useFormController';
import { FormItemWithTypedError } from 'src/components/Form/FormItemWithTypedError';
import type { FormItemWithTypedErrorPayload } from 'src/components/Form/FormItemWithTypedError';
import { Input } from 'src/components/Input';
import { USER_PASSWORD_MAX_LENGTH } from 'src/constants/users';
import { authApiService } from 'src/services/api';
import { isValidFunction } from 'src/utils';
import { FormErrorBlock } from 'src/components/Form/FormErrorBlock';
import { defaultErrorParser } from 'src/components/Form/FormErrorBlock/functions';

import type * as T from './types';
import classes from './ForgotPasswordForm.module.css';
import passwordFormsClasses from 'src/styles/common/passwordForms.module.css';

const DEFAULT_FORM_DATA: Required<T.ForgotPasswordFormData> = {
  id: '',
};

const FORM_ID_INPUT_ID = 'forgot_password_id';

// обязательность
const requiredValidationRule: FormItemValidationRule<
  string,
  FormItemWithTypedErrorPayload
> = {
  validator: stringRequiredValidatorFactory(),
  payload: {
    tKey: 'form.validation.required',
  },
};

const ForgotPasswordForm: FC<T.ForgotPasswordFormProps> = (props) => {
  const { className, onSuccess: onSuccessProp } = props;
  const { t } = useTranslation();

  const controller = useFormController<T.ForgotPasswordFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: DEFAULT_FORM_DATA,
  });

  const { handleSubmit } = controller;

  const [
    disabled,
    setDisabled,
  ] = useState<boolean>(false);
  const [
    error,
    setError,
  ] = useState<any>(null);

  const onSubmitSuccess: FormSubmitHandler<T.ForgotPasswordFormData> = async (
    data,
    event
  ) => {
    setDisabled(true);
    setError(null);

    try {
      //await authApiService.forgetPassword(data.id);
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(authApiService.forgetPassword(data.id));
        }, 3000);
      });

      isValidFunction(onSuccessProp) && onSuccessProp();
    } catch (e) {
      setError(e);
    } finally {
      setDisabled(false);
    }
  };

  const onSubmit = handleSubmit(onSubmitSuccess);

  return (
    <Form<T.ForgotPasswordFormData>
      className={classNames(classes.root, className)}
      controller={controller}
      onSubmit={onSubmit}
    >
      <FormItemWithTypedError<
        HTMLInputElement,
        string,
        T.ForgotPasswordFormData
      >
        name="id"
        labelFor={FORM_ID_INPUT_ID}
        slots={{
          input: ({ field: { onChange, onBlur, value, ref }, valid }) => {
            return (
              <Input
                classes={{
                  input: passwordFormsClasses.input,
                  root: passwordFormsClasses.inputRoot,
                }}
                colorVariant={valid ? 'primary' : 'danger'}
                disabled={disabled}
                id={FORM_ID_INPUT_ID}
                maxLength={USER_PASSWORD_MAX_LENGTH}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={t('forgotPasswordPage.form.labelId')}
                ref={ref}
                type="text"
                value={value}
              />
            );
          },
        }}
        validation={{
          required: requiredValidationRule,
        }}
      />

      <div className={classes.buttons}>
        <LoadingButton
          className={passwordFormsClasses.submitButton}
          colorVariant="primary"
          disabled={disabled}
          loading={disabled}
          variant="contained"
          type="submit"
          uppercased
        >
          {t('forgotPasswordPage.form.submit')}
        </LoadingButton>
      </div>
      {error && (
        <FormErrorBlock
          className={passwordFormsClasses.errorBlock}
          {...defaultErrorParser(error)}
        />
      )}
    </Form>
  );
};

export default ForgotPasswordForm;
