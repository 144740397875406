import { type FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from 'src/containers/Header';
import { Background } from 'src/containers/Layout/Background';

import classes from './MainPage.module.css';

const MainPage: FC = () => {
  return (
    <div className={classes.root}>
      <Background />
      <div className={classes.wrapper}>
        <Header />
        <div className={classes.layout}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
