import type { MouseEvent } from 'react';
import { type FC, type ReactNode } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Avatar } from 'src/components/Avatar';
import type { InternalUserView } from 'src/services/api';
import { INTERNAL_USER_PERMISSION } from 'src/services/api';

import classes from './ProfileView.module.css';
import { UserRoleChip } from 'src/components/RoleChip/UserRoleChip';
import { UserLoginAttribute } from 'src/containers/User/UserLoginAttribute';
import { UserEmailAttribute } from 'src/containers/User/UserEmailAttribute';
import { Button } from 'src/components/Button/Button2';

import { StyledBlock } from 'src/components/Styled/Block/StyledBlock';
import { useSmallMediaQuery } from 'src/hooks';
import { FlexContainer } from 'src/components/FlexContainer';
import { UserBlockedChip } from 'src/containers/User/UserBlockedChip';
import { IconAlertCircle, IconLock, IconPen } from 'src/assets/svg';
import { ProfileEditButton } from 'src/containers/Profile/ProfileEditButton';
import { ButtonLink } from 'src/components/Button/ButtonLink';
import { PATHS } from 'src/constants/paths';

export interface UserViewEditForm {
  avatar: string;
  email: string;
  name: string;
  role: number;
}

export type ProfileViewResendHandler = (
  event: MouseEvent<HTMLButtonElement>
) => Promise<void>;

export type ProfileViewLogoutHandler = (
  event: MouseEvent<HTMLButtonElement>
) => Promise<void>;

export type ProfileViewEditHandler = (
  data: UserViewEditForm,
  event: MouseEvent<HTMLButtonElement>
) => void;

export interface ProfileViewProps {
  className?: string;
  data: InternalUserView;
  onEdit?: ProfileViewEditHandler;
  onLogout?: ProfileViewLogoutHandler;
  onResend?: ProfileViewResendHandler;
}

const ProfileView: FC<ProfileViewProps> = (props) => {
  const {
    className,
    data: { id, avatar, email, name, login, locked, role, permissions },
    onEdit: onEditProp,
    onLogout,
  } = props;

  const { t } = useTranslation();
  const isSmall = useSmallMediaQuery();

  const blockedChip = (
    <UserBlockedChip
      className={classes.blockedChip}
      value={locked}
    />
  );

  const attributes: ReactNode[] = [];
  // login
  if (login) {
    attributes.push(
      <UserLoginAttribute
        className={classes.attribute}
        key="login"
        sizeVariant="medium"
        value={login}
      />
    );
  }

  // email
  if (email) {
    attributes.push(
      <UserEmailAttribute
        className={classes.attribute}
        key="link"
        email={email}
        sizeVariant="medium"
      />
    );
  }

  // basic actions
  const actions: ReactNode[] = [
    // logout
    <Button
      colorVariant="primary"
      key="logout"
      onClick={onLogout}
      sizeVariant="small"
      variant="outlined"
    >
      {t('user.actions.logout')}
    </Button>,
    <ButtonLink
      colorVariant="primary"
      key="password"
      sizeVariant="small"
      to={PATHS.changePassword.path}
      variant="contained"
    >
      {t('user.actions.changePassword')}
    </ButtonLink>,
    // edit
    <ProfileEditButton key="edit" />,
    /*
    <Button
      colorVariant="primary"
      key="edit"
      prefixIcon={IconPen}
      sizeVariant="small"
      variant="contained"
    >
      {t('user.actions.edit')}
    </Button>,
    */
  ];

  return (
    <div className={classNames(classes.root, className)}>
      <StyledBlock className={classes.styledBlock}>
        <div className={classes.wrapper}>
          <div className={classes.background} />
          <div className={classes.contentWrapper}>
            <div className={classes.content}>
              <div className={classes.avatarContainer}>
                <Avatar
                  className={classes.avatar}
                  fallbackLabelSource={name}
                  sizeVariant={isSmall ? 'xlarge' : 'larger'}
                  src={avatar}
                  variant="rounded"
                />
              </div>
              <div className={classes.dataContainer}>
                <h1 className={classes.name}>{name}</h1>
                <div className={classes.chipContainer}>
                  <UserRoleChip
                    className={classes.roleChip}
                    role={role}
                    sizeVariant="medium"
                  />
                  {blockedChip}
                </div>
                {attributes.length > 0 && (
                  <div className={classes.atributeContainer}>{attributes}</div>
                )}
              </div>
            </div>
            {actions.length > 0 && (
              <FlexContainer
                className={classes.actions}
                classes={{ container: classes.actionsFlexContainer }}
              >
                {actions}
              </FlexContainer>
            )}
          </div>
        </div>

        {permissions.includes(
          INTERNAL_USER_PERMISSION.RESEND_PASSWORD_MAIL
        ) && (
          <div className={classes.wrapper}>
            <div className={classes.resendHintContainer}>
              <IconAlertCircle className={classes.resendHintIcon} />
              <div className={classes.resendHintText}>
                {t('user.dialog.resendHint')}
              </div>
            </div>

            <div className={classNames(classes.actions, classes.resendActions)}>
              <Button
                colorVariant="secondary"
                sizeVariant="small"
                variant="outlined"
              >
                {t('user.actions.resend')}
              </Button>
            </div>
          </div>
        )}
      </StyledBlock>
    </div>
  );
};

export default ProfileView;
