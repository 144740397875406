import classNames from 'classnames';
import { type FC, type ReactNode } from 'react';
import type { ReactSVGComponent } from 'src/types/svg';

import classes from './IconizedContent.module.css';

export interface IconizedContentClasses {
  icon: string;
  label: string;
}

export interface IconizedContentProps {
  children?: ReactNode;
  className?: string;
  classes?: Partial<IconizedContentClasses>;
  color?: string;
  icon?: ReactSVGComponent | null;
  keepIconSpace?: boolean;
  prefix?: ReactNode;
}

export const IconizedContent: FC<IconizedContentProps> = (props) => {
  const {
    children,
    className,
    classes: classesProp = {},
    color,
    icon: IconComponent,
    keepIconSpace,
    prefix,
  } = props;

  return (
    <div
      className={classNames(
        classes.root,
        !IconComponent && !prefix && classes.noIcon,
        keepIconSpace && classes.keepIconSpace,
        className
      )}
      style={{ color: color }}
    >
      {IconComponent ? (
        <IconComponent
          className={classNames(classes.icon, classesProp.icon)}
          style={{ fill: color }}
        />
      ) : prefix ? (
        <div className={classNames(classes.icon, classesProp.icon)}>
          {prefix}
        </div>
      ) : null}
      {children ? (
        <div className={classNames(classes.label, classesProp.label)}>
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default IconizedContent;
