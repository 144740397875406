import type { ReactElement, ReactNode } from 'react';
import type { FieldValues } from 'react-hook-form';

import { FormItem } from 'src/components/Form/FormItem';
import type { FormItemRender } from 'src/components/Form/FormItem';
import { FormItemTypedErrorBlockContent } from 'src/components/Form/FormItemTypedErrorBlockContent';

import * as T from './types';

const FormItemWithTypedError = <
  E extends Element = Element,
  V = any,
  T extends FieldValues = FieldValues
>(
  props: T.FormItemWithTypedErrorProps<E, V, T>
): ReactElement<any, any> | null => {
  const { slots, ...rest } = props;

  const errorRender: FormItemRender<
    E,
    V,
    T,
    T.FormItemWithTypedErrorPayload
  > = (renderProps) => {
    const { payload } = renderProps.validation || {};

    return <FormItemTypedErrorBlockContent<E, V, T> payload={payload} />;
  };

  return (
    <FormItem<E, V, T, T.FormItemWithTypedErrorPayload>
      {...rest}
      slots={{ ...slots, error: errorRender }}
    />
  );
};

export default FormItemWithTypedError;
