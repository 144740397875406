import { type FC } from 'react';

import { IconAccount } from 'src/assets/svg';
import {
  ATTRIBUTE_SIZE_VARIANT,
  type AttributeSizeVariant,
} from 'src/components/AttributeList';
import { TextAttribute } from 'src/components/AttributeList/TextAttribute';
import {
  UserAttributeIconDescriptor,
  type UserAttributeIconDescriptorSizeVariant,
  USER_ATTRIBUTE_ICON_DESCRIPTOR_SIZE_VARIANT,
} from 'src/containers/User/UserAttributeIconDescriptor';

export interface UserLoginAttributeProps {
  className?: string;
  sizeVariant?: AttributeSizeVariant;
  value?: string;
}

const UserLoginAttribute: FC<UserLoginAttributeProps> = (props) => {
  const {
    className,
    sizeVariant = ATTRIBUTE_SIZE_VARIANT.small,
    value,
  } = props;

  const iconSizeVariant: UserAttributeIconDescriptorSizeVariant =
    sizeVariant === ATTRIBUTE_SIZE_VARIANT.small
      ? USER_ATTRIBUTE_ICON_DESCRIPTOR_SIZE_VARIANT.small
      : USER_ATTRIBUTE_ICON_DESCRIPTOR_SIZE_VARIANT.medium;

  return value ? (
    <TextAttribute
      className={className}
      descriptor={
        <UserAttributeIconDescriptor
          icon={IconAccount}
          sizeVariant={iconSizeVariant}
        />
      }
      noWrap
      sizeVariant={sizeVariant}
      value={value}
    />
  ) : null;
};

export default UserLoginAttribute;
