import { ApiBackend } from './ApiBackend';

export class ApiService {
  private _backend: ApiBackend;

  constructor(backend: ApiBackend) {
    this._backend = backend;
  }

  get backend(): ApiBackend {
    return this._backend;
  }
}
