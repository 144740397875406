import { type ReactSVGComponent } from 'src/types/svg';
import { IconFlagRussia, IconFlagUK } from 'src/assets/svg';

export interface Language {
  locale: string;
  name: string;
  icon: ReactSVGComponent;
}

const languages: readonly Language[] = [
  { locale: 'ru', name: 'Русский', icon: IconFlagRussia },
  { locale: 'en', name: 'English', icon: IconFlagUK },
] as const;

export default languages;
