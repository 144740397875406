import { type FC } from 'react';
import { Link, type LinkProps } from 'react-router-dom';
import classNames from 'classnames';

import classes from './HeaderLink.module.css';

type HeaderLinkProps = LinkProps;

const HeaderLink: FC<HeaderLinkProps> = (props) => {
  const { className, ...rest } = props;

  return (
    <Link
      {...rest}
      className={classNames(classes.root, className)}
      rel="noopener"
    />
  );
};

export default HeaderLink;
