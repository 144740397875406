import { type FC, type ReactNode } from 'react';

import { IconMail } from 'src/assets/svg';
import {
  type AttributeSizeVariant,
  ATTRIBUTE_SIZE_VARIANT,
} from 'src/components/AttributeList';
import { TextAttribute } from 'src/components/AttributeList/TextAttribute';
import {
  UserAttributeIconDescriptor,
  type UserAttributeIconDescriptorSizeVariant,
  USER_ATTRIBUTE_ICON_DESCRIPTOR_SIZE_VARIANT,
} from 'src/containers/User/UserAttributeIconDescriptor';

export interface UserEmailAttributeProps {
  className?: string;
  email?: string;
  sizeVariant?: AttributeSizeVariant;
}

const UserEmailAttribute: FC<UserEmailAttributeProps> = (props) => {
  const {
    className,
    email,
    sizeVariant = ATTRIBUTE_SIZE_VARIANT.small,
  } = props;

  const iconSizeVariant: UserAttributeIconDescriptorSizeVariant =
    sizeVariant === ATTRIBUTE_SIZE_VARIANT.small
      ? USER_ATTRIBUTE_ICON_DESCRIPTOR_SIZE_VARIANT.small
      : USER_ATTRIBUTE_ICON_DESCRIPTOR_SIZE_VARIANT.medium;

  return email ? (
    <TextAttribute
      className={className}
      descriptor={
        <UserAttributeIconDescriptor
          icon={IconMail}
          sizeVariant={iconSizeVariant}
        />
      }
      noWrap
      sizeVariant={sizeVariant}
      value={email}
    />
  ) : null;
};

export default UserEmailAttribute;
