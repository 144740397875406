import {
  type Executor,
  type ExecutorEntry,
  type ExecutorEntryId,
  type ExecutorResult,
  type ExecutorJob,
  EXECUTOR_RESULT_STATUS,
} from './types';
// import { type ExecutorTask } from './internalTypes';
import { getEntryId } from './id';
import { DebuggableExecutor } from 'src/utils/executors/DebuggableExecutor';

interface InternalExecutorTask<T = any> {
  abortController?: AbortController;
  id: ExecutorEntryId;
  job: ExecutorJob<T>;
  jobPromise?: Promise<T>;
  resultPromise?: Promise<ExecutorResult<T>>;
  state: 'pending' | 'active' | 'canceled' | 'ok' | 'error';
}

export class OnlyLastExecutor extends DebuggableExecutor implements Executor {
  private _tasks: Array<InternalExecutorTask<any>> = [];

  private async _wrap<T>(task: InternalExecutorTask<T>) {
    const { id } = task;

    let result: ExecutorResult<T>;
    let originalResult: T;
    let hasError: boolean = false;
    let originalError: any;

    this.logEntryState(id, 'awaiting original request');
    try {
      // результат оригинального запроса
      originalResult = await task.jobPromise!;
      this.logEntryState(id, 'original request result:', originalResult);
    } catch (e) {
      hasError = true;
      originalError = e;
      this.logEntryState(id, 'original request error:', e);
    }

    if (
      task.state === 'canceled' ||
      (hasError &&
        originalError &&
        originalError instanceof DOMException &&
        originalError.name === 'AbortError')
    ) {
      // отмена
      result = {
        id,
        status: EXECUTOR_RESULT_STATUS.invalid,
        error: originalError,
      };
    } else if (hasError) {
      // ошибка
      result = {
        id,
        status: EXECUTOR_RESULT_STATUS.error,
        error: originalError,
      };
    } else {
      result = {
        id,
        status: EXECUTOR_RESULT_STATUS.ok,
        data: originalResult!,
      };
    }

    // теперь очистка идет уже на этапе создания задачи,
    // так как прошлые задачи инвалидируются и уже не имеют смысла
    /*
    // очистка
    this._clean(task);
    */

    const { status } = result;
    task.state =
      status === EXECUTOR_RESULT_STATUS.ok
        ? 'ok'
        : status === EXECUTOR_RESULT_STATUS.error
        ? 'error'
        : 'canceled';

    this.logEntryState(id, 'task state', task.state, ', result:', result);

    return result;
  }

  private _clean(task: InternalExecutorTask) {
    // делаем предположение, что задачи добавляются в нормальном порядке
    // и если какая-то задача завершилась, то все предыдущие задачи тоже автоматом завершены
    const index = this._tasks.indexOf(task);
    let deleted;
    if (index !== -1) {
      deleted = this._tasks.splice(0, index);
    }

    if (deleted && deleted.length > 0) {
      this.logEntryState(
        task.id,
        'cleanup',
        '(',
        deleted.map(({ id }) => id).join(', '),
        ')'
      );
    }
  }

  queue<T>(job: ExecutorJob<T>): ExecutorEntry<T> {
    const id: ExecutorEntryId = getEntryId();

    this.logEntryState(id, 'queued');
    const task: InternalExecutorTask<T> = {
      id,
      job,
      state: 'pending',
    };

    // не важно, когда будет запущена работа, инвалидация предыдущих идет сейчас
    this.invalidateAll();
    this._tasks.push(task);

    const execute: ExecutorEntry<T>['execute'] = async () => {
      return this._execute(task);
    };

    return {
      id,
      execute,
    };
  }

  invalidateAll(): void {
    this._tasks.forEach((task) => {
      task.state = 'canceled';
      const { abortController } = task;
      abortController && abortController.abort();
      this.logEntryState(task.id, 'invalidate');
      this.logEntryState(task.id, 'cleanup');
    });

    this._tasks = [];
  }

  private async _execute<T>(
    task: InternalExecutorTask<T>
  ): Promise<ExecutorResult<T>> {
    const { state } = task;

    // запуск работы может пройти в любой момент и несколько раз,
    // поэтому проверяем
    if (state === 'canceled') {
      return {
        id: task.id,
        status: EXECUTOR_RESULT_STATUS.invalid,
      };
    } else if (task.resultPromise) {
      return task.resultPromise;
    }

    task.state = 'active';
    const abortController = new AbortController();
    task.abortController = abortController;
    // на этом моменте стартует выполнения исходной сущности job
    task.jobPromise = task.job(abortController);
    const result = this._wrap(task);
    task.resultPromise = result;

    return result;
  }
}
