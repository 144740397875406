import type { MouseEventHandler, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from 'src/components/Modal/Dialog';
import { DialogContent } from 'src/components/Modal/DialogContent';
import { DialogTitle } from 'src/components/Modal/DialogTitle';
import { UserEditForm } from 'src/containers/UserEditForm';
import { isValidFunction } from 'src/utils';

import type * as T from './types';
import classes from './UserEditDialog.module.css';

const UserEditDialog: FC<T.UserEditDialogProps> = (props) => {
  const {
    className,
    initialData,
    onCancel,
    onEdit: onEditProp,
    open,
    onCloseRequested,
    ...rest
  } = props;
  const { t } = useTranslation();

  const onEdit: T.UserEditDialogSubmitHandler = async (data, event) => {
    isValidFunction(onEditProp) && (await onEditProp(data, event));
    isValidFunction(onCloseRequested) && onCloseRequested();
  };

  return (
    <Dialog
      {...rest}
      className={classes.root}
      onCloseRequested={onCloseRequested}
      open={open}
      scroll="body"
    >
      <DialogTitle>{t('user.dialog.editTitle')}</DialogTitle>
      <DialogContent>
        <UserEditForm
          initialData={initialData}
          onCancel={onCloseRequested}
          onSubmit={onEdit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default UserEditDialog;
