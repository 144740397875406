import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/Button/Button2';
import { LoadingButton } from 'src/components/Button/LoadingButton';
import { Form, type FormSubmitHandler } from 'src/components/Form/Form';
import { useFormController } from 'src/components/Form/Form/useFormController';
import { defaultErrorToast, toast } from 'src/components/Toast';
import {
  UserAvatarFormItem,
  type UserAvatarFormItemValue,
} from 'src/containers/User/UserAvatarFormItem';
import { useUserAvatarValidation } from 'src/containers/User/UserAvatarFormItem/useUserAvatartValidation';
import { UserEmailFormItem } from 'src/containers/User/UserEmailFormItem';
import { useUserEmailValidation } from 'src/containers/User/UserEmailFormItem/useUserEmailValidation';
import { maybeGetErrorDataFromBackend } from 'src/services/api/functions';
import { isValidFunction } from 'src/utils';

import classes from './EditUserProfileForm.module.css';
import { errorDataToDisplayErrorData, getFirstInvalidField } from './functions';
import type { FieldPath } from 'react-hook-form';

export interface EditUserProfileFormData {
  avatar: UserAvatarFormItemValue | null;
  email: string;
}

export type EditUserProfileFormSubmitHandler =
  FormSubmitHandler<EditUserProfileFormData>;

export type EditUserProfileFormCloseHandler = () => void;

const DEFAULT_FORM_DATA: Required<EditUserProfileFormData> = {
  avatar: null,
  email: '',
};

const FORM_FIELD_LIST: Array<FieldPath<EditUserProfileFormData>> = [
  'email',
  'avatar',
];

export interface EditUserProfileFormProps {
  className?: string;
  initialData?: EditUserProfileFormData;
  onClose?: EditUserProfileFormCloseHandler;
  onSubmit?: EditUserProfileFormSubmitHandler;
}

const Msg = (props: any) => {
  const { closeToast, toastProps } = props;
  return (
    <div>
      Lorem ipsum dolor {toastProps.position}
      <button>Retry</button>
      <button onClick={closeToast}>Close</button>
    </div>
  );
};

const EditUserProfileForm: FC<EditUserProfileFormProps> = (props) => {
  const { className, initialData, onClose, onSubmit: onSubmitProp } = props;
  const { t } = useTranslation();

  const defaultValues = useMemo(
    () => ({ ...DEFAULT_FORM_DATA, ...initialData }),
    [initialData]
  );

  const controller = useFormController<EditUserProfileFormData>({
    defaultValues,
  });

  const { handleSubmit, setFocus } = controller;

  const [
    disabled,
    setDisabled,
  ] = useState<boolean>(false);

  // валидация
  // email
  const {
    validate: validateEmail,
    validation: emailValidation,
    validationState: emailValidationState,
  } = useUserEmailValidation(
    controller,
    'email',
    defaultValues.email ? [defaultValues.email] : undefined
  );

  // avatar
  const { validate: validateAvatar, validation: avatarValidation } =
    useUserAvatarValidation(controller, 'avatar');

  const [
    firstInvaliField,
    setFirstInvaliField,
  ] = useState<FieldPath<EditUserProfileFormData> | undefined>();

  // валидация ручная, поэтому submit формы всегда будет успешным
  const onSubmitSuccess: FormSubmitHandler<EditUserProfileFormData> = async (
    data,
    event
  ) => {
    let localFirstInvaliField: FieldPath<EditUserProfileFormData> | undefined;

    try {
      setDisabled(true);
      setFirstInvaliField(undefined);

      const { avatar, email } = data;
      // валидация
      const validList = await Promise.all([
        validateEmail(email),
        validateAvatar(avatar),
      ]);
      if (validList.every(Boolean)) {
        isValidFunction(onSubmitProp) && (await onSubmitProp(data, event));
        localFirstInvaliField = undefined;
      } else {
        // для фокуса первого инвалидного элемента
        localFirstInvaliField = getFirstInvalidField<EditUserProfileFormData>(
          validList,
          FORM_FIELD_LIST
        );
      }
    } catch (e) {
      const errorData = maybeGetErrorDataFromBackend(e);
      if (errorData.status !== 401) {
        defaultErrorToast(errorDataToDisplayErrorData(errorData, t));
      }
    } finally {
      setDisabled(false);
      setFirstInvaliField(localFirstInvaliField);
    }
  };

  // фокус на первой инвалидном элементе
  useEffect(() => {
    if (firstInvaliField) {
      setFocus(firstInvaliField);
    }
  }, [
    firstInvaliField,
    setFocus,
  ]);

  return (
    <Form
      className={className}
      controller={controller}
      onSubmit={handleSubmit(onSubmitSuccess)}
    >
      <UserEmailFormItem
        disabled={disabled}
        hintVisible={false}
        validation={emailValidation}
        validationState={emailValidationState}
      />
      <UserAvatarFormItem
        disabled={disabled}
        hintVisible
        validation={avatarValidation}
      />
      <div className={classes.actions}>
        <Button
          className={classes.actionButton}
          colorVariant="primary"
          onClick={onClose}
          sizeVariant="small"
          type="button"
          variant="outlined"
        >
          {t('dialog.cancel')}
        </Button>
        <LoadingButton
          className={classes.actionButton}
          colorVariant="primary"
          disabled={disabled}
          loading={disabled}
          sizeVariant="small"
          type="submit"
          variant="contained"
        >
          {t('user.actions.edit')}
        </LoadingButton>
      </div>
    </Form>
  );
};

export default EditUserProfileForm;
