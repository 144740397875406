import { FC, forwardRef } from 'react';

import { Select } from 'src/components/Select/Select';
import { SelectOption } from 'src/components/Select/SelectOption';
import { ALL_USER_ROLES } from 'src/services/api';
import type { UserRole } from 'src/services/api';
import { UserRoleChip } from 'src/components/RoleChip/UserRoleChip';

import type * as T from './types';
import classes from './UserRoleSelect.module.css';

const UserRoleSelect = forwardRef<HTMLDivElement, T.UserRoleSelect>(
  (props, ref) => {
    const { className, ...rest } = props;

    return (
      <Select<UserRole>
        className={className}
        {...rest}
        ref={ref}
      >
        {ALL_USER_ROLES.map((role) => (
          <SelectOption
            key={role}
            value={role}
          >
            <UserRoleChip role={role} />
          </SelectOption>
        ))}
      </Select>
    );
  }
);

export default UserRoleSelect;
