import type { ReactNode } from 'react';
import {
  type DetailedHTMLProps,
  InputHTMLAttributes,
  PropsWithRef,
  type FC,
  forwardRef,
  ForwardedRef,
} from 'react';
import MUIFormControlLabel, {
  FormControlLabelProps as MUIFormControlLabelProps,
} from '@mui/material/FormControlLabel';
import type { CheckboxProps as MUICheckboxProps } from '@mui/material/Checkbox';
import MUICheckbox from '@mui/material/Checkbox';

import classes from './Checkbox.module.css';

import classNames from 'classnames';
import type { ValueOf } from 'src/types/utils';

export const CHECKBOX_SIZE_VARIANT = {
  small: 'small',
  medium: 'medium',
} as const;

export type CheckboxSizeVariant = ValueOf<typeof CHECKBOX_SIZE_VARIANT>;

export const CHECKBOX_COLOR_VARIANT = {
  danger: 'danger',
  primary: 'primary',
  secondary: 'secondary',
} as const;
export type CheckboxColorVariant = ValueOf<typeof CHECKBOX_COLOR_VARIANT>;

export interface CheckboxClasses {
  label: string;
  input: string;
  root: string;
}

export interface CheckboxProps
  extends MUICheckboxProps /*Omit<PropsWithRef<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>, 'size' | 'color' | 'ref'>*/ {
  checked?: boolean;
  children?: ReactNode;
  className?: string;
  classes?: Partial<CheckboxClasses>;
  colorVariant?: CheckboxColorVariant;
  defaultChecked?: boolean;
  disabled?: boolean;
  // используется либо label, либо children для отображкния лейбла
  label?: ReactNode;
  sizeVariant?: CheckboxSizeVariant;
}

const Checkbox: FC<CheckboxProps> = forwardRef<
  HTMLButtonElement,
  CheckboxProps
>((props, ref) => {
  const {
    className,
    classes: classesProp = {},
    checked,
    children,
    colorVariant = CHECKBOX_COLOR_VARIANT.primary,
    defaultChecked,
    disabled = false,
    label,
    sizeVariant = CHECKBOX_SIZE_VARIANT.medium,
    ...rest
  } = props;

  const sizeVariantClassName =
    sizeVariant === CHECKBOX_SIZE_VARIANT.small ? classes.rootSmall : null;

  const colorVariantClassName =
    colorVariant === CHECKBOX_COLOR_VARIANT.danger
      ? classes.rootDanger
      : colorVariant === CHECKBOX_COLOR_VARIANT.secondary
      ? classes.rootSecondary
      : null;

  const calculatedRootClasses = classNames(
    classes.root,
    sizeVariantClassName,
    colorVariantClassName,
    className,
    classesProp.root
  );

  return (
    <MUIFormControlLabel
      classes={{
        label: classNames(classes.label, classesProp.label),
        root: calculatedRootClasses,
      }}
      control={
        <MUICheckbox
          {...rest}
          className={classNames(classes.input, classesProp.input)}
          classes={{ checked: classes.inputChecked }}
          checked={checked}
          defaultChecked={defaultChecked}
          disableRipple
          ref={ref}
        />
      }
      disabled={disabled}
      label={children || label}
    />
  );
});

export default Checkbox;
