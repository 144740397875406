import { type FC, type ReactNode } from 'react';
import { default as MUIDialogActions } from '@mui/material/DialogActions';

import classes from './DialogActions.module.css';

import { useDialogContext } from 'src/components/Modal/useDialogContext';

export interface DialogActionsProps {
  children?: ReactNode;
  className?: string;
}

const DialogActions: FC<DialogActionsProps> = (props) => {
  const { children, className } = props;

  const context = useDialogContext();

  return (
    <MUIDialogActions className={classes.root}>{children}</MUIDialogActions>
  );
};

export default DialogActions;
