import { type ReactNode, type FC, useState } from 'react';

import { AuthStateContext, AuthActionsContext } from './contexts';
import type {
  AuthGetPersonalDataAction,
  AuthInterceptorSetter,
  AuthLogoutAction,
  AuthUpdatePersonalDataAction,
} from './types';
import {
  type AuthState,
  type AuthActions,
  type AuthVerifyAction,
  type AuthAuthenticateAction,
} from './types';
import { AuthController } from './AuthController';

import { useReadonlyLazyRef } from 'src/hooks';
import type { ApiPersonalForm } from 'src/services/api';
import { authApiService } from 'src/services/api';
import { isValidFunction } from 'src/utils';

export interface AuthProviderProps {
  children?: ReactNode;
}

function getStateFromController(controller: AuthController): AuthState {
  return {
    authenticated: controller.authenticated,
    verified: controller.verified,
    user: controller.user,
    role: controller.role,
  };
}

function getDefaultState() {
  return {
    authenticated: false,
    verified: false,
    user: null,
    role: null,
  };
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [
    state,
    setState,
  ] = useState<AuthState>(getDefaultState);

  const controller = useReadonlyLazyRef(
    () => new AuthController(authApiService)
  );

  const authenticate: AuthAuthenticateAction = useReadonlyLazyRef(
    () => async (username: string, password: string) => {
      try {
        const authenticated = await controller.authenticate(username, password);
        setState(getStateFromController(controller));
        return authenticated;
      } catch (e) {
        setState(getStateFromController(controller));
        throw e;
      }
    }
  );

  const getPersonalData: AuthGetPersonalDataAction = useReadonlyLazyRef(
    () => async () => {
      try {
        await controller.reloadUserData();
      } catch (e) {
      } finally {
        setState(getStateFromController(controller));
      }
    }
  );

  const logout: AuthLogoutAction = useReadonlyLazyRef(() => async () => {
    await controller.logout();
    setState(getStateFromController(controller));
  });

  const setUnauthorizedInterceptor: AuthInterceptorSetter = useReadonlyLazyRef(
    () => (interceptor) => {
      const _interceptor = () => {
        isValidFunction(interceptor) && interceptor();
        // не нравится это место и то, как оно по итогу вышло
        setState(getStateFromController(controller));
      };

      controller.setUnauthorizedInterceptor(_interceptor);
    }
  );

  const updatePersonal: AuthUpdatePersonalDataAction = useReadonlyLazyRef(
    () => async (form: ApiPersonalForm) => {
      await controller.updatePersonal(form);
      setState(getStateFromController(controller));
    }
  );

  const actions: AuthActions = useReadonlyLazyRef(() => {
    const verify: AuthVerifyAction = async (force?: boolean) => {
      const oldVerified = controller.verified;
      await controller.verify(force);

      /**
       * делаем предположение, что если пользователь уже был верифицирован,
       * то стейт содержит актуальные данные
       */
      if (!oldVerified || force) {
        setState(getStateFromController(controller));
      }
      return controller.authenticated;
    };

    return {
      authenticate,
      getPersonalData,
      logout,
      setUnauthorizedInterceptor,
      verify,
      updatePersonal,
    };
  });

  return (
    <AuthStateContext.Provider value={state}>
      <AuthActionsContext.Provider value={actions}>
        {children}
      </AuthActionsContext.Provider>
    </AuthStateContext.Provider>
  );
};

export default AuthProvider;
