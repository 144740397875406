import type { UserId } from 'src/services/api';

export const PATHS = {
  changePassword: {
    path: '/change-password',
  },
  forgotPassword: {
    path: '/forgot-password',
  },
  newRoom: {
    path: '/new-room',
  },
  newUser: {
    path: '/new-user',
  },
  profile: {
    path: '/profile',
  },
  records: {
    path: '/records',
  },
  resetPassword: {
    path: '/reset-password',
  },
  root: {
    path: '/',
  },
  room: {
    path: '/rooms/:roomId',
    format(roomId: string | number) {
      return `/rooms/${roomId}`;
    },
    param: 'roomId',
  },
  rooms: {
    path: '/rooms',
  },
  login: {
    path: '/login',
  },
  setPassword: {
    path: '/set-password',
  },
  user: {
    path: '/users/:userId',
    format(userId: UserId) {
      return `/users/${userId}`;
    },
    param: 'userId',
  },
  userEdit: {
    path: '/users/:userId/edit/',
    format(userId: UserId) {
      return `/users/${userId}/edit/`;
    },
    param: 'userId',
  },
  users: {
    path: '/users',
  },
};

export const REDIRECT_PARAM = 'redirect';
export const EXTERNAL_REDIRECT_PARAM = 'redirect1';
