import { type SyntheticEvent, type FC, useCallback } from 'react';
import classNames from 'classnames';

import { IconSearch } from 'src/assets/svg';
import {
  Button,
  type ButtonColorVariant,
  BUTTON_COLOR_VARIANT,
} from 'src/components/Button/Button2';
import { OldForm, type FormSubmitHandler } from 'src/components/Form';
import { FormItem } from 'src/components/Form/FormItem';
import type { InputProps } from 'src/components/Input';
import {
  Input,
  type InputColorVariant,
  INPUT_COLOR_VARIANT,
} from 'src/components/Input';

import classes from './SearchWidget.module.css';
import { type ValueOf } from 'src/types/utils';

export type SearchWidgetFormSubmit = (
  query: string,
  event?: SyntheticEvent<HTMLFormElement, SubmitEvent>
) => void;

export const SEARCH_WIDGET_COLOR_VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
} as const;

export type SearchWidgetColorVariant = ValueOf<
  typeof SEARCH_WIDGET_COLOR_VARIANT
>;

export interface SearchWidgetProps {
  className?: string;
  colorVariant?: SearchWidgetColorVariant;
  initialQuery?: string;
  inputProps?: Omit<InputProps, 'onChange' | 'onBlur' | 'ref' | 'value'>;
  onSubmit?: SearchWidgetFormSubmit;
}

interface SearchWidgetFormData {
  query: string;
}

const SearchWidget: FC<SearchWidgetProps> = (props) => {
  const {
    className,
    colorVariant = SEARCH_WIDGET_COLOR_VARIANT.primary,
    initialQuery = '',
    inputProps,
    onSubmit,
  } = props;

  console.log('SearchWidget render', initialQuery);

  const onFormSubmit: FormSubmitHandler<SearchWidgetFormData> = useCallback(
    (data, event) => {
      onSubmit && typeof onSubmit === 'function' && onSubmit(data.query, event);
    },
    [onSubmit]
  );

  let inputColorVariant: InputColorVariant;
  let buttonColorVariant: ButtonColorVariant;
  if (colorVariant === SEARCH_WIDGET_COLOR_VARIANT.secondary) {
    inputColorVariant = INPUT_COLOR_VARIANT.secondary;
    buttonColorVariant = BUTTON_COLOR_VARIANT.secondary;
  } else {
    inputColorVariant = INPUT_COLOR_VARIANT.primary;
    buttonColorVariant = BUTTON_COLOR_VARIANT.primary;
  }

  return (
    <div className={classNames(classes.root, className)}>
      <OldForm<SearchWidgetFormData>
        action="."
        className={classes.form}
        onSuccessSubmit={onFormSubmit}
        shouldFocusError={false}
      >
        <FormItem<HTMLInputElement, string>
          className={classes.formItem}
          defaultValue={initialQuery}
          name="query"
        >
          {(data) => {
            const {
              field: { onChange, onBlur, ref, value },
            } = data;

            return (
              <Input
                {...inputProps}
                className={classes.inputRoot}
                classes={{ input: classes.input }}
                colorVariant={inputColorVariant}
                maxLength={50}
                name="query"
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                type="search"
                value={value}
              />
            );
          }}
        </FormItem>
        <Button
          className={classes.submitButton}
          colorVariant={buttonColorVariant}
          icon={IconSearch}
          type="submit"
          variant="contained"
        ></Button>
      </OldForm>
    </div>
  );
};

export default SearchWidget;
