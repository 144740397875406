import type { FC } from 'react';

import classes from './LoginPageBackground.module.css';

const LoginPageBackground: FC = () => {
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}></div>
    </div>
  );
};

export default LoginPageBackground;
