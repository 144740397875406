import {
  type Executor,
  type ExecutorEntry,
  type ExecutorEntryId,
  type ExecutorResult,
  type ExecutorJob,
  EXECUTOR_RESULT_STATUS,
} from './types';
import { type ExecutorTask } from './internalTypes';
import { getEntryId } from './id';
import { DebuggableExecutor } from 'src/utils/executors/DebuggableExecutor';

export class QueueExecutor extends DebuggableExecutor implements Executor {
  private _tasks: Array<ExecutorTask<any>> = [];
  private _chain: Promise<any> | null = null;

  private async _wrap<T>(task: ExecutorTask<T>) {
    let result: ExecutorResult<T>;
    const { id } = task;
    try {
      // ждем завершения цепочки предыдущих запросов
      this.logEntryState(id, 'awaiting chain');
      await this._chain;
      this.logEntryState(id, 'awaiting original request');
      // результат оригинального запроса
      const originalResult = await task.promise;
      this.logEntryState(id, 'original request result:', originalResult);
      const status = task.invalid
        ? EXECUTOR_RESULT_STATUS.invalid
        : EXECUTOR_RESULT_STATUS.ok;

      result = {
        id,
        status,
        data: originalResult,
      };
    } catch (e) {
      const status =
        task.invalid || (e instanceof DOMException && e.name === 'AbortError')
          ? EXECUTOR_RESULT_STATUS.invalid
          : EXECUTOR_RESULT_STATUS.error;

      result = {
        id,
        status,
        error: e,
      };
    } finally {
      const index = this._tasks.indexOf(task);
      let deleted;
      if (index !== -1) {
        deleted = this._tasks.splice(index, 1);
      }

      if (deleted && deleted.length > 0) {
        this.log(
          'entry ids:',
          deleted.map(({ id }) => id).join(', '),
          ', deleted on complete'
        );
      }
    }

    this.logEntryState(id, 'result:', result);

    return result;
  }

  invalidateAll(): void {
    this._tasks.forEach((task) => {
      task.invalid = true;
      const { abortController } = task;
      abortController && abortController.abort();
    });

    this._chain = null;
  }

  queue<T>(entry: Promise<T> | ExecutorJob<T>): ExecutorEntry<T> {
    const id: ExecutorEntryId = getEntryId();

    this.logEntryState(id, 'queued');
    const execute: ExecutorEntry<T>['execute'] = async () => {
      return this._execute(id, entry);
    };

    return {
      id,
      execute,
    };
  }

  private async _execute<T>(
    id: ExecutorEntryId,
    entry: Promise<T> | ExecutorJob<T>
  ): Promise<ExecutorResult<T>> {
    const abortController = new AbortController();
    let promise: Promise<T>;
    if (typeof entry === 'function') {
      promise = entry(abortController);
    } else {
      promise = entry;
    }

    const task: ExecutorTask<T> = {
      abortController,
      id,
      invalid: false,
      promise,
    };

    this._tasks.push(task);
    const result = this._wrap(task);

    this._chain = this._chain ? this._chain.then(() => result) : result;

    return result;
  }
}
