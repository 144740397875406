import { type FC, useCallback, useMemo, forwardRef } from 'react';
import classNames from 'classnames';

import { Input, type InputProps } from 'src/components/Input';

import classes from './TextInput.module.css';
import { mergeClasses } from 'src/styles/functions';

export interface TextInputProps extends InputProps {
  className?: string;
  type?: 'number' | 'text' | 'search' | 'url' | 'tel' | 'email' | 'password';
  value?: string | number;
}

const TextInput: FC<TextInputProps> = forwardRef<
  HTMLInputElement,
  TextInputProps
>((props, ref) => {
  const { classes: classesProp, maxLength, value, ...rest } = props;

  const length = useMemo(
    () =>
      typeof value === 'number'
        ? value.toString().length
        : typeof value === 'string'
        ? value.length
        : 0,
    [value]
  );

  const suffix =
    typeof maxLength === 'number' && maxLength >= 0 ? (
      <span className={classes.counter}>{`${length}/${maxLength}`}</span>
    ) : null;

  const classesValue = mergeClasses(classesProp, {
    input: classes.input,
    suffix: classes.suffix,
  });

  return (
    <Input
      {...rest}
      classes={classesValue}
      maxLength={maxLength}
      ref={ref}
      slots={{ suffix }}
      value={value}
    />
  );
});

export default TextInput;
