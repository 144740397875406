import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IconPen } from 'src/assets/svg';
import { Button } from 'src/components/Button/Button2';
import { useDropdown } from 'src/components/Dropdown/useDropdown';
import { EditAdminProfileDialog } from 'src/containers/Profile/EditAdminProfileDialog';
import { EditUserProfileDialog } from 'src/containers/Profile/EditUserProfileDialog';
import { useAuth } from 'src/contexts/auth';
import { USER_ROLE } from 'src/services/api';

import type * as T from './types';

const ProfileEditButton: FC<T.ProfileEditButtonProps> = (props) => {
  const { t } = useTranslation();
  const { className } = props;
  const [{ role, user }] = useAuth();

  const [
    open,
    close,
    opened,
  ] = useDropdown();

  return (
    user && (
      <>
        <Button
          className={className}
          colorVariant="primary"
          onClick={open}
          prefixIcon={IconPen}
          sizeVariant="small"
          variant="contained"
        >
          {t('user.actions.edit')}
        </Button>

        {role === USER_ROLE.USER ? (
          <EditUserProfileDialog
            open={opened}
            onCloseRequested={close}
          />
        ) : role === USER_ROLE.ADMIN ? (
          <EditAdminProfileDialog
            open={opened}
            onCloseRequested={close}
          />
        ) : null}
      </>
    )
  );
};

export default ProfileEditButton;
