import classNames from 'classnames';
import type { ReactElement } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormItemErrorBlock } from 'src/components/Form/FormItemErrorBlock';
import type { FormItemWithTypedErrorPayload } from 'src/components/Form/FormItemWithTypedError';
import { Spinner } from 'src/components/Spinner';
import { FormItemTypedErrorBlockContent } from 'src/components/Form/FormItemTypedErrorBlockContent';
import { IconizedContent } from 'src/components/IconizedContent';
import { IconAlertCircle } from 'src/assets/svg';

import * as T from './types';
import classes from './FormItemAsyncTypedErrorBlock.module.css';

const SPINNER_SIZE = 14;

const FormItemAsyncTypedErrorBlock = <
  E extends Element,
  V,
  T extends FieldValues = FieldValues
>(
  props: T.FormItemAsyncTypedErrorBlockProps<E, V, T>
): ReactElement<any, any> | null => {
  const {
    asyncValidating,
    asyncHasError,
    renderProps: renderPropsProp,
    ...rest
  } = props;

  const { t } = useTranslation();

  if (asyncValidating) {
    return (
      <Spinner
        color="#a92626"
        size={SPINNER_SIZE}
      />
    );
  } else if (asyncHasError) {
    return (
      <IconizedContent
        classes={{ icon: classes.icon, label: classes.label }}
        icon={IconAlertCircle}
      >
        {t('form.validation.errorWhileAsync')}
      </IconizedContent>
    );
  } else {
    return (
      <FormItemErrorBlock<E, V, T, FormItemWithTypedErrorPayload>
        renderProps={renderPropsProp}
        slots={{
          error: (renderProps) => {
            const { payload } = renderProps.validation || {};
            return (
              <FormItemTypedErrorBlockContent<E, V, T> payload={payload} />
            );
          },
        }}
        {...rest}
      />
    );
  }
};

export default FormItemAsyncTypedErrorBlock;
