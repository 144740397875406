import classNames from 'classnames';
import type { FormEventHandler, SyntheticEvent } from 'react';
import {
  useCallback,
  type FC,
  type ReactNode,
  type ReactElement,
  type DetailedHTMLProps,
  type FormHTMLAttributes,
} from 'react';
import {
  useForm,
  FormProvider,
  type UseFormProps,
  type FieldValues,
  type FieldErrors,
  type DeepPartial,
  type SubmitHandler,
  type SubmitErrorHandler,
  type UseFormSetValue,
  type UseFormReset,
} from 'react-hook-form';

export { FieldValues };

export type FormSubmitHandler<T extends FieldValues = FieldValues> = (
  data: T,
  event?: SyntheticEvent<HTMLFormElement, SubmitEvent>
) => void;

export type FormErrorHandler<T extends FieldValues = FieldValues> = (
  data: FieldErrors<T>,
  event?: SyntheticEvent<HTMLFormElement, SubmitEvent>
) => void;

export interface FormRendererProps<T extends FieldValues = FieldValues> {
  reset: UseFormReset<T>;
  setValue: UseFormSetValue<T>;
}
export type FormRenderer<T extends FieldValues = FieldValues> = (
  props: FormRendererProps<T>
) => ReactNode;

export interface FormProps<T extends FieldValues = FieldValues>
  extends Omit<
    DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
    'children'
  > {
  children?: ReactNode | FormRenderer<T>;
  className?: string;
  criteriaMode?: UseFormProps['criteriaMode'];
  defaultValues?: DeepPartial<T>;
  onErrorSubmit?: FormErrorHandler<T>;
  onSuccessSubmit?: FormSubmitHandler<T>;
  revalidationMode?: UseFormProps['reValidateMode'];
  shouldFocusError?: boolean;
  validationMode?: UseFormProps['mode'];
}

const Form = <T extends FieldValues = FieldValues>(
  props: FormProps<T>,
  context?: any
): ReactElement<any, any> | null => {
  const {
    children,
    className,
    criteriaMode = 'all',
    defaultValues,
    onErrorSubmit,
    onSuccessSubmit,
    revalidationMode = 'onChange',
    shouldFocusError = true,
    validationMode = 'onSubmit',
    ...rest
  } = props;

  const useFormProps: UseFormProps<T> = {
    criteriaMode,
    defaultValues,
    mode: validationMode,
    reValidateMode: revalidationMode,
    shouldFocusError,
  };
  const useFormMethods = useForm<T>(useFormProps);
  const { handleSubmit, reset, setValue } = useFormMethods;

  const onValid: SubmitHandler<T> = useCallback(
    (data, event) => {
      onSuccessSubmit &&
        typeof onSuccessSubmit === 'function' &&
        onSuccessSubmit(
          data,
          // у них плохо прописаны тут типы
          event as SyntheticEvent<HTMLFormElement, SubmitEvent> | undefined
        );
    },
    [onSuccessSubmit]
  );

  const onInvalid: SubmitErrorHandler<T> = useCallback(
    (errors, event) => {
      onErrorSubmit &&
        typeof onErrorSubmit === 'function' &&
        onErrorSubmit(
          errors,
          event as SyntheticEvent<HTMLFormElement, SubmitEvent> | undefined
        );
    },
    [onErrorSubmit]
  );

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      handleSubmit(
        onValid,
        onInvalid
      )(event).then((value) => console.log('promise', value));
    },
    [
      handleSubmit,
      onValid,
      onInvalid,
    ]
  );

  return (
    <FormProvider {...useFormMethods}>
      <form
        {...rest}
        className={className}
        onSubmit={onSubmit /*handleSubmit(onValid, onInvalid)*/}
      >
        {typeof children === 'function'
          ? children({ reset, setValue })
          : children}
      </form>
    </FormProvider>
  );
};

export default Form;

// для типов
export function formFactory<T extends FieldValues = FieldValues>(): FC<
  FormProps<T>
> {
  return Form;
}
