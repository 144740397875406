import type { FC, ReactNode, ReactElement } from 'react';

import { AuthRequiredRedirect } from 'src/components/Auth/AuthRequired/components/AuthRequiredRedirect';
import type { UserRole } from 'src/services/api';
import { useAuthState } from 'src/contexts/auth';

export interface AuthRequiredProps {
  children?: ReactNode;
  fallback?: ReactNode;
  acceptedRoles?: readonly UserRole[] | UserRole[];
}

const AuthRequired: FC<AuthRequiredProps> = (props) => {
  console.log('auth required render');
  const { children, fallback, acceptedRoles } = props;

  const { authenticated, role } = useAuthState();

  const permitted =
    authenticated &&
    (!acceptedRoles || (acceptedRoles && role && acceptedRoles.includes(role)));

  return permitted ? (
    (children as ReactElement)
  ) : authenticated ? (
    // пользователь аутентифицирован, но у него нет прав на просмотр,
    // в этом случае показываем 404
    (fallback as ReactElement)
  ) : (
    <AuthRequiredRedirect />
  );
};

export default AuthRequired;
