import { type ReactNode, type FC } from 'react';
import classNames from 'classnames';

import classes from './FullscreenLayout.module.css';

export interface FullscreenLayoutClasses {
  content: string;
  header: string;
}

export interface FullscreenLayoutProps {
  children?: ReactNode;
  className?: string;
  classes?: Partial<FullscreenLayoutClasses>;
  header?: ReactNode;
}

const FullscreenLayout: FC<FullscreenLayoutProps> = (props) => {
  const { children, className, classes: classesProp = {}, header } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classNames(classes.header, classesProp.header)}>
        {header}
      </div>
      <div className={classNames(classes.content, classesProp.content)}>
        {children}
      </div>
    </div>
  );
};

export default FullscreenLayout;
