import { type FC } from 'react';
import classNames from 'classnames';

import {
  SimpleLink,
  type SimpleLinkProps,
} from 'src/components/Link/SimpleLink';

import classes from './TitleLink.module.css';
import { type ValueOf } from 'src/types/utils';

export const TITLE_LINK_COLOR_VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
} as const;

export type TitleLinkColorVariant = ValueOf<typeof TITLE_LINK_COLOR_VARIANT>;

export interface TitleLinkProps
  extends Omit<SimpleLinkProps, 'classes' | 'sizeVariant'> {
  colorVariant?: TitleLinkColorVariant;
}

const TitleLink: FC<TitleLinkProps> = (props) => {
  const { className, colorVariant, ...rest } = props;

  const colorVariantClassName =
    colorVariant === TITLE_LINK_COLOR_VARIANT.secondary
      ? classes.rootSecondary
      : null;

  return (
    <SimpleLink
      className={classNames(classes.root, colorVariantClassName, className)}
      classes={{ link: classes.link }}
      {...rest}
    />
  );
};

export default TitleLink;
