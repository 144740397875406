import { type FC, type ReactNode } from 'react';
import classNames from 'classnames';

import classes from './Badge.module.css';
import type { ValueOf } from 'src/types/utils';

export const BADGE_COLOR_VARIANT = {
  danger: 'danger',
  primary: 'primary',
  secondary: 'secondary',
} as const;

export type BadgeColorVariant = ValueOf<typeof BADGE_COLOR_VARIANT>;

export interface BadgeProps {
  badge?: ReactNode;
  children?: ReactNode;
  className?: string;
  colorVariant?: BadgeColorVariant;
}

export const Badge: FC<BadgeProps> = (props) => {
  const {
    badge,
    children,
    className,
    colorVariant = BADGE_COLOR_VARIANT.primary,
  } = props;

  const colorVariantClassName =
    colorVariant === BADGE_COLOR_VARIANT.danger
      ? classes.danger
      : colorVariant === BADGE_COLOR_VARIANT.secondary
      ? classes.secondary
      : null;

  return (
    <div className={classNames(classes.root, colorVariantClassName, className)}>
      {children}
      {badge && <div className={classes.badge}>{badge}</div>}
    </div>
  );
};

export default Badge;
