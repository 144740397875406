import { type FC } from 'react';
import type { MenuItemProps as MUIMenuItemProps } from '@mui/material/MenuItem';
import MUIMenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';

import classes from './MenuItem.module.css';

export interface MenuItemProps
  extends Omit<
    MUIMenuItemProps,
    'disableGutters' | 'disableRipple' | 'disableTouchRipple'
  > {
  active?: boolean;
}

export const MenuItem: FC<MenuItemProps> = (props) => {
  const { active, classes: classesProp = {}, ...rest } = props;

  const calculatedClasses: MenuItemProps['classes'] = {
    ...classesProp,
    root: classNames(classes.root, classesProp.root),
    selected: classNames(classes.selected, classesProp.selected),
  };

  return (
    <MUIMenuItem
      className={classNames(classes.root, classesProp.root)}
      classes={calculatedClasses}
      disableGutters
      disableRipple
      disableTouchRipple
      {...rest}
    />
  );
};

export default MenuItem;
