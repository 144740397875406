import classNames from 'classnames';
import { type FC, type ReactNode } from 'react';

import { type ValueOf } from 'src/types/utils';

import classes from './StyledFormItemHint.module.css';

export const STYLED_FORM_ITEM_HINT_COLOR_VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
} as const;

export type StyledFormItemHintColorVariant = ValueOf<
  typeof STYLED_FORM_ITEM_HINT_COLOR_VARIANT
>;

export interface StyledFormItemHintProps {
  children?: ReactNode;
  className?: string;
  colorVariant?: StyledFormItemHintColorVariant;
}

const StyledFormItemHint: FC<StyledFormItemHintProps> = (props) => {
  const {
    children,
    className,
    colorVariant = STYLED_FORM_ITEM_HINT_COLOR_VARIANT.primary,
  } = props;

  const colorVariantClassName =
    colorVariant === STYLED_FORM_ITEM_HINT_COLOR_VARIANT.secondary
      ? classes.rootSecondary
      : null;

  return (
    <p className={classNames(classes.root, colorVariantClassName, className)}>
      {children}
    </p>
  );
};

export default StyledFormItemHint;
