import { type FromURLMapper, type ToURLMapper } from 'src/utils/mappers/types';
import { type InternalUserListFilterDataType } from 'src/types/common';
import { ALL_USER_ROLES, type UserRole } from 'src/services/api';
import { USER_LIST_DEFAULT_FILTER_DATA } from 'src/constants/users';

export const USER_LIST_FILTER_SEARCH_PARAMS = {
  role: 'role',
  blocked: 'b',
  unblocked: 'ub',
} as const;

const TRUE_VALUE = '1';

// role
const roleFromURLMapper: FromURLMapper<UserRole[]> = (input) => {
  const params = input.getAll(USER_LIST_FILTER_SEARCH_PARAMS.role);

  const parsedList: UserRole[] = [];
  params.forEach((param) => {
    const parsed = param ? Number.parseInt(param, 10) : null;
    const result: UserRole | null =
      parsed !== null &&
      !Number.isNaN(parsed) &&
      ALL_USER_ROLES.includes(parsed as UserRole)
        ? (parsed as UserRole)
        : null;

    if (result && !parsedList.includes(result)) {
      parsedList.push(result);
    }
  });

  return parsedList;
};

const roleToURLMapper: ToURLMapper<UserRole[]> = (input) => {
  const { value, accumulator } = input;

  accumulator.delete(USER_LIST_FILTER_SEARCH_PARAMS.role);
  // свято верим, что все значения, переданные нам в массиве, будут разными,
  // даже если переданы все значения, то все равно передаем их в параметры
  if (value && value.length > 0) {
    value.forEach((role) => {
      accumulator.append(USER_LIST_FILTER_SEARCH_PARAMS.role, role.toString());
    });
  }

  return accumulator;
};

// blocked/unblocked
const blockedFromURLMapper: FromURLMapper<boolean> = (input) => {
  const param = input.get(USER_LIST_FILTER_SEARCH_PARAMS.blocked);
  const trimmed = param && param.trim();
  const result = trimmed
    ? trimmed === TRUE_VALUE
    : USER_LIST_DEFAULT_FILTER_DATA.blocked;

  return result;
};

const unblockedFromURLMapper: FromURLMapper<boolean> = (input) => {
  const param = input.get(USER_LIST_FILTER_SEARCH_PARAMS.unblocked);
  const trimmed = param && param.trim();
  const result = trimmed
    ? trimmed === TRUE_VALUE
    : USER_LIST_DEFAULT_FILTER_DATA.unblocked;

  return result;
};

const blockToURLMapper: ToURLMapper<
  [blocked: boolean | undefined, unblocked: boolean | undefined]
> = (input) => {
  const { value, accumulator } = input;

  accumulator.delete(USER_LIST_FILTER_SEARCH_PARAMS.blocked);
  accumulator.delete(USER_LIST_FILTER_SEARCH_PARAMS.unblocked);
  if (value) {
    const [
      blocked,
      unblocked,
    ] = value;

    if (blocked !== USER_LIST_DEFAULT_FILTER_DATA.blocked) {
      accumulator.set(USER_LIST_FILTER_SEARCH_PARAMS.blocked, TRUE_VALUE);
    }
    if (unblocked !== USER_LIST_DEFAULT_FILTER_DATA.unblocked) {
      accumulator.set(USER_LIST_FILTER_SEARCH_PARAMS.unblocked, TRUE_VALUE);
    }
  }

  return accumulator;
};

// filter
export const filterFromURLMapper: FromURLMapper<
  InternalUserListFilterDataType
> = (input) => {
  const roleList = roleFromURLMapper(input);
  const blocked = blockedFromURLMapper(input);
  const unblocked = unblockedFromURLMapper(input);

  return {
    roleList,
    blocked,
    unblocked,
  };
};

export const filterToURLMapper: ToURLMapper<InternalUserListFilterDataType> = (
  input
) => {
  const { value: { roleList, blocked, unblocked } = {}, accumulator } = input;

  roleToURLMapper({ accumulator, value: roleList });
  blockToURLMapper({
    accumulator,
    value: [
      blocked,
      unblocked,
    ],
  });

  return accumulator;
};
