import { useContext, type FC, type ReactNode } from 'react';

import { ActionContainerContext } from '../ActionContainerContext';
import { MenuItem, type MenuItemProps } from 'src/components/Menu';
import { isValidFunction } from 'src/utils';

export interface ActionEntryProps extends MenuItemProps {}

export const ActionEntry: FC<ActionEntryProps> = (props) => {
  const context = useContext(ActionContainerContext);
  if (context === null) {
    throw new Error('ActionEntry must be used within a SortWidget');
  }

  return <MenuItem {...props} />;
};

export default ActionEntry;
