import { type ValueOf } from 'src/types/utils';

export type ExecutorEntryId = number;

export type ExecutorJob<T = any> = (
  abortController: AbortController
) => Promise<T>;

export interface ExecutorEntry<T> {
  id: ExecutorEntryId;
  execute: () => Promise<ExecutorResult<T>>;
}

export interface Executor {
  invalidateAll(): void;

  // queue<T>(entry: Promise<T>): ExecutorEntry<T>;
  queue<T>(entry: ExecutorJob<T>): ExecutorEntry<T>;
}

export const EXECUTOR_RESULT_STATUS = {
  ok: 1,
  error: 2,
  invalid: 3,
} as const;

export type ExecutorResultStatus = ValueOf<typeof EXECUTOR_RESULT_STATUS>;
export interface BaseExecutorResult {
  id: ExecutorEntryId;
  status: ExecutorResultStatus;
}

export interface SuccessExecutorResult<T> extends BaseExecutorResult {
  status: typeof EXECUTOR_RESULT_STATUS.ok;
  data: T;
}

export interface FailedExecutorResult extends BaseExecutorResult {
  status: typeof EXECUTOR_RESULT_STATUS.error;
  error?: any;
}

export interface InvalidExecutorResult<T> extends BaseExecutorResult {
  status: typeof EXECUTOR_RESULT_STATUS.invalid;
  data?: T;
  error?: any;
}

export type ExecutorResult<T> =
  | SuccessExecutorResult<T>
  | FailedExecutorResult
  | InvalidExecutorResult<T>;
