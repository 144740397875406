import classNames from 'classnames';
import { type ReactNode, type FC } from 'react';
import { MenuItem, type MenuItemProps } from 'src/components/Menu';

import classes from './SelectOption.module.css';

export interface SelectOptionProps extends MenuItemProps {
  children?: ReactNode;
  className?: string;
}

export const SelectOption: FC<SelectOptionProps> = (props) => {
  const { className, ...rest } = props;

  const rootClassName = classNames(classes.root, className);
  return (
    <MenuItem
      className={rootClassName}
      {...rest}
    />
  );
};

export default SelectOption;
