import type { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { Avatar } from 'src/components/Avatar';
import { INTERNAL_USER_PERMISSION } from 'src/services/api';
import { UserRoleChip } from 'src/components/RoleChip/UserRoleChip';
import { UserLoginAttribute } from 'src/containers/User/UserLoginAttribute';
import { UserEmailAttribute } from 'src/containers/User/UserEmailAttribute';
import { Button } from 'src/components/Button/Button2';
import { useTranslation } from 'react-i18next';
import { StyledBlock } from 'src/components/Styled/Block/StyledBlock';
import { useSmallMediaQuery } from 'src/hooks';
import { FlexContainer } from 'src/components/FlexContainer';
import { UserBlockedChip } from 'src/containers/User/UserBlockedChip';
import { IconAlertCircle } from 'src/assets/svg';
import { UserBlockButton } from 'src/containers/UserView/components/UserBlockButton';
import { UserUnblockButton } from 'src/containers/UserView/components/UserUnblockButton';
import type { UserEditFormInitialData } from 'src/containers/UserEditForm';
import { UserEditButton } from 'src/containers/UserView/components/UserEditButton';

import type * as T from './types';
import classes from './UserView.module.css';
import { UserResendButton } from 'src/containers/UserView/components/UserResendButton';

const UserView: FC<T.UserViewProps> = (props) => {
  const {
    className,
    data: { id, avatar, email, name, login, locked, role, permissions },
    onBlock,
    onEdit,
    onResend,
    onUnblock,
  } = props;

  const { t } = useTranslation();
  const isSmall = useSmallMediaQuery();

  const userEditFormInitialData: UserEditFormInitialData = {
    avatar,
    email,
    name,
    role,
  };

  const blockedChip = (
    <UserBlockedChip
      className={classes.blockedChip}
      value={locked}
    />
  );

  const attributes: ReactNode[] = [];
  // login
  if (login) {
    attributes.push(
      <UserLoginAttribute
        className={classes.attribute}
        key="login"
        sizeVariant="medium"
        value={login}
      />
    );
  }

  // email
  if (email) {
    attributes.push(
      <UserEmailAttribute
        className={classes.attribute}
        key="link"
        email={email}
        sizeVariant="medium"
      />
    );
  }

  // basic actions
  const actions: ReactNode[] = [];
  // block
  if (permissions.includes(INTERNAL_USER_PERMISSION.BLOCK)) {
    actions.push(
      <UserBlockButton
        key="block"
        onBlock={onBlock}
      />
    );
  }

  // unblock
  if (permissions.includes(INTERNAL_USER_PERMISSION.UNBLOCK)) {
    actions.push(
      <UserUnblockButton
        key="unblock"
        onUnblock={onUnblock}
      />
    );
  }

  // edit
  if (permissions.includes(INTERNAL_USER_PERMISSION.CHANGE_USER_DATA)) {
    actions.push(
      <UserEditButton
        initialData={userEditFormInitialData}
        key="edit"
        onEdit={onEdit}
      />
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      <StyledBlock className={classes.styledBlock}>
        <div className={classes.wrapper}>
          <div className={classes.background} />
          <div className={classes.contentWrapper}>
            <div className={classes.content}>
              <div className={classes.avatarContainer}>
                <Avatar
                  className={classes.avatar}
                  fallbackLabelSource={name}
                  sizeVariant={isSmall ? 'xlarge' : 'larger'}
                  src={avatar}
                  variant="rounded"
                />
              </div>
              <div className={classes.dataContainer}>
                <h1 className={classes.name}>{name}</h1>
                <div className={classes.chipContainer}>
                  <UserRoleChip
                    className={classes.roleChip}
                    role={role}
                    sizeVariant="medium"
                  />
                  {blockedChip}
                </div>
                {attributes.length > 0 && (
                  <div className={classes.atributeContainer}>{attributes}</div>
                )}
              </div>
            </div>
            {actions.length > 0 && (
              <FlexContainer
                className={classes.actions}
                classes={{ container: classes.actionsFlexContainer }}
              >
                {actions}
              </FlexContainer>
            )}
          </div>
        </div>

        {permissions.includes(
          INTERNAL_USER_PERMISSION.RESEND_PASSWORD_MAIL
        ) && (
          <div className={classes.wrapper}>
            <div className={classes.resendHintContainer}>
              <IconAlertCircle className={classes.resendHintIcon} />
              <div className={classes.resendHintText}>
                {t('user.dialog.resendHint')}
              </div>
            </div>

            <div className={classNames(classes.actions, classes.resendActions)}>
              <UserResendButton onResend={onResend} />
            </div>
          </div>
        )}
      </StyledBlock>
    </div>
  );
};

export default UserView;
