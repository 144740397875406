import classNames from 'classnames';
import { type FC, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { IconYouTube } from 'src/assets/svg';
import { Attribute } from 'src/components/AttributeList';
import { RoomAttributeIconDescriptor } from 'src/containers/Room/RoomAttributeIconDescriptor';

import classes from './RoomRecordsAttribute.module.css';

export interface RoomRecordsAttributeProps {
  className?: string;
  count: number;
  sizeVariant?: 'small' | 'medium';
}

const RoomRecordsAttribute: FC<RoomRecordsAttributeProps> = (props) => {
  const { className, count, sizeVariant } = props;
  const { t } = useTranslation();

  const sizeVariantClassname = sizeVariant === 'small' ? classes.small : null;

  return count > 0 ? (
    <Attribute
      className={className}
      descriptor={
        <RoomAttributeIconDescriptor
          icon={IconYouTube}
          sizeVariant={sizeVariant}
        />
      }
    >
      <span className={classNames(classes.count, sizeVariantClassname)}>
        {t('room.records.records', {
          count,
        })}
      </span>
    </Attribute>
  ) : null;
};

export default RoomRecordsAttribute;
