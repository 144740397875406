import { type ReactNode, type FC, type CSSProperties } from 'react';
import classNames from 'classnames';

import classes from './Chip.module.css';
import type { ValueOf } from 'src/types/utils';

export const CHIP_COLOR_VARIANT = {
  primary: 'primary',
  danger: 'danger',
} as const;

export type ChipColorVariant = ValueOf<typeof CHIP_COLOR_VARIANT>;

export const CHIP_SIZE_VARIANT = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export type ChipSizeVariant = ValueOf<typeof CHIP_SIZE_VARIANT>;

export interface ChipProps {
  children?: ReactNode;
  className?: string;
  colorVariant?: ChipColorVariant;
  sizeVariant?: ChipSizeVariant;
  width?: number;
}

const Chip: FC<ChipProps> = (props) => {
  const {
    children,
    className,
    colorVariant = CHIP_COLOR_VARIANT.primary,
    sizeVariant = CHIP_SIZE_VARIANT.medium,
    width,
  } = props;

  const style: CSSProperties = {};
  if (width) {
    style.width = `${width}px`;
  }

  const colorVariantClassName =
    colorVariant === CHIP_COLOR_VARIANT.danger ? classes.danger : null;

  const sizeVariantClassName =
    sizeVariant === CHIP_SIZE_VARIANT.large
      ? classes.large
      : sizeVariant === CHIP_SIZE_VARIANT.small
      ? classes.small
      : null;

  return (
    <span
      className={classNames(
        classes.root,
        colorVariantClassName,
        sizeVariantClassName,
        className
      )}
      style={style}
    >
      <div className={classes['content']}>{children}</div>
    </span>
  );
};

export default Chip;
