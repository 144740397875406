import { type FC } from 'react';

import { type InternalUserView } from 'src/services/api';
import { useAuthState } from 'src/contexts/auth';

import {
  UserListItem,
  type UserListItemBlockHandler,
  type UserListItemCheckHandler,
} from 'src/containers/UserListItem/UserListItem';
import { Grid } from 'src/components/Grid';

export interface UserListContentProps {
  data: InternalUserView[];
  onBlockUser?: UserListItemBlockHandler;
  onCheckUser?: UserListItemCheckHandler;
  onUnblockUser?: UserListItemBlockHandler;
}

const UserListContent: FC<UserListContentProps> = (props) => {
  const { user } = useAuthState();
  const { data, onBlockUser, onCheckUser, onUnblockUser } = props;

  return (
    <Grid>
      {data.map((item) => (
        <UserListItem
          checkable={true}
          data={item}
          key={item.id}
          onBlock={onBlockUser}
          onCheck={onCheckUser}
          onUnblock={onUnblockUser}
        />
      ))}
    </Grid>
  );
};

export default UserListContent;
