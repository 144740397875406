import {
  type FC,
  type DetailedHTMLProps,
  type InputHTMLAttributes,
  type PropsWithRef,
  forwardRef,
  type ReactNode,
} from 'react';
import classNames from 'classnames';
import type { ReactSVGComponent } from 'src/types/svg';

import classes from './Input.module.css';
import type { ValueOf } from 'src/types/utils';

export interface InputClassesProp {
  root: string;
  input: string;
  prefix: string;
  prefixIcon: string;
  suffix: string;
  suffixIcon: string;
}

export interface InputSlots {
  prefix: ReactNode;
  suffix: ReactNode;
}

export const INPUT_COLOR_VARIANT = {
  danger: 'danger',
  primary: 'primary',
  secondary: 'secondary',
} as const;
export type InputColorVariant = ValueOf<typeof INPUT_COLOR_VARIANT>;

export const INPUT_SIZE_VARIANT = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;
export type InputSizeVariant = ValueOf<typeof INPUT_SIZE_VARIANT>;

export interface InputProps
  extends PropsWithRef<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  > {
  classes?: Partial<InputClassesProp>;
  colorVariant?: InputColorVariant;
  prefixIcon?: ReactSVGComponent;
  sizeVariant?: InputSizeVariant;
  slots?: Partial<InputSlots>;
}

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const {
      className: classNameProp,
      classes: classesProp = {},
      colorVariant = INPUT_COLOR_VARIANT.primary,
      disabled,
      prefixIcon: PrefixIcon,
      sizeVariant = INPUT_SIZE_VARIANT.medium,
      slots = {},
      ...rest
    } = props;

    const { prefix: prefixSlot, suffix: suffixSlot } = slots;

    const prefix: ReactNode = prefixSlot ? (
      prefixSlot
    ) : PrefixIcon ? (
      <PrefixIcon
        className={classNames(classes.icon, classesProp.prefixIcon)}
      />
    ) : null;

    const suffix: ReactNode = suffixSlot ? suffixSlot : null;

    const colorVariantClassName =
      colorVariant === INPUT_COLOR_VARIANT.danger
        ? classes.danger
        : colorVariant === INPUT_COLOR_VARIANT.secondary
        ? classes.rootSecondary
        : classes.primary;

    const sizeVariantClassName =
      sizeVariant === INPUT_SIZE_VARIANT.large
        ? classes.large
        : sizeVariant === INPUT_SIZE_VARIANT.small
        ? classes.small
        : classes.medium;

    const rootClassName = classNames(
      classes.root,
      colorVariantClassName,
      sizeVariantClassName,
      Boolean(prefix) && classes.hasPrefix,
      Boolean(suffix) && classes.hasSuffix,
      disabled && classes.disabled,
      classesProp.root,
      classNameProp
    );

    return (
      <div className={rootClassName}>
        {prefix && (
          <div
            className={classNames(
              classes.appendix,
              classes.prefix,
              classesProp.prefix
            )}
          >
            {prefix}
          </div>
        )}
        <input
          className={classNames(classes.input, classesProp.input)}
          disabled={disabled}
          ref={ref}
          {...rest}
        ></input>
        {suffix && (
          <div
            className={classNames(
              classes.appendix,
              classes.suffix,
              classesProp.suffix
            )}
          >
            {suffix}
          </div>
        )}
      </div>
    );
  }
);

export default Input;
