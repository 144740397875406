import { type FC, type ReactNode } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  Chip,
  type ChipSizeVariant,
  CHIP_SIZE_VARIANT,
  CHIP_COLOR_VARIANT,
} from 'src/components/Chip';

import { type ValueOf } from 'src/types/utils';

import classes from './RoomChips.module.css';

export const ROOM_CHIPS_SIZE_VARIANT = {
  medium: 'medium',
  large: 'large',
} as const;

export type RoomChipsSizeVariant = ValueOf<typeof ROOM_CHIPS_SIZE_VARIANT>;

export interface RoomChipsProps {
  className?: string;
  deleted?: boolean;
  externalLinkAvailable?: boolean;
  isModerator?: boolean;
  isOwner?: boolean;
  sizeVariant?: RoomChipsSizeVariant;
}

const RoomChips: FC<RoomChipsProps> = (props) => {
  const {
    className,
    deleted,
    externalLinkAvailable,
    isModerator,
    isOwner,
    sizeVariant,
  } = props;

  const { t } = useTranslation();

  const chipSizeVariant: ChipSizeVariant =
    sizeVariant === ROOM_CHIPS_SIZE_VARIANT.large
      ? CHIP_SIZE_VARIANT.large
      : CHIP_SIZE_VARIANT.medium;

  const chips: ReactNode[] = [];
  // owner chip
  if (isOwner) {
    chips.push(
      <Chip
        className={classes.chip}
        colorVariant={CHIP_COLOR_VARIANT.primary}
        key="owner"
        sizeVariant={chipSizeVariant}
      >
        {t('room.chip.myRoom')}
      </Chip>
    );
  }

  // moderator chip
  if (isModerator) {
    chips.push(
      <Chip
        className={classes.chip}
        colorVariant={CHIP_COLOR_VARIANT.danger}
        key="moderator"
        sizeVariant={chipSizeVariant}
      >
        {t('room.chip.moderator')}
      </Chip>
    );
  }

  // external link chip
  if (externalLinkAvailable) {
    chips.push(
      <Chip
        className={classes.chip}
        key="external"
        colorVariant={CHIP_COLOR_VARIANT.primary}
        sizeVariant={chipSizeVariant}
      >
        {t('room.chip.external')}
      </Chip>
    );
  }

  // deleted
  if (deleted) {
    chips.push(
      <Chip
        className={classes.chip}
        key="deleted"
        colorVariant={CHIP_COLOR_VARIANT.danger}
        sizeVariant={chipSizeVariant}
      >
        {t('room.chip.deleted')}
      </Chip>
    );
  }

  return chips.length > 0 ? (
    <div className={classNames(classes.root, className)}>
      <div className={classes.chipContainerWrapper}>
        <div className={classes.chipContainer}>{chips}</div>
      </div>
    </div>
  ) : null;
};

export default RoomChips;
