import { useEffect } from 'react';

import { useOnlyLastRequest } from 'src/hooks/request/implmentations/useOnlyLastRequest';
import { type UseRequestDataSupplier } from 'src/hooks/request/useRequest';
import {
  authApiService,
  API_PASSWORD_TOKEN_VERIFIER_VALUE,
} from 'src/services/api';

export type UsePasswordVerifierTokenType = string | undefined | null;
export type UsePasswordTokenVerifierResult = 'ok' | 'invalid' | 'error';
export type UsePasswordTokenVerifierHandler = (
  result: UsePasswordTokenVerifierResult | null
) => void;

const dataSupplier: UseRequestDataSupplier<
  UsePasswordTokenVerifierResult,
  UsePasswordVerifierTokenType
> = async (token) => {
  if (!token) {
    return 'error';
  }

  try {
    const { data } = await authApiService.verifyPasswordToken(token);
    return !data
      ? 'ok'
      : data === API_PASSWORD_TOKEN_VERIFIER_VALUE.timeout ||
        data === API_PASSWORD_TOKEN_VERIFIER_VALUE.invalid
      ? 'invalid'
      : 'error';
  } catch (e) {
    return 'error';
  }
};

export function usePasswordTokenVerifier(
  token: UsePasswordVerifierTokenType,
  callback: UsePasswordTokenVerifierHandler
) {
  // через dataSetter все делается в едином рендере
  const { getData } = useOnlyLastRequest<
    UsePasswordTokenVerifierResult,
    UsePasswordVerifierTokenType
  >({
    dataSetter: callback,
    dataSupplier,
  });

  useEffect(() => {
    getData(token);
  }, [
    getData,
    token,
  ]);
}
