import { useMemo, type FC, type ReactNode } from 'react';
import {
  default as MUIDialog,
  type DialogProps as MUIDialogProps,
} from '@mui/material/Dialog';

import { DialogContext } from '../DialogContext';

import classes from './Dialog.module.css';
import type { DialogContextType } from 'src/components/Modal/typesInternal';
import { isValidFunction } from 'src/utils';
import classNames from 'classnames';

export type DialogOnCloseHandler = () => void;

export interface DialogProps extends Omit<MUIDialogProps, 'onClose' | 'open'> {
  children?: ReactNode;
  onCloseRequested?: DialogOnCloseHandler;
  open: boolean;
}

const Dialog: FC<DialogProps> = (props) => {
  const {
    children,
    className,
    onCloseRequested: onCloseRequestedProp,
    open,
    ...rest
  } = props;

  const onCloseRequested: DialogOnCloseHandler | undefined = useMemo(
    () =>
      isValidFunction(onCloseRequestedProp) ? onCloseRequestedProp : undefined,
    [onCloseRequestedProp]
  );

  const context: DialogContextType = useMemo(
    () => ({ close: onCloseRequested }),
    [onCloseRequested]
  );

  return (
    <MUIDialog
      {...rest}
      className={classNames(classes.root, className)}
      open={open}
      onClose={onCloseRequested}
    >
      <DialogContext.Provider value={context}>
        {children}
      </DialogContext.Provider>
    </MUIDialog>
  );
};

export default Dialog;
