import type { ChangeEvent, MouseEvent, ReactNode } from 'react';
import { MouseEventHandler, useMemo } from 'react';
import { useCallback, type FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  type InternalUserView,
  INTERNAL_USER_PERMISSION,
  type ApiUserView,
} from 'src/services/api';

import { useMediumMediaQuery, useSmallMediaQuery } from 'src/hooks/responsive';
import { PATHS } from 'src/constants/paths';
import { IconDelete, IconLock, IconUnlock } from 'src/assets/svg';
import { AttributeList } from 'src/components/AttributeList';
import { Button } from 'src/components/Button/Button';
import {
  Checkbox,
  CHECKBOX_COLOR_VARIANT,
  CHECKBOX_SIZE_VARIANT,
} from 'src/components/Checkbox';
import { isValidFunction } from 'src/utils';

import classes from './UserListItem.module.css';
import {
  StyledListItemBlock,
  STYLED_LIST_ITEM_BLOCK_COLOR_VARIANT,
} from 'src/components/Styled/Block/StyledListItemBlock';
import { Avatar, AVATAR_COLOR_VARIANT } from 'src/components/Avatar';
import {
  TitleLink,
  TITLE_LINK_COLOR_VARIANT,
} from 'src/components/Link/TitleLink';
import { UserRoleChip } from 'src/components/RoleChip/UserRoleChip';
import { ROLE_CHIP_SIZE_VARIANT } from 'src/components/RoleChip/RoleChip';
import { UserEmailAttribute } from 'src/containers/User/UserEmailAttribute';
import { UserLoginAttribute } from 'src/containers/User/UserLoginAttribute';
import { ActionContainer } from 'src/components/ActionContainer/ActionContainer';
import { ActionEntry } from 'src/components/ActionContainer/ActionEntry';
import { IconizedContent } from 'src/components/IconizedContent';
import { Chip } from 'src/components/Chip';
import { UserBlockedChip } from 'src/containers/User/UserBlockedChip';
import { FlexContainer } from 'src/components/FlexContainer';
import type { UserListItemActionBlockHandler } from 'src/containers/UserListItem/UserListItemActionContainer';
import { UserListItemActionContainer } from 'src/containers/UserListItem/UserListItemActionContainer';

export type UserListItemCheckHandler = (
  data: InternalUserView,
  event: ChangeEvent<HTMLInputElement>
) => void;

export type UserListItemBlockHandler = (
  data: InternalUserView,
  event: MouseEvent
) => Promise<void>;

export interface UserListItemProps {
  checkable?: boolean;
  checked?: boolean;
  className?: string;
  currentUser?: ApiUserView | null;
  data: InternalUserView;
  onBlock?: UserListItemBlockHandler;
  onCheck?: UserListItemCheckHandler;
  onUnblock?: UserListItemBlockHandler;
}

const RoomListItem: FC<UserListItemProps> = (props) => {
  const {
    checkable,
    checked,
    className,
    currentUser,
    data,
    onBlock: onBlockProp,
    onCheck: onCheckProp,
    onUnblock: onUnblockProp,
  } = props;

  const {
    avatar,
    email,
    id,
    locked,
    login,
    name,
    permissions: permissionsProp,
    role,
  } = data;

  const { t } = useTranslation();
  const isMedium = useMediumMediaQuery();
  const isSmall = useSmallMediaQuery();

  /*
  // actions
  const actions: ReactNode[] = [];

  if (deleteAvailable) {
    actions.push(
      <Button
        className={classes.actionButton}
        key="delete"
        icon={IconDelete}
        variant="text"
        colorVariant="danger"
      >
        {t('room.actions.delete')}
      </Button>
    );
  }

  if (restoreAvailable) {
    actions.push(
      <Button
        className={classes.actionButton}
        key="delete"
        icon={IconRestore}
        variant="text"
        colorVariant="primary"
      >
        {t('room.actions.restore')}
      </Button>
    );
  }
  */

  // checkbox
  const onCheck = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      isValidFunction(onCheckProp) && onCheckProp(data, event);
    },
    [
      onCheckProp,
      data,
    ]
  );

  //chips
  const chips = <Chip>{}</Chip>;

  const attributes: ReactNode[] = [];
  // login
  if (login) {
    attributes.push(
      <UserLoginAttribute
        className={classes.attribute}
        key="login"
        value={login}
      />
    );
  }

  // email
  if (email) {
    attributes.push(
      <UserEmailAttribute
        className={classes.attribute}
        key="link"
        email={email}
      />
    );
  }

  // actions
  const onBlock: UserListItemActionBlockHandler | undefined = useMemo(
    () =>
      isValidFunction(onBlockProp)
        ? (event) => {
            return onBlockProp(data, event);
          }
        : undefined,
    [
      onBlockProp,
      data,
    ]
  );

  const onUnblock: UserListItemActionBlockHandler | undefined = useMemo(
    () =>
      isValidFunction(onUnblockProp)
        ? (event) => {
            return onUnblockProp(data, event);
          }
        : undefined,
    [
      onUnblockProp,
      data,
    ]
  );

  const actionContainer = (
    <UserListItemActionContainer
      onBlock={onBlock}
      onUnblock={onUnblock}
      permissions={permissionsProp}
    />
  );

  const blockedChip = (
    <UserBlockedChip
      className={classes.chip}
      value={locked}
    />
  );

  const checkbox = checkable && (
    <div className={classes.checkboxWrapper}>
      <Checkbox
        checked={checked}
        colorVariant={CHECKBOX_COLOR_VARIANT.secondary}
        onChange={onCheck}
        sizeVariant={CHECKBOX_SIZE_VARIANT.small}
      />
    </div>
  );

  return (
    <StyledListItemBlock
      accented={checked}
      className={classNames(
        classes.root,
        checkable && classes.rootCheckable,
        checked && classes.rootChecked,
        Boolean(actionContainer) && classes.hasActions,
        className
      )}
      colorVariant={STYLED_LIST_ITEM_BLOCK_COLOR_VARIANT.secondary}
    >
      <div className={classes.wrapper}>
        {checkbox}
        <div className={classes.content}>
          <Avatar
            alt={name}
            className={classes.avatar}
            colorVariant={AVATAR_COLOR_VARIANT.secondary}
            fallbackLabelSource={name}
            src={avatar}
          />
          <div className={classes.data}>
            <div className={classes.header}>
              <TitleLink
                className={classes.link}
                colorVariant={TITLE_LINK_COLOR_VARIANT.secondary}
                noWrap
                to={PATHS.user.format(id)}
                rel="noopener"
              >
                {name}
              </TitleLink>
              {isSmall && blockedChip}
              {actionContainer}
            </div>
            <div className={classes.details}>
              <FlexContainer
                className={classes.attributes}
                classes={{ container: classes.attributesContainer }}
              >
                <UserRoleChip
                  className={classes.roleChip}
                  role={role}
                  sizeVariant={ROLE_CHIP_SIZE_VARIANT.small}
                />
                {attributes}
              </FlexContainer>
              {!isSmall && (
                <div className={classes.chipContainer}>{blockedChip}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </StyledListItemBlock>
  );
};

export default RoomListItem;
