import { useMediaQuery } from 'react-responsive';

import breakpoints from 'src/styles/breakpoints.module.css';

export const useSmallMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${breakpoints.s})` });

export const useMediumMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${breakpoints.md})` });

export const useLargeMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${breakpoints.l})` });

export const useXLargeMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${breakpoints.xl})` });
