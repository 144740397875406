import {
  type ReactNode,
  type FC,
  useRef,
  type ReactElement,
  useCallback,
  type MouseEventHandler,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { UseFormProps } from 'react-hook-form';

import { Dropdown, useDropdown } from 'src/components/Dropdown';
import { ToolbarButton } from 'src/components/ToolbarButton';
import { IconFilter } from 'src/assets/svg';
import { IconizedContent } from 'src/components/IconizedContent';
import { useMediumMediaQuery } from 'src/hooks/responsive';
import { OldForm, type FieldValues } from 'src/components/Form';
import type { FilterFormColorVariant } from './FilterForm';
import {
  FilterForm,
  FILTER_FORM_COLOR_VARIANT,
  type FilterFormProps,
  type FilterFormSubmitHandler,
} from './FilterForm';
import { NumberBadge } from 'src/components/Badge/NumberBadge';
import { type ValueOf } from 'src/types/utils';
import { useFormController } from 'src/components/Form/Form/useFormController';
import { isValidFunction } from 'src/utils';
import {
  BUTTON_COLOR_VARIANT,
  type ButtonColorVariant,
} from 'src/components/Button/Button2';

import classes from './FilterWidget.module.css';
import type { BadgeColorVariant } from 'src/components/Badge/Badge';
import { BADGE_COLOR_VARIANT } from 'src/components/Badge/Badge';

export const FILTER_WIDGET_COLOR_VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
} as const;

export type FilterWidgetColorVariant = ValueOf<
  typeof FILTER_WIDGET_COLOR_VARIANT
>;

export type FilterWidgetSubmitHandler<T extends FieldValues = FieldValues> =
  FilterFormSubmitHandler<T>;

export interface FilterWidgetProps<T extends FieldValues = FieldValues> {
  badgeCount?: number;
  children?: ReactNode;
  className?: string;
  colorVariant?: FilterWidgetColorVariant;

  /**
   * Значения для сброса
   */
  defaultValues?: T;

  /**
   * Начальные значения, которые будут выставлены на форме
   */
  initialValues?: UseFormProps<T>['defaultValues'];
  onSubmit: FilterWidgetSubmitHandler<T>;
}

const FilterWidget = <T extends FieldValues = FieldValues>(
  props: FilterWidgetProps<T>,
  context?: any
): ReactElement<any, any> | null => {
  const {
    badgeCount = 0,
    children,
    className,
    colorVariant = FILTER_WIDGET_COLOR_VARIANT.primary,
    defaultValues,
    initialValues,
    onSubmit,
  } = props;

  const { t } = useTranslation();

  // dropdown
  const anchor = useRef<HTMLButtonElement>(null);
  const [
    open,
    close,
    opened,
  ] = useDropdown();

  // form
  const controller = useFormController<T>({
    defaultValues: initialValues,
  });
  const { reset } = controller;

  const onFormSubmit: FilterFormSubmitHandler<T> = useCallback(
    (data, event) => {
      isValidFunction(onSubmit) && onSubmit(data, event);
      close();
      event?.preventDefault();
    },
    [
      onSubmit,
      close,
    ]
  );

  const onResetClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      reset(defaultValues);
      event.preventDefault();
    },
    [
      reset,
      defaultValues,
    ]
  );

  // style
  let colorVariantClassName: any;
  let toolbarButtonColorVariant: ButtonColorVariant | undefined;
  let formColorVariant: FilterFormColorVariant | undefined;
  let badgeColorVariant: BadgeColorVariant | undefined;

  switch (colorVariant) {
    case FILTER_WIDGET_COLOR_VARIANT.secondary: {
      colorVariantClassName = classes.secondary;
      toolbarButtonColorVariant = BUTTON_COLOR_VARIANT.secondaryLight;
      formColorVariant = FILTER_FORM_COLOR_VARIANT.secondary;
      badgeColorVariant = BADGE_COLOR_VARIANT.secondary;

      break;
    }

    case FILTER_WIDGET_COLOR_VARIANT.primary:
    default: {
      colorVariantClassName = null;
      toolbarButtonColorVariant = BUTTON_COLOR_VARIANT.primaryLight;
      formColorVariant = FILTER_FORM_COLOR_VARIANT.primary;
      badgeColorVariant = BADGE_COLOR_VARIANT.primary;

      break;
    }
  }

  const isMedium = useMediumMediaQuery();

  let buttonContent: ReactNode;
  if (isMedium) {
    buttonContent = (
      <IconizedContent
        className={classes.buttonContent}
        classes={{ icon: classes.icon }}
        icon={IconFilter}
      >
        {t('filters.title')}
      </IconizedContent>
    );
  } else {
    buttonContent = (
      <IconizedContent
        classes={{ icon: classes.icon }}
        icon={IconFilter}
      ></IconizedContent>
    );
  }

  return (
    <div className={classNames(classes.root, colorVariantClassName, className)}>
      <NumberBadge
        colorVariant={badgeColorVariant}
        value={badgeCount}
      >
        <ToolbarButton
          className={classes.button}
          colorVariant={toolbarButtonColorVariant}
          onClick={open}
          ref={anchor}
        >
          {buttonContent}
        </ToolbarButton>
      </NumberBadge>
      <Dropdown
        anchorEl={anchor.current}
        open={opened}
        onCloseRequested={close}
      >
        <FilterForm<T>
          colorVariant={formColorVariant}
          formController={controller}
          onReset={onResetClick}
          onSubmit={onFormSubmit}
        >
          {children}
        </FilterForm>
      </Dropdown>
    </div>
  );
};

export default FilterWidget;
