import {
  toast as libToast,
  type ToastContent,
  type ToastOptions,
  Bounce,
} from 'react-toastify';

import { ToastErrorContent } from 'src/components/Toast/ToastErrorContent';
import type { ToastErrorContentProps } from 'src/components/Toast/ToastErrorContent';

import { maybeGetErrorDataFromBackend } from 'src/services/api/functions';

const DEFAULT_TOAST_OPTIONS: ToastOptions = {
  autoClose: 8000,
  transition: Bounce,
};

export function toast(content: ToastContent, options?: ToastOptions) {
  return libToast(content, { ...DEFAULT_TOAST_OPTIONS, ...options });
}

export function defaultErrorToast(contentProps?: ToastErrorContentProps) {
  const content = <ToastErrorContent {...contentProps} />;
  return toast(content, { type: 'error' });
}

export type WithToastSkipErrorFunction = (error: any) => boolean;
export interface WithToastProps {
  shouldSkipError?: WithToastSkipErrorFunction;
  toastOptions?: ToastOptions;
}

/*
export const skip401Error: WithToastSkipErrorFunction = function (error) {
  const data = maybeGetErrorDataFromBackend(error);
  return data.status === 401;
};

export function withToastAsync<T extends any[], R>(
  fn: (...args: T) => R | Promise<R>,
  content: ToastContent,
  props?: WithToastProps
) {
  const wrapped: (...args: T) => Promise<R> = async (...args) => {
    try {
      const result = await fn(...args);
      return result;
    } catch (e) {
      const { shouldSkipError } = props || {};
      const skip: boolean = shouldSkipError ? shouldSkipError(e) : false;

      if (!skip) {
        toast(content, props?.toastOptions);
      }
      throw e;
    }
  };

  return wrapped;
}
*/
