import { type FC } from 'react';

import { IconUsers } from 'src/assets/svg';
import { Attribute } from 'src/components/AttributeList';
import { RoomAttributeIconDescriptor } from 'src/containers/Room/RoomAttributeIconDescriptor';
import { RoomOccupantsAttributeValue } from 'src/containers/Room/RoomOccupantsAttributeValue';
import {
  type InternalPersonalView,
  type RoomOccupantView,
} from 'src/services/api';

export interface RoomOccupantsAttributeProps {
  className?: string;
  currentUser?: InternalPersonalView | null;
  occupants?: RoomOccupantView[];
  owner?: RoomOccupantView;
  sizeVariant?: 'small' | 'medium';
}

const RoomOccupantsAttribute: FC<RoomOccupantsAttributeProps> = (props) => {
  const { className, currentUser, occupants, owner, sizeVariant } = props;

  const count = Array.isArray(occupants) ? occupants.length : 0;

  return count > 0 || owner ? (
    <Attribute
      className={className}
      descriptor={
        <RoomAttributeIconDescriptor
          icon={IconUsers}
          sizeVariant={sizeVariant}
        />
      }
    >
      <RoomOccupantsAttributeValue
        currentUser={currentUser}
        occupants={occupants}
        owner={owner}
        sizeVariant={sizeVariant}
      />
    </Attribute>
  ) : null;
};

export default RoomOccupantsAttribute;
