import classNames from 'classnames';
import {
  type FC,
  memo,
  type DetailedHTMLProps,
  type ImgHTMLAttributes,
} from 'react';

import classes from './PlaceholderImage.module.css';

export interface PlaceholderImageProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {}

const PlaceholderImage: FC<PlaceholderImageProps> = (props) => {
  const { className, ...rest } = props;

  return (
    <img
      {...props}
      className={classNames(classes.root, className)}
      loading="eager"
    />
  );
};

export default memo(PlaceholderImage);
