import { useEffect } from 'react';
import type { FC } from 'react';

import { useRedirect } from 'src/components/Redirect/useRedirect';
import { PATHS } from 'src/constants/paths';
import { useAuthActions } from 'src/contexts/auth/hooks';
import { composeRedirectParams } from 'src/components/Redirect/functions';

const AuthUnauthorizedInterpectorSetter: FC = () => {
  const { setUnauthorizedInterceptor } = useAuthActions();
  const redirect = useRedirect();

  useEffect(() => {
    setUnauthorizedInterceptor(() => {
      console.log('intercepted');

      const [
        _,
        to,
      ] = composeRedirectParams(PATHS.login.path);
      redirect({ redirect: to });
    });
    return () => setUnauthorizedInterceptor();
  }, [
    setUnauthorizedInterceptor,
    redirect,
  ]);

  return null;
};

export default AuthUnauthorizedInterpectorSetter;
