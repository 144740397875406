import type { ChangeEvent, ReactNode } from 'react';
import { useCallback, type FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './RoomListItem.module.css';

import type { InternalPersonalView, RoomId } from 'src/services/api';
import {
  type ApiRoomPreviewView,
  type RoomOccupantRole,
  ROOM_OCCUPANT_ROLE,
  type RoomPermission,
  ROOM_PERMISSION,
  type ApiUserView,
} from 'src/services/api';

import { useMediumMediaQuery, useSmallMediaQuery } from 'src/hooks/responsive';
import { PATHS } from 'src/constants/paths';
import { IconDelete, IconRestore } from 'src/assets/svg';
import { AttributeList } from 'src/components/AttributeList';
import { Button } from 'src/components/Button/Button';
import { Checkbox, CheckboxSizeVariant } from 'src/components/Checkbox';
import { isValidFunction } from 'src/utils';
import { RoomChips } from 'src/containers/Room/RoomChips';
import { extractRoomData } from 'src/containers/Room/functions';
import { RoomListItemHeaderLink } from 'src/containers/RoomListItem/RoomListItemHeaderLink';
import { RoomLinkAttribute } from 'src/containers/Room/RoomLinkAttribute';
import { RoomExternalLinkAttribute } from 'src/containers/Room/RoomExternalLinkAttribute';
import { RoomOccupantsAttribute } from 'src/containers/Room/RoomOccupantsAttribute';
import { RoomRecordsAttribute } from 'src/containers/Room/RoomRecordsAttribute';

import type * as T from './types';
import { StyledListItemBlock } from 'src/components/Styled/Block/StyledListItemBlock';
import { TitleLink } from 'src/components/Link/TitleLink';
import { FlexContainer } from 'src/components/FlexContainer';

const RoomListItem: FC<T.RoomListItemProps> = (props) => {
  const {
    checkable,
    checked: checkedProp,
    className,
    currentUser,
    data,
    onCheck: onCheckProp,
  } = props;
  const {
    description,
    deleted: deletedProp,
    externalLink,
    id,
    link,
    name,
    occupants: occupantsProp,
    owner,
    permissions: permissionsProp,
    recordCount,
  } = data;

  const { t } = useTranslation();
  const isMedium = useMediumMediaQuery();
  const isSmall = useSmallMediaQuery();

  const {
    allOccupants,
    deleted,
    deleteAvailable,
    externalLinkAvailable,
    isModerator,
    isOwner,
    occupants,
    permissions,
    restoreAvailable,
  } = extractRoomData(data, currentUser);

  const currentUserId = currentUser?.id;

  const allOccupantsCount = allOccupants.length;

  // actions
  const actions: ReactNode[] = [];

  if (deleteAvailable) {
    actions.push(
      <Button
        className={classes.actionButton}
        key="delete"
        icon={IconDelete}
        variant="text"
        colorVariant="danger"
      >
        {t('room.actions.delete')}
      </Button>
    );
  }

  if (restoreAvailable) {
    actions.push(
      <Button
        className={classes.actionButton}
        key="delete"
        icon={IconRestore}
        variant="text"
        colorVariant="primary"
      >
        {t('room.actions.restore')}
      </Button>
    );
  }

  //chips
  const chips = (
    <RoomChips
      className={classes.chipContainer}
      deleted={deleted}
      externalLinkAvailable={externalLinkAvailable}
      isModerator={isModerator}
      isOwner={isOwner}
    />
  );

  // attributes
  let linkAttributes: ReactNode | undefined;
  let attributes: ReactNode | undefined;
  // link
  const linkAttribute = link ? (
    <RoomLinkAttribute
      key="link"
      link={link}
      sizeVariant="small"
    />
  ) : null;

  const externalLinkAttribute =
    externalLinkAvailable && externalLink ? (
      <RoomExternalLinkAttribute
        className={classes.secondAttribute}
        externalLink={externalLink}
        key="externalLink"
        sizeVariant="small"
      />
    ) : null;

  if (linkAttribute || externalLinkAttribute) {
    linkAttributes = (
      <div
        className={classNames(
          classes.attributesWrapper,
          externalLinkAttribute && classes.attributesWrapperGrid
        )}
      >
        {linkAttribute}
        {externalLinkAttribute}
      </div>
    );
  }

  // occupants
  const occupantsAttribute =
    allOccupantsCount > 0 ? (
      <RoomOccupantsAttribute
        key="occupants"
        currentUser={currentUser}
        occupants={occupants}
        owner={owner}
        sizeVariant="small"
      />
    ) : null;

  // records
  const recordsAttribute =
    recordCount && recordCount > 0 ? (
      <RoomRecordsAttribute
        className={classes.secondAttribute}
        count={recordCount}
        key="records"
        sizeVariant="small"
      />
    ) : null;

  if (occupantsAttribute || recordsAttribute) {
    attributes = (
      <div
        className={classNames(
          classes.attributesWrapper,
          recordsAttribute && classes.attributesWrapperGrid
        )}
      >
        {occupantsAttribute}
        {recordsAttribute}
      </div>
    );
  }

  // actions
  const actionContainer = null;

  // checkbox
  const checked = checkable && checkedProp;

  const onCheck = (event: ChangeEvent<HTMLInputElement>) => {
    isValidFunction(onCheckProp) && onCheckProp(data, event);
  };

  const checkbox = checkable && (
    <div className={classes.checkboxWrapper}>
      <Checkbox
        checked={checked}
        colorVariant={'primary'}
        onChange={onCheck}
        sizeVariant="small"
      />
    </div>
  );

  return (
    <StyledListItemBlock
      accented={checked}
      className={classNames(
        classes.root,
        checkable && classes.rootCheckable,
        checked && classes.rootChecked,
        Boolean(actionContainer) && classes.hasActions,
        className
      )}
      colorVariant="primary"
    >
      <div className={classes.wrapper}>
        {checkbox}
        <div className={classes.data}>
          <div className={classes.header}>
            <TitleLink
              className={classes.link}
              colorVariant="primary"
              noWrap
              to={PATHS.room.format(id)}
              rel="noopener"
            >
              {name}
            </TitleLink>
            {isSmall && chips}
            {actionContainer}
          </div>

          <div className={classes.details}>
            {description && (
              <p className={classes.description}>{description}</p>
            )}
            {!isSmall && chips}
            {linkAttributes}
            {attributes}
          </div>
        </div>
      </div>
    </StyledListItemBlock>
  );
};

export default RoomListItem;
