import type { ReactElement } from 'react';
import type { FieldValues } from 'react-hook-form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import * as T from './types';
import classes from './FormItemTypedErrorBlockContent.module.css';
import { getTypedErrorMessage } from './functions';

const FormItemTypedErrorBlockContent = <
  E extends Element,
  V,
  T extends FieldValues = FieldValues
>(
  props: T.FormItemTypedErrorBlockContentProps<E, V, T>
): ReactElement<any, any> | null => {
  const { className, payload } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.root, className)}>
      {getTypedErrorMessage(t, payload)}
    </div>
  );
};

export default FormItemTypedErrorBlockContent;
