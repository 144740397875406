import classNames from 'classnames';
import type { Ref } from 'react';
import {
  type DetailedHTMLProps,
  type InputHTMLAttributes,
  type FC,
  type ReactNode,
  type ChangeEvent,
  useMemo,
  type ChangeEventHandler,
  forwardRef,
  type PropsWithRef,
} from 'react';
import { isValidFunction } from 'src/utils';

import classes from './FileInput.module.css';

export type FileInputChangeHandler = (
  files: FileList | null,
  event: ChangeEvent<HTMLInputElement>
) => void;

export interface FileInputProps {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  id: string;
  inputProps?: Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'disabled' | 'id' | 'type' | 'onChange'
  >;
  onChange?: FileInputChangeHandler;
  ref?: Ref<HTMLInputElement>;
}

const FileInput: FC<FileInputProps> = forwardRef<
  HTMLInputElement,
  FileInputProps
>((props, ref) => {
  const {
    children,
    className,
    disabled,
    id,
    inputProps,
    onChange: onChangeProp,
  } = props;

  const onChange: ChangeEventHandler<HTMLInputElement> | undefined = useMemo(
    () =>
      isValidFunction(onChangeProp)
        ? (event: ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files;
            onChangeProp(files, event);
          }
        : undefined,
    [onChangeProp]
  );

  return (
    <div className={classNames(classes.root, className)}>
      {children}
      <input
        {...inputProps}
        className={classes.input}
        disabled={disabled}
        id={id}
        onChange={onChange}
        ref={ref}
        type="file"
      ></input>
    </div>
  );
});

export default FileInput;
