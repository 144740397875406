import classNames from 'classnames';
import { memo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Placeholder,
  PLACEHOLDER_COLOR_VARIANT,
} from 'src/components/Placeholder';

import classes from './RoomListNoDataPlaceholder.module.css';

export interface RoomListNoDataPlaceholderProps {
  className?: string;
}

const RoomListNoDataPlaceholder: FC<RoomListNoDataPlaceholderProps> = (
  props
) => {
  const { className } = props;
  const { t } = useTranslation();

  const icon = <div className={classes.icon} />;

  return (
    <Placeholder
      className={className}
      colorVariant={PLACEHOLDER_COLOR_VARIANT.primary}
      description={t('search.noResultsDescription')}
      slots={{ icon }}
      title={t('search.noResultsTitle')}
    />
  );
};

export default memo(RoomListNoDataPlaceholder);
