import {
  USER_LIST_DEFAULT_SORT,
  type UserListSort,
  USER_LIST_SORT_LIST,
  USER_LIST_DEFAULT_DISPLAY_PAGE,
  USER_LIST_DEFAULT_PAGE_SIZE,
  USER_LIST_PAGE_SIZE_VALID_LIST,
} from 'src/constants/users';

import {
  queryFromURLMapperFactory,
  queryToURLMapperFactory,
  sortFromURLMapperFactory,
  sortToURLMapperFactory,
  pageFromURLMapperFactory,
  pageToURLMapperFactory,
  pageSizeToURLMapperFactory,
  pageSizeFromURLMapperFactory,
} from 'src/utils/mappers/common';

// query
export const USER_LIST_QUERY_SEARCH_PARAM = 'q';

export const queryFromURLMapper = queryFromURLMapperFactory(
  USER_LIST_QUERY_SEARCH_PARAM
);
export const queryToURLMapper = queryToURLMapperFactory(
  USER_LIST_QUERY_SEARCH_PARAM
);

// sort
export const USER_LIST_SORT_SEARCH_PARAM = 'sort_by';

export const sortFromURLMapper = sortFromURLMapperFactory(
  USER_LIST_SORT_SEARCH_PARAM,
  USER_LIST_DEFAULT_SORT,
  Object.values(USER_LIST_SORT_LIST)
);
export const sortToURLMapper = sortToURLMapperFactory<UserListSort>(
  USER_LIST_SORT_SEARCH_PARAM,
  USER_LIST_DEFAULT_SORT
);

// page
export const USER_LIST_PAGE_SEARCH_PARAM = 'page';

export const pageFromURLMapper = pageFromURLMapperFactory(
  USER_LIST_PAGE_SEARCH_PARAM,
  USER_LIST_DEFAULT_DISPLAY_PAGE
);

export const pageToURLMapper = pageToURLMapperFactory(
  USER_LIST_PAGE_SEARCH_PARAM,
  USER_LIST_DEFAULT_DISPLAY_PAGE
);

// pageSize
export const USER_LIST_PAGE_SIZE_SEARCH_PARAM = 'page_size';

export const pageSizeFromURLMapper = pageSizeFromURLMapperFactory(
  USER_LIST_PAGE_SIZE_SEARCH_PARAM,
  USER_LIST_DEFAULT_PAGE_SIZE,
  USER_LIST_PAGE_SIZE_VALID_LIST
);

export const pageSizeToURLMapper = pageSizeToURLMapperFactory(
  USER_LIST_PAGE_SIZE_SEARCH_PARAM,
  USER_LIST_DEFAULT_PAGE_SIZE
);
