import type { FC, ReactNode, ReactElement } from 'react';

import AuthForbiddenRedirect from 'src/components/Auth/AuthForbidden/components/AuthForbiddenRedirect';
import { useAuthState } from 'src/contexts/auth';

export interface AuthForbiddenProps {
  /**
   * Будут отрисованы, если пользователь НЕаутентифицирован
   */
  children?: ReactNode;

  /**
   * Учитывать для перехода параметры редиректа из адреса и location state
   */
  parseParams?: boolean;
}

/**
 * Компонент запрещает отображение children, если пользователь аутентифицирован,
 * иницируя редирект на главную страницу.
 * Некоторые страницы недоступны для посещения аутентифицированным пользователям.
 */
const AuthForbidden: FC<AuthForbiddenProps> = (props) => {
  const { children, parseParams } = props;
  const { authenticated } = useAuthState();

  return authenticated ? (
    <AuthForbiddenRedirect parseParams={parseParams} />
  ) : (
    // пользователь аутентифицирован, но у него нет прав на просмотр,
    // в этом случае показываем 404
    (children as ReactElement)
  );
};

export default AuthForbidden;
