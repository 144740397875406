import {
  type FC,
  type ReactNode,
  type SyntheticEvent,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';

import { Popper, type PopperProps } from 'src/components/Popper';

import classes from './Dropdown.module.css';

export type DropdownCloseRequestedType = 'clickAway';
export interface DropdownCloseRequestedHandler<
  T extends string = DropdownCloseRequestedType
> {
  (event: Event, type: T): void;
  (event: SyntheticEvent, type: T): void;
}

export type DropdownRenderer = (
  onCloseRequested: DropdownCloseRequestedHandler
) => ReactNode;

export interface DropdownProps {
  /**
   * HTML-элемент или виртуальный элемент PopperVirtualElement (см. popperjs),
   * на основе которого будет устанавливаться позиция поппера
   */
  anchorEl?: HTMLElement | null;

  /**
   * Контент поппера
   */
  children?: ReactNode;

  onCloseRequested?: DropdownCloseRequestedHandler;
  open: boolean;

  placement?: PopperProps['placement'];
}

const Dropdown: FC<DropdownProps> = (props) => {
  const {
    anchorEl,
    children,
    onCloseRequested,
    open,
    placement = 'bottom-end',
  } = props;

  const onClickAwayHandler = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
        return;
      }

      onCloseRequested &&
        typeof onCloseRequested === 'function' &&
        onCloseRequested(event, 'clickAway');
    },
    [onCloseRequested]
  );

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorEl) {
      anchorEl.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Popper
      anchorEl={anchorEl}
      disablePortal
      open={open}
      placement={placement}
      transition
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <div className={classes.content}>
            <ClickAwayListener onClickAway={onClickAwayHandler}>
              <div className={classes.children}>{children}</div>
            </ClickAwayListener>
          </div>
        </Grow>
      )}
    </Popper>
  );
};

export default Dropdown;
