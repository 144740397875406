import { ROOM_LIST_DEFAULT_FILTER_DATA } from 'src/constants/rooms';
import type { InternalRoomListFilterData } from 'src/types/common';
import type { FromURLMapper, ToURLMapper } from 'src/utils/mappers/types';

// filter
export const ROOM_LIST_FILTER_SEARCH_PARAMS = {
  onlyMine: 'mine',
} as const;

const TRUE_VALUE = '1';

// "только мои"
export const onlyMineFromURLMapper: FromURLMapper<boolean> = (input) => {
  const param = input.get(ROOM_LIST_FILTER_SEARCH_PARAMS.onlyMine);
  const trimmed = param && param.trim();
  const result = trimmed ? trimmed === TRUE_VALUE : false;

  return result;
};

export const onlyMineToURLMapper: ToURLMapper<boolean> = (input) => {
  const { value, accumulator } = input;
  if (Boolean(value) !== ROOM_LIST_DEFAULT_FILTER_DATA.onlyMine) {
    accumulator.set(ROOM_LIST_FILTER_SEARCH_PARAMS.onlyMine, TRUE_VALUE);
  }

  return accumulator;
};

// весь фильтр
export const filterFromURLMapper: FromURLMapper<InternalRoomListFilterData> = (
  input
) => {
  const onlyMine = onlyMineFromURLMapper(input);

  return {
    onlyMine,
  };
};

export const filterToURLMapper: ToURLMapper<InternalRoomListFilterData> = (
  input
) => {
  const { value: { onlyMine } = {}, accumulator } = input;

  onlyMineToURLMapper({ accumulator, value: onlyMine });

  return accumulator;
};
