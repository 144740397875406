import type { FieldValues, FieldPath } from 'react-hook-form';

import { USER_LOGIN_MAX_LENGTH } from 'src/constants/users';
import type { ErrorData } from 'src/services/api/functions';
import type { RequestDisplayErrorData } from 'src/types/common';

export function getLoginSuggestion(value: string): string {
  let suggestion: string = '';
  if (value) {
    const index = value.indexOf('@');
    suggestion = value.slice(
      0,
      Math.min(index === -1 ? value.length : index, USER_LOGIN_MAX_LENGTH)
    );
  }
  return suggestion;
}

export function errorDataToDisplayErrorData(
  error: ErrorData
): RequestDisplayErrorData {
  const { status, message } = error;
  return { message, status };
}

export function getFirstInvalidField<T extends FieldValues = FieldValues>(
  validList: boolean[],
  fieldList: Array<FieldPath<T>>
): FieldPath<T> | undefined {
  const position = validList.indexOf(false);
  return position !== -1 && position < fieldList.length
    ? fieldList[position]
    : undefined;
}
