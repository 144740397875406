import classNames from 'classnames';
import { type Ref, useRef, type FC, type ReactNode, ReactElement } from 'react';
import { CSSTransition } from 'react-transition-group';
import { type CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import classes from './SlideCssTransition.module.css';

export type SlideCssTransitionDirection = 'up' | 'down';

export interface SlideCssTransitionClasses extends CSSTransitionClassNames {}

const ACTIVE_CLASSES: (keyof CSSTransitionClassNames)[] = [
  'enter',
  'enterActive',
  'exit',
  'exitActive',
  'exitDone',
];

export interface SlideCssTransitionProps<T extends Element> {
  /**
   * анимировать первое появление или нет
   */
  appear?: boolean;
  classes?: Partial<SlideCssTransitionClasses>;
  direction?: SlideCssTransitionDirection;
  /**
   * несмотря на то, что анимация идет средствами CSS,
   * длительность нужна, чтобы компонент вовремя подставлял
   * классы, по умолчанию 200 мс
   */
  duration?: number;
  in: boolean;
  render: (ref: Ref<T>) => ReactNode;
}

const SlideCssTransition = <T extends HTMLElement>(
  props: SlideCssTransitionProps<T>,
  context?: any
): ReactElement<any, any> | null => {
  const {
    appear,
    classes: classesProp = {},
    direction = 'up',
    duration = 200,
    in: inProp,
    render: renderProp,
  } = props;

  const down = direction === 'down';
  const transitionClasses: CSSTransitionClassNames =
    ACTIVE_CLASSES.reduce<CSSTransitionClassNames>((accumulator, current) => {
      const transitionClass = down ? `${current}Down` : current;
      accumulator[current] = classNames(
        classes[transitionClass],
        classesProp[current]
      );

      return accumulator;
    }, {});

  const ref = useRef<T>(null);

  return (
    <CSSTransition
      appear={appear}
      classNames={transitionClasses}
      in={inProp}
      nodeRef={ref}
      mountOnEnter
      timeout={duration}
    >
      {renderProp?.(ref)}
    </CSSTransition>
  );
};

export function slideCssTransitionFactory<T extends HTMLElement>(): FC<
  SlideCssTransitionProps<T>
> {
  return SlideCssTransition;
}

export default SlideCssTransition;
