import { useTranslation, Trans } from 'react-i18next';
import type { FieldValues } from 'react-hook-form';

import { FormItemWithTypedError } from 'src/components/Form/FormItemWithTypedError';
import { TextInput } from 'src/components/Input/TextInput';
import { StyledFormItemHint } from 'src/components/Styled/Forms/StyledFormItemHint';
import { USER_LOGIN_MAX_LENGTH } from 'src/constants/users';
import { FormItemAsyncTypedErrorBlock } from 'src/components/Styled/Forms/FormItemAsyncTypedErrorBlock';

import * as T from './types';
import classes from './UserLoginFormItem.module.css';

const DEFAULT_INPUT_ID = 'user_form_login_input';

const UserLoginFormItem = <T extends FieldValues = FieldValues>(
  props: T.UserLoginFormItemProps<T>
) => {
  const {
    className,
    defaultValue,
    disabled,
    hintVisible,
    id = DEFAULT_INPUT_ID,
    maxLength = USER_LOGIN_MAX_LENGTH,
    name = 'login',
    validation,
    validationState,
  } = props;
  const { validating, hasError } = validationState || {};

  const { t } = useTranslation();

  return (
    <FormItemWithTypedError<HTMLInputElement, string, T>
      className={className}
      defaultValue={defaultValue}
      name={name}
      label={t('user.form.labelLogin')}
      labelFor={id}
      required
      slots={{
        hint: () =>
          hintVisible ? (
            <StyledFormItemHint>
              <Trans
                i18nKey="user.form.hintLogin"
                t={t}
              >
                <strong>0</strong>
                <strong>1</strong>
                <strong>2</strong>
              </Trans>
            </StyledFormItemHint>
          ) : null,

        input: ({ field: { onChange, onBlur, value, ref }, valid }) => {
          return (
            <TextInput
              className={classes.input}
              colorVariant={valid ? 'secondary' : 'danger'}
              disabled={disabled}
              id={id}
              maxLength={maxLength}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={t('user.form.placeholderLogin')}
              ref={ref}
              type="text"
              sizeVariant="medium"
              value={value}
            />
          );
        },

        validation: (renderProps) => (
          <FormItemAsyncTypedErrorBlock
            asyncHasError={hasError}
            asyncValidating={validating}
            renderProps={renderProps}
          />
        ),
      }}
      validation={validation}
    />
  );
};

export default UserLoginFormItem;
