import {
  ROOM_LIST_DEFAULT_DISPLAY_PAGE,
  ROOM_LIST_DEFAULT_PAGE_SIZE,
  ROOM_LIST_DEFAULT_SORT,
  ROOM_LIST_PAGE_SIZE_VALID_LIST,
  ROOM_LIST_SORT_LIST,
} from 'src/constants/rooms';
import type { RoomListSort } from 'src/constants/rooms';

import {
  queryFromURLMapperFactory,
  queryToURLMapperFactory,
  sortFromURLMapperFactory,
  sortToURLMapperFactory,
  pageFromURLMapperFactory,
  pageToURLMapperFactory,
  pageSizeToURLMapperFactory,
  pageSizeFromURLMapperFactory,
} from 'src/utils/mappers/common';

// query
export const ROOM_LIST_QUERY_SEARCH_PARAM = 'q';

export const queryFromURLMapper = queryFromURLMapperFactory(
  ROOM_LIST_QUERY_SEARCH_PARAM
);
export const queryToURLMapper = queryToURLMapperFactory(
  ROOM_LIST_QUERY_SEARCH_PARAM
);

// sort
export const ROOM_LIST_SORT_SEARCH_PARAM = 'sort_by';

export const sortFromURLMapper = sortFromURLMapperFactory(
  ROOM_LIST_SORT_SEARCH_PARAM,
  ROOM_LIST_DEFAULT_SORT,
  Object.values(ROOM_LIST_SORT_LIST)
);
export const sortToURLMapper = sortToURLMapperFactory<RoomListSort>(
  ROOM_LIST_SORT_SEARCH_PARAM,
  ROOM_LIST_DEFAULT_SORT
);

// page
export const ROOM_LIST_PAGE_SEARCH_PARAM = 'page';

export const pageFromURLMapper = pageFromURLMapperFactory(
  ROOM_LIST_PAGE_SEARCH_PARAM,
  ROOM_LIST_DEFAULT_DISPLAY_PAGE
);

export const pageToURLMapper = pageToURLMapperFactory(
  ROOM_LIST_PAGE_SEARCH_PARAM,
  ROOM_LIST_DEFAULT_DISPLAY_PAGE
);

// pageSize
export const ROOM_LIST_PAGE_SIZE_SEARCH_PARAM = 'page_size';

export const pageSizeFromURLMapper = pageSizeFromURLMapperFactory(
  ROOM_LIST_PAGE_SIZE_SEARCH_PARAM,
  ROOM_LIST_DEFAULT_PAGE_SIZE,
  ROOM_LIST_PAGE_SIZE_VALID_LIST
);

export const pageSizeToURLMapper = pageSizeToURLMapperFactory(
  ROOM_LIST_PAGE_SIZE_SEARCH_PARAM,
  ROOM_LIST_DEFAULT_PAGE_SIZE
);
