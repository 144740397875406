import type { ValueOf } from 'src/types/utils';

export const USER_ROLE = {
  USER: 1,
  ADMIN: 2,
} as const;

/**
 * Роль пользователя в системе
 */
export type UserRole = ValueOf<typeof USER_ROLE>;

export const ALL_USER_ROLES: readonly UserRole[] = Object.values(USER_ROLE);
export const ADMIN_USER_ROLES: readonly UserRole[] = [USER_ROLE.ADMIN];

export interface ApiUserRoleView {
  id: UserRole;
  name?: string;
}

export type ApiUserRoleForm = Pick<ApiUserRoleView, 'id'>;

export type UserId = string;
export type RoomId = string;

export interface ApiPermissionView {
  id: number;
  name: string;
}

export const INTERNAL_USER_PERMISSION = {
  BLOCK: 1,
  UNBLOCK: 2,
  CHANGE_USER_DATA: 3,
  RESEND_PASSWORD_MAIL: 4,
} as const;

export type InternalUserPermission = ValueOf<typeof INTERNAL_USER_PERMISSION>;

/**
 * Полная информация о пользователе, доступна текущему пользователю о себе
 * и Администратору
 */
export interface ApiUserView {
  avatar?: string;

  /**
   * Поле обязательное, но доступ только у Администратора,
   * поэтому идет опциональным
   */
  created?: number;

  /**
   * Поле обязательное, но доступ только у Администратора,
   * поэтому идет опциональным
   */
  email?: string;

  id: UserId;

  /**
   * Поле обязательное, но доступ только у Администратора,
   * поэтому идет опциональным
   */
  login?: string;

  locked?: number;

  /**
   * Доступ на чтение - все, редактирование - только Администратор
   */
  name: string;

  role: ApiUserRoleView;

  permissions?: ApiPermissionView[];
}

/**
 * Объект юзера с точки зрения Администратора
 */
export interface ApiUserAdminView extends ApiUserView {
  created: NonNullable<ApiUserView['created']>;
  email: NonNullable<ApiUserView['email']>;
  login: NonNullable<ApiUserView['login']>;
  name: NonNullable<ApiUserView['name']>;
}

/**
 * Интерфейс с данным собственного профиля, некоторые поля присутствуют всегда,
 * в сравнении с UserData
 */
export interface ApiPersonalView extends ApiUserView {
  created: NonNullable<ApiUserView['created']>;
  email: NonNullable<ApiUserView['email']>;
  login: NonNullable<ApiUserView['login']>;
  name: NonNullable<ApiUserView['name']>;
}

export interface InternalPersonalView {
  avatar?: ApiPersonalView['avatar'];
  created: ApiPersonalView['created'];
  email: ApiPersonalView['email'];
  id: ApiPersonalView['id'];
  login: ApiPersonalView['login'];
  locked?: boolean;
  name: ApiPersonalView['name'];
  role: UserRole;
  permissions: InternalUserPermission[];
}

export interface ApiPersonalUserForm {
  avatar?: string;
  email: string;
}

export interface ApiPersonalAdminForm {
  avatar?: string;
  email: string;
  name: string;
}

export type ApiPersonalForm = ApiPersonalUserForm | ApiPersonalAdminForm;

export interface InternalUserView {
  avatar?: ApiUserView['avatar'];

  created?: ApiUserView['created'];

  /**
   * Доступно только для Администратора
   */
  email?: ApiUserView['email'];
  id: UserId;

  /**
   * Доступно только для Администратора
   */
  login?: ApiUserView['login'];
  locked?: boolean;
  name: string;
  role: UserRole;
  permissions: InternalUserPermission[];
}

export interface InternalUserAdminView extends InternalUserView {
  email: NonNullable<InternalUserView['email']>;
  login: NonNullable<InternalUserView['login']>;
  name: NonNullable<InternalUserView['name']>;
}

export const ROOM_OCCUPANT_ROLE = {
  PARTICIPANT: 1,
  MODERATOR: 2,
  OWNER: 3,
} as const;

export type RoomOccupantRole = ValueOf<typeof ROOM_OCCUPANT_ROLE>;

export const ROOM_PERMISSION = {
  /**
   * Изменять данные комнате, доступно владельцу и Администратору
   */
  CHANGE: 1,
  /**
   * Удалять комнату, доступно владельцу и Администратору
   */
  DELETE: 2,
  /**
   * Восстанавливать удаленную комнату, доступно Администратору
   */
  RESTORE: 3,
  /**
   * Доступ к информации о внешних ссылках,
   * доступно владельцу, Администратору и Модератору в комнате
   */
  EXTERNAL: 4,
} as const;

export type RoomPermission = ValueOf<typeof ROOM_PERMISSION>;

/**
 * Пользователь в контексте комнаты, не должен содержать лишнюю информацию
 */
export interface RoomOccupantView {
  id: ApiUserView['id'];

  /**
   * Так же, как и в UserView, доступ на чтение имеют все
   */
  name: ApiUserView['name'];

  avatar?: ApiUserView['avatar'];
  occupantRole: RoomOccupantRole;
  locked?: ApiUserView['locked'];
}

export type RoomOccupantSuggestionView = Pick<
  RoomOccupantView,
  'id' | 'name' | 'avatar'
>;

export type NamelessRoomOccupant = Pick<
  RoomOccupantView,
  'id' | 'occupantRole'
>;

export interface RoomExternalLink {
  enabled: boolean;
  link: string;
  /**
   * Если поле отсутствует, то ссылка считается бессрочной
   */
  expires?: number;
}

export interface ApiRoomView {
  id: RoomId;
  name: string;
  /**
   * Поле доступно только Администраторам
   */
  deleted?: boolean;
  description?: string;
  created: number;
  owner: RoomOccupantView;
  occupants?: RoomOccupantView[];
  link?: string;
  /**
   * Поле доступно только при наличии соответствующего разрешения
   * в permissions
   */
  externalLink?: RoomExternalLink;
  permissions?: RoomPermission[];

  records?: RecordData[];
}

/**
 * Информация о комнате в рамках списка
 */
export type ApiRoomPreviewView = Pick<
  ApiRoomView,
  | 'created'
  | 'deleted'
  | 'description'
  | 'externalLink'
  | 'id'
  | 'link'
  | 'name'
  | 'owner'
  | 'occupants'
  | 'permissions'
> & {
  /**
   * Доступно только количество записей, нет смысла передавать полную информацию о записях
   */
  recordCount?: number;
};

export interface CreateRoomForm {
  name: string;
  description?: string;
  occupants?: NamelessRoomOccupant[];
  externalLink?: boolean;
  externalLinkExpires?: number;
}

export interface ApiCreateUserForm {
  avatar?: string;
  email: string;
  login: string;
  name: string;
  role: ApiUserRoleForm;
}

export interface ApiCreateUserResult {
  sendMail: boolean;
  obj: ApiUserAdminView;
}

export interface ApiSendMailResult {
  sendMail: boolean;
}

export interface InternalCreateUserResult {
  sendMail: boolean;
  obj: InternalUserAdminView;
}

export interface ApiUpdateUserForm {
  avatar?: string;
  email: string;
  name: string;
  role: ApiUserRoleForm;
}

export interface ApiChangePasswordForm {
  oldPassword: string;
  newPassword: string;
}

export interface RecordData {
  id: number;
  link?: string;
  duration: number;
  created: number;
  size: number;
}

export type UpdateUserForm = Pick<
  ApiCreateUserForm,
  'avatar' | 'email' | 'name' | 'role'
>;

export interface RoomExternalLinkForm {
  enabled: boolean;
  expires?: number;
}

export interface PageRequest {
  page: number;
  size?: number;
  sort?: any;
}

export interface PageablePageData {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Pageable<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: PageablePageData;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface UserListRequestFilterData {
  query?: string;
  blocked?: boolean;
  unblocked?: boolean;
  roles?: UserRole[];
}

export interface ApiRoomListRequestFilter {
  query?: string;
  onlyMine?: boolean;
}

export interface UserLoginCheckView {
  result: boolean;
}

export interface CheckResultView {
  result: boolean;
}

export type ApiCheckTokenResult = string | undefined;

export interface ApiSetPasswordForm {
  oldPassword: string;
  newPassword: string;
}

export interface ApiVerifyPasswordTokenForm {
  token: string;
}
