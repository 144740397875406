import type { ReactNode, FC } from 'react';

import { AuthAwaited } from 'src/components/Auth/AuthAwaited';
import { FullscreenSpinner } from 'src/components/Spinner/FullscreenSpinner';

export interface AuthAwaitedWithFullscreenFallbackProps {
  children?: ReactNode;
  className?: string;
}

const AuthAwaitedWithFullscreenFallback: FC<
  AuthAwaitedWithFullscreenFallbackProps
> = (props) => {
  const { className, ...rest } = props;

  return (
    <AuthAwaited
      fallback={<FullscreenSpinner />}
      {...rest}
    />
  );
};

export default AuthAwaitedWithFullscreenFallback;
