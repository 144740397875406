import { type FC, type HTMLAttributes } from 'react';
import classNames from 'classnames';

import type { ReactSVGComponent } from 'src/types/svg';
import classes from './Icon.module.css';

export const DEFAULT_ICON_SIZE = 24;
const DEFAULT_ICON_COLOR = 'black';

export interface IconProps {
  className?: HTMLAttributes<HTMLSpanElement>['className'];
  color?: string;
  size?: number;
  svg: ReactSVGComponent;
  svgClassName?: HTMLAttributes<HTMLSpanElement>['className'];
}

const Icon: FC<IconProps> = (props) => {
  const { className, color, size, svg: SvgIcon, svgClassName } = props;

  const calculatedSize = size ?? DEFAULT_ICON_SIZE;
  const calculatedColor = color ?? DEFAULT_ICON_COLOR;

  return (
    <span className={classNames(classes['root'], className)}>
      <SvgIcon
        className={svgClassName}
        fill={calculatedColor}
        height={calculatedSize}
        width={calculatedSize}
      />
    </span>
  );
};

export default Icon;
