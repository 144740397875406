import type { UseFormProps, FieldValues, UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import type { UseFormControllerReturn } from './types';

// изврат с Partial и Pick, иначе возникают ошибки типизации
const DEFAULT_USE_FORM_PROPS: Partial<
  Pick<
    UseFormProps,
    'criteriaMode' | 'mode' | 'reValidateMode' | 'shouldFocusError'
  >
> = {
  criteriaMode: 'all',
  mode: 'onSubmit',
  reValidateMode: 'onChange',
  shouldFocusError: true,
};

export function useFormController<T extends FieldValues = FieldValues>(
  props: UseFormProps<T>
): UseFormControllerReturn<T> {
  return useForm<T>({
    ...DEFAULT_USE_FORM_PROPS,
    ...props,
  }) as UseFormControllerReturn<T>;
}
