import {
  type Executor,
  type ExecutorEntry,
  type ExecutorEntryId,
  type ExecutorJob,
} from './types';

export abstract class DebuggableExecutor implements Executor {
  private _debug: boolean;
  private _label?: string;

  constructor(debug?: boolean, label?: string) {
    this._debug = Boolean(debug);
    this._label = label;
  }

  abstract invalidateAll(): void;
  abstract queue<T>(job: ExecutorJob<T>): ExecutorEntry<T>;

  protected log(message: any, ...args: any[]): void {
    if (this._debug) {
      const { _label: label } = this;
      console.log(label ? `(${label})` : '', message, ...args);
    }
  }

  protected logEntryState(id: ExecutorEntryId, ...args: any[]): void {
    this.log('entry id:', id, ',', ...args);
  }
}
