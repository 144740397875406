import type { MouseEvent } from 'react';
import {
  type MouseEventHandler,
  type ReactNode,
  useState,
  type FC,
  useCallback,
  useMemo,
} from 'react';
import { IconLock, IconUnlock } from 'src/assets/svg';
import { Spinner } from 'src/components/Spinner';

import { ActionContainer } from 'src/components/ActionContainer/ActionContainer';
import { ActionEntry } from 'src/components/ActionContainer/ActionEntry';
import { useActionContainer } from 'src/components/ActionContainer/useActionContainer';
import { IconizedContent } from 'src/components/IconizedContent';
import {
  INTERNAL_USER_PERMISSION,
  type InternalUserPermission,
} from 'src/services/api';

import classes from './UserListItemActionContainer.module.css';
import { useTranslation } from 'react-i18next';
import { isValidFunction } from 'src/utils';
import classNames from 'classnames';

export type UserListItemActionBlockHandler = (
  event: MouseEvent
) => Promise<void>;

export interface UserListItemActionContainerProps {
  onBlock?: UserListItemActionBlockHandler;
  onUnblock?: UserListItemActionBlockHandler;
  permissions: InternalUserPermission[];
}

const UserListItemActionContainer: FC<UserListItemActionContainerProps> = (
  props
) => {
  const { onBlock: onBlockProp, onUnblock: onUnblockProp, permissions } = props;

  // i18n
  const { t } = useTranslation();

  // action controller
  const controller = useActionContainer();
  const { close } = controller;

  // requests
  const [
    disabled,
    setDisabled,
  ] = useState<boolean>(false);
  const [
    isBlocking,
    setBlocking,
  ] = useState<boolean>(false);
  const [
    isUnblocking,
    setUnblocking,
  ] = useState<boolean>(false);

  const onBlock: MouseEventHandler | undefined = useMemo(
    () =>
      isValidFunction(onBlockProp)
        ? (event) => {
            setDisabled(true);
            setBlocking(true);
            onBlockProp(event).finally(() => {
              close();
              setDisabled(false);
              setBlocking(false);
            });
          }
        : undefined,
    [
      onBlockProp,
      close,
    ]
  );

  const onUnblock: MouseEventHandler | undefined = useMemo(
    () =>
      isValidFunction(onUnblockProp)
        ? (event) => {
            setDisabled(true);
            setUnblocking(true);
            onUnblockProp(event).finally(() => {
              close();
              setDisabled(false);
              setUnblocking(false);
            });
          }
        : undefined,
    [
      onUnblockProp,
      close,
    ]
  );

  const actions: ReactNode[] = [];
  if (permissions.length > 0) {
    // block
    if (permissions.includes(INTERNAL_USER_PERMISSION.BLOCK)) {
      actions.push(
        <ActionEntry
          disabled={disabled}
          id="block"
          key="block"
          onClick={onBlock}
        >
          <IconizedContent
            className={classes.dangerAction}
            classes={{ icon: classNames(classes.icon, classes.dangerIcon) }}
            icon={!isBlocking ? IconLock : undefined}
            keepIconSpace
            prefix={isBlocking && <Spinner size={16} />}
          >
            {t('user.actions.block')}
          </IconizedContent>
        </ActionEntry>
      );
    }

    // unblock
    if (permissions.includes(INTERNAL_USER_PERMISSION.UNBLOCK)) {
      actions.push(
        <ActionEntry
          disabled={disabled}
          id="unblock"
          key="unblock"
          onClick={onUnblock}
        >
          <IconizedContent
            classes={{ icon: classes.icon }}
            icon={!isUnblocking ? IconUnlock : undefined}
            keepIconSpace
            prefix={isUnblocking && <Spinner size={16} />}
          >
            {t('user.actions.unblock')}
          </IconizedContent>
        </ActionEntry>
      );
    }
  }

  return actions.length > 0 ? (
    <ActionContainer
      className={classes.actionContainer}
      colorVariant="secondary"
      controller={controller}
    >
      {actions}
    </ActionContainer>
  ) : null;
};

export default UserListItemActionContainer;
