import classNames from 'classnames';
import { type FC, type ReactNode } from 'react';

import { type ValueOf } from 'src/types/utils';

import classes from './StyledListItemBlock.module.css';

export const STYLED_LIST_ITEM_BLOCK_COLOR_VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
} as const;

export type StyledListItemBlockColorVariant = ValueOf<
  typeof STYLED_LIST_ITEM_BLOCK_COLOR_VARIANT
>;

export interface StyledListItemBlockProps {
  accented?: boolean;
  children?: ReactNode;
  className?: string;
  colorVariant?: StyledListItemBlockColorVariant;
  hasPrefix?: boolean;
  hasSuffix?: boolean;
}

const StyledListItemBlock: FC<StyledListItemBlockProps> = (props) => {
  const {
    accented,
    children,
    className,
    colorVariant = STYLED_LIST_ITEM_BLOCK_COLOR_VARIANT.primary,
    hasPrefix,
    hasSuffix,
  } = props;

  const colorVariantClassName =
    colorVariant === STYLED_LIST_ITEM_BLOCK_COLOR_VARIANT.secondary
      ? classes.secondary
      : null;

  return (
    <div
      className={classNames(
        classes.root,
        colorVariantClassName,
        accented && classes.accented,
        hasPrefix && classes.hasPrefix,
        hasSuffix && classes.hasSuffix,
        className
      )}
    >
      {children}
    </div>
  );
};

export default StyledListItemBlock;
