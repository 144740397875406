import { type FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { PATHS } from 'src/constants/paths';
import LOGO from 'src/assets/images/logo.png';
import SMALL_LOGO from 'src/assets/images/logo_square.png';

import classes from './Header.module.css';
import { HeaderLink } from 'src/components/Link/HeaderLink';

import { HeaderProfileButton } from 'src/containers/HeaderProfileButton';

import { ContentWrapper } from 'src/components/ContentWrapper';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from 'src/containers/LanguageSelector';

export interface HeaderProps {
  className?: string;
}

const Header: FC<HeaderProps> = (props) => {
  const { t } = useTranslation();
  const { className } = props;
  const logoAlt = t('logo');
  const logoClassName = classes.logo;

  return (
    <div className={classNames(classes.root, className)}>
      <ContentWrapper>
        <header className={classes.header}>
          <div className={classes.logoContainer}>
            <Link
              className={classes.logoLink}
              to={PATHS.root.path}
              rel="noopener"
            >
              <img
                alt={logoAlt}
                className={classNames(logoClassName, classes.logoSmall)}
                loading="lazy"
                src={SMALL_LOGO}
              ></img>
              <img
                alt={logoAlt}
                className={classNames(logoClassName, classes.logoLarge)}
                loading="lazy"
                src={LOGO}
              ></img>
            </Link>
          </div>
          <nav className={classes.nav}>
            <HeaderLink
              className={classes.navLink}
              to={PATHS.rooms.path}
            >
              {t('rooms.title')}
            </HeaderLink>
            <HeaderLink
              className={classes.navLink}
              to={PATHS.users.path}
            >
              {t('users.title')}
            </HeaderLink>
          </nav>
          <div style={{ flexGrow: 1 }} />
          <div className={classes.locale}>
            <LanguageSelector />
          </div>
          <HeaderProfileButton className={classes.profileButton} />
        </header>
      </ContentWrapper>
    </div>
  );
};

export default Header;
