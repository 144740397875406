import classNames from 'classnames';
import { memo, type MouseEventHandler, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/Button/Button2';
import {
  Placeholder,
  PLACEHOLDER_COLOR_VARIANT,
} from 'src/components/Placeholder';

import classes from './RoomListErrorPlaceholder.module.css';

export interface RoomListErrorPlaceholderProps {
  className?: string;
  onRetryClick?: MouseEventHandler;
}

const RoomListErrorPlaceholder: FC<RoomListErrorPlaceholderProps> = (props) => {
  const { className, onRetryClick } = props;
  const { t } = useTranslation();

  const icon = <div className={classes.icon} />;
  const appendix = (
    <div className={classes.appendix}>
      <Button
        colorVariant="primary"
        onClick={onRetryClick}
        sizeVariant="small"
        variant="outlined"
      >
        {t('search.noErrorRetry')}
      </Button>
    </div>
  );

  return (
    <Placeholder
      className={className}
      colorVariant={PLACEHOLDER_COLOR_VARIANT.danger}
      slots={{ appendix, icon }}
      title={t('search.noErrorTitle')}
    />
  );
};

export default memo(RoomListErrorPlaceholder);
