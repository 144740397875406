import { type FC, memo } from 'react';

import {
  PlaceholderImage,
  type PlaceholderImageProps,
} from 'src/containers/Placeholder/PlaceholderImage';

import imageURL from 'src/assets/images/error.png';

export interface ErrorPlaceholderImageProps
  extends Omit<PlaceholderImageProps, 'src' | 'srcSet'> {}

const ErrorPlaceholderImage: FC<ErrorPlaceholderImageProps> = (props) => {
  return (
    <PlaceholderImage
      {...props}
      src={imageURL}
    />
  );
};

export default memo(ErrorPlaceholderImage);
