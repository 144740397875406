import classNames from 'classnames';
import { FormProvider } from 'react-hook-form';
import type { UseFormReturn, FieldValues } from 'react-hook-form';

import classes from './Form.module.css';
import * as T from './types';

const Form = <T extends FieldValues = FieldValues>(props: T.FormProps<T>) => {
  const { children, className, controller, ...rest } = props;

  return (
    <FormProvider {...(controller as UseFormReturn<T>)}>
      <form
        {...rest}
        className={classNames(classes.root, className)}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
