import classNames from 'classnames';
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { FieldPath } from 'react-hook-form';

import type { FormSubmitHandler } from 'src/components/Form';
import { Form } from 'src/components/Form/Form';
import { StyledSection } from 'src/components/Styled/Forms/StyledSection';
import { useFormController } from 'src/components/Form/Form/useFormController';
import { USER_ROLE } from 'src/services/api';
import { LoadingButton } from 'src/components/Button/LoadingButton';
import { useUserEmailValidation } from 'src/containers/User/UserEmailFormItem/useUserEmailValidation';
import { useUserNameValidation } from 'src/containers/User/UserNameFormItem/useUserNameValidation';
import { useUserAvatarValidation } from 'src/containers/User/UserAvatarFormItem/useUserAvatartValidation';
import { useUserRoleValidation } from 'src/containers/User/UserRoleFormItem/useUserRoleValidation';
import { maybeGetErrorDataFromBackend } from 'src/services/api/functions';
import { defaultErrorToast } from 'src/components/Toast/functions';
import { errorDataToDisplayErrorData, getFirstInvalidField } from './functions';
import { isValidFunction } from 'src/utils';
import { UserEmailFormItem } from 'src/containers/User/UserEmailFormItem';
import { UserNameFormItem } from 'src/containers/User/UserNameFormItem';
import { UserRoleFormItem } from 'src/containers/User/UserRoleFormItem';
import { UserAvatarFormItem } from 'src/containers/User/UserAvatarFormItem';
import { Button } from 'src/components/Button/Button2';
import { encodeImage } from 'src/containers/AvatarUpload/functions';

import type * as T from './types';
import classes from './UserEditForm.module.css';

const DEFAULT_FORM_DATA: Required<T.UserEditFormData> = {
  avatar: null,
  email: '',
  name: '',
  role: USER_ROLE.USER,
};

const FORM_FIELD_LIST: Array<FieldPath<T.UserEditFormData>> = [
  'email',
  'name',
  'role',
  'avatar',
];

const UserEditForm: FC<T.UserEditFormProps> = (props) => {
  const {
    className,
    initialData: initialDataProp,
    onCancel,
    onSubmit: onSubmitProp,
  } = props;
  const { t } = useTranslation();

  const defaultValues: Required<T.UserEditFormData> = initialDataProp
    ? {
        ...DEFAULT_FORM_DATA,
        ...initialDataProp,
        avatar: initialDataProp.avatar ? { url: initialDataProp.avatar } : null,
      }
    : DEFAULT_FORM_DATA;

  const controller = useFormController<T.UserEditFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues,
  });

  const { handleSubmit, setFocus } = controller;

  // email
  const {
    validate: validateEmail,
    validation: emailValidation,
    validationState: emailValidationState,
  } = useUserEmailValidation(
    controller,
    'email',
    defaultValues.email ? [defaultValues.email] : undefined
  );

  // name
  const { validate: validateName, validation: nameValidation } =
    useUserNameValidation(controller, 'name');

  // avatar
  const { validate: validateAvatar, validation: avatartValidation } =
    useUserAvatarValidation(controller, 'avatar');

  // role
  const { validate: validateRole, validation: roleValidation } =
    useUserRoleValidation(controller, 'role');

  // submit
  const [
    disabled,
    setDisabled,
  ] = useState<boolean>(false);

  const [
    firstInvaliField,
    setFirstInvaliField,
  ] = useState<FieldPath<T.UserEditFormData> | undefined>();

  // валидация ручная, поэтому submit формы всегда будет успешным
  const onSubmitValid: FormSubmitHandler<T.UserEditFormData> = async (
    data,
    event
  ) => {
    const { avatar, email, name, role } = data;
    let localFirstInvaliField: FieldPath<T.UserEditFormData> | undefined;

    try {
      setDisabled(true);
      setFirstInvaliField(undefined);
      // валидация
      // отдельные вызовы для валидации МОГУТ выбрасывать исключения,
      // поэтому используется Promise.all, в случае исключения
      // процесс остановится, API-вызов не будет сделан
      const validList = await Promise.all([
        validateEmail(email),
        validateName(name),
        validateRole(role),
        validateAvatar(avatar),
      ]);

      if (validList.every(Boolean)) {
        const encodedAvatar = avatar
          ? (avatar.file && (await encodeImage(avatar.file))) || avatar.url
          : undefined;

        const submitData: T.UserEditFormSubmitData = {
          avatar: encodedAvatar,
          email: data.email.trim(),
          name: data.name.trim(),
          role: data.role,
        };

        isValidFunction(onSubmitProp) &&
          (await onSubmitProp(submitData, event));
        localFirstInvaliField = undefined;
      } else {
        // для фокуса первого инвалидного элемента
        localFirstInvaliField = getFirstInvalidField<T.UserEditFormData>(
          validList,
          FORM_FIELD_LIST
        );
      }
    } catch (e) {
    } finally {
      setDisabled(false);
      setFirstInvaliField(localFirstInvaliField);
    }
  };

  useEffect(() => {
    if (firstInvaliField) {
      setFocus(firstInvaliField);
    }
  }, [
    firstInvaliField,
    setFocus,
  ]);

  return (
    <div className={classNames(classes.root, className)}>
      <Form<T.UserEditFormData>
        controller={controller}
        onSubmit={handleSubmit(onSubmitValid)}
      >
        <div className={classes.wrapper}>
          <UserEmailFormItem<T.UserEditFormData>
            disabled={disabled}
            validation={emailValidation}
            validationState={emailValidationState}
          />

          <UserNameFormItem<T.UserEditFormData>
            disabled={disabled}
            validation={nameValidation}
          />

          <UserRoleFormItem<T.UserEditFormData>
            disabled={disabled}
            validation={roleValidation}
          />

          <UserAvatarFormItem<T.UserEditFormData>
            disabled={disabled}
            validation={avatartValidation}
          />
        </div>

        <div className={classes.buttons}>
          <Button
            className={classes.button}
            colorVariant="secondary"
            disabled={disabled}
            onClick={onCancel}
            sizeVariant="small"
            type="button"
            variant="outlined"
          >
            {t('user.dialog.cancel')}
          </Button>
          <LoadingButton
            className={classes.button}
            colorVariant="secondary"
            disabled={disabled}
            loading={disabled}
            sizeVariant="small"
            type="submit"
            variant="contained"
          >
            {t('user.dialog.save')}
          </LoadingButton>
        </div>
      </Form>
    </div>
  );
};

export default UserEditForm;
