import classNames from 'classnames';
import { useMemo } from 'react';
import type { ReactNode, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type {
  UseControllerProps,
  ControllerProps,
  FieldValues,
  Validate,
  Path,
  PathValue,
} from 'react-hook-form';

import { isValidFunction } from 'src/utils';
import { FormItemErrorBlock } from 'src/components/Form/FormItemErrorBlock';

import * as T from './types';
import classes from './FormItem.module.css';

/**
 * @template E Тип инпута
 * @template V Тип значения
 * @template T Тип значений всей формы
 * @template P Полезная нагрузка валидаторов, позволяет с учетом типов передавать
 * дополнительную информацию с результатами валидации в рендер
 */
const FormItem = <
  E extends Element,
  V,
  T extends FieldValues = FieldValues,
  P = never
>(
  props: T.FormItemProps<E, V, T, P>
): ReactElement<any, any> | null => {
  const {
    children: childrenRender,
    className,
    classes: classesProp,
    defaultValue,
    name,
    label: labelProp,
    labelFor,
    labelId,
    required,
    slots,
    validation: validationProp,
  } = props;
  const {
    error: errorSlotProp,
    hint: hintSlotProp,
    input: inputSlotProp,
    label: labelSlotProp,
    validation: validationSlotProp,
    layout: layoutSlotProp,
  } = slots || {};
  const {
    hint: hintClassProp,
    input: inputClassProp,
    label: labelClassProp,
  } = classesProp || {};

  const { control } = useFormContext<T>();

  const rules: UseControllerProps['rules'] | undefined = useMemo(() => {
    let result: UseControllerProps['rules'] | undefined;
    if (validationProp) {
      const mapped: Record<string, Validate<any>> | undefined = Object.keys(
        validationProp
      ).reduce<Record<string, Validate<any>> | undefined>(
        (accumulator, current) => {
          const validator = validationProp[current];
          if (!validator.internal && validator.validator) {
            if (!accumulator) {
              accumulator = {};
            }
            accumulator[current] = validator.validator;
          }
          return accumulator;
        },
        undefined
      );

      if (mapped) {
        result = {
          validate: mapped,
        };
      }
    }

    return result;
  }, [validationProp]);

  const validationEnabled =
    validationProp && Object.keys(validationProp).length > 0;

  const render: ControllerProps<T, Path<T>>['render'] = (renderProps) => {
    const {
      field,
      fieldState: { error, isDirty, isTouched },
      formState,
    } = renderProps;

    let validationData: T.FormItemValidationData<P> | undefined;
    if (error) {
      const { type, types } = error;

      const payload = validationProp ? validationProp[type].payload : undefined;
      validationData = {
        type,
        payload,
        all: undefined,
      };

      if (types) {
        const all = Object.keys(types).reduce<
          | Record<string, { type: string; value: boolean; payload?: P }>
          | undefined
        >((accumulator, current) => {
          if (!accumulator) {
            accumulator = {};
          }

          const result = {
            type: current,
            value: Boolean(types[current]),
            payload: validationProp
              ? validationProp[current].payload
              : undefined,
          };

          accumulator[current] = result;

          return accumulator;
        }, undefined);

        if (all) {
          validationData.all = all;
        }
      }
    }

    const valid = !Boolean(error);

    const slotRenderProps: T.FormItemRenderProps<E, V, T, P> = {
      field,
      fieldState: { isDirty, isTouched },
      formState,
      valid,
      validation: validationData,
    };

    let children: ReactNode;
    if (isValidFunction(childrenRender)) {
      children = childrenRender(slotRenderProps);
    } else {
      // label
      const labelContent: ReactNode = isValidFunction(labelSlotProp) ? (
        labelSlotProp(slotRenderProps)
      ) : labelProp ? (
        <label
          htmlFor={labelFor}
          id={labelId}
        >
          {labelProp}
        </label>
      ) : null;

      const label: ReactNode = labelContent && (
        <div
          className={classNames(
            classes.label,
            required && classes.labelRequired,
            labelClassProp
          )}
        >
          {labelContent}
        </div>
      );

      // hint
      const hint: ReactNode = isValidFunction(hintSlotProp) ? (
        <div className={classNames(classes.hint, hintClassProp)}>
          {hintSlotProp(slotRenderProps)}
        </div>
      ) : null;

      // validation
      const validation: ReactNode = validationEnabled ? (
        <div className={classes.errorBlock}>
          {validationSlotProp ? (
            validationSlotProp(slotRenderProps)
          ) : (
            <FormItemErrorBlock
              renderProps={slotRenderProps}
              slots={{ error: errorSlotProp }}
            />
          )}
        </div>
      ) : null;

      // input
      const input: ReactNode = (
        <div className={classNames(classes.input, inputClassProp)}>
          {isValidFunction(inputSlotProp) ? (
            <div className={classes.inputWrapper}>
              {inputSlotProp(slotRenderProps)}
            </div>
          ) : null}
          {validation}
        </div>
      );

      // layout
      children = layoutSlotProp ? (
        layoutSlotProp(label, input, slotRenderProps)
      ) : (
        <>
          {label}
          {hint}
          {input}
        </>
      );
    }

    return (
      <div
        className={classNames(
          classes.root,
          validationEnabled && classes.rootWithValidation,
          className
        )}
      >
        {children}
      </div>
    );
  };

  return (
    <Controller<T, Path<T>>
      control={control}
      defaultValue={defaultValue as PathValue<T, Path<T>> | undefined}
      name={name as Path<T>}
      render={render}
      rules={rules}
    />
  );
};

export default FormItem;
