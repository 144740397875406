export function extractLabelFromString(value?: string): string | undefined {
  if (!value) {
    return undefined;
  }
  const parts = value.split(/\s+/g).filter(Boolean);

  /*
  if (parts.length >= 2) {
    return `${parts[0][0]}${parts[1][0]}`;
  } else if (parts.length === 1) {
    return parts[0][0];
  }
  */

  if (parts.length >= 1) {
    return parts[0][0];
  }
}

const stringToColorCache = Object.create(null);
export function stringToColor(value: string): string {
  const cached = stringToColorCache[value];
  if (cached) {
    return cached;
  }

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < value.length; i += 1) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  stringToColorCache[value] = color;
  return color;
}
