import { useCallback, useEffect } from 'react';
import { useFormState } from 'react-hook-form';
import type { Subscription } from 'react-hook-form/dist/utils/createSubject';
import type { FieldValues } from 'react-hook-form';

import type { UseFormControllerReturn } from 'src/components/Form/Form';
import type { FormItemValidation } from 'src/components/Form/FormItem';
import type { FormItemWithTypedErrorPayload } from 'src/components/Form/FormItemWithTypedError';
import {
  requiredInternalValidationRuleFactory,
  requiredSyntheticValidatorFactory,
} from 'src/components/Form/validation/syntheticValidators';
import type {
  SyntheticValidatorResult,
  KeyType,
} from 'src/components/Form/validation/types';
import { useManualValidationResultSetter } from 'src/components/Form/validation/useManualValidationResultSetter';

import type * as T from './types';

// внутренние правила валидации
const validationRules: FormItemValidation<
  T.UserRoleFormItemValue,
  FormItemWithTypedErrorPayload
> = {
  required: requiredInternalValidationRuleFactory(),
};

// валидаторы
const requiredValidator =
  requiredSyntheticValidatorFactory<T.UserRoleFormItemValue>('required');

export function useUserRoleValidation<T extends FieldValues = FieldValues>(
  controller: UseFormControllerReturn<T>,
  key: KeyType<T>
): T.UseUserRoleValidationReturn {
  // ручной setter
  const validationResultSetter = useManualValidationResultSetter(
    controller,
    key
  );

  const validationHandler = useCallback(
    (value: T.UserRoleFormItemValue) => {
      const result: SyntheticValidatorResult = requiredValidator(value);

      validationResultSetter(result);
    },
    [
      requiredValidator,
      validationResultSetter,
    ]
  );

  const { watch } = controller;
  const { isSubmitted } = useFormState(controller);
  // надо валидировать постоянно только после первого submit
  useEffect(() => {
    let subscription: Subscription | undefined;

    if (isSubmitted) {
      subscription = watch((data, info) => {
        switch (info.name) {
          case key: {
            validationHandler(data[key]);
            break;
          }
        }
      });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [
    isSubmitted,
    key,
    validationHandler,
    watch,
  ]);

  // полная валидация для итогового результата
  const validate: T.UseUserRoleValidationValdateHandler = (
    value: T.UserRoleFormItemValue
  ) => {
    const result: SyntheticValidatorResult = requiredValidator(value);

    validationResultSetter(result);
    return !result || result.result;
  };

  return { validate, validation: validationRules };
}
