import {
  useCallback,
  useEffect,
  useRef,
  type ReactNode,
  type FC,
  type KeyboardEvent,
  SyntheticEvent,
} from 'react';
import MenuList from '@mui/material/MenuList';

import {
  Dropdown,
  type DropdownCloseRequestedType,
  type DropdownCloseRequestedHandler,
} from 'src/components/Dropdown';

export type MenuCloseRequestedHandlerType =
  | DropdownCloseRequestedType
  | 'escapeKeyDown';
export type MenuCloseRequestedHandler =
  DropdownCloseRequestedHandler<MenuCloseRequestedHandlerType>;

export interface MenuProps {
  anchorEl?: HTMLElement | null;
  children?: ReactNode;
  onCloseRequested?: MenuCloseRequestedHandler;
  open: boolean;
}

const Menu: FC<MenuProps> = (props) => {
  const { anchorEl, children, onCloseRequested, open } = props;

  const handleListKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        onCloseRequested && onCloseRequested(event, 'escapeKeyDown');
      } else if (event.key === 'Escape') {
        onCloseRequested && onCloseRequested(event, 'escapeKeyDown');
      }
    },
    [onCloseRequested]
  );
  /*
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      //anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);*/

  return (
    <Dropdown
      anchorEl={anchorEl}
      onCloseRequested={onCloseRequested}
      open={open}
      placement="bottom-end"
    >
      <MenuList
        autoFocusItem={false}
        id="composition-menu"
        aria-labelledby="composition-button"
        onKeyDown={handleListKeyDown}
      >
        {children}
      </MenuList>
    </Dropdown>
  );
};

export default Menu;
