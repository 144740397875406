import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { FieldValues } from 'react-hook-form';

import type { UseFormControllerReturn } from 'src/components/Form/Form';
import type { FormItemValidation } from 'src/components/Form/FormItem';
import type { FormItemWithTypedErrorPayload } from 'src/components/Form/FormItemWithTypedError';

import {
  stringRequiredSyntheticValidatorFactory,
  requiredInternalValidationRuleFactory,
  maxLengthSyntheticValidatorFactory,
  maxLengthInternalValidationRuleFactory,
  regexpSyntheticValidatorFactory,
  regexpInternalValidationRuleFactory,
  asyncSyntheticValidatorFactory,
} from 'src/components/Form/validation/syntheticValidators';

import type {
  SyntheticAsyncValidator,
  AsyncValidatorSupplier,
  SyntheticValidatorResult,
  KeyType,
  SyntheticAsyncValidationState,
} from 'src/components/Form/validation/types';
import { useManualValidationResultSetter } from 'src/components/Form/validation/useManualValidationResultSetter';
import { USER_LOGIN_MAX_LENGTH, USER_LOGIN_REGEXP } from 'src/constants/users';
import { useReadonlyLazyRef } from 'src/hooks/useLazyRef';
import { userApiService } from 'src/services/api';
import { OnlyLastExecutorDebouncer } from 'src/utils/executors/extensions/OnlyLastDebouncer';
import { OnlyLastExecutor } from 'src/utils/executors/OnlyLastExecutor';

import * as T from './types';

// внутренние правила валидации
const validationRules: FormItemValidation<
  string,
  FormItemWithTypedErrorPayload
> = {
  required: requiredInternalValidationRuleFactory(),
  maxLength: maxLengthInternalValidationRuleFactory(USER_LOGIN_MAX_LENGTH),
  regexp: regexpInternalValidationRuleFactory(),
  async: {
    payload: {
      tKey: 'user.form.validationLoginNonUnique',
    },
  },
};

// валидаторы
const stringRequiredValidator =
  stringRequiredSyntheticValidatorFactory('required');
const maxLengthValidator = maxLengthSyntheticValidatorFactory(
  USER_LOGIN_MAX_LENGTH,
  'maxLength'
);
const regexpLengthValidator = regexpSyntheticValidatorFactory(
  USER_LOGIN_REGEXP,
  'regexp'
);

const syncValidators = [
  stringRequiredValidator,
  maxLengthValidator,
  regexpLengthValidator,
];

export function useUserLoginValidation<T extends FieldValues = FieldValues>(
  controller: UseFormControllerReturn<T>,
  key: KeyType<T>,
  acceptedNonUniqueValues?: string[]
): T.UseUserLoginValidationReturn {
  const { watch } = controller;

  // ручной setter
  const validationResultSetter = useManualValidationResultSetter(
    controller,
    key
  );

  // асинхронная валидация почты
  const [
    asyncValidationState,
    setAsyncValidationState,
  ] = useState<SyntheticAsyncValidationState>({
    validating: false,
    hasError: false,
  });

  // исполнитель для аснихронной валидации почты
  const executorDebouncer = useReadonlyLazyRef(() => {
    const executor = new OnlyLastExecutor(true, 'login_async_validation');
    return new OnlyLastExecutorDebouncer(executor, 1000);
  });

  // для случаев редактирования текущее значение должно быть допустимым,
  // костыль, но если что вдруг, то ошибка будет на этапе итогового запроса
  const acceptedNonUniqueValuesRef = useRef<string[] | undefined>();
  acceptedNonUniqueValuesRef.current = acceptedNonUniqueValues;

  const asyncValidatorSupplier: AsyncValidatorSupplier<string> = useCallback(
    async (value: string) => {
      if (
        !value ||
        (Array.isArray(acceptedNonUniqueValuesRef.current) &&
          acceptedNonUniqueValuesRef.current.includes(value))
      ) {
        return true;
      }

      const result = await userApiService.checkLogin(value);
      return result.data.result;
    },
    [acceptedNonUniqueValuesRef]
  );

  const asyncValidator: SyntheticAsyncValidator<string> = useMemo(
    () => asyncSyntheticValidatorFactory(asyncValidatorSupplier, 'async'),
    [
      asyncSyntheticValidatorFactory,
      asyncValidatorSupplier,
    ]
  );

  // асинхронный обработчик, который будет откладываться до нужного момента
  // через debouncer
  const debouncerHandler = useCallback(
    async (value: string) => {
      // если запрос был инвалидирован, то он не будет выполнен,
      // в этом случае мы никак не реагируем
      setAsyncValidationState({ validating: true, hasError: false });

      let result: SyntheticValidatorResult | null = null;

      try {
        result = await asyncValidator(value);
        setAsyncValidationState({ validating: false, hasError: false });
        validationResultSetter(result);
      } catch (e) {
        // считаем, что проверить валидность невозможно из-за ошибок запроса,
        // поэтому результат игнорируем
        setAsyncValidationState({ validating: false, hasError: true });
      }
    },
    [
      asyncValidator,
      validationResultSetter,
    ]
  );

  const validateCallback = useCallback(
    (value: string) => {
      let result: SyntheticValidatorResult | null = null;

      for (let validator of syncValidators) {
        result = validator(value);
        if (!result.result) {
          break;
        }
      }

      if (result && !result.result) {
        // синхронная валидация дала ошибку,
        setAsyncValidationState({
          validating: false,
          hasError: false,
        });
        executorDebouncer.invalidateAll();
        validationResultSetter(result);
      } else {
        // Запускаем асинхронный компонент валидации с debounce,
        // вспомогательный debouncer создает работу в очереди executor,
        // но откладывает ее запуск с помощью debounce.
        //
        // Debounce не умеет в async/await, поэтому приходится идти на
        // такие извраты. Главное то, что сам запрос идет в цикле исполнителя
        // executor, а поэтому может быть инвалидирован.
        // Debouncer при этом в момент выполнения запроса вызывает переданную
        // ему обертку, которая работает непосредственно с результатом запроса.
        executorDebouncer.execute(() => debouncerHandler(value));
      }
    },
    [
      syncValidators,
      executorDebouncer,
      validationResultSetter,
      debouncerHandler,
    ]
  );

  // email надо валидировать постоянно
  useEffect(() => {
    const subscription = watch((data, info) => {
      switch (info.name) {
        case key: {
          validateCallback(data[key]);
          break;
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    key,
    watch,
    validateCallback,
  ]);

  // полная асинхронная валидация для итогового результата
  const validate: T.UseUserLoginValidationValdateHandler = async (
    value: string
  ) => {
    let result: SyntheticValidatorResult | null = null;

    for (let validator of [
      ...syncValidators,
      asyncValidator,
    ]) {
      result = await validator(value);
      if (!result.result) {
        break;
      }
    }

    validationResultSetter(result, true);
    return !result || result.result;
  };

  return {
    validationState: asyncValidationState,
    validation: validationRules,
    validate,
  };
}
