import type { CSSProperties } from 'react';
import {
  useState,
  type FC,
  type ReactNode,
  useCallback,
  useEffect,
  type DetailedHTMLProps,
  type ImgHTMLAttributes,
} from 'react';
import classNames from 'classnames';

import classes from './Avatar.module.css';
import { IconAccount } from 'src/assets/svg';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { extractLabelFromString, stringToColor } from './functions';
/* eslint-enable @typescript-eslint/no-unused-vars */
import { type ValueOf } from 'src/types/utils';

export interface AvatarPropsClasses {
  image: string;
}

export const AVATAR_SIZE_VARIANT = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  larger: 'larger',
  extraLarge: 'xlarge',
} as const;

export type AvatarSizeVariant = ValueOf<typeof AVATAR_SIZE_VARIANT>;

export const AVATAR_VARIANT = {
  circle: 'circle',
  rounded: 'rounded',
} as const;

export type AvatarVariant = ValueOf<typeof AVATAR_VARIANT>;

export const AVATAR_COLOR_VARIANT = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
} as const;

export type AvatarColorVariant = ValueOf<typeof AVATAR_COLOR_VARIANT>;

export interface AvatarProps {
  alt?: string;
  /**
   * fallback для ситуаций, когда изображение не загрузилось, а fallback
   * по умолчанию не подходит
   */
  children?: ReactNode;
  className?: string;
  classes?: Partial<AvatarPropsClasses>;
  colorVariant?: AvatarColorVariant;
  imageProps?: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;

  /**
   * Используется для отображения инициалов, когда изображение отсутствует или
   * не было загружено
   */
  fallbackLabelSource?: string;
  sizeVariant?: AvatarSizeVariant;
  src?: string;
  srcSet?: string;
  variant?: AvatarVariant;
}

export const Avatar: FC<AvatarProps> = (props) => {
  const {
    alt,
    children: childrenProp,
    className,
    classes: classesProp = {},
    colorVariant = AVATAR_COLOR_VARIANT.default,
    imageProps,
    fallbackLabelSource,
    sizeVariant = AVATAR_SIZE_VARIANT.medium,
    src,
    srcSet,
    variant = AVATAR_VARIANT.circle,
  } = props;

  const [
    loaded,
    setLoaded,
  ] = useState(false);
  const [
    imageRef,
    setImageRef,
  ] = useState<HTMLImageElement | null>();
  const callbackRef = useCallback((ref?: HTMLImageElement | null) => {
    console.log('ref', ref);
    setImageRef(ref);
  }, []);

  useEffect(() => {
    setLoaded(false);
  }, [
    src,
    srcSet,
  ]);

  useEffect(() => {
    const onLoad = (event: Event) => {
      console.log('loaded');
      setLoaded(true);
    };
    const onError = (event: Event) => {
      setLoaded(false);
    };

    if (imageRef) {
      imageRef.addEventListener('load', onLoad);
      imageRef.addEventListener('error', onError);
    }

    return () => {
      if (imageRef) {
        imageRef.removeEventListener('load', onLoad);
        imageRef.removeEventListener('error', onError);
      }
    };
  }, [
    imageRef,
    src,
    srcSet,
  ]);

  let withExtracted = false;
  let rootStyle: CSSProperties | undefined;
  let avatar: ReactNode | undefined;
  let fallback: ReactNode | undefined;

  if (src || srcSet) {
    let style: CSSProperties | undefined;
    if (!loaded) {
      style = {
        display: 'none',
        opacity: 0,
        visibility: 'hidden',
        position: 'absolute',
      };
    }

    avatar = (
      <img
        {...imageProps}
        alt={alt}
        className={classNames(classes.img, classesProp.image)}
        ref={callbackRef}
        src={src}
        srcSet={srcSet}
        style={style}
      />
    );
  }

  if (!loaded) {
    // изображение не загружено
    if (childrenProp) {
      fallback = childrenProp;
    } else {
      const extracted = extractLabelFromString(fallbackLabelSource);
      if (extracted) {
        withExtracted = true;
        fallback = <span className={classes.extracted}>{extracted}</span>;
        /*
        rootStyle = {
          backgroundColor: stringToColor(extracted),
        };
        */
      }
    }

    if (!fallback) {
      fallback = <IconAccount className={classes.fallback} />;
    }
  }

  const variantClassName =
    variant === AVATAR_VARIANT.rounded ? classes.rounded : null;

  const sizeVariantClassName =
    sizeVariant === AVATAR_SIZE_VARIANT.small
      ? classes.small
      : sizeVariant === AVATAR_SIZE_VARIANT.large
      ? classes.large
      : sizeVariant === AVATAR_SIZE_VARIANT.larger
      ? classes.larger
      : sizeVariant === AVATAR_SIZE_VARIANT.extraLarge
      ? classes.extraLarge
      : classes.medium;

  const colorVariantClassName =
    colorVariant === AVATAR_COLOR_VARIANT.primary
      ? classes.rootPrimary
      : colorVariant === AVATAR_COLOR_VARIANT.secondary
      ? classes.rootSecondary
      : null;

  return (
    <div
      className={classNames(
        classes.root,
        colorVariantClassName,
        variantClassName,
        sizeVariantClassName,
        withExtracted && classes.withExtracted,
        className
      )}
      style={rootStyle}
    >
      {avatar}
      {fallback}
    </div>
  );
};

// TODO мемоизировать
export default Avatar;
