export type PaginationLayoutElementType = 'page' | 'left' | 'right' | 'dots';

export interface PaginationLayoutBaseElement {
  type: PaginationLayoutElementType;
}

export interface PaginationLayoutPageElement
  extends PaginationLayoutBaseElement {
  type: 'page';
  current?: boolean;
  page: number;
}

export interface PaginationLayoutSymbolElement
  extends PaginationLayoutBaseElement {
  type: 'left' | 'right' | 'dots';
  disabled?: boolean;
}

export type PaginationLayoutElement =
  | PaginationLayoutPageElement
  | PaginationLayoutSymbolElement;

export type PaginationLayout = PaginationLayoutElement[];

export const getPaginationLayout = (
  firstPage: number,
  lastPage: number,
  page: number,

  /* первая, последняя и текущая страницы показываются всегда,
  для получения промежуточных страниц используется
  допустимая для показа величина между текущей страницей и соседями,
  то есть, если она выставлена в 2, то для страницы Н будут
  видны Н-2, Н-1, Н, Н+1, Н+2 без всяких пропусков */
  visibleNeighborsCount: number,

  /* минимальная дельта между первым (последним) значением
  и первым (последним) значением центрального блока, при которой промежуток
  между ними заменяются дотами, например,

  minDeltaToMakeDots = 4:

  1-?-3, delta = 3 - 1 = 2, delta !>= 4 => 1-2-3;
  1-?-4, delta = 4 - 1 = 3, delta !>= 4 => 1-2-3-4;
  3-?-5, delta = 5 - 3 = 2, delta !>= 4 => 3-4-5;
  
  minDeltaToMakeDots = 3:

  1-?-3, delta = 3 - 1 = 2, delta !>= 3 => 1-2-3;
  1-?-4, delta = 4 - 1 = 3, delta >= 3 => 1-...-4;
  3-?-5, delta = 5 - 3 = 2, delta !> 3 => 3-4-5;

  minDeltaToMakeDots = 2:
  
  1-?-3, delta = 3 - 1 = 2, delta >= 2 => 1-...-3;
  1-?-4, delta = 4 - 1 = 3, delta >= 2 => 1-...-4;
  3-?-5, delta = 5 - 3 = 2, delta >= 2 => 3-...-5;

  сделано из-за того, что на мелких экранах помещается уменьшенный квадрат с дотами,
  но не влезет целая кнопка со страницей, а на больших экранах нет смысла
  делать пропуск вместо одного квадрата в ситуациях типа 1-...-3 */
  minDeltaToMakeDots: number
): PaginationLayout => {
  const _minDeltaToMakeDots = Math.max(minDeltaToMakeDots, 2);
  const _visibleNeighborsCount = Math.max(visibleNeighborsCount, 0);

  const result: PaginationLayout = [];
  if (lastPage === firstPage) {
    result.push({ type: 'page', current: true, page: firstPage });
  } else {
    // стрелки есть всегда, если страниц > 1
    result.push({ type: 'left', disabled: page === firstPage });
    // особая обработка first page
    result.push({ type: 'page', current: page === firstPage, page: firstPage });

    // текущая страница и соседи
    const allNeighborsCount = 1 + 2 * _visibleNeighborsCount;
    for (let i = 0; i < allNeighborsCount; i++) {
      const current = page - _visibleNeighborsCount + i;
      // текущая страница может быть как первой, так и последней
      if (current >= firstPage && current <= lastPage) {
        if (i === 0) {
          // один элемент может быть крайним и слева, и справа,
          // когда visibleNeighborsCount = 0
          // проверка на точки для первого элемента в среднем блоке
          const delta = current - firstPage;
          if (delta >= _minDeltaToMakeDots) {
            result.push({ type: 'dots' });
          } else {
            // вставляем все элементы (firstPage, current)
            for (let j = firstPage + 1; j < current; j++) {
              result.push({ type: 'page', page: j });
            }
          }
        }

        if (current !== firstPage && current !== lastPage) {
          result.push({
            type: 'page',
            current: page === current,
            page: current,
          });
        }

        if (i === allNeighborsCount - 1) {
          // аналогично для последнего элемента
          const delta = lastPage - current;
          if (delta >= _minDeltaToMakeDots) {
            result.push({ type: 'dots' });
          } else {
            // вставляем все элементы (current, lastPage)
            for (let j = current + 1; j < lastPage; j++) {
              result.push({ type: 'page', page: j });
            }
          }
        }
      }
    }

    // особая обработка lastPage
    result.push({ type: 'page', current: page === lastPage, page: lastPage });
    // стрелки есть всегда, если страниц > 1
    result.push({ type: 'right', disabled: page === lastPage });
  }
  return result;
};

/*
console.log('1, 1, 1, 1, 2', getPaginationLayout(1, 1, 1, 1, 1));

console.log('1, 2, 1, 1, 2', getPaginationLayout(1, 2, 1, 1, 1));
console.log('1, 2, 2, 1, 2', getPaginationLayout(1, 2, 2, 1, 1));
console.log('1, 2, 1, 2, 2', getPaginationLayout(1, 2, 1, 2, 1));
console.log('1, 2, 2, 2, 2', getPaginationLayout(1, 2, 2, 2, 1));

console.log('1, 3, 1, 1, 2', getPaginationLayout(1, 3, 1, 1, 1));
console.log('1, 3, 2, 1, 2', getPaginationLayout(1, 3, 2, 1, 1));
console.log('1, 3, 3, 1, 2', getPaginationLayout(1, 3, 3, 1, 1));

console.log('1, 6, 3, 1, 2', getPaginationLayout(1, 6, 3, 1, 2));
console.log('1, 6, 3, 2, 2', getPaginationLayout(1, 6, 3, 2, 2));
console.log('1, 6, 3, 2, 3', getPaginationLayout(1, 6, 3, 2, 3));
console.log('1, 6, 3, 1, 3', getPaginationLayout(1, 6, 3, 1, 3));

console.log('1, 10, 6, 1, 2', getPaginationLayout(1, 10, 6, 1, 2));
console.log('1, 10, 8, 1, 2', getPaginationLayout(1, 10, 8, 1, 2));
console.log('1, 10, 9, 1, 2', getPaginationLayout(1, 10, 9, 1, 2));
console.log('1, 10, 10, 1, 2', getPaginationLayout(1, 10, 10, 1, 2));
*/

export function getOffset(
  page: number,
  pageSize: number,
  totalElements: number,
  totalPages: number
): [left: number, right: number] {
  let left: number;
  let right: number;
  if (page <= totalPages && totalElements > 0) {
    left = (page - 1) * pageSize + 1;
    right = Math.min(page * pageSize, totalElements);
  } else {
    left = 0;
    right = 0;
  }

  return [
    left,
    right,
  ];
}
