import { type CSSProperties, type FC, type ReactNode } from 'react';
import classNames from 'classnames';

import type { ValueOf } from 'src/types/utils';

import classes from './ContentWrapper.module.css';

export const CONTENT_WRAPPER_SIZE_VARIANT = {
  small: 'small',
  medium: 'medium',
} as const;

export type ContentWrapperSizeVariant = ValueOf<
  typeof CONTENT_WRAPPER_SIZE_VARIANT
>;

export interface ContentWrapperProps {
  children?: ReactNode;
  className?: string;
  contentWrapperClassName?: string;
  maxWidth?: string;
  sizeVariant?: ContentWrapperSizeVariant;
}

const ContentWrapper: FC<ContentWrapperProps> = (props) => {
  const {
    children,
    className,
    contentWrapperClassName,
    maxWidth,
    sizeVariant = CONTENT_WRAPPER_SIZE_VARIANT.medium,
  } = props;

  const sizeVariantClassName =
    sizeVariant === CONTENT_WRAPPER_SIZE_VARIANT.small
      ? classes.rootSmall
      : classes.rootMedium;

  let styles: CSSProperties | undefined;
  if (maxWidth) {
    styles = { maxWidth };
  }

  return (
    <div
      className={classNames(classes.root, sizeVariantClassName, className)}
      style={styles}
    >
      <div className={classNames(classes.content, contentWrapperClassName)}>
        {children}
      </div>
    </div>
  );
};

export default ContentWrapper;
