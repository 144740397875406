import type { FC } from 'react';
import { useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './Pagination.module.css';

import {
  IconChevronLeft,
  IconChevronRight,
  IconDotsHorizontal,
} from 'src/assets/svg';
import { useMediaQuery } from 'react-responsive';
import { isValidFunction } from 'src/utils';
import {
  getOffset,
  getPaginationLayout,
} from 'src/components/Pagination/functions';
import { PaginationPageButton } from 'src/components/Pagination/PaginationPageButton';
import { type ValueOf } from 'src/types/utils';

export const PAGINATION_COLOR_VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
} as const;

export type PaginationColorVariant = ValueOf<typeof PAGINATION_COLOR_VARIANT>;

export type PaginationOnPageHandler = (page: number) => void;
export type PaginationOnPageSizeHandler = (pageSize: number) => void;

export interface PaginationProps {
  className?: string;
  colorVariant?: PaginationColorVariant;
  onPage: PaginationOnPageHandler;
  onPageSize?: PaginationOnPageSizeHandler;
  // начинается с 1, на дробные значения не проверяем, верим так
  page: number;
  pageSize: number;
  pageSizeVariants?: number[];
  totalElements?: number;
  totalPages: number;
}

const FIRST_PAGE = 1;

const Pagination: FC<PaginationProps> = (props) => {
  const {
    className,
    colorVariant = PAGINATION_COLOR_VARIANT.primary,
    page: pageProp,
    onPage,
    onPageSize,
    pageSize,
    pageSizeVariants,
    totalElements = 0,
    totalPages,
  } = props;

  const { t } = useTranslation();

  const isWider380 = useMediaQuery({ query: '(min-width: 380px)' });
  const isWider420 = useMediaQuery({ query: '(min-width: 420px)' });

  const page = pageProp < FIRST_PAGE ? FIRST_PAGE : pageProp;
  // страница может быть выбрана за пределами переданного количества страниц
  const lastPage = Math.max(page, totalPages);

  /*
  const onPageRef = useRef<PaginationOnPageHandler>(onPageProp);
  onPageRef.current = onPageProp;
  */

  const variantsEnabled =
    isValidFunction(onPageSize) &&
    Array.isArray(pageSizeVariants) &&
    pageSizeVariants.length > 0;

  const neighborsCount = isWider420 ? 1 : 1;
  const minDeltaToDots = isWider380 ? 3 : 2;

  const layout = getPaginationLayout(
    FIRST_PAGE,
    lastPage,
    page,
    neighborsCount,
    minDeltaToDots
  );
  console.log(layout);

  let dotsIndex = 0;
  const layoutChildren = layout.map((element) => {
    const { type } = element;

    switch (type) {
      case 'dots': {
        const key = `dots_${dotsIndex++}`;

        return (
          <div
            className={classNames(classes.dots, classes.layoutItem)}
            key={key}
          >
            <IconDotsHorizontal className={classes.layoutDotsIcon} />
          </div>
        );
      }

      case 'left': {
        return (
          <PaginationPageButton
            className={classes.layoutItem}
            disabled={element.disabled}
            icon={IconChevronLeft}
            key="left"
            onClick={() => onPage(page - 1)}
          />
        );
      }

      case 'right': {
        return (
          <PaginationPageButton
            className={classes.layoutItem}
            disabled={element.disabled}
            icon={IconChevronRight}
            key="right"
            onClick={() => onPage(page + 1)}
          />
        );
      }

      case 'page':
      default: {
        const { page: pageIndex } = element;
        const key = `page_${pageIndex}`;

        return (
          <PaginationPageButton
            active={pageIndex === page}
            className={classes.layoutItem}
            key={key}
            onClick={() => onPage(pageIndex)}
          >
            {pageIndex}
          </PaginationPageButton>
        );
      }
    }
  });

  let colorVariantClassName;
  switch (colorVariant) {
    case PAGINATION_COLOR_VARIANT.secondary: {
      colorVariantClassName = classes.rootSecondary;
      break;
    }

    case PAGINATION_COLOR_VARIANT.primary:
    default: {
      colorVariantClassName = null;
    }
  }

  const [
    left,
    right,
  ] = getOffset(page, pageSize, totalElements, totalPages);

  return (
    <div className={classNames(classes.root, colorVariantClassName, className)}>
      {totalElements > 0 && (
        <div className={classes.total}>
          {left}...{right}/{totalElements}
        </div>
      )}
      <div className={classes.layout}>{layoutChildren}</div>
    </div>
  );
};

export default Pagination;
