import type { AxiosError } from 'axios';
import Axios, {
  type AxiosResponse,
  type AxiosInstance,
  type AxiosRequestConfig,
} from 'axios';
import qs from 'qs';

const DEFAULT_API_SERVICE_CONFIG: AxiosRequestConfig = {
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
      strictNullHandling: true,
      filter: (prefix, value) => {
        if (typeof value === 'boolean') {
          return value ? 1 : undefined;
        } else if (typeof value === 'string') {
          return value || undefined;
        }
        return value;
      },
    });
  },
  responseType: 'json',
  timeout: 10000,
};

export interface ApiBackendUnauthorizedInterceptorHandler {
  (error: any): void;
}

export type ApiBackendResponse<T> = AxiosResponse<T, any>;
export type ApiBackendError<T> = AxiosError<T>;
export interface ApiBackendErrorData {
  error?: string;
  message?: string;
  path?: string;
  status?: number;
  timestamp?: number;
}

/**
 * В идеале классе должен наследоваться от общего интерфейса,
 * а все типы - скрывать реализацию функционала осуществления запросов,
 * чтобы можно было спокойно подменять одну реализацию на другую
 * (например, axios на fetch)
 */
export class ApiBackend {
  private _axios: AxiosInstance;

  private _interceptorId: number | null = null;

  constructor(
    config?: AxiosRequestConfig,
    unauthorizedInterceptor?: ApiBackendUnauthorizedInterceptorHandler
  ) {
    const axiosConfig: AxiosRequestConfig = {
      ...DEFAULT_API_SERVICE_CONFIG,
      ...config,
    };
    this._axios = Axios.create(axiosConfig);
    if (unauthorizedInterceptor) {
      this.setUnauthorizedInterceptor(unauthorizedInterceptor);
    }
  }

  get<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<ApiBackendResponse<T>> {
    return this._axios.get<T>(url, config);
  }

  post<T, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<ApiBackendResponse<T>> {
    return this._axios.post<T, AxiosResponse<T>, D>(url, data, config);
  }

  put<T, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<ApiBackendResponse<T>> {
    return this._axios.put<T, AxiosResponse<T>, D>(url, data, config);
  }

  setUnauthorizedInterceptor(
    handler: ApiBackendUnauthorizedInterceptorHandler | null
  ) {
    if (this._interceptorId !== null) {
      this._axios.interceptors.request.eject(this._interceptorId);
      this._interceptorId = null;
    }

    if (handler) {
      this._interceptorId = this._axios.interceptors.response.use(
        undefined,
        (error) => {
          if (error?.response?.status === 401) {
            handler(error);
          }

          throw error;
        }
      );
    }
  }
}
