import { useCallback, type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, type To } from 'react-router-dom';

import { PATHS } from 'src/constants/paths';
import { type SearchWidgetFormSubmit } from 'src/containers/SearchWidget';
import {
  sortFromURLMapper,
  sortToURLMapper,
  queryFromURLMapper,
  queryToURLMapper,
  pageFromURLMapper,
  pageToURLMapper,
  pageSizeFromURLMapper,
  pageSizeToURLMapper,
  filterFromURLMapper,
  filterToURLMapper,
} from './mappers';

import { UserListToolbar } from 'src/containers/UserList/UserListToolbar';
import {
  USER_LIST_DEFAULT_FILTER_DATA,
  type UserListSort,
} from 'src/constants/users';
import { UserList } from 'src/containers/UserList/UserList';
import { type InternalUserListFilterDataType } from 'src/types/common';
import { type UserListFilterWidgetFormSubmitHandler } from 'src/containers/UserList/UserListFilterWidget';
import type { UserListSortWidgetSelectHandler } from 'src/containers/UserList/UserListSortWidget';
import { type UserListSortWidgetLinkPathComposer } from 'src/containers/UserList/UserListSortWidget';
import { CreateUserButton } from 'src/containers/CreateUserButton';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { Typography, TYPOGRAPHY_VARIANT } from 'src/components/Typography';

import layoutClasses from 'src/styles/common/layout.module.css';
import classNames from 'classnames';
import type {
  PaginationOnPageHandler,
  PaginationOnPageSizeHandler,
} from 'src/components/Pagination';

const UsersPage: FC = () => {
  const { t } = useTranslation();
  const [
    searchParams,
    setSearchParams,
  ] = useSearchParams();

  // query
  const query: string = useMemo<string>(
    () => queryFromURLMapper(searchParams) || '',
    [searchParams]
  );

  // page
  const page: number = useMemo<number>(
    () => pageFromURLMapper(searchParams),
    [searchParams]
  );
  const pageSize: number = useMemo<number>(
    () => pageSizeFromURLMapper(searchParams),
    [searchParams]
  );

  // sort
  const sort: UserListSort = useMemo<UserListSort>(
    () => sortFromURLMapper(searchParams),
    [searchParams]
  );

  // filter
  /*
  const filter = useMemo<InternalUserListFilterDataType>(
    () => filterFromURLMapper(searchParams),
    [searchParams]
  );
  */
  // благодаря этому "хаку" страница будет ререндериться, если была подтверждена
  // какая-либо форма, но данные не были изменены; ререндер будет вызывать
  // запросы и тд, что является ожидаемым поведением;
  // сложно сказать, насколько это "допустимый" подход
  const filter: InternalUserListFilterDataType =
    filterFromURLMapper(searchParams);

  // поиск по строке
  const onSearchSubmit: SearchWidgetFormSubmit = useCallback(
    (inputQuery) => {
      // изменяется только query, остальное не трогаем
      const params = new URLSearchParams(searchParams);
      queryToURLMapper({ value: inputQuery, accumulator: params });
      // сброс страницы
      pageToURLMapper({ value: undefined, accumulator: params });

      console.log('onSearchSubmit', params.toString());

      setSearchParams(params);
    },
    [
      searchParams,
      setSearchParams,
    ]
  );

  // формирование ссылки для виджета сортировки
  const sortLinkPathComposer: UserListSortWidgetLinkPathComposer = useCallback(
    (id) => {
      const params = new URLSearchParams(searchParams);
      sortToURLMapper({ value: id, accumulator: params });

      const result: To = {
        pathname: PATHS.rooms.path,
        search: `?${params.toString()}`,
      };
      return result;
    },
    [searchParams]
  );

  const onSortSelect: UserListSortWidgetSelectHandler = (value) => {
    const params = new URLSearchParams(searchParams);
    sortToURLMapper({ value, accumulator: params });
    // сброс страницы
    pageToURLMapper({ value: undefined, accumulator: params });

    setSearchParams(params);
  };

  // подтверждение формы фильтрации
  const onFilterSubmit: UserListFilterWidgetFormSubmitHandler = useCallback(
    (data: any) => {
      const params = new URLSearchParams(searchParams);
      filterToURLMapper({ value: data, accumulator: params });
      // сброс страницы
      pageToURLMapper({ value: undefined, accumulator: params });

      setSearchParams(params);
    },
    [
      searchParams,
      setSearchParams,
    ]
  );

  // pagination
  const onPage: PaginationOnPageHandler = useCallback(
    (page) => {
      const params = new URLSearchParams(searchParams);
      pageToURLMapper({ value: page, accumulator: params });

      setSearchParams(params);
    },
    [
      searchParams,
      setSearchParams,
    ]
  );

  const onPageSize: PaginationOnPageSizeHandler = useCallback(
    (pageSize) => {
      const params = new URLSearchParams(searchParams);
      pageSizeToURLMapper({ value: pageSize, accumulator: params });

      setSearchParams(params);
    },
    [
      searchParams,
      setSearchParams,
    ]
  );

  return (
    <div>
      <ContentWrapper>
        <Typography variant={TYPOGRAPHY_VARIANT.h1}>
          {t('users.title')}
        </Typography>

        <div className={layoutClasses.listLayoutSection}>
          <CreateUserButton />
        </div>
        <UserListToolbar
          className={layoutClasses.listLayoutSection}
          currentFilterData={filter}
          defaultFilterData={USER_LIST_DEFAULT_FILTER_DATA}
          initialQuery={query}
          onFilterSubmit={onFilterSubmit}
          onSearchSubmit={onSearchSubmit}
          onSortSelect={onSortSelect}
          sortLinkPathComposer={sortLinkPathComposer}
          sortValue={sort}
        />
        <div
          className={classNames(
            layoutClasses.listLayoutSection,
            layoutClasses.listLayoutList
          )}
        >
          <UserList
            filter={filter}
            onPage={onPage}
            onPageSize={onPageSize}
            page={page}
            pageSize={pageSize}
            query={query}
            sort={sort}
          />
        </div>
      </ContentWrapper>
    </div>
  );
};

export default UsersPage;
