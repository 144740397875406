import type { InternalRoomListFilterData } from 'src/types/common';
import type { ValueOf } from 'src/types/utils';

export const ROOM_LIST_SORT_LIST = {
  ALPHABETICAL_ASC: 1,
  ALPHABETICAL_DESC: 2,
  DATE_ASC: 3,
  DATE_DESC: 4,
} as const;

export const ROOM_LIST_DEFAULT_SORT = ROOM_LIST_SORT_LIST.DATE_DESC;
export type RoomListSort = ValueOf<typeof ROOM_LIST_SORT_LIST>;

export const ROOM_LIST_DEFAULT_FILTER_DATA: Required<InternalRoomListFilterData> =
  {
    onlyMine: false,
  } as const;

// для апи
export const ROOM_LIST_DEFAULT_PAGE = 0;
// для интерфейса
export const ROOM_LIST_DEFAULT_DISPLAY_PAGE = 1;
export const ROOM_LIST_DEFAULT_PAGE_SIZE = 10;
export const ROOM_LIST_PAGE_SIZE_VALID_LIST = [
  5,
  10,
  20,
];

// константы для комнат
export const ROOM_TITLE_MAX_LENGTH = 50;
export const ROOM_DESCRIPTION_MAX_LENGTH = 200;
