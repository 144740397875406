import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button/Button2';

import { Dialog, type DialogProps } from 'src/components/Modal/Dialog';
import { DialogActions } from 'src/components/Modal/DialogActions';
import { DialogContent } from 'src/components/Modal/DialogContent';
import { DialogTitle } from 'src/components/Modal/DialogTitle';
import { encodeImage } from 'src/containers/AvatarUpload/functions';
import type {
  EditAdminProfileFormData,
  EditAdminProfileFormSubmitHandler,
} from 'src/containers/Profile/EditAdminProfileForm';
import { EditAdminProfileForm } from 'src/containers/Profile/EditAdminProfileForm';

import { useAuth } from 'src/contexts/auth';
import type { ApiPersonalForm } from 'src/services/api';
import { isValidFunction } from 'src/utils';

import classes from './EditAdminProfileDialog.module.css';

export interface EditAdminProfileDialogProps extends DialogProps {
  className?: string;
}

const EditAdminProfileDialog: FC<EditAdminProfileDialogProps> = (props) => {
  const { className, open, onCloseRequested, ...rest } = props;
  const { t } = useTranslation();
  const [
    { user },
    { updatePersonal },
  ] = useAuth();

  if (user === null) {
    throw 'Error, user is null';
  }

  const defaultValues: Required<EditAdminProfileFormData> = {
    avatar: user.avatar ? { url: user.avatar } : null,
    email: user.email,
    name: user.name,
  };

  const onSubmit: EditAdminProfileFormSubmitHandler = async (data) => {
    const { url: avatarURL, file: avatarFile } = data.avatar || {};

    const avatarFormValue =
      (avatarFile ? await encodeImage(avatarFile) : avatarURL) || undefined;

    const form: ApiPersonalForm = {
      avatar: avatarFormValue,
      email: data.email,
      name: data.name,
    };

    await updatePersonal(form);
    isValidFunction(onCloseRequested) && onCloseRequested();
  };

  return (
    <Dialog
      {...rest}
      className={classes.root}
      onCloseRequested={onCloseRequested}
      open={open}
      scroll="body"
    >
      <DialogTitle>{t('user.dialog.profileEditTitle')}</DialogTitle>
      <DialogContent>
        <EditAdminProfileForm
          initialData={defaultValues}
          onClose={onCloseRequested}
          onSubmit={onSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditAdminProfileDialog;
