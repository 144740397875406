import type { Pageable } from 'src/services/api';
import {
  type ApiBackendErrorData,
  type ApiBackendResponse,
  type ApiBackendError,
} from 'src/services/api';

export interface ErrorData {
  status?: number;
  message?: string;
}

export function maybeGetErrorDataFromBackend(error?: any): ErrorData {
  const result: ErrorData = {};
  if (error && error.response) {
    const { response } = error as ApiBackendError<ApiBackendErrorData>;
    const { status, data } = response!;

    result.status = status;
    if (data) {
      result.message = data.error || data.message;
    }
  }

  return result;
}

export function is404Error(e: any) {
  const errorData = maybeGetErrorDataFromBackend(e);
  return errorData.status === 404;
}

export type ApiMapperFunction<T, V> = (data: T) => V;

export class MapperValidationError<T> {
  public name: string = 'MapperValidationError';

  constructor(
    public originalError: any,
    public originalResponse?: ApiBackendResponse<T>
  ) {}
}

export function mapToInternalType<T, V>(
  response: ApiBackendResponse<T>,
  mapper: ApiMapperFunction<T, V>
): ApiBackendResponse<V> {
  try {
    const mapped = mapper(response.data);

    return {
      ...response,
      data: mapped,
    };
  } catch (e: any) {
    throw new MapperValidationError<T>(e, response);
  }
}

export function mapPageableToInternalType<T, V>(
  response: ApiBackendResponse<Pageable<T>>,
  mapper: ApiMapperFunction<T, V>
): ApiBackendResponse<Pageable<V>> {
  try {
    const mapped = response.data.content.map(mapper);

    return {
      ...response,
      data: {
        ...response.data,
        content: mapped,
      },
    };
  } catch (e: any) {
    throw new MapperValidationError<Pageable<T>>(e, response);
  }
}
