import classNames from 'classnames';
import { type FC, type ReactNode } from 'react';
import type { ValueOf } from 'src/types/utils';

import classes from './Attribute.module.css';

export interface AttributeClasses {
  descriptor: string;
  root: string;
  value: string;
}

export const ATTRIBUTE_SIZE_VARIANT = {
  small: 'small',
  medium: 'medium',
} as const;

export type AttributeSizeVariant = ValueOf<typeof ATTRIBUTE_SIZE_VARIANT>;

export interface AttributeProps {
  // value
  children?: ReactNode;
  classes?: Partial<AttributeClasses>;
  className?: string;
  // key
  descriptor?: ReactNode;
  sizeVariant?: AttributeSizeVariant;
}

const Attribute: FC<AttributeProps> = (props) => {
  const {
    children,
    classes: classesProp = {},
    className,
    descriptor: descriptorProp,
    sizeVariant = ATTRIBUTE_SIZE_VARIANT.medium,
  } = props;

  const descriptor = descriptorProp ? (
    <div className={classNames(classes.descriptor, classesProp.descriptor)}>
      {descriptorProp}
    </div>
  ) : null;

  const sizeVariantClassName =
    sizeVariant === ATTRIBUTE_SIZE_VARIANT.small ? classes.rootSmall : null;

  return (
    <div
      className={classNames(
        classes.root,
        sizeVariantClassName,
        !descriptor && classes.onlyValue,
        className,
        classes.root
      )}
    >
      {descriptor}
      <div className={classNames(classes.value, classesProp.value)}>
        {children}
      </div>
    </div>
  );
};

export default Attribute;
