import type { UserRole } from 'src/services/api';
import { ALL_USER_ROLES } from 'src/services/api';
import { type InternalUserListFilterDataType } from 'src/types/common';
import { type ValueOf } from 'src/types/utils';

export const USER_PASSWORD_MAX_LENGTH = 50;
export const USER_PASSWORD_MIN_LENGTH = 4;
export const USER_PASSWORD_REGEXP =
  /^[a-zA-Z0-9!@#$%^&*()_+|~\-=\\`{}[\]:«;'<>?,./]+$/i;

export const USER_EMAIL_MAX_LENGTH = 100;
export const USER_EMAIL_REGEXP =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

export const USER_LOGIN_MAX_LENGTH = 30;
export const USER_LOGIN_REGEXP = /^[a-zA-Z0-9_.-]+$/i;
export const USER_NAME_MAX_LENGTH = 150;
export const USER_NAME_REGEXP = /^[a-zA-Z0-9\s'"_.-]+$/i;
export const USER_AVATAR_MAX_SIZE_MBYTES = 5;
export const USER_AVATAR_MAX_SIZE_BYTES = 5_242_880;

export const USER_AVATAR_ACCEPTED = 'image/png, image/jpeg';
export const USER_AVATAR_DISPLAY_ACCEPTED = 'PNG, JPG, JPEG';

// sort
export const USER_LIST_SORT_LIST = {
  ALPHABETICAL_ASC: 1,
  ALPHABETICAL_DESC: 2,
} as const;

export const USER_LIST_DEFAULT_SORT = USER_LIST_SORT_LIST.ALPHABETICAL_ASC;
export type UserListSort = ValueOf<typeof USER_LIST_SORT_LIST>;

// pageable
// для апи
export const USER_LIST_DEFAULT_PAGE = 0;
// для интерфейса
export const USER_LIST_DEFAULT_DISPLAY_PAGE = 1;
export const USER_LIST_DEFAULT_PAGE_SIZE = 10;
export const USER_LIST_PAGE_SIZE_VALID_LIST = [
  5,
  10,
  20,
];

// default filter params
export const USER_LIST_DEFAULT_FILTER_DATA: Readonly<
  Required<InternalUserListFilterDataType>
> = {
  roleList: [] as UserRole[],
  blocked: false,
  unblocked: false,
} as const;
