import { type MutableRefObject, useRef } from 'react';

const none: symbol = Symbol('useLazyRef');

export type UseLazyRefInitializer<T> = () => T;

export function useLazyRef<T>(
  initializer: UseLazyRefInitializer<T>
): MutableRefObject<T> {
  const ref = useRef<T | typeof none>(none);

  if (ref.current === none) {
    ref.current = initializer();
  }

  return ref as MutableRefObject<T>;
}

export function useReadonlyLazyRef<T>(
  initializer: UseLazyRefInitializer<T>
): T {
  const ref = useLazyRef<T>(initializer);
  return ref.current;
}
