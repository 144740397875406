import { type ReactNode, type FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FilterWidget,
  FILTER_WIDGET_COLOR_VARIANT,
  type FilterWidgetSubmitHandler,
} from 'src/components/FilterWidget2';
import { FormItem } from 'src/components/Form/FormItem';
import {
  Checkbox,
  type CheckboxColorVariant,
  CHECKBOX_COLOR_VARIANT,
} from 'src/components/Checkbox';
import { type InternalUserListFilterDataType } from 'src/types/common';
import { type UserListFilterFormData } from './internalTypes';
import {
  convertDataTypeToInternalFormType,
  convertInternalFormTypeToDataType,
} from './functions';
import { isValidFunction } from 'src/utils';

import classes from './UserListFilterWidget.module.css';

export type UserListFilterWidgetFormSubmitHandler =
  FilterWidgetSubmitHandler<InternalUserListFilterDataType>;

export interface UserListFilterWidgetProps {
  className?: string;
  currentData: InternalUserListFilterDataType;
  defaultData: Required<InternalUserListFilterDataType>;
  onSubmit: UserListFilterWidgetFormSubmitHandler;
}

const FORM_DATA = {
  roleAdmin: {
    name: 'roleAdmin',
    label: 'userList.filter.roleAdmin',
  },
  roleUser: {
    name: 'roleUser',
    label: 'userList.filter.roleUser',
  },
  blocked: {
    name: 'blocked',
    label: 'userList.filter.blocked',
  },
  unblocked: {
    name: 'unblocked',
    label: 'userList.filter.unblocked',
  },
};

const UserListFilterWidget: FC<UserListFilterWidgetProps> = (props) => {
  const { className, currentData, defaultData, onSubmit: onSubmitProp } = props;

  const { t } = useTranslation();

  const count: number = useMemo<number>(() => {
    const { roleList, blocked, unblocked } = currentData;
    const result =
      roleList.length +
      (blocked !== defaultData.blocked ? 1 : 0) +
      (unblocked !== defaultData.unblocked ? 1 : 0);

    return result;
  }, [
    currentData,
    defaultData,
  ]);

  const convertedCurrentData: UserListFilterFormData =
    useMemo<UserListFilterFormData>(
      () => convertDataTypeToInternalFormType(currentData),
      [currentData]
    );

  const convertedDefaultData: UserListFilterFormData =
    useMemo<UserListFilterFormData>(
      () => convertDataTypeToInternalFormType(defaultData),
      [defaultData]
    );

  const onSubmit: FilterWidgetSubmitHandler<UserListFilterFormData> =
    useCallback(
      (data, event) => {
        isValidFunction(onSubmitProp) &&
          onSubmitProp(convertInternalFormTypeToDataType(data), event);
      },
      [onSubmitProp]
    );

  const checkboxColorVariant: CheckboxColorVariant =
    CHECKBOX_COLOR_VARIANT.secondary;

  return (
    <FilterWidget<UserListFilterFormData>
      badgeCount={count}
      className={className}
      colorVariant={FILTER_WIDGET_COLOR_VARIANT.secondary}
      defaultValues={convertedDefaultData}
      initialValues={convertedCurrentData}
      onSubmit={onSubmit}
    >
      <div className={classes.block}>
        <div className={classes.blockTitle}>
          {t('userList.filter.roleBlockTitle')}
        </div>
        <div className={classes.blockContent}>
          <FormItem<HTMLInputElement, boolean>
            className={classes.formItem}
            name={FORM_DATA.roleAdmin.name}
          >
            {(data) => {
              const {
                field: { onChange, onBlur, ref, value },
              } = data;

              return (
                <Checkbox
                  checked={value}
                  className={classes.checkbox}
                  colorVariant={checkboxColorVariant}
                  label={t(FORM_DATA.roleAdmin.label)}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  title={t(FORM_DATA.roleAdmin.label)}
                />
              );
            }}
          </FormItem>
          <FormItem<HTMLInputElement, boolean>
            className={classes.formItem}
            name={FORM_DATA.roleUser.name}
          >
            {(data) => {
              const {
                field: { onChange, onBlur, ref, value },
              } = data;

              return (
                <Checkbox
                  checked={value}
                  className={classes.checkbox}
                  colorVariant={checkboxColorVariant}
                  label={t(FORM_DATA.roleUser.label)}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  title={t(FORM_DATA.roleUser.label)}
                />
              );
            }}
          </FormItem>
        </div>
      </div>

      <div className={classes.block}>
        <div className={classes.blockTitle}>
          {t('userList.filter.blockedBlockTitle')}
        </div>
        <div className={classes.blockContent}>
          <FormItem<HTMLInputElement, boolean>
            className={classes.formItem}
            name={FORM_DATA.blocked.name}
          >
            {(data) => {
              const {
                field: { onChange, onBlur, ref, value },
              } = data;

              return (
                <Checkbox
                  checked={value}
                  className={classes.checkbox}
                  colorVariant={checkboxColorVariant}
                  label={t(FORM_DATA.blocked.label)}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  title={t(FORM_DATA.blocked.label)}
                />
              );
            }}
          </FormItem>
          <FormItem<HTMLInputElement, boolean>
            className={classes.formItem}
            name={FORM_DATA.unblocked.name}
          >
            {(data) => {
              const {
                field: { onChange, onBlur, ref, value },
              } = data;

              return (
                <Checkbox
                  checked={value}
                  className={classes.checkbox}
                  colorVariant={checkboxColorVariant}
                  label={t(FORM_DATA.unblocked.label)}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  title={t(FORM_DATA.unblocked.label)}
                />
              );
            }}
          </FormItem>
        </div>
      </div>
    </FilterWidget>
  );
};

export default UserListFilterWidget;
