import { useContext } from 'react';

import { DialogContext } from 'src/components/Modal/DialogContext';

export function useDialogContext() {
  const context = useContext(DialogContext);

  if (context === null) {
    throw new Error('Component must be used within a DialogContext');
  }

  return context;
}
