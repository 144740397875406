import classNames from 'classnames';
import {
  type PropsWithRef,
  type DetailedHTMLProps,
  type ButtonHTMLAttributes,
  type FC,
  forwardRef,
  useMemo,
} from 'react';

import {
  type ButtonLikeBaseProps,
  type ButtonLikeClasses,
  ButtonLike,
} from 'src/components/Button/ButtonLike';

import classes from './Button.module.css';

export {
  BUTTON_COLOR_VARIANT,
  type ButtonColorVariant,
  BUTTON_SIZE_VARIANT,
  type ButtonSizeVariant,
  BUTTON_VARIANT,
  type ButtonVariant,
} from 'src/components/Button/ButtonLike';

export interface ButtonProps
  extends PropsWithRef<
      DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >
    >,
    ButtonLikeBaseProps {}

const BUTTON_CLASSES: Partial<ButtonLikeClasses> = {
  colorVariantDanger: classes.danger,
  colorVariantPrimary: classes.primary,
  colorVariantSecondary: classes.secondary,
  root: classes.root,
  variantContained: classes.contained,
  variantOutlined: classes.outlined,
  variantText: classes.text,
};

const Button: FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { className, classes: classesProp = {}, disabled } = props;

    const resolvedClasses = useMemo(
      () => ({ ...BUTTON_CLASSES, ...classesProp }),
      [classesProp]
    );

    return (
      <ButtonLike<
        DetailedHTMLProps<
          ButtonHTMLAttributes<HTMLButtonElement>,
          HTMLButtonElement
        >
      >
        as="button"
        className={classNames(className, disabled && classes.disabled)}
        classes={resolvedClasses}
        disabled={disabled}
        ref={ref}
        {...props}
      ></ButtonLike>
    );
  }
);

export default Button;
