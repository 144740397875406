import type { TFunction } from 'react-i18next';

import type { FormItemTypedErrorPayload } from 'src/components/Form/FormItemTypedErrorBlockContent/types';

export function getTypedErrorMessage(
  t: TFunction,
  payload?: FormItemTypedErrorPayload
): string {
  const { tKey, tOptions } = payload || {};

  return tKey ? t(tKey, tOptions) : t('form.validation.common');
}
