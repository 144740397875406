import { useContext } from 'react';

import { type AuthState, type AuthActions } from './types';
import { AuthStateContext, AuthActionsContext } from './contexts';

export function useAuthState(): AuthState {
  const state = useContext(AuthStateContext);
  if (!state) {
    throw new Error('AuthProvider is not provided');
  }

  return state;
}

export function useAuthActions(): AuthActions {
  const actions = useContext(AuthActionsContext);
  if (!actions) {
    throw new Error('AuthProvider is not provided');
  }

  return actions;
}

export function useAuth(): readonly [state: AuthState, actions: AuthActions] {
  const state = useAuthState();
  const actions = useAuthActions();

  return [
    state,
    actions,
  ];
}
