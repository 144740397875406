import type { FormItemValidationRule } from 'src/components/Form/FormItem';
import type { FormItemWithTypedErrorPayload } from 'src/components/Form/FormItemWithTypedError';
import type { FileUploadData } from 'src/types/common';

import type {
  AsyncValidatorSupplier,
  SyntheticAsyncValidator,
  SyntheticValidator,
} from './types';

/**
 * Внутренние правила для обеспечения валидации.
 * Так как internal = false, эти правила не передаются дальше, используются
 * только для отображения ошибки на основе выставленного типа в форме
 */
export function requiredInternalValidationRuleFactory(
  tKey: string = 'form.validation.required'
): FormItemValidationRule<any, FormItemWithTypedErrorPayload> {
  return {
    internal: true,
    payload: {
      tKey,
    },
  };
}

export function maxLengthInternalValidationRuleFactory(
  maxLength: number,
  tKey: string = 'form.validation.maxLength'
): FormItemValidationRule<any, FormItemWithTypedErrorPayload> {
  return {
    internal: true,
    payload: {
      tKey,
      tOptions: {
        maxLength,
      },
    },
  };
}

export function regexpInternalValidationRuleFactory(
  tKey: string = 'form.validation.regexp'
): FormItemValidationRule<any, FormItemWithTypedErrorPayload> {
  return {
    internal: true,
    payload: {
      tKey,
    },
  };
}

// валидаторы
export function asyncSyntheticValidatorFactory<T>(
  supplier: AsyncValidatorSupplier<T>,
  type: string
): SyntheticAsyncValidator<T> {
  return async (value: T) => {
    const result = await supplier(value);
    return { result, type };
  };
}

export function stringRequiredSyntheticValidatorFactory(
  type: string
): SyntheticValidator<string> {
  return (value) => {
    const trimmed = value.trim();
    return {
      result: Boolean(trimmed),
      type,
    };
  };
}

export function requiredSyntheticValidatorFactory<T>(
  type: string
): SyntheticValidator<T> {
  return (value) => {
    const valid = value !== undefined && value !== null;
    return {
      result: valid,
      type,
    };
  };
}

export function regexpSyntheticValidatorFactory(
  regexp: RegExp,
  type: string
): SyntheticValidator<string> {
  return (value) => {
    const result = regexp.test(value);
    return { result, type };
  };
}

export function maxLengthSyntheticValidatorFactory(
  maxLength: number,
  type: string
): SyntheticValidator<string | undefined> {
  return (value) => {
    const valid = !value || value.length <= maxLength;
    return { result: valid, type };
  };
}

export function maxFileSizeSyntheticValidatorFactory(
  maxSize: number,
  type: string
): SyntheticValidator<FileUploadData | null | undefined> {
  return (value) => {
    const valid = !value || !value.file || value.file.size <= maxSize;
    return { result: valid, type };
  };
}
