import { forwardRef, type FC, type ReactNode } from 'react';
import classNames from 'classnames';

import classes from './ToolbarButton.module.css';
import {
  Button,
  BUTTON_VARIANT,
  type ButtonProps,
} from 'src/components/Button/Button2';

export interface ToolbarButtonProps extends ButtonProps {}

const ToolbarButton: FC<ToolbarButtonProps> = forwardRef<
  HTMLButtonElement,
  ToolbarButtonProps
>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <Button
      {...rest}
      className={classNames(classes.root, className)}
      ref={ref}
      variant={BUTTON_VARIANT.contained}
    />
  );
});

export default ToolbarButton;
