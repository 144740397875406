import classNames from 'classnames';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './ToastErrorContent.module.css';

export interface ToastErrorContentProps {
  className?: string;
  message?: ReactNode;
  status?: number;
  title?: ReactNode;
}

const ToastErrorContent: FC<ToastErrorContentProps> = (props) => {
  const { className, title: labelProp, message, status: statusProp } = props;
  const { t } = useTranslation();

  const status = statusProp !== undefined ? ` (${statusProp})` : '';
  const label = labelProp || `${t('error.unknown')}${status}`;

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.label}>{label}</div>
      {message && <div className={classes.detail}>{message}</div>}
    </div>
  );
};

export default ToastErrorContent;
