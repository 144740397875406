import type {
  ApiBackendUnauthorizedInterceptorHandler,
  ApiPersonalForm,
  InternalPersonalView,
} from 'src/services/api';
import { type AuthApiService, type UserRole } from 'src/services/api';
import { isValidFunction } from 'src/utils';
import { EXECUTOR_RESULT_STATUS, QueueExecutor } from 'src/utils/executors';

export type AuthControllerUnaithorizedInterceptor = () => void;

/**
 * Обертка для работы процессов, связанных с аутентификацией.
 * Построена с учетом того, что методы типа верификация (verify),
 * аутентификация (authenticate) не будут вызываться одновременно
 *
 * @TODO
 * возможно, надо переделать так, чтобы все вызовы шли друг за другом
 * и поддерживали согласованность данных
 */
export class AuthController {
  private _api: AuthApiService;

  private _authenticated: boolean = false;
  private _authenticating: boolean = false;
  private _verified: boolean = false;
  private _verifying: boolean = false;
  private _verifyPromise: Promise<boolean> | null = null;

  private _intercepted: boolean = false;

  private _user: InternalPersonalView | null = null;
  private _role: UserRole | null = null;

  private _executor = new QueueExecutor(true);

  constructor(apiService: AuthApiService) {
    this._api = apiService;
  }

  setUnauthorizedInterceptor(
    interceptor?: AuthControllerUnaithorizedInterceptor
  ) {
    const _interceptor: ApiBackendUnauthorizedInterceptorHandler = () => {
      if (this._authenticated && !this._intercepted) {
        this._invalidate();
        this._intercepted = true;
        isValidFunction(interceptor) && interceptor();
      }
    };

    this._api.backend.setUnauthorizedInterceptor(_interceptor);
  }

  private _resetIntercepted() {
    this._intercepted = false;
  }

  get authenticated(): boolean {
    return this._authenticated;
  }

  get verified(): boolean {
    return this._verified;
  }

  get user(): InternalPersonalView | null {
    return this._user;
  }

  get role(): UserRole | null {
    return this._role;
  }

  /**
   * Выбрасывает исключение, если что-то пошло не так
   * @param username
   * @param password
   */
  private async _authenticate(
    username: string,
    password: string
  ): Promise<boolean> {
    this._authenticating = true;
    try {
      await this._api.authenticate(username, password);
      const response = await this._api.getProfileData();
      const { data } = response;
      this._user = data;
      this._role = data.role;
      this._authenticated = true;
      this._resetIntercepted();
    } catch (e) {
      this._user = null;
      this._role = null;
      this._authenticated = false;
      throw e;
    } finally {
      this._authenticating = false;
    }

    return this._authenticated;
  }

  async authenticate(username: string, password: string) {
    console.log('authenticate call');
    const entry = this._executor.queue(this._authenticate(username, password));
    const result = await entry.execute();
    if (result.status === EXECUTOR_RESULT_STATUS.ok) {
      return result.data;
    } else {
      throw result.error;
    }
  }

  async reloadUserData() {
    const response = await this._api.getProfileData();
    const { data } = response;
    this._user = data;
    this._role = data.role;
  }

  async updatePersonal(form: ApiPersonalForm) {
    const response = await this._api.updateProfileData(form);
    const { data } = response;
    this._user = data;
    this._role = data.role;
  }

  private async _logout() {
    await this._api.logout();
    this._verified = true;
    this._invalidate();
  }

  async logout() {
    const entry = this._executor.queue(this._logout());
    const result = await entry.execute();
    if (result.status === EXECUTOR_RESULT_STATUS.ok) {
      return result.data;
    } else {
      throw result.error;
    }
  }

  private async _verify(): Promise<boolean> {
    this._verifying = true;
    try {
      const response = await this._api.getProfileData();
      const { data } = response;
      this._user = data;
      this._role = data.role;
      this._authenticated = true;
      this._resetIntercepted();
    } catch (e) {
      this._user = null;
      this._role = null;
      this._authenticated = false;
    } finally {
      this._verifying = false;
      this._verified = true;
    }

    return this._authenticated;
  }

  async verify(force?: boolean): Promise<boolean> {
    console.log('verify call', force, this._verifyPromise, this._verified);
    if (!force) {
      // уже верифицировано ранее
      if (this._verified) {
        return this._authenticated;
      }

      // есть активный промис верификации
      if (this._verifyPromise) {
        return this._verifyPromise;
      }
    }

    // либо верификации еще не было,
    // либо принудительный (force) запрос на верификацию
    const entry = this._executor.queue(this._verify());
    const promise = entry.execute().then(() => this._authenticated);
    this._verifyPromise = promise;
    return this._verifyPromise;
  }

  private _invalidate() {
    this._authenticated = false;
    this._user = null;
    this._role = null;
  }
}
