import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { type To } from 'react-router-dom';

import { SortWidget } from 'src/components/SortWdget/SortWidget2';
import { SortWidgetEntry } from 'src/components/SortWdget/SortWidgetEntry';
import { type RoomListSort, ROOM_LIST_SORT_LIST } from 'src/constants/rooms';

export type RoomListSortWidgetLinkPathComposer = (id?: RoomListSort) => To;
export interface RoomListSortWidgetProps {
  className?: string;
  linkPathComposer?: RoomListSortWidgetLinkPathComposer;
  onSelect?: RoomListSortWidgetSelectHandler;
  value: RoomListSort;
}

export type RoomListSortWidgetSelectHandler = (value: RoomListSort) => void;

const RoomListSortWidget: FC<RoomListSortWidgetProps> = (props) => {
  const { className, onSelect, value } = props;

  const { t } = useTranslation();

  return (
    <SortWidget
      className={className}
      colorVariant="primary"
      onSelect={onSelect}
      value={value}
    >
      <SortWidgetEntry
        direction="asc"
        value={ROOM_LIST_SORT_LIST.ALPHABETICAL_ASC}
      >
        {t('roomList.sort.alphabeticalAsc')}
      </SortWidgetEntry>
      <SortWidgetEntry
        direction="desc"
        value={ROOM_LIST_SORT_LIST.ALPHABETICAL_DESC}
      >
        {t('roomList.sort.alphabeticalDesc')}
      </SortWidgetEntry>
      <SortWidgetEntry
        direction="asc"
        value={ROOM_LIST_SORT_LIST.DATE_ASC}
      >
        {t('roomList.sort.dateAsc')}
      </SortWidgetEntry>
      <SortWidgetEntry
        direction="desc"
        value={ROOM_LIST_SORT_LIST.DATE_DESC}
      >
        {t('roomList.sort.dateDesc')}
      </SortWidgetEntry>
    </SortWidget>
  );
};

export default RoomListSortWidget;
