import { type FC } from 'react';
import { Navigate, type To } from 'react-router-dom';

import type * as T from './types';

const Redirect: FC<T.RedirectProps> = (props) => {
  const { externalRedirect, redirect, replace } = props;

  if (externalRedirect) {
    if (replace) {
      window.location.replace(externalRedirect);
    } else {
      window.location.assign(externalRedirect);
    }
  } else if (redirect) {
    return (
      <Navigate
        replace={replace}
        to={redirect}
      />
    );
  }

  return null;
};

export default Redirect;
