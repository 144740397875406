import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LOGO from 'src/assets/images/logo.png';
import { PATHS } from 'src/constants/paths';
import { getRedirectParams } from 'src/components/Redirect/functions';
import { useRedirect } from 'src/components/Redirect/useRedirect';
import { FullscreenLayout } from 'src/containers/FullscreenLayout';
import { NonAuthorizedHeader } from 'src/containers/Header/NonAuthorizedHeader';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { LoginPageBackground } from 'src/pages/LoginPage/components/LoginPageBackground';
import { LoginPageForm } from 'src/pages/LoginPage/components/LoginPageForm';
import type { LoginPageFormSuccessHandler } from 'src/pages/LoginPage/components/LoginPageForm';

import classes from './LoginPage.module.css';

const LoginPage: FC = () => {
  const location = useLocation();
  const redirect = useRedirect();
  const { t } = useTranslation();

  const onSubmitSuccess: LoginPageFormSuccessHandler = (data, event) => {
    const redirectData = getRedirectParams(location);
    const { externalRedirect, redirect: redirectProp } = redirectData;

    redirect({
      externalRedirect,
      redirect: redirectProp || PATHS.rooms.path,
      replace: true,
    });
  };

  return (
    <div className={classes.root}>
      <LoginPageBackground />
      <FullscreenLayout
        className={classes.rootWrapper}
        classes={{ content: classes.contentLayout, header: classes.header }}
        header={<NonAuthorizedHeader loginButtonHidden={true} />}
      >
        <ContentWrapper>
          <div className={classes.logoContainer}>
            <img
              alt={t('loginPage.logoAlt')}
              className={classes.logo}
              loading="lazy"
              src={LOGO}
            ></img>
          </div>
        </ContentWrapper>
        <LoginPageForm onSubmitSuccess={onSubmitSuccess} />
      </FullscreenLayout>
    </div>
  );
};

export default LoginPage;
