export interface InternalPageRequest<S> {
  page: number;
  pageSize?: number;
  sort?: S;
}

/**
 * Внутренние структуры для хранения и передачи данных о пагинации.
 * Формат передачи подобных данных в энпдоинты API МОЖЕТ отличаться
 */
export class InternalPageRequest<S> implements InternalPageRequest<S> {
  constructor(public page: number, public pageSize?: number, public sort?: S) {}
}
