import classNames from 'classnames';
import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { LoadingButton } from 'src/components/Button/LoadingButton';
import type { FormItemValidationRule } from 'src/components/Form/FormItem';
import { Form } from 'src/components/Form/Form';
import type { FormSubmitHandler } from 'src/components/Form/Form';
import {
  maxLengthValidatorFactory,
  minLengthValidatorFactory,
  regexpValidatorFactory,
  stringRequiredValidatorFactory,
} from 'src/components/Form/validation/validators';
import { useFormController } from 'src/components/Form/Form/useFormController';
import { FormItemWithTypedError } from 'src/components/Form/FormItemWithTypedError';
import type { FormItemWithTypedErrorPayload } from 'src/components/Form/FormItemWithTypedError';
import { TextInput } from 'src/components/Input/TextInput';
import {
  USER_PASSWORD_MAX_LENGTH,
  USER_PASSWORD_MIN_LENGTH,
  USER_PASSWORD_REGEXP,
} from 'src/constants/users';
import { isValidFunction } from 'src/utils';
import { FormErrorBlock } from 'src/components/Form/FormErrorBlock';
import { defaultErrorParser } from 'src/components/Form/FormErrorBlock/functions';

import type * as T from './types';
import classes from './SetPasswordForm.module.css';
import passwordFormsClasses from 'src/styles/common/passwordForms.module.css';

const DEFAULT_FORM_DATA: Required<T.SetPasswordFromData> = {
  password: '',
  passwordConfirm: '',
};

const FORM_PASSWORD_INPUT_ID = 'set_password_password';
const FORM_PASSWORD_CONFIRM_INPUT_ID = 'set_password_password_confirm';

// валидаторы
// обязательность
const requiredValidationRule: FormItemValidationRule<
  string,
  FormItemWithTypedErrorPayload
> = {
  validator: stringRequiredValidatorFactory(),
  payload: {
    tKey: 'form.validation.required',
  },
};

// длина
const minLengthValidationRule: FormItemValidationRule<
  string,
  FormItemWithTypedErrorPayload
> = {
  validator: minLengthValidatorFactory(USER_PASSWORD_MIN_LENGTH),
  payload: {
    tKey: 'form.validation.passwordMinLength',
    tOptions: {
      minLength: USER_PASSWORD_MIN_LENGTH,
    },
  },
};
const maxLengthValidationRule: FormItemValidationRule<
  string,
  FormItemWithTypedErrorPayload
> = {
  validator: maxLengthValidatorFactory(USER_PASSWORD_MAX_LENGTH),
  payload: {
    tKey: 'form.validation.maxLength',
    tOptions: {
      maxLength: USER_PASSWORD_MAX_LENGTH,
    },
  },
};

//regexp
const regexpValidationRule: FormItemValidationRule<
  string,
  FormItemWithTypedErrorPayload
> = {
  validator: regexpValidatorFactory(USER_PASSWORD_REGEXP),
  payload: {
    tKey: 'form.validation.regexp',
  },
};

const SetPasswordForm: FC<T.SetPasswordFormProps> = (props) => {
  const { className, onSubmit: onSubmitProp, onSuccess } = props;
  const { t } = useTranslation();

  const controller = useFormController<T.SetPasswordFromData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: DEFAULT_FORM_DATA,
  });

  const { handleSubmit, formState, trigger, watch } = controller;

  // password confirm
  const passwordConfirmEqualValidationRule: FormItemValidationRule<
    string,
    FormItemWithTypedErrorPayload
  > = {
    validator: (value: string) => {
      const password = watch('password');
      return value === password;
    },
    payload: {
      tKey: 'setPasswordForm.validationPasswordConfirmEqual',
    },
  };

  // триггерим валидацию для confirm, когда изменяется основное поле
  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (formState.isSubmitted && name === 'password') {
        trigger('passwordConfirm');
      }
    });

    return () => subscription.unsubscribe();
  }, [
    formState,
    trigger,
    watch,
  ]);

  const [
    disabled,
    setDisabled,
  ] = useState<boolean>(false);
  const [
    error,
    setError,
  ] = useState<any>(null);

  const onSubmitValid: FormSubmitHandler<T.SetPasswordFromData> = async (
    data,
    event
  ) => {
    setDisabled(true);
    setError(null);

    try {
      if (isValidFunction(onSubmitProp)) {
        await onSubmitProp(data, event!);
      }
      //throw { response: { status: 777, data: { error: 'error message' } } };
      isValidFunction(onSuccess) && onSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Form<T.SetPasswordFromData>
      className={classNames(classes.root, className)}
      controller={controller}
      onSubmit={handleSubmit(onSubmitValid)}
    >
      <p className={classNames(passwordFormsClasses.hintBlock, classes.hint)}>
        <Trans
          i18nKey="setPasswordForm.hint"
          t={t}
          values={{ length: USER_PASSWORD_MIN_LENGTH }}
        >
          <strong>0</strong>
          <strong>1</strong>
          <strong>2</strong>
          <strong>3</strong>
        </Trans>
      </p>
      <div>
        <FormItemWithTypedError<HTMLInputElement, string, T.SetPasswordFromData>
          name="password"
          labelFor={FORM_PASSWORD_INPUT_ID}
          slots={{
            input: ({ field: { onChange, onBlur, value, ref }, valid }) => {
              return (
                <TextInput
                  autoComplete="new-password"
                  classes={{
                    input: passwordFormsClasses.input,
                    root: passwordFormsClasses.inputRoot,
                  }}
                  colorVariant={valid ? 'primary' : 'danger'}
                  disabled={disabled}
                  id={FORM_PASSWORD_INPUT_ID}
                  maxLength={USER_PASSWORD_MAX_LENGTH}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={t('setPasswordForm.passwordLabel')}
                  ref={ref}
                  type="password"
                  value={value}
                />
              );
            },
          }}
          validation={{
            required: requiredValidationRule,
            minLength: minLengthValidationRule,
            maxLength: maxLengthValidationRule,
            regexp: regexpValidationRule,
          }}
        />

        <FormItemWithTypedError<HTMLInputElement, string, T.SetPasswordFromData>
          name="passwordConfirm"
          labelFor={FORM_PASSWORD_CONFIRM_INPUT_ID}
          slots={{
            input: ({ field: { onChange, onBlur, value, ref }, valid }) => {
              return (
                <TextInput
                  autoComplete="new-password"
                  classes={{
                    input: passwordFormsClasses.input,
                    root: passwordFormsClasses.inputRoot,
                  }}
                  colorVariant={valid ? 'primary' : 'danger'}
                  disabled={disabled}
                  id={FORM_PASSWORD_CONFIRM_INPUT_ID}
                  maxLength={USER_PASSWORD_MAX_LENGTH}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={t('setPasswordForm.passwordConfirmLabel')}
                  ref={ref}
                  type="password"
                  value={value}
                />
              );
            },
          }}
          validation={{
            required: requiredValidationRule,
            equal: passwordConfirmEqualValidationRule,
          }}
        />

        <div className={classes.buttons}>
          <LoadingButton
            className={passwordFormsClasses.submitButton}
            colorVariant="primary"
            disabled={disabled}
            loading={disabled}
            variant="contained"
            type="submit"
            uppercased
          >
            {t('setPasswordForm.submit')}
          </LoadingButton>
        </div>
        {error && (
          <FormErrorBlock
            className={passwordFormsClasses.errorBlock}
            {...defaultErrorParser(error)}
          />
        )}
      </div>
    </Form>
  );
};

export default SetPasswordForm;
