import { useCallback, useEffect } from 'react';
import { useFormState } from 'react-hook-form';
import type { Subscription } from 'react-hook-form/dist/utils/createSubject';
import type { FieldValues } from 'react-hook-form';

import type { UseFormControllerReturn } from 'src/components/Form/Form';
import type { FormItemValidation } from 'src/components/Form/FormItem';
import type { FormItemWithTypedErrorPayload } from 'src/components/Form/FormItemWithTypedError';
import {
  maxLengthInternalValidationRuleFactory,
  maxLengthSyntheticValidatorFactory,
  regexpInternalValidationRuleFactory,
  regexpSyntheticValidatorFactory,
  requiredInternalValidationRuleFactory,
  stringRequiredSyntheticValidatorFactory,
} from 'src/components/Form/validation/syntheticValidators';
import type {
  SyntheticValidatorResult,
  KeyType,
} from 'src/components/Form/validation/types';
import { useManualValidationResultSetter } from 'src/components/Form/validation/useManualValidationResultSetter';
import { USER_NAME_MAX_LENGTH, USER_NAME_REGEXP } from 'src/constants/users';

import * as T from './types';

type ValueType = string;

// внутренние правила валидации
const validationRules: FormItemValidation<
  string,
  FormItemWithTypedErrorPayload
> = {
  required: requiredInternalValidationRuleFactory(),
  maxLength: maxLengthInternalValidationRuleFactory(USER_NAME_MAX_LENGTH),
  regexp: regexpInternalValidationRuleFactory(),
};

// валидаторы
const stringRequiredValidator =
  stringRequiredSyntheticValidatorFactory('required');
const maxLengthValidator = maxLengthSyntheticValidatorFactory(
  USER_NAME_MAX_LENGTH,
  'maxLength'
);
const regexpLengthValidator = regexpSyntheticValidatorFactory(
  USER_NAME_REGEXP,
  'regexp'
);

const validators = [
  stringRequiredValidator,
  maxLengthValidator,
  regexpLengthValidator,
];

export function useUserNameValidation<T extends FieldValues = FieldValues>(
  controller: UseFormControllerReturn<T>,
  key: KeyType<T>
): T.UseUserNameValidationReturn {
  // ручной setter
  const validationResultSetter = useManualValidationResultSetter(
    controller,
    key
  );

  const validationHandler = useCallback(
    (value: ValueType) => {
      let result: SyntheticValidatorResult | null = null;

      for (let validator of validators) {
        result = validator(value);
        if (!result.result) {
          break;
        }
      }

      validationResultSetter(result);
    },
    [
      validators,
      validationResultSetter,
    ]
  );

  const { watch } = controller;
  const { isSubmitted } = useFormState(controller);
  // надо валидировать постоянно только после первого submit
  useEffect(() => {
    let subscription: Subscription | undefined;

    if (isSubmitted) {
      subscription = watch((data, info) => {
        switch (info.name) {
          case key: {
            validationHandler(data[key]);
            break;
          }
        }
      });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [
    isSubmitted,
    key,
    validationHandler,
    watch,
  ]);

  // полная валидация для итогового результата
  const validate: T.UseUserNameValidationValdateHandler = (
    value: ValueType
  ) => {
    let result: SyntheticValidatorResult | null = null;

    for (let validator of validators) {
      result = validator(value);
      if (!result.result) {
        break;
      }
    }

    validationResultSetter(result);
    return !result || result.result;
  };

  return { validate, validation: validationRules };
}
