import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PATHS } from 'src/constants/paths';
import { NonAuthorizedHeader } from 'src/containers/Header/NonAuthorizedHeader';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { FullscreenLayout } from 'src/containers/FullscreenLayout';

import classes from './Page404.module.css';
import { Header } from 'src/containers/Header';
import { EmptyPlaceholderImage } from 'src/containers/Placeholder/EmptyPlaceholderImage';
import { PlaceholderImage } from 'src/containers/Placeholder/PlaceholderImage';
import { Placeholder } from 'src/components/Placeholder';

import IMG_404 from 'src/assets/images/404.png';
import { SimpleLink } from 'src/components/Link/SimpleLink';

const Page404: FC = (props) => {
  const { t } = useTranslation();

  /*
  const link =
    stage === STAGE_ERROR_VERIFYING_TOKEN ||
    stage === STAGE_INVAILD_TOKEN ||
    stage === STAGE_SUCCESS ? (
      <div className={classes.linkBlock}>
        <SimpleLink
          className={passwordFormsClasses.link}
          sizeVariant="small"
          to={PATHS.login.path}
        >
          {t('links.toLoginPage')}
        </SimpleLink>
      </div>
    ) : null;
    */

  const icon = <EmptyPlaceholderImage alt={t('search.noResultPlaceholder')} />;

  return (
    <FullscreenLayout
      className={classes.root}
      classes={{ content: classes.content }}
      header={<Header />}
    >
      <ContentWrapper className={classes.wrapper}>
        <Placeholder
          description={t('placeholder.notFoundDescription')}
          slots={{
            icon: (
              <PlaceholderImage
                alt={t('placeholder.notFoundTitle')}
                src={IMG_404}
              />
            ),
          }}
          title={t('placeholder.notFoundTitle')}
        />
        <div className={classes.linkBlock}>
          <SimpleLink
            sizeVariant="small"
            to={PATHS.root.path}
          >
            {t('links.toMainPage')}
          </SimpleLink>
        </div>
      </ContentWrapper>
    </FullscreenLayout>
  );
};

export default Page404;
