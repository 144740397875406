import type { MouseEventHandler, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/Button/Button2';
import { useDropdown } from 'src/components/Dropdown/useDropdown';
import { UserBlockDialog } from 'src/containers/UserView/components/UserBlockDialog';

import type * as T from './types';

const UserBlockButton: FC<T.UserBlockButtonProps> = (props) => {
  const { t } = useTranslation();
  const { className, onBlock } = props;

  const [
    open,
    close,
    opened,
  ] = useDropdown();

  const onClick: MouseEventHandler<HTMLButtonElement> = () => {
    open();
  };

  return (
    <>
      <Button
        className={className}
        colorVariant="danger"
        onClick={onClick}
        sizeVariant="small"
        variant="contained"
      >
        {t('user.actions.block')}
      </Button>

      <UserBlockDialog
        onBlock={onBlock}
        onCloseRequested={close}
        open={opened}
      />
    </>
  );
};

export default UserBlockButton;
