import { ApiService } from '../ApiService';
import { type ApiBackendResponse } from '../ApiBackend';
import type {
  ApiRoomListRequestFilter,
  RoomExternalLink,
  RoomExternalLinkForm,
  RoomOccupantSuggestionView,
} from 'src/services/api/types';
import {
  type Pageable,
  type PageRequest,
  type ApiRoomPreviewView,
  type RoomOccupantView,
  ROOM_OCCUPANT_ROLE,
  ROOM_PERMISSION,
  type RoomId,
  type ApiRoomView,
} from 'src/services/api/types';
import { ROOM_LIST_DEFAULT_PAGE_SIZE } from 'src/constants/rooms';

const occupant50: RoomOccupantView = {
  id: '-1',
  name: 'User 50',
  occupantRole: ROOM_OCCUPANT_ROLE.PARTICIPANT,
};

const occupant51: RoomOccupantView = {
  id: '51',
  name: 'User 51',
  occupantRole: ROOM_OCCUPANT_ROLE.PARTICIPANT,
};

const occupant52: RoomOccupantView = {
  id: '52',
  name: 'User 52',
  occupantRole: ROOM_OCCUPANT_ROLE.PARTICIPANT,
};

const occupant53: RoomOccupantView = {
  id: '53',
  name: 'User 53',
  occupantRole: ROOM_OCCUPANT_ROLE.PARTICIPANT,
};

const room10: ApiRoomPreviewView = {
  id: '10',
  name: 'Room 10',
  description: 'Room 10 description',
  created: 1654079085000,
  owner: { ...occupant50, occupantRole: ROOM_OCCUPANT_ROLE.OWNER },
  occupants: [
    { ...occupant51, occupantRole: ROOM_OCCUPANT_ROLE.MODERATOR },
    occupant52,
  ],
  link: 'https://domain/link?param=very_very_very_very_very_very_very_very_long_param_value',
  permissions: [
    ROOM_PERMISSION.EXTERNAL,
    ROOM_PERMISSION.DELETE,
  ],
  externalLink: {
    enabled: true,
    link: 'https://external/link',
    expires: 1654079085000,
  },
  recordCount: 2,
};

const room10_full: ApiRoomView = {
  ...room10,
  records: [
    {
      id: 100,
      duration: 23444,
      created: 1658757998000,
      size: 12500000,
    },
    {
      id: 101,
      duration: 789987,
      created: 1658671598000,
      size: 123789487,
    },
  ],
};

const room11: ApiRoomPreviewView = {
  id: '11',
  name: 'Room 11 loooooooooong roooooom tiiiiiitle 123123123 123123123123123123123123123123',
  deleted: true,
  description:
    'Room 11 veryyyyyyyyyyyyyyy looooooooooooooooooong description Room 11 veryyyyyyyyyyyyyyylooooooooooooooooooong description Room 11 veryyyyyyyyyyyyyyy looooooooooooooooooong description',
  created: 1654079085000,
  owner: { ...occupant50, occupantRole: ROOM_OCCUPANT_ROLE.OWNER },
  permissions: [
    ROOM_PERMISSION.EXTERNAL,
    ROOM_PERMISSION.RESTORE,
  ],
};

const room12: ApiRoomPreviewView = {
  id: '12',
  name: 'Room 12',
  created: 1654079085000,
  link: 'https://domain.dev/video/link?param=very_very_very_very_very_very_very_very_long_param_value',
  owner: { ...occupant51, occupantRole: ROOM_OCCUPANT_ROLE.OWNER },
  occupants: [
    occupant50,
    occupant52,
    occupant53,
  ],
  permissions: [ROOM_PERMISSION.EXTERNAL],
};

// my
const room13: ApiRoomPreviewView = {
  id: '13',
  name: 'Room 13 title',
  description: 'Room 13 looooooooooooooooooong description',
  created: 1654079085000,
  // link: 'https://domain.dev/video/link',
  externalLink: {
    enabled: true,
    link: 'https://external/link',
    expires: 1654079085000,
  },
  permissions: [
    ROOM_PERMISSION.EXTERNAL,
  ],
  owner: { ...occupant50, occupantRole: ROOM_OCCUPANT_ROLE.OWNER },
  occupants: [occupant53],
};

const room14: ApiRoomPreviewView = {
  id: '14',
  name: 'Room 14',
  created: 1654079085000,
  link: 'https://domain.dev/video/link',
  owner: { ...occupant53, occupantRole: ROOM_OCCUPANT_ROLE.OWNER },
  occupants: [
    { ...occupant50, occupantRole: ROOM_OCCUPANT_ROLE.MODERATOR },
    occupant51,
  ],
  permissions: [ROOM_PERMISSION.EXTERNAL],
};

const roomList: Pageable<ApiRoomPreviewView> = {
  number: 0,
  size: 5,
  totalElements: 12,
  totalPages: 3,
  last: false,
  content: [
    room10,
    room11,
    room12,
    room13,
    room14,
  ],
} as Pageable<ApiRoomPreviewView>;

// suggestions
const occupantSuggestions: RoomOccupantSuggestionView[] = [];
for (let i = 1; i <= 100; i++) {
  occupantSuggestions.push({
    id: i.toString(),
    name: `User${i}`,
  });
}

occupantSuggestions[3].avatar = 'https://mui.com/static/images/avatar/2.jpg';
occupantSuggestions[4].avatar = 'https://mui.com/static/images/avatar/2.jpg';
occupantSuggestions[6].avatar = 'https://mui.com/static/images/avatar/2.jpg';
occupantSuggestions[7].avatar = 'https://mui.com/static/images/avatar/2.jpg';

export class RoomApiService extends ApiService {
  async getRoomList(
    pageRequest: PageRequest,
    filter?: ApiRoomListRequestFilter
  ): Promise<ApiBackendResponse<Pageable<ApiRoomPreviewView>>> {
    const {
      page,
      size: pageSize = ROOM_LIST_DEFAULT_PAGE_SIZE,
      sort,
    } = pageRequest;

    this.backend.get<Pageable<ApiRoomPreviewView>>('/rooms', {
      params: { page, pageSize, sort, ...filter },
    });
    return new Promise<ApiBackendResponse<Pageable<ApiRoomPreviewView>>>(
      (resolve) => {
        setTimeout(() => {
          resolve({ data: roomList } as ApiBackendResponse<
            Pageable<ApiRoomPreviewView>
          >);
        }, 2000);
      }
    );
  }

  async getRoom(id: RoomId): Promise<ApiBackendResponse<ApiRoomView>> {
    return this.backend.get<ApiRoomView>(`/rooms/${id}`);
    /*
    return new Promise<ApiBackendResponse<ApiRoomView>>((resolve) => {
      setTimeout(() => {
        resolve({ data: room10_full } as ApiBackendResponse<ApiRoomView>);
      }, 2000);
    });
    */
  }

  async getRoomOccupantSuggestions(
    id: RoomId,
    pageRequest: PageRequest,
    query?: string
  ): Promise<ApiBackendResponse<Pageable<RoomOccupantSuggestionView>>> {
    const {
      page,
      size: pageSize = ROOM_LIST_DEFAULT_PAGE_SIZE,
      sort,
    } = pageRequest;
    this.backend.get<Pageable<RoomOccupantSuggestionView>>(
      `rooms/${id}/occupants/suggestion`,
      {
        params: { page, pageSize, sort, query },
      }
    );

    return new Promise<
      ApiBackendResponse<Pageable<RoomOccupantSuggestionView>>
    >((resolve) => {
      setTimeout(() => {
        const index = page * pageSize;
        const totalElements = occupantSuggestions.length;
        const totalPages = Math.floor(totalElements / pageSize);

        const pageble: Pageable<RoomOccupantSuggestionView> = {
          content: occupantSuggestions.slice(index, pageSize),
          last: page >= totalPages,
          number: page,
          size: pageSize,
          totalElements,
          totalPages,
        } as Pageable<RoomOccupantSuggestionView>;
        resolve({ data: pageble } as ApiBackendResponse<
          Pageable<RoomOccupantSuggestionView>
        >);
      }, 2000);
    });
  }

  async updateRoomExternalLink(
    id: RoomId,
    form: RoomExternalLinkForm
  ): Promise<ApiBackendResponse<RoomExternalLink>> {
    this.backend.put<RoomExternalLink>(`/rooms/${id}/externalLink`, form);

    return new Promise<ApiBackendResponse<RoomExternalLink>>((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            enabled: false,
            link: 'https://new-external/link',
            expires: 1654079085000,
          },
        } as ApiBackendResponse<RoomExternalLink>);
      }, 2000);
    });
  }
}
