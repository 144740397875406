import { type FromURLMapper, type ToURLMapper } from '../types';

export const queryFromURLMapperFactory: (
  searchParam: string
) => FromURLMapper<string | null> = (searchParam) => (input) => {
  const query = input.get(searchParam);
  const trimmed = query && query.trim();
  const result = trimmed || null;

  return result;
};

export const queryToURLMapperFactory: (
  searchParam: string
) => ToURLMapper<string | null> = (searchParam) => (input) => {
  const { value, accumulator } = input;

  accumulator.delete(searchParam);
  const trimmed = value && value.trim();
  if (trimmed) {
    accumulator.set(searchParam, trimmed);
  }

  return accumulator;
};
