import { useCallback } from 'react';

import { type UseRequestPerformer } from '../types';
import { replaceMerger } from '../functions';
import {
  type UsePageableRequestProps,
  usePageableRequest,
} from '../usePageableRequest';
import { useLazyRef } from 'src/hooks/useLazyRef';
import { type InternalPageRequest } from 'src/utils/pageable';
import { OnlyLastExecutor } from 'src/utils/executors';
import { type Pageable } from 'src/services/api';

export const useSinglePagePageableRequest = <T, C, S>(
  props: UsePageableRequestProps<T, C, S>
) => {
  const { getData: getDataFunction, ...rest } = usePageableRequest(props);

  const executor = useLazyRef(
    () => new OnlyLastExecutor(true, 'use_single_page_pageable_request')
  );
  const performer: UseRequestPerformer<Pageable<T>> = useCallback(
    (request) => {
      return executor.current.queue(() => request);
    },
    [executor]
  );

  const getData = useCallback(
    async (pageRequest: InternalPageRequest<S>, context?: C) => {
      return getDataFunction(performer, replaceMerger, pageRequest, context);
    },
    [
      getDataFunction,
      performer,
    ]
  );

  return {
    getData,
    ...rest,
  } as const;
};
