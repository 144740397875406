import {
  useCallback,
  useContext,
  useEffect,
  type FC,
  type ReactNode,
  type MouseEvent,
  type MouseEventHandler,
} from 'react';
import classNames from 'classnames';

import { IconizedContent } from 'src/components/IconizedContent';
import { MenuItem } from 'src/components/Menu/MenuItem';
import { IconSort, IconSortAsc, IconSortDesc } from 'src/assets/svg';

import {
  type SortWidgetValue,
  type SortWidgetDirection,
  SORT_WIDGET_COLOR_VARIANT,
} from '../types';
import { SortWidgetContext } from 'src/components/SortWdget/SortWidgetContext';

import commonClasses from '../SortWidget2/SortWidget.module.css';
import classes from './SortWidgetEntry.module.css';

export interface SortWidgetEntryProps {
  children?: ReactNode;
  className?: string;
  direction?: SortWidgetDirection;
  value: SortWidgetValue;
}

const SortWidgetEntry: FC<SortWidgetEntryProps> = (props) => {
  const { children, className, direction, value: valueProp } = props;

  const context = useContext(SortWidgetContext);
  if (context === null) {
    throw new Error('SortWidgetEntry must be used within a SortWidget');
  }

  const { close, colorVariant, onEntryClick, value } = context;

  const IconComponent =
    direction === 'asc'
      ? IconSortAsc
      : direction === 'desc'
      ? IconSortDesc
      : null;

  const onClick: MouseEventHandler<HTMLLIElement> = (
    event: MouseEvent<HTMLLIElement>
  ) => {
    onEntryClick(valueProp);
    close();
  };

  const colorVariantClassName =
    colorVariant === SORT_WIDGET_COLOR_VARIANT.secondary
      ? classes.secondary
      : null;

  return (
    <MenuItem
      className={classNames(classes.root, colorVariantClassName, className)}
      key={value}
      onClick={onClick}
      selected={value === valueProp}
    >
      <IconizedContent
        className={classes.item}
        classes={{ icon: commonClasses.icon }}
        icon={IconComponent}
        keepIconSpace
      >
        {children}
      </IconizedContent>
    </MenuItem>
  );
};

export default SortWidgetEntry;
