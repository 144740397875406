import classNames from 'classnames';
import { type FC, memo } from 'react';

import classes from './RoomListSkeletonItem.module.css';

export interface RoomListSkeletonItemProps {
  className?: string;
}

const RoomListSkeletonItem: FC<RoomListSkeletonItemProps> = memo((props) => {
  const { className } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.header} />
      <div className={classes.content}>
        <div className={classes.description} />
        <div className={classes.attribute}>
          <div className={classes.attributeKey} />
          <div className={classes.attributeValue} />
        </div>
        <div className={classes.attribute}>
          <div className={classes.attributeKey} />
          <div className={classes.attributeValue} />
        </div>
      </div>
    </div>
  );
});

export default memo(RoomListSkeletonItem);
