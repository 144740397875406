import { type FC, type ReactNode } from 'react';
import classNames from 'classnames';

import classes from './HeaderWrapper.module.css';

import { ContentWrapper } from 'src/components/ContentWrapper';

export interface HeaderWrapperClasses {
  header: string;
  root: string;
}

export interface HeaderWrapperProps {
  children: ReactNode;
  className?: string;
  classes?: Partial<HeaderWrapperClasses>;
}

const HeaderWrapper: FC<HeaderWrapperProps> = (props) => {
  const { children, className, classes: classesProp = {} } = props;

  return (
    <div className={classNames(classes.root, className, classesProp.root)}>
      <ContentWrapper>
        <header className={classNames(classes.header, classesProp.header)}>
          {children}
        </header>
      </ContentWrapper>
    </div>
  );
};

export default HeaderWrapper;
