import { useState, useCallback } from 'react';

import {
  type ExecutorEntry,
  EXECUTOR_RESULT_STATUS,
} from 'src/utils/executors';
import { type Pageable } from 'src/services/api';
import { type InternalPageRequest } from 'src/utils/pageable';
import { type UseRequestMerger, type UseRequestPerformer } from './types';

export interface UsePageableContentPageData {
  last: boolean;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
}

const pageableToPageData = <T>(
  pageable: Pageable<T>
): UsePageableContentPageData => {
  return {
    last: pageable.last,
    pageNumber: pageable.number,
    pageSize: pageable.size,
    totalElements: pageable.totalElements,
    totalPages: pageable.totalPages,
  };
};

export type UsePageableRequestDataSupplier<T, C, S> = (
  pageRequest: InternalPageRequest<S>,
  context?: C
) => Promise<Pageable<T>>;

export interface UsePageableRequestProps<T, C, S> {
  dataSupplier: UsePageableRequestDataSupplier<T, C, S>;
}

export const usePageableRequest = <T, C, S>(
  props: UsePageableRequestProps<T, C, S>
) => {
  const { dataSupplier } = props;

  const [
    data,
    setData,
  ] = useState<T[] | null>(null);
  const [
    pageData,
    setPageData,
  ] = useState<UsePageableContentPageData | null>(null);
  const [
    lastOkPageData,
    setLastOkPageData,
  ] = useState<UsePageableContentPageData | null>(null);

  const [
    isLoading,
    setLoading,
  ] = useState<boolean>(false);
  const [
    isError,
    setError,
  ] = useState<boolean>(false);
  const [
    errorData,
    setErrorData,
  ] = useState<any>(null);

  const fetchData = useCallback(
    async (
      performer: UseRequestPerformer<Pageable<T>>,
      pageRequest: InternalPageRequest<S>,
      context?: C
    ) => {
      const entry = performer(dataSupplier(pageRequest, context));
      const result = await entry.execute();
      return result;
    },
    [
      dataSupplier,
    ]
  );

  const getData = useCallback(
    async (
      performer: UseRequestPerformer<Pageable<T>>,
      merger: UseRequestMerger<T[]>,
      pageRequest: InternalPageRequest<S>,
      context?: C
    ) => {
      // сброс флагов
      setLoading(true);
      setError(false);

      const result = await fetchData(performer, pageRequest, context);

      switch (result.status) {
        case EXECUTOR_RESULT_STATUS.ok: {
          const { data } = result;
          setData((prevData) => merger(prevData, data.content));
          const pageData = pageableToPageData(data);
          setPageData(pageData);
          setLastOkPageData(pageData);
          setError(false);
          setLoading(false);
          break;
        }

        case EXECUTOR_RESULT_STATUS.invalid: {
          break;
        }

        case EXECUTOR_RESULT_STATUS.error:
        default: {
          setData(null);
          setPageData(null);
          setError(true);
          setErrorData(result.error);
          setLoading(false);
        }
      }
    },
    [fetchData]
  );

  return {
    data,
    pageData,
    lastOkPageData,
    isLoading,
    isError,
    errorData,
    getData,
  } as const;
};
