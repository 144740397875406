import classNames from 'classnames';
import { Fragment, useCallback, type FC, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleLink } from 'src/components/Link/SimpleLink';
import { PATHS } from 'src/constants/paths';
import {
  UserId,
  type RoomOccupantView,
  type InternalPersonalView,
} from 'src/services/api';

import classes from './RoomOccupantsAttributeValue.module.css';

export interface RoomBlockOccupantAttributeValueProps {
  currentUser?: InternalPersonalView | null;
  occupants?: RoomOccupantView[];
  owner?: RoomOccupantView;
  sizeVariant?: 'small' | 'medium';
  visibleCount?: number;
}

const DEFAULT_VISIBLE_COUNT = 2;

const RoomBlockOccupantAttributeValue: FC<
  RoomBlockOccupantAttributeValueProps
> = (props) => {
  const {
    currentUser,
    occupants = [],
    owner,
    sizeVariant,
    visibleCount: visibleCountProp,
  } = props;

  const isSmallSizeVariant = sizeVariant === 'small';

  const { t } = useTranslation();

  const currentUserId = currentUser?.id;
  // TODO
  const getDisplayName = useCallback(
    (occupant: RoomOccupantView): string => {
      const { id, name } = occupant;
      return currentUserId != null && currentUserId === id
        ? t('room.occupants.you')
        : name || '?';
    },
    [
      currentUserId,
      t,
    ]
  );

  const allOccupants: RoomOccupantView[] = [
    owner,
    ...occupants,
  ].filter(Boolean) as RoomOccupantView[];
  const allOccupantsCount = allOccupants.length;

  if (allOccupantsCount === 0) {
    return null;
  }

  const visibleCount = Math.min(
    allOccupantsCount,
    visibleCountProp || DEFAULT_VISIBLE_COUNT
  );

  const visibleOccupants = allOccupants.slice(0, visibleCount);

  const restCount = allOccupantsCount - visibleCount;
  const hasTail = restCount > 0;
  const commaCount = hasTail ? visibleCount - 1 : Math.max(visibleCount - 2, 0);

  const elements: ReactNode[] = [];
  visibleOccupants.forEach((occupant, index) => {
    const { id } = occupant;
    const fragment = (
      <Fragment key={id}>
        <SimpleLink
          className={classes.link}
          to={PATHS.user.format(id)}
          sizeVariant={isSmallSizeVariant ? 'smaller' : 'small'}
        >
          {getDisplayName(occupant)}
        </SimpleLink>
        {index < commaCount && (
          <span className={classes.part}>{`${t(
            'room.occupants.separator'
          )} `}</span>
        )}
      </Fragment>
    );

    elements.push(fragment);

    if (!hasTail && index === visibleCount - 2) {
      elements.push(
        <span
          className={classes.part}
          key="and"
        >{` ${t('room.occupants.and')} `}</span>
      );
    }
  });

  let tail = hasTail && (
    <span className={classes.part}>{` ${t('room.occupants.andMore', {
      count: restCount,
    })}`}</span>
  );

  const sizeVariantClassName =
    sizeVariant === 'small' ? classes.rootSmall : null;

  return (
    <div className={classNames(classes.root, sizeVariantClassName)}>
      {elements}
      {tail}
    </div>
  );
};

export default RoomBlockOccupantAttributeValue;
