import type { To, Location } from 'react-router-dom';
import { parsePath } from 'react-router-dom';

import type {
  RedirectExternalRedirect,
  RedirectRedirect,
  RedirectParamsData,
} from './types';
import { EXTERNAL_REDIRECT_PARAM, REDIRECT_PARAM } from 'src/constants/paths';

export function getRedirectParams(location: Location): RedirectParamsData {
  const { search } = location;
  const params = new URLSearchParams(search);

  // external
  let externalRedirect: RedirectExternalRedirect = null;
  const externalRedirectParam = params.get(EXTERNAL_REDIRECT_PARAM);
  if (externalRedirectParam) {
    try {
      externalRedirect = decodeURIComponent(externalRedirectParam);
    } catch {
      externalRedirect = null;
    }
  }

  // inner redirect
  let redirect: RedirectRedirect = null;
  const redirectParam = params.get(REDIRECT_PARAM);

  if (redirectParam) {
    try {
      redirect = decodeURIComponent(redirectParam);
    } catch {}
  } else {
    // на всякий случай смотрим еще state
    const redirectStateValue: To | undefined = (location.state as any)?.[
      REDIRECT_PARAM
    ];
    if (redirectStateValue) {
      redirect =
        typeof redirectStateValue === 'string'
          ? redirectStateValue
          : { ...redirectStateValue };
    }
  }

  // зачем-то тут такая интересная замена
  if (typeof redirect === 'string') {
    externalRedirect = redirect;
  }
  redirect = null;

  return { externalRedirect, redirect };
}

export function composeRedirectParams(
  /**
   * Адрес, куда будет сделан переход
   */
  to: To,
  /**
   * Адрес, который будет передан как редирект в параметре нового адреса.
   * Если не указан - берется текущий адрес страницы.
   */
  redirect?: Location | string
): readonly [state: Location['state'], to: To] {
  const redirectHref = redirect
    ? typeof redirect === 'string'
      ? redirect
      : redirect.pathname + redirect.search + redirect.hash
    : window.location.pathname + window.location.search + window.location.hash;

  // адрес могли передать уже с параметрами, поэтому придется его парсить
  const formattedTo = typeof to === 'string' ? parsePath(to) : to;
  const params = new URLSearchParams(formattedTo.search);
  params.set(REDIRECT_PARAM, redirectHref);
  formattedTo.search = `?${params.toString()}`;

  const state: Location['state'] = { [REDIRECT_PARAM]: redirect };

  return [
    state,
    formattedTo,
  ];
}
