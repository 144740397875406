import { type ValueOf } from 'src/types/utils';

export type SortWidgetDirection = 'asc' | 'desc';
export type SortWidgetValue = any;

export const SORT_WIDGET_COLOR_VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
} as const;

export type SortWidgetColorVariant = ValueOf<typeof SORT_WIDGET_COLOR_VARIANT>;
