import classNames from 'classnames';
import { type FC, memo } from 'react';
import { StyledBlock } from 'src/components/Styled/Block/StyledBlock';

import classes from './UserListSkeletonItem.module.css';

export interface RoomListSkeletonItemProps {
  className?: string;
}

const RoomListSkeletonItem: FC<RoomListSkeletonItemProps> = memo((props) => {
  const { className } = props;

  return (
    <StyledBlock className={classNames(classes.root, className)}>
      <div className={classes.avatar} />
      <div className={classes.content}>
        <div className={classes.header} />
        <div className={classes.attributes} />
      </div>
    </StyledBlock>
  );
});

export default memo(RoomListSkeletonItem);
