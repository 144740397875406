import type { TFunction } from 'react-i18next';

import type { ErrorData } from 'src/services/api/functions';
import type { RequestDisplayErrorData } from 'src/types/common';

export function errorDataToDisplayErrorData(
  error: ErrorData,
  t: TFunction
): RequestDisplayErrorData {
  const { status, message } = error;
  return { message, status, title: t('error.common') };
}
