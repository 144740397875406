import type {
  ForwardedRef,
  ReactElement,
  ReactNode,
  RefAttributes,
} from 'react';
import { forwardRef } from 'react';
import classNames from 'classnames';
import {
  FormControl,
  InputLabel,
  Select as SelectComponent,
  type SelectProps as MUISelectProps,
  type SelectChangeEvent as MUISelectChangeEvent,
} from '@mui/material';

import classes from './Select.module.css';
import { type ValueOf } from 'src/types/utils';

export type SelectChangeEvent<T = unknown> = MUISelectChangeEvent<T>;
export interface SelectClasses {
  root: string;
  select: string;
  selectRoot: string;
}

export type SelectOnChangeHandler<T> = (event: SelectChangeEvent<T>) => void;

export const SELECT_VARIANT = {
  blank: 'blank',
  outlined: 'outlined',
} as const;

export type SelectVariant = ValueOf<typeof SELECT_VARIANT>;

export const SELECT_SIZE_VARIANT = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export type SelectSizeVariant = ValueOf<typeof SELECT_SIZE_VARIANT>;

export const SELECT_COLOR_VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
  danger: 'danger',
} as const;

export type SelectColorVariant = ValueOf<typeof SELECT_COLOR_VARIANT>;

export interface SelectProps<T>
  extends Omit<MUISelectProps<T>, 'onChange' | 'variant' | 'ref'> {
  children?: ReactNode;
  className?: string;
  classes?: Partial<SelectClasses>;
  colorVariant?: SelectColorVariant;
  labelId?: string;
  onChange?: SelectOnChangeHandler<T>;

  sizeVariant?: SelectSizeVariant;
  variant?: SelectVariant;
}

const Select = <T,>(
  props: SelectProps<T>,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement<any, any> => {
  const {
    children: childrenProp,
    className,
    classes: classesProp = {},
    colorVariant = SELECT_COLOR_VARIANT.primary,
    label,
    labelId,
    onChange,
    sizeVariant,
    variant,
    ...rest
  } = props;

  const variantClassName =
    variant === SELECT_VARIANT.blank ? classes.blank : null;
  const sizeVariantClassName =
    sizeVariant === SELECT_SIZE_VARIANT.small
      ? classes.small
      : sizeVariant === SELECT_SIZE_VARIANT.large
      ? classes.large
      : null;

  const colorVariantClassName =
    colorVariant === SELECT_COLOR_VARIANT.secondary
      ? classes.rootSecondary
      : null;

  const rootClassName = classNames(
    classes.root,
    variantClassName,
    sizeVariantClassName,
    colorVariantClassName,
    className,
    classesProp.root
  );

  const selectRootClassName = classNames(
    classes.selectRoot,
    classesProp.selectRoot
  );

  const selectClassName = classNames(classes.select, classesProp.select);

  return (
    <FormControl className={rootClassName}>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <SelectComponent
        className={selectRootClassName}
        classes={{ select: selectClassName }}
        label={label}
        labelId={labelId}
        ref={ref}
        onChange={onChange}
        variant="outlined"
        {...rest}
      >
        {childrenProp}
      </SelectComponent>
    </FormControl>
  );
};

export default forwardRef(Select) as <T>(
  props: SelectProps<T> & RefAttributes<HTMLDivElement>,
  ref: ForwardedRef<HTMLDivElement>
) => ReturnType<typeof Select<T>>;
