import { type FC, type ReactNode, useRef, useMemo } from 'react';
import classNames from 'classnames';

import { EllipsisButton } from 'src/components/Button/EllipsisButton';

import classes from './ActionContainer.module.css';
import type { ButtonColorVariant } from 'src/components/Button/Button2';
import { Menu } from 'src/components/Menu';
import type { UseActionContainerController } from '../types';
import { type ActionContainerColorVariant } from '../types';
import { type ActionContainerContextType } from '../typesInternal';
import { ACTION_CONTAINER_COLOR_VARIANT } from '../constants';
import { ActionContainerContext } from '../ActionContainerContext';

export interface ActionContainerProps {
  children?: ReactNode;
  className?: string;
  colorVariant?: ActionContainerColorVariant;
  controller: UseActionContainerController;
}

const ActionContainer: FC<ActionContainerProps> = (props) => {
  const { children, className, colorVariant, controller } = props;

  // dropdown
  const { open, opened, close } = controller;
  const anchorEl = useRef<HTMLButtonElement>(null);

  // context
  const contextValue: ActionContainerContextType = useMemo(
    () => ({ colorVariant, close }),
    [
      colorVariant,
      close,
    ]
  );

  let buttonColorVariant: ButtonColorVariant;
  switch (colorVariant) {
    case ACTION_CONTAINER_COLOR_VARIANT.secondary: {
      buttonColorVariant = 'secondary';
      break;
    }

    case ACTION_CONTAINER_COLOR_VARIANT.primary:
    default: {
      buttonColorVariant = 'primary';
      break;
    }
  }

  return (
    <div className={classNames(classes.root, className)}>
      <ActionContainerContext.Provider value={contextValue}>
        <EllipsisButton
          colorVariant={buttonColorVariant}
          onClick={open}
          ref={anchorEl}
          sizeVariant="smaller"
        />
        <Menu
          anchorEl={anchorEl.current}
          onCloseRequested={close}
          open={opened}
        >
          {children}
        </Menu>
      </ActionContainerContext.Provider>
    </div>
  );
};

export default ActionContainer;
