import classNames from 'classnames';
import { type ReactNode, type FC } from 'react';

import type { ValueOf } from 'src/types/utils';

import classes from './Typography.module.css';

export const TYPOGRAPHY_SIZE_VARIANT = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export type TypographySizeVariant = ValueOf<typeof TYPOGRAPHY_SIZE_VARIANT>;

export const TYPOGRAPHY_VARIANT = {
  div: 'div',
  h1: 'h1',
  h2: 'h2',
  p: 'p',
} as const;

export type TypographyVariant = ValueOf<typeof TYPOGRAPHY_VARIANT>;

export interface TypographyProps {
  children?: ReactNode;
  className?: string;
  sizeVariant?: TypographySizeVariant;
  variant?: TypographyVariant;
}

const TYPOGRAPHY_VARIANT_MAP: Record<
  TypographyVariant,
  { className: string; component: keyof JSX.IntrinsicElements }
> = {
  [TYPOGRAPHY_VARIANT.div]: { className: classes.div, component: 'div' },
  [TYPOGRAPHY_VARIANT.h1]: { className: classes.h1, component: 'h1' },
  [TYPOGRAPHY_VARIANT.h2]: { className: classes.h2, component: 'h2' },
  [TYPOGRAPHY_VARIANT.p]: { className: classes.p, component: 'p' },
} as const;

export const Typography: FC<TypographyProps> = (props) => {
  const {
    children,
    className: classNameProp,
    sizeVariant = TYPOGRAPHY_SIZE_VARIANT.medium,
    variant,
  } = props;
  const { className, component: Component } =
    (variant && TYPOGRAPHY_VARIANT_MAP[variant]) ||
    TYPOGRAPHY_VARIANT_MAP[TYPOGRAPHY_VARIANT.div];

  const sizeVariantClassName =
    sizeVariant === TYPOGRAPHY_SIZE_VARIANT.small
      ? classes.small
      : sizeVariant === TYPOGRAPHY_SIZE_VARIANT.large
      ? classes.large
      : classes.medium;

  return (
    <Component
      className={classNames(
        classes.root,
        sizeVariantClassName,
        className,
        classNameProp
      )}
    >
      {children}
    </Component>
  );
};

export default Typography;
