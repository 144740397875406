import { useCallback } from 'react';

import { type UseRequestPerformer } from '../types';
import { replaceMerger } from '../functions';
import { type UseRequestProps, useRequest } from '../useRequest';
import { useLazyRef } from 'src/hooks/useLazyRef';
import { OnlyLastExecutor } from 'src/utils/executors';

export const useOnlyLastRequest = <T, C>(props: UseRequestProps<T, C>) => {
  const { getData: getDataFunction, ...rest } = useRequest(props);

  const executor = useLazyRef(() => new OnlyLastExecutor());
  const performer: UseRequestPerformer<T> = useCallback(
    (request) => {
      return executor.current.queue(() => request);
    },
    [executor]
  );

  const getData = useCallback(
    async (context: C) => {
      return getDataFunction(performer, replaceMerger, context);
    },
    [
      getDataFunction,
      performer,
    ]
  );

  return {
    getData,
    ...rest,
  } as const;
};
