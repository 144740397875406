import classNames from 'classnames';
import { type FC, type ReactNode } from 'react';

import classes from './RoomLinkAttributeValue.module.css';

export interface RoomLinkAttributeValueProps {
  children?: ReactNode;
  className?: string;
  expired?: number;
  hint?: ReactNode;
}

const RoomLinkAttributeValue: FC<RoomLinkAttributeValueProps> = (props) => {
  const { children, className, hint } = props;

  return (
    <div className={classNames(classes.root, className)}>
      {hint && <div className={classes.hint}>{hint}</div>}
      <div className={classes.link}>{children}</div>
    </div>
  );
};

export default RoomLinkAttributeValue;
