import classNames from 'classnames';
import { type ReactNode, type FC } from 'react';
import {
  Link,
  useMatch,
  useResolvedPath,
  type LinkProps,
} from 'react-router-dom';
import type { ValueOf } from 'src/types/utils';

import classes from './StyledLink.module.css';

export const STYLED_LINK_VARIANT = {
  menuItem: 'menuItem',
} as const;

export type StyledLinkVariant = ValueOf<typeof STYLED_LINK_VARIANT>;

export interface StyledLinkClasses {
  active: string;
  root: string;
}

export interface StyledLinkProps extends LinkProps {
  classes?: Partial<StyledLinkClasses>;
  activeClassName?: string;
  variant?: StyledLinkVariant;
}

const StyledLink: FC<StyledLinkProps> = (props) => {
  const {
    children,
    className: classNameProp,
    classes: classesProp = {},
    to,
    variant,
    ...rest
  } = props;

  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: true });

  const className = classNames(
    classes.root,
    classNameProp,
    classesProp.root,
    variant === STYLED_LINK_VARIANT.menuItem && classes.menuItem,
    Boolean(match) && classesProp.active
  );

  return (
    <Link
      {...{
        className: className,
        to,
        ...rest,
      }}
    >
      {children}
    </Link>
  );
};

export default StyledLink;
