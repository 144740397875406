import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Placeholder } from 'src/components/Placeholder';
import { EmptyPlaceholderImage } from 'src/containers/Placeholder/EmptyPlaceholderImage';

export interface RoomNotFoundPlaceholderProps {
  className?: string;
}

const RoomNotFoundPlaceholder: FC<RoomNotFoundPlaceholderProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();

  const icon = <EmptyPlaceholderImage alt={t('placeholder.room.notFound')} />;

  return (
    <Placeholder
      className={className}
      slots={{ icon }}
      title={t('placeholder.room.notFound')}
    />
  );
};

export default RoomNotFoundPlaceholder;
