import classNames from 'classnames';
import { type ReactNode, type FC } from 'react';

import { Button, type ButtonProps } from 'src/components/Button/Button2';
import { Spinner } from 'src/components/Spinner';

import classes from './LoadingButton.module.css';

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

const LoadingButton: FC<LoadingButtonProps> = (props) => {
  const { children, className, loading, ...rest } = props;

  const spinnerColor = '#ffffff';

  return (
    <Button
      className={classNames(classes.root, className)}
      {...rest}
    >
      <div className={classes.wrapper}>
        {loading && (
          <Spinner
            className={classes.spinner}
            color={spinnerColor}
            size={16}
          />
        )}
        {children}
      </div>
    </Button>
  );
};

export default LoadingButton;
