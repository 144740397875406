import { type UserListFilterFormData } from './internalTypes';
import { type InternalUserListFilterDataType } from 'src/types/common';
import { type UserRole, USER_ROLE } from 'src/services/api';

export function convertDataTypeToInternalFormType(
  data: InternalUserListFilterDataType
): UserListFilterFormData {
  const { roleList } = data;

  return {
    roleAdmin: roleList.includes(USER_ROLE.ADMIN),
    roleUser: roleList.includes(USER_ROLE.USER),
    blocked: data.blocked,
    unblocked: data.unblocked,
  };
}

export function convertInternalFormTypeToDataType(
  data: UserListFilterFormData
): InternalUserListFilterDataType {
  const { roleAdmin, roleUser } = data;

  return {
    roleList: [
      roleAdmin ? USER_ROLE.ADMIN : null,
      roleUser ? USER_ROLE.USER : null,
    ].filter(Boolean) as UserRole[],
    blocked: data.blocked,
    unblocked: data.unblocked,
  };
}
