import type { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import type { Location, To } from 'react-router-dom';

import { PATHS } from 'src/constants/paths';
import { composeRedirectParams } from 'src/components/Redirect/functions';

const AuthRequiredRedirect: FC = () => {
  const location = useLocation();
  const { pathname } = location;

  let state: Location['state'] = null;
  let to: To = PATHS.login.path;
  if (pathname !== PATHS.root.path && pathname !== PATHS.login.path) {
    [
      state,
      to,
    ] = composeRedirectParams(PATHS.login.path, location);
  }

  // адрес редиректа передаем и в урле, и в стейте на всякий случай
  return (
    <Navigate
      state={state}
      to={to}
    />
  );
};

export default AuthRequiredRedirect;
