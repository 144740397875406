import imageCompressor from 'browser-image-compression';

const DEFAULT_IMAGE_COMPRESSOR_OPTIONS: Parameters<typeof imageCompressor>[1] =
  {
    maxWidthOrHeight: 200,
    useWebWorker: true,
  };

export async function defaultImageCompressor(file: File): Promise<File> {
  console.log('image compressor start', file.size);
  const compressed = await imageCompressor(
    file,
    DEFAULT_IMAGE_COMPRESSOR_OPTIONS
  );
  console.log('image compressor end', compressed.size);
  return compressed;
}

export async function encodeImage(file?: File | null): Promise<string | null> {
  if (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string | null);
      };
      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  return null;
}
