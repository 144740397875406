import { type FC, Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { PATHS } from 'src/constants/paths';

// страница логина
import { LoginPage } from 'src/pages/LoginPage';
// список пользователей
import { UsersPage } from 'src/pages/UsersPage';
// профиль пользователя
import { ProfilePage } from 'src/pages/ProfilePage';
// список комнат
import { RoomsPage } from 'src/pages/RoomsPage';
// создание комнаты
import { CreateRoomPage } from 'src/pages/CreateRoomPage';
// страница пользователя
import { UserPage } from './pages/UserPage';
// установить пароль
import { SetPasswordPage } from 'src/pages/SetPasswordPage';
// страница "не помню пароль"
import { ForgotPasswordPage } from 'src/pages/ForgotPasswordPage';
// изменение пароля
import { ChangePasswordPage } from 'src/pages/ChangePasswordPage';
// создание пользователя
import { CreateUserPage } from 'src/pages/CreateUserPage';
// страница отдельной комнаты
import { RoomPage } from 'src/pages/RoomPage';
// 404
import { Page404 } from 'src/pages/Page404';

import { MainPage } from 'src/pages/MainPage';

import { AuthAwaited } from 'src/components/Auth/AuthAwaited';
import { AuthRequired } from 'src/components/Auth/AuthRequired';
import { ADMIN_USER_ROLES, ApiServiceProvider } from 'src/services/api';
import {
  AuthProvider,
  AuthUnauthorizedInterpectorSetter,
} from 'src/contexts/auth';

import { ToastContainer } from 'src/components/Toast';

// чтобы стили из mui инжектились первыми, давая возможность
// адекватно их переопределять у нас
import { StyledEngineProvider } from '@mui/material';

import { AuthAwaitedWithFullscreenFallback } from 'src/components/Auth/AuthAwaitedWithFullscreenFallback';
import { AuthForbidden } from 'src/components/Auth/AuthForbidden';

const App: FC = () => {
  const roomsPage = <RoomsPage />;

  return (
    <StyledEngineProvider injectFirst>
      <Suspense fallback={null}>
        <AuthProvider>
          <BrowserRouter>
            <AuthUnauthorizedInterpectorSetter />
            <Routes>
              <Route
                path={PATHS.login.path}
                element={
                  <AuthAwaitedWithFullscreenFallback>
                    <AuthForbidden parseParams>
                      <LoginPage />
                    </AuthForbidden>
                  </AuthAwaitedWithFullscreenFallback>
                }
              />

              <Route
                path={PATHS.forgotPassword.path}
                element={
                  <AuthAwaitedWithFullscreenFallback>
                    <AuthForbidden>
                      <ForgotPasswordPage />
                    </AuthForbidden>
                  </AuthAwaitedWithFullscreenFallback>
                }
              />

              <Route
                path={PATHS.setPassword.path}
                element={<SetPasswordPage sourceType="set" />}
              />
              <Route
                path={PATHS.resetPassword.path}
                element={<SetPasswordPage sourceType="reset" />}
              />
              <Route
                path={PATHS.changePassword.path}
                element={
                  <AuthAwaitedWithFullscreenFallback>
                    <AuthRequired>
                      <ChangePasswordPage />
                    </AuthRequired>
                  </AuthAwaitedWithFullscreenFallback>
                }
              />

              <Route
                path={PATHS.root.path}
                element={
                  <AuthAwaitedWithFullscreenFallback>
                    <AuthRequired>
                      <MainPage />
                    </AuthRequired>
                  </AuthAwaitedWithFullscreenFallback>
                }
              >
                <Route
                  index
                  element={roomsPage}
                />
                <Route
                  path={PATHS.rooms.path}
                  element={roomsPage}
                />
                <Route
                  path={PATHS.newRoom.path}
                  element={/*<CreateRoomPage />*/ 'create room page'}
                />
                <Route
                  path={PATHS.newUser.path}
                  element={
                    <AuthRequired
                      acceptedRoles={ADMIN_USER_ROLES}
                      fallback="forbidden"
                    >
                      <CreateUserPage />
                    </AuthRequired>
                  }
                />
                <Route
                  path={PATHS.profile.path}
                  element={<ProfilePage />}
                />
                <Route
                  path={PATHS.room.path}
                  element={<RoomPage />}
                />
                <Route
                  path={PATHS.users.path}
                  element={
                    <AuthRequired
                      acceptedRoles={ADMIN_USER_ROLES}
                      fallback="forbidden"
                    >
                      <UsersPage />
                    </AuthRequired>
                  }
                />
                <Route
                  path={PATHS.user.path}
                  element={
                    <AuthRequired>
                      <UserPage />
                    </AuthRequired>
                  }
                />
              </Route>

              <Route
                path="*"
                element={
                  <AuthAwaitedWithFullscreenFallback>
                    <Page404 />
                  </AuthAwaitedWithFullscreenFallback>
                }
              />
            </Routes>
          </BrowserRouter>
          <ToastContainer />
        </AuthProvider>
      </Suspense>
    </StyledEngineProvider>
  );
};

export default App;
