import classNames from 'classnames';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { IconAlertCircleFilled } from 'src/assets/svg';

import type * as T from './types';
import classes from './FormErrorBlock.module.css';

const FormErrorBlock: FC<T.FormErrorBlockProps> = (props) => {
  const { className, title: titleProp, message, status: statusProp } = props;
  const { t } = useTranslation();

  const status = statusProp !== undefined ? ` (${statusProp})` : '';
  const label = titleProp || `${t('error.unknown')}${status}`;

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.iconWrapper}>
        <IconAlertCircleFilled className={classes.icon} />
      </div>
      <div className={classes.message}>
        <div className={classes.label}>{label}</div>
        {message && <div className={classes.detail}>{message}</div>}
      </div>
    </div>
  );
};

export default FormErrorBlock;
