import type { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormItemWithTypedError } from 'src/components/Form/FormItemWithTypedError';
import type { SelectOnChangeHandler } from 'src/components/Select/Select';
import { UserRoleSelect } from 'src/containers/UserRoleSelect';

import * as T from './types';
import classes from './UserRoleFormItem.module.css';

const DEFAULT_INPUT_ID = 'user_form_role_input';
const DEFAULT_INPUT_LABEL_ID = 'user_form_role_input_label';
const DEFAULT_INPUT_NAME = 'role';

const UserRoleFormItem = <T extends FieldValues = FieldValues>(
  props: T.UserRoleFormItemProps<T>
) => {
  const {
    className,
    defaultValue,
    disabled,
    id = DEFAULT_INPUT_ID,
    labelId = DEFAULT_INPUT_LABEL_ID,
    name = DEFAULT_INPUT_NAME,
    validation,
  } = props;

  const { t } = useTranslation();

  return (
    <FormItemWithTypedError<HTMLDivElement, T.UserRoleFormItemValue, T>
      className={className}
      defaultValue={defaultValue}
      name={name}
      label={t('user.form.labelRole')}
      labelFor={id}
      labelId={labelId}
      required
      slots={{
        input: ({ field: { onChange, onBlur, value, ref }, valid }) => {
          return (
            <UserRoleSelect
              className={classes.select}
              colorVariant={valid ? 'secondary' : 'danger'}
              disabled={disabled}
              id={id}
              labelId={labelId}
              name={name}
              onBlur={onBlur}
              onChange={
                onChange as SelectOnChangeHandler<T.UserRoleFormItemValue>
              }
              placeholder={t('user.form.placeholderRole')}
              ref={ref}
              type="text"
              sizeVariant="medium"
              value={value}
            />
          );
        },
      }}
      validation={validation}
    />
  );
};

export default UserRoleFormItem;
