import { useEffect, useState, useCallback } from 'react';
import type { FC } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { Select } from 'src/components/Select/Select';
import type { SelectOnChangeHandler } from 'src/components/Select/Select';
import { SelectOption } from 'src/components/Select/SelectOption';
import languages from 'src/constants/languages';
import type { Language } from 'src/constants/languages';

import classes from './LanguageSelector.module.css';

const LanguageSelector: FC = () => {
  const { t } = useTranslation();

  // locale
  const [
    locale,
    setLocale,
  ] = useState<string>(i18next.resolvedLanguage);

  useEffect(() => {
    const onLanguageChanged = (lng: string) => {
      setLocale(i18next.resolvedLanguage);
    };

    i18next.on('languageChanged', onLanguageChanged);

    return () => {
      i18next.off('languageChanged', onLanguageChanged);
    };
  }, []);

  // change handler
  const onChange: SelectOnChangeHandler<string> = useCallback((event) => {
    i18next.changeLanguage(event.target.value);
  }, []);

  const renderOption = (item: Language, index: number) => {
    const { locale } = item;
    return (
      <SelectOption
        className={classes.option}
        key={locale}
        value={locale}
      >
        <div className={classes.item}>
          <item.icon className={classes.languageIcon} />
          <span className={classes.languageName}>{t(`locale.${locale}`)}</span>
        </div>
      </SelectOption>
    );
  };

  const children = languages.map(renderOption);

  return (
    <Select<string>
      autoWidth
      className={classes.root}
      value={locale}
      onChange={onChange}
      variant="blank"
    >
      {children}
    </Select>
  );
};

export default LanguageSelector;
