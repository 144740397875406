import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IconVideo } from 'src/assets/svg';
import { Attribute } from 'src/components/AttributeList';
import { SimpleExternalLink } from 'src/components/Link/SimpleLink';
import { RoomAttributeIconDescriptor } from 'src/containers/Room/RoomAttributeIconDescriptor';
import { RoomLinkAttributeValue } from 'src/containers/Room/RoomLinkAttributeValue';

export interface RoomLinkAttributeValueProps {
  className?: string;
  link?: string;
  sizeVariant?: 'small' | 'medium';
}

const RoomLinkAttribute: FC<RoomLinkAttributeValueProps> = (props) => {
  const { className, link, sizeVariant } = props;
  const { t } = useTranslation();

  return link ? (
    <Attribute
      className={className}
      descriptor={
        <RoomAttributeIconDescriptor
          icon={IconVideo}
          sizeVariant={sizeVariant}
        />
      }
    >
      <RoomLinkAttributeValue hint={t('room.link.link')}>
        <SimpleExternalLink
          href={link}
          rel="noopener"
          noWrap
          sizeVariant={sizeVariant === 'small' ? 'smaller' : 'small'}
        >
          {link}
        </SimpleExternalLink>
      </RoomLinkAttributeValue>
    </Attribute>
  ) : null;
};

export default RoomLinkAttribute;
