import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Redirect } from 'src/components/Redirect';
import type {
  RedirectExternalRedirect,
  RedirectRedirect,
} from 'src/components/Redirect';
import { getRedirectParams } from 'src/components/Redirect/functions';
import { PATHS } from 'src/constants/paths';

export interface AuthForbiddenRedirectProps {
  parseParams?: boolean;
}

/**
 * В отдельном компоненте для изоляции хуков из react-dom
 */
const AuthForbiddenRedirect: FC<AuthForbiddenRedirectProps> = (props) => {
  const { parseParams } = props;

  const location = useLocation();

  let externalRedirect: RedirectExternalRedirect | undefined;
  let redirect: RedirectRedirect | undefined;
  if (parseParams) {
    ({ externalRedirect, redirect } = getRedirectParams(location));
  }

  if (!redirect) {
    redirect = PATHS.root.path;
  }

  return (
    <Redirect
      externalRedirect={externalRedirect}
      redirect={redirect}
      replace
    />
  );
};

export default AuthForbiddenRedirect;
