import classNames from 'classnames';
import type { LabelHTMLAttributes } from 'react';
import {
  type PropsWithRef,
  type DetailedHTMLProps,
  type ButtonHTMLAttributes,
  type FC,
  forwardRef,
  useMemo,
} from 'react';

import {
  type ButtonLikeBaseProps,
  type ButtonLikeClasses,
  ButtonLike,
} from 'src/components/Button/ButtonLike';

import classes from './ButtonLikeLabel.module.css';

export {
  BUTTON_COLOR_VARIANT,
  type ButtonColorVariant,
  BUTTON_SIZE_VARIANT,
  type ButtonSizeVariant,
  BUTTON_VARIANT,
  type ButtonVariant,
} from 'src/components/Button/ButtonLike';

export interface ButtonLikeLabelProps
  extends PropsWithRef<
      DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
    >,
    ButtonLikeBaseProps {
  syntheticDisabled?: boolean;
}

const BUTTON_CLASSES: Partial<ButtonLikeClasses> = {
  colorVariantDanger: classes.danger,
  colorVariantPrimary: classes.primary,
  colorVariantSecondary: classes.secondary,
  root: classes.root,
  variantContained: classes.contained,
  variantOutlined: classes.outlined,
  variantText: classes.text,
};

const ButtonLikeLabel: FC<ButtonLikeLabelProps> = forwardRef<
  HTMLLabelElement,
  ButtonLikeLabelProps
>((props, ref) => {
  const {
    className,
    classes: classesProp = {},
    syntheticDisabled,
    ...rest
  } = props;

  const resolvedClasses = useMemo(
    () => ({ ...BUTTON_CLASSES, ...classesProp }),
    [classesProp]
  );

  return (
    <ButtonLike<
      DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
    >
      as="label"
      className={classNames(className, syntheticDisabled && classes.disabled)}
      classes={resolvedClasses}
      ref={ref}
      {...rest}
    ></ButtonLike>
  );
});

export default ButtonLikeLabel;
