import type {
  FormItemValidator,
  FormItemAsyncValidator,
} from 'src/components/Form/FormItem';
import type { AsyncValidatorSupplier } from './types';

export function minLengthValidatorFactory(
  minLength: number
): FormItemValidator<string> {
  return (value) => Boolean(value && value.length >= minLength);
}

export function maxLengthValidatorFactory(
  maxLength: number
): FormItemValidator<string | undefined> {
  return (value) => !value || value.length <= maxLength;
}

export function stringRequiredValidatorFactory(): FormItemValidator<string> {
  return (value) => Boolean(value.trim());
}

export function regexpValidatorFactory(
  regexp: RegExp
): FormItemValidator<string> {
  return (value) => regexp.test(value);
}

/**
 * Ошибки прокидываются дальше
 */
export function asyncValidatorFactory<T>(
  request: AsyncValidatorSupplier<T>
): FormItemAsyncValidator<T> {
  return async (value: T) => {
    const result = await request(value);
    return result;
  };
}
