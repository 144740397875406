import { type ReactNode, type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FilterWidget,
  type FilterWidgetSubmitHandler,
} from 'src/components/FilterWidget2';
import { FormItem } from 'src/components/Form/FormItem';
import type { FormSubmitHandler } from 'src/components/Form/Form';
import { Checkbox } from 'src/components/Checkbox';

import classes from './RoomListFilterWidget.module.css';
import type { InternalRoomListFilterData } from 'src/types/common';

export type RoomListFilterWidgetFormData = Required<InternalRoomListFilterData>;

export type RoomListFilterWidgetFormSubmitHandler =
  FilterWidgetSubmitHandler<RoomListFilterWidgetFormData>;

export interface RoomListFilterWidgetProps {
  className?: string;
  currentData: InternalRoomListFilterData;
  defaultData: Required<InternalRoomListFilterData>;
  onSubmit: RoomListFilterWidgetFormSubmitHandler;
}

const RoomListFilterWidget: FC<RoomListFilterWidgetProps> = (props) => {
  const { className, currentData, defaultData, onSubmit } = props;

  const { t } = useTranslation();

  const count: number = useMemo(() => {
    const { onlyMine } = currentData;
    const result = onlyMine !== defaultData.onlyMine ? 1 : 0;

    return result;
  }, [
    currentData,
    defaultData,
  ]);

  return (
    <FilterWidget<RoomListFilterWidgetFormData>
      badgeCount={count}
      className={className}
      colorVariant="primary"
      defaultValues={defaultData}
      initialValues={currentData}
      onSubmit={onSubmit}
    >
      <div className={classes.items}>
        <FormItem<HTMLInputElement, boolean>
          defaultValue={false}
          name="onlyMine"
        >
          {(data) => {
            const {
              field: { onChange, onBlur, ref, value },
            } = data;

            return (
              <Checkbox
                checked={value}
                className={classes.checkbox}
                label={t('roomList.filters.onlyMine')}
                onBlur={onBlur}
                onChange={onChange}
                inputRef={ref}
                title={t('roomList.filters.onlyMine')}
              />
            );
          }}
        </FormItem>
      </div>
    </FilterWidget>
  );
};

export default RoomListFilterWidget;
