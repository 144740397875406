import { useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { PATHS } from 'src/constants/paths';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { authApiService } from 'src/services/api';
import type { ApiChangePasswordForm } from 'src/services/api';
import { FullscreenLayout } from 'src/containers/FullscreenLayout';
import { Header } from 'src/containers/Header';
import type {
  ChangePasswordFormSuccessHandler,
  ChangePasswordFromSubmitHandler,
} from 'src/containers/ChangePasswordForm';
import { ChangePasswordForm } from 'src/containers/ChangePasswordForm';
import { SimpleLink } from 'src/components/Link/SimpleLink';

import classes from './ChangePasswordPage.module.css';
import passwordFormsClasses from 'src/styles/common/passwordForms.module.css';

const STAGE_FORM = 3;
const STAGE_SUCCESS = 20;

const ChangePasswordPage: FC = () => {
  const { t } = useTranslation();

  const [
    stage,
    setStage,
  ] = useState<number>(STAGE_FORM);

  const onSubmit: ChangePasswordFromSubmitHandler = async (data, event) => {
    const form: ApiChangePasswordForm = {
      oldPassword: data.currentPassword,
      newPassword: data.password,
    };

    await authApiService.changePassword(form);
  };

  const onSuccess: ChangePasswordFormSuccessHandler = () => {
    setStage(STAGE_SUCCESS);
  };

  const description = (
    <p className={passwordFormsClasses.descriptionBlock}>
      {stage === STAGE_FORM
        ? t('changePasswordPage.descriptionForm')
        : t('changePasswordPage.descriptionSuccess')}
    </p>
  );

  const link =
    stage === STAGE_SUCCESS ? (
      <div className={classes.linkBlock}>
        <SimpleLink
          className={passwordFormsClasses.link}
          sizeVariant="small"
          to={PATHS.profile.path}
        >
          {t('links.toProfilePage')}
        </SimpleLink>
      </div>
    ) : null;

  return (
    <FullscreenLayout
      className={classes.root}
      classes={{ content: classes.mainLayoutContent }}
      header={<Header />}
    >
      <div className={classes.content}>
        <ContentWrapper className={classes.contentInnerWrapper}>
          {description}
          {stage === STAGE_FORM && (
            <div className={classes.formBlock}>
              <div className={classes.formBlockInnerWrapper}>
                <ChangePasswordForm
                  onSubmit={onSubmit}
                  onSuccess={onSuccess}
                />
              </div>
            </div>
          )}
          {link}
        </ContentWrapper>
      </div>
    </FullscreenLayout>
  );
};

export default ChangePasswordPage;
