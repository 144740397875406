import { useEffect, useCallback, useState } from 'react';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { RoomExternalLinkForm } from 'src/services/api';
import { roomApiService } from 'src/services/api';
import type { ApiRoomView, RoomId } from 'src/services/api';
import classes from './RoomPage.module.css';
import { ContentWrapper } from 'src/components/ContentWrapper';
import {
  useOnlyLastRequest,
  type UseRequestDataSupplier,
} from 'src/hooks/request';
import {
  type RoomViewOnExternalLinkSwitchHandler,
  RoomView,
} from 'src/containers/RoomView';
import { PATHS } from 'src/constants/paths';
import { is404Error } from 'src/services/api/functions';
import { useReadonlyLazyRef } from 'src/hooks';
import { AsyncContainer } from 'src/components/AsyncContainer/AsyncContainer';
import { RoomErrorPlaceholder } from 'src/pages/RoomPage/components/RoomErrorPlaceholder';
import { RoomNotFoundPlaceholder } from 'src/pages/RoomPage/components/RoomNotFoundPlaceholder';
import { RoomSkeletonLoadingPlaceholder } from 'src/pages/RoomPage/components/RoomSkeletonLoadingPlaceholder';

type DataType = ApiRoomView | null;
type IdType = RoomId | undefined;

const RoomPage: FC = () => {
  const { [PATHS.room.param]: id } = useParams();
  const { t } = useTranslation();

  const [
    data,
    setData,
  ] = useState<DataType>(null);

  const dataSupplier: UseRequestDataSupplier<DataType, IdType> =
    useReadonlyLazyRef(() => async (id) => {
      if (id) {
        try {
          const { data } = await roomApiService.getRoom(id);
          return data;
        } catch (e: any) {
          if (is404Error(e)) {
            // ловим 404 ошибку
            return null;
          } else {
            throw e;
          }
        }
      }

      return null;
    });

  // через dataSetter все делается в едином рендере
  const { isLoading, isError, getData } = useOnlyLastRequest<DataType, IdType>({
    dataSetter: setData,
    dataSupplier,
  });

  useEffect(() => {
    getData(id);
  }, [
    getData,
    id,
  ]);

  const retry = () => {
    getData(id);
  };

  const hasData = Boolean(data);

  // external link
  const onExternalLinkSwitch: RoomViewOnExternalLinkSwitchHandler | undefined =
    id
      ? async (enabled) => {
          const form: RoomExternalLinkForm = {
            enabled,
          };
          try {
            const { data: responseData } =
              await roomApiService.updateRoomExternalLink(id, form);

            setData((data) => ({ ...data!, externalLink: responseData }));
          } catch (e) {}
        }
      : undefined;

  return (
    <ContentWrapper className={classes.root}>
      <AsyncContainer
        className={classes.content}
        hasData={hasData}
        isError={isError}
        isRequesting={isLoading}
        slots={{
          content: hasData
            ? /*<RoomView data={data!} onExternalLinkSwitch={onExternalLinkSwitch} />*/ 'room content'
            : null,
          errorPlaceholder: <RoomErrorPlaceholder onRetry={retry} />,
          loadingPlaceholder: <RoomSkeletonLoadingPlaceholder />,
          noDataPlaceholder: <RoomNotFoundPlaceholder />,
        }}
      ></AsyncContainer>
    </ContentWrapper>
  );
};

export default RoomPage;
