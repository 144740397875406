import {
  type ReactNode,
  type FC,
  useRef,
  type MouseEvent,
  useCallback,
  useMemo,
  useState,
  Children,
  isValidElement,
  type ReactElement,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useMediumMediaQuery } from 'src/hooks/responsive';
import { Menu, MenuItem } from 'src/components/Menu';
import { useDropdown } from 'src/components/Dropdown';
import { ToolbarButton } from 'src/components/ToolbarButton';
import { IconSort, IconSortAsc, IconSortDesc } from 'src/assets/svg';
import { IconizedContent } from 'src/components/IconizedContent';

import { type ValueOf } from 'src/types/utils';
import {
  type SortWidgetDirection,
  type SortWidgetColorVariant,
  type SortWidgetValue,
  SORT_WIDGET_COLOR_VARIANT,
} from '../types';
import { SortWidgetEntry, type SortWidgetEntryProps } from '../SortWidgetEntry';

import classes from './SortWidget.module.css';
import {
  SortWidgetUnregisterType,
  type SortWidgetContextType,
  type SortWidgetRegisterType,
} from '../typesInternal';
import { SortWidgetContext } from '../SortWidgetContext';
import { BUTTON_COLOR_VARIANT } from 'src/components/Button/Button2';
import { isValidFunction } from 'src/utils';

export type SortWidgetItemId = any;
export interface SortWidgetItem {
  id: SortWidgetItemId;
  direction?: 'asc' | 'desc';
  label: string;
}

export type SortWidgetItemRender = (
  item: SortWidgetItem,
  active: boolean,
  defaultRender: ReactNode,
  onClick: any
) => ReactNode;

export interface SortWidgetSlots {
  item: SortWidgetItemRender;
}

export interface SortWidgetClasses {
  item: string;
}

export type SortWidgetSelectHandler = (value: SortWidgetValue) => void;

export interface SortWidgetProps {
  children?: ReactNode;
  className?: string;
  colorVariant?: SortWidgetColorVariant;
  onSelect?: SortWidgetSelectHandler;
  value: SortWidgetValue;
}

interface SortWidgetInternalItemData {
  direction?: SortWidgetDirection;
  label?: ReactNode;
  value: SortWidgetValue;
}

type SortWidgetInternalItemDict = Record<
  SortWidgetValue,
  SortWidgetInternalItemData
>;

type SortWidgetEntryElement = ReactElement<
  SortWidgetEntryProps,
  typeof SortWidgetEntry
>;

const SortWidget: FC<SortWidgetProps> = (props) => {
  const {
    children,
    className,
    colorVariant,
    onSelect,
    value: valueProp,
  } = props;

  // i18n
  const { t } = useTranslation();

  // dropdown
  const anchor = useRef<HTMLButtonElement>(null);
  const [
    open,
    close,
    opened,
  ] = useDropdown();

  const onSelectRef = useRef<SortWidgetSelectHandler | null>(null);
  onSelectRef.current = onSelect || null;
  const onEntryClick = useCallback(
    (id: SortWidgetValue) => {
      isValidFunction(onSelectRef.current) && onSelectRef.current(id);
    },
    [onSelectRef]
  );

  // context
  const contextValue: SortWidgetContextType = useMemo(
    () => ({ colorVariant, value: valueProp, close, onEntryClick }),
    [
      colorVariant,
      valueProp,
      close,
      onEntryClick,
    ]
  );

  let selected: SortWidgetInternalItemData | null = null;

  Children.forEach(children, (child) => {
    if (
      isValidElement<SortWidgetEntryProps>(child) &&
      child.type === SortWidgetEntry
    ) {
      const { props } = child;
      const { value } = props;

      if (value === valueProp && !selected) {
        const { direction, children: label } = props;
        selected = {
          direction,
          label,
          value,
        };
      }
    }
  });

  let direction: SortWidgetDirection | null | undefined = null;
  let label: ReactNode | null | undefined = null;

  if (selected) {
    direction = (selected as SortWidgetInternalItemData).direction;
    label = (selected as SortWidgetInternalItemData).label;
  }

  const colorVariantClassName =
    colorVariant === SORT_WIDGET_COLOR_VARIANT.secondary
      ? classes.secondary
      : null;

  const buttonColorVariant =
    colorVariant === SORT_WIDGET_COLOR_VARIANT.secondary
      ? BUTTON_COLOR_VARIANT.secondaryLight
      : BUTTON_COLOR_VARIANT.primaryLight;

  const isMedium = useMediumMediaQuery();
  let buttonContent: ReactNode;
  if (isMedium) {
    const Icon =
      direction === 'desc'
        ? IconSortDesc
        : direction === 'asc'
        ? IconSortAsc
        : IconSort;

    buttonContent = (
      <IconizedContent
        className={classNames(classes.item, classes.buttonContent)}
        classes={{ icon: classes.icon }}
        icon={Icon}
      >
        {label || t('sort.title')}
      </IconizedContent>
    );
  } else {
    buttonContent = (
      <IconizedContent
        className={classes.item}
        classes={{ icon: classes.icon }}
        icon={IconSort}
      ></IconizedContent>
    );
  }

  return (
    <div className={classNames(classes.root, colorVariantClassName, className)}>
      <ToolbarButton
        className={classes.button}
        colorVariant={buttonColorVariant}
        onClick={open}
        ref={anchor}
        type="button"
      >
        {buttonContent}
      </ToolbarButton>
      <Menu
        anchorEl={anchor.current}
        open={opened}
        onCloseRequested={close}
      >
        <SortWidgetContext.Provider value={contextValue}>
          {children}
        </SortWidgetContext.Provider>
      </Menu>
    </div>
  );
};

export default SortWidget;
