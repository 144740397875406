import type { InternalPersonalView } from 'src/services/api';
import {
  type ApiRoomPreviewView,
  type ApiUserView,
  ROOM_OCCUPANT_ROLE,
  ROOM_PERMISSION,
} from 'src/services/api';

export function extractRoomData(
  room: ApiRoomPreviewView,
  currentUser?: InternalPersonalView | null
) {
  const {
    deleted: deletedProp,
    externalLink,
    occupants: occupantsProp,
    owner,
    permissions: permissionsProp,
  } = room;

  const currentUserId = currentUser?.id;
  // owner
  const ownerId = owner?.id;
  const isOwner = Boolean(ownerId && ownerId === currentUserId);

  // occupants
  const occupants = Array.isArray(occupantsProp) ? occupantsProp : [];
  const permissions = Array.isArray(permissionsProp) ? permissionsProp : [];
  const allOccupants = [
    owner,
    ...occupants,
  ].filter(Boolean);

  // deleted
  const deleted: boolean = Boolean(deletedProp);

  // moderator
  const isModerator = occupants.some(
    (occupant) =>
      occupant.id === currentUserId &&
      occupant.occupantRole === ROOM_OCCUPANT_ROLE.MODERATOR
  );

  // external link
  const externalLinkAvailable = Boolean(
    permissions.includes(ROOM_PERMISSION.EXTERNAL) &&
      externalLink &&
      externalLink.link
  );

  // remove
  const deleteAvailable =
    permissions.includes(ROOM_PERMISSION.DELETE) && !deleted;

  const restoreAvailable =
    permissions.includes(ROOM_PERMISSION.RESTORE) && deleted;

  return {
    allOccupants,
    deleted,
    deleteAvailable,
    externalLinkAvailable,
    isModerator,
    isOwner,
    occupants,
    permissions,
    restoreAvailable,
  } as const;
}
