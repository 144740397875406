import type { ReactNode } from 'react';
import { type FC } from 'react';
import classNames from 'classnames';

import { type ReactSVGComponent } from 'src/types/svg';
import { type ValueOf } from 'src/types/utils';

import classes from './RoomAttributeIconDescriptor.module.css';

export const ROOM_ATTRIBUTE_ICON_DESCRIPTOR_SIZE_VARIANT = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export type RoomAttributeIconDescriptorSizeVariant = ValueOf<
  typeof ROOM_ATTRIBUTE_ICON_DESCRIPTOR_SIZE_VARIANT
>;

export interface RoomAttributeIconDescriptorProps {
  children?: ReactNode;
  icon: ReactSVGComponent;
  sizeVariant?: RoomAttributeIconDescriptorSizeVariant;
  text?: string | number;
}

const RoomAttributeIconDescriptor: FC<RoomAttributeIconDescriptorProps> = (
  props
) => {
  const {
    icon: IconComponent,
    children: childrenProp,
    sizeVariant,
    text,
  } = props;

  const children = childrenProp || text;

  const sizeVariantClassName =
    sizeVariant === ROOM_ATTRIBUTE_ICON_DESCRIPTOR_SIZE_VARIANT.large
      ? classes.large
      : sizeVariant === ROOM_ATTRIBUTE_ICON_DESCRIPTOR_SIZE_VARIANT.small
      ? classes.small
      : null;

  return (
    <div className={classNames(classes.root, sizeVariantClassName)}>
      <div className={classes.iconWrapper}>
        <IconComponent className={classes.icon} />
      </div>
      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default RoomAttributeIconDescriptor;
