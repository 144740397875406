import type { MouseEventHandler, FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/Button/Button2';
import { LoadingButton } from 'src/components/Button/LoadingButton';
import { Dialog } from 'src/components/Modal/Dialog';
import { DialogActions } from 'src/components/Modal/DialogActions';
import { DialogContent } from 'src/components/Modal/DialogContent';
import { DialogTitle } from 'src/components/Modal/DialogTitle';
import { isValidFunction } from 'src/utils';

import type * as T from './types';
import classes from './UserBlockDialog.module.css';

const UserBlockDialog: FC<T.UserBlockDialogProps> = (props) => {
  const {
    className,
    onBlock: onBlockProp,
    open,
    onCloseRequested,
    ...rest
  } = props;
  const { t } = useTranslation();

  const [
    disabled,
    setDisabled,
  ] = useState<boolean>(false);

  const onBlock: MouseEventHandler<HTMLButtonElement> = async (event) => {
    try {
      setDisabled(true);
      isValidFunction(onBlockProp) && (await onBlockProp(event));
      isValidFunction(onCloseRequested) && onCloseRequested();
    } catch (e) {
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Dialog
      {...rest}
      className={classes.root}
      onCloseRequested={onCloseRequested}
      open={open}
      scroll="body"
    >
      <DialogTitle>{t('user.dialog.blockTitle')}</DialogTitle>
      <DialogContent>{t('user.dialog.blockDescription')}</DialogContent>
      <DialogActions>
        <Button
          colorVariant="secondary"
          disabled={disabled}
          onClick={onCloseRequested}
          sizeVariant="small"
          variant="outlined"
        >
          {t('user.dialog.cancel')}
        </Button>
        <LoadingButton
          colorVariant="danger"
          disabled={disabled}
          loading={disabled}
          onClick={onBlock}
          sizeVariant="small"
          variant="contained"
        >
          {t('user.dialog.blockOk')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UserBlockDialog;
