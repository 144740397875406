import { type FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconLock } from 'src/assets/svg';

import { Chip, CHIP_COLOR_VARIANT, type ChipProps } from 'src/components/Chip';

import classes from './UserBlockedChip.module.css';

export interface UserBlockedChipProps extends ChipProps {
  className?: string;
  value: boolean | undefined | null;
}

const UserBlockedChip: FC<UserBlockedChipProps> = (props) => {
  const { value, ...rest } = props;
  const { t } = useTranslation();

  const icon = <IconLock className={classes.icon} />;

  return value ? (
    <Chip
      {...rest}
      colorVariant={CHIP_COLOR_VARIANT.danger}
    >
      <div className={classes.wrapper}>
        {icon}
        <span className={classes.label}>{t('user.chip.blocked')}</span>
      </div>
    </Chip>
  ) : null;
};

export default memo(UserBlockedChip);
