import {
  INTERNAL_USER_PERMISSION,
  type InternalUserPermission,
} from 'src/services/api/types';

export const ALL_USER_PERMISSIONS: readonly InternalUserPermission[] = [
  INTERNAL_USER_PERMISSION.BLOCK,
  INTERNAL_USER_PERMISSION.UNBLOCK,
  INTERNAL_USER_PERMISSION.CHANGE_USER_DATA,
  INTERNAL_USER_PERMISSION.RESEND_PASSWORD_MAIL,
];
