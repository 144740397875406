import type { UseActionContainerController } from './types';
import { useDropdown } from 'src/components/Dropdown';

export function useActionContainer(): UseActionContainerController {
  const [
    open,
    close,
    opened,
  ] = useDropdown();

  return {
    open,
    opened,
    close,
  };
}
