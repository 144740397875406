import { type FC, memo } from 'react';

import { RoomListSkeletonItem } from 'src/containers/RoomListItem/RoomListSkeletonItem';

import { Grid } from 'src/components/Grid';

export interface RoomListLoadingPlaceholderProps {
  className?: string;
}

const RoomListLoadingPlaceholder: FC<RoomListLoadingPlaceholderProps> = (
  props
) => {
  const { className } = props;

  return (
    <Grid className={className}>
      <RoomListSkeletonItem />
      <RoomListSkeletonItem />
    </Grid>
  );
};

export default memo(RoomListLoadingPlaceholder);
