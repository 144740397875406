import { useCallback, type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, type To } from 'react-router-dom';

import { PATHS } from 'src/constants/paths';
import {
  SearchWidget,
  type SearchWidgetFormSubmit,
} from 'src/containers/SearchWidget';
import {
  sortFromURLMapper,
  sortToURLMapper,
  queryFromURLMapper,
  queryToURLMapper,
  pageFromURLMapper,
  pageToURLMapper,
  pageSizeFromURLMapper,
  pageSizeToURLMapper,
  filterFromURLMapper,
  filterToURLMapper,
} from './mappers';

import { type InternalRoomListFilterData } from 'src/types/common';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { Typography, TYPOGRAPHY_VARIANT } from 'src/components/Typography';

import layoutClasses from 'src/styles/common/layout.module.css';
import classNames from 'classnames';
import toolbarClasses from 'src/containers/Layout/ListContentLayout/ListContentLayoutToolbar.module.css';

import type {
  PaginationOnPageHandler,
  PaginationOnPageSizeHandler,
} from 'src/components/Pagination';
import type { RoomListSort } from 'src/constants/rooms';
import { ROOM_LIST_DEFAULT_FILTER_DATA } from 'src/constants/rooms';
import { CreateRoomButton } from 'src/containers/CreateRoomButton';
import { StyledBlock } from 'src/components/Styled/Block/StyledBlock';
import { RoomListSortWidget } from 'src/containers/RoomListSortWidget';
import type {
  RoomListSortWidgetLinkPathComposer,
  RoomListSortWidgetSelectHandler,
} from 'src/containers/RoomListSortWidget';
import type { RoomListFilterWidgetFormSubmitHandler } from 'src/containers/RoomListFilterWidget';
import { RoomListFilterWidget } from 'src/containers/RoomListFilterWidget';
import { RoomList } from 'src/containers/RoomList';

const RoomsPage: FC = () => {
  const { t } = useTranslation();
  const [
    searchParams,
    setSearchParams,
  ] = useSearchParams();

  // query
  const query: string = queryFromURLMapper(searchParams) || '';

  // page
  const page: number = pageFromURLMapper(searchParams);
  const pageSize: number = pageSizeFromURLMapper(searchParams);

  // sort
  const sort: RoomListSort = sortFromURLMapper(searchParams);

  // filter
  /*
  const filter = useMemo<InternalUserListFilterDataType>(
    () => filterFromURLMapper(searchParams),
    [searchParams]
  );
  */
  // благодаря этому "хаку" страница будет ререндериться, если была подтверждена
  // какая-либо форма, но данные не были изменены; ререндер будет вызывать
  // запросы и тд, что является ожидаемым поведением;
  // сложно сказать, насколько это "допустимый" подход
  const filter: InternalRoomListFilterData = filterFromURLMapper(searchParams);

  // поиск по строке
  const onSearchSubmit: SearchWidgetFormSubmit = useCallback(
    (inputQuery) => {
      // изменяется только query, остальное не трогаем
      const params = new URLSearchParams(searchParams);
      queryToURLMapper({ value: inputQuery, accumulator: params });
      // сброс страницы
      pageToURLMapper({ value: undefined, accumulator: params });

      console.log('onSearchSubmit', params.toString());

      setSearchParams(params);
    },
    [
      searchParams,
      setSearchParams,
    ]
  );

  // формирование ссылки для виджета сортировки
  const sortLinkPathComposer: RoomListSortWidgetLinkPathComposer = useCallback(
    (id) => {
      const params = new URLSearchParams(searchParams);
      sortToURLMapper({ value: id, accumulator: params });

      const result: To = {
        pathname: PATHS.rooms.path,
        search: `?${params.toString()}`,
      };
      return result;
    },
    [searchParams]
  );

  const onSortSelect: RoomListSortWidgetSelectHandler = (value) => {
    const params = new URLSearchParams(searchParams);
    sortToURLMapper({ value, accumulator: params });
    // сброс страницы
    pageToURLMapper({ value: undefined, accumulator: params });

    setSearchParams(params);
  };

  // подтверждение формы фильтрации
  const onFilterSubmit: RoomListFilterWidgetFormSubmitHandler = useCallback(
    (data: any) => {
      const params = new URLSearchParams(searchParams);
      filterToURLMapper({ value: data, accumulator: params });
      // сброс страницы
      pageToURLMapper({ value: undefined, accumulator: params });

      setSearchParams(params);
    },
    [
      searchParams,
      setSearchParams,
    ]
  );

  // pagination
  const onPage: PaginationOnPageHandler = useCallback(
    (page) => {
      const params = new URLSearchParams(searchParams);
      pageToURLMapper({ value: page, accumulator: params });

      setSearchParams(params);
    },
    [
      searchParams,
      setSearchParams,
    ]
  );

  const onPageSize: PaginationOnPageSizeHandler = useCallback(
    (pageSize) => {
      const params = new URLSearchParams(searchParams);
      pageSizeToURLMapper({ value: pageSize, accumulator: params });

      setSearchParams(params);
    },
    [
      searchParams,
      setSearchParams,
    ]
  );

  const searchInputHint = t('roomList.search.placeholder');

  return (
    <div>
      <ContentWrapper>
        <Typography variant={TYPOGRAPHY_VARIANT.h1}>
          {t('rooms.title')}
        </Typography>

        <div className={layoutClasses.listLayoutSection}>
          <CreateRoomButton />
        </div>
        <StyledBlock className={layoutClasses.listLayoutSection}>
          <div className={classNames(toolbarClasses.toolbar)}>
            <div
              className={classNames(
                toolbarClasses.toolbarBlock,
                toolbarClasses.flex
              )}
            >
              <SearchWidget
                colorVariant="primary"
                initialQuery={query}
                inputProps={{
                  placeholder: searchInputHint,
                }}
                onSubmit={onSearchSubmit}
              />
            </div>
            <RoomListSortWidget
              className={toolbarClasses.toolbarBlock}
              onSelect={onSortSelect}
              value={sort}
            />
            <RoomListFilterWidget
              className={toolbarClasses.toolbarBlock}
              currentData={filter}
              defaultData={ROOM_LIST_DEFAULT_FILTER_DATA}
              onSubmit={onFilterSubmit}
            />
          </div>
        </StyledBlock>
        <div
          className={classNames(
            layoutClasses.listLayoutSection,
            layoutClasses.listLayoutList
          )}
        >
          <RoomList
            filter={filter}
            onPage={onPage}
            onPageSize={onPageSize}
            page={page}
            pageSize={pageSize}
            query={query}
            sort={sort}
          />
        </div>
      </ContentWrapper>
    </div>
  );
};

export default RoomsPage;
