import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FieldPath } from 'react-hook-form';

import type { FormSubmitHandler } from 'src/components/Form';
import { Form } from 'src/components/Form/Form';
import { IconAccount } from 'src/assets/svg';

import { StyledSection } from 'src/components/Styled/Forms/StyledSection';

import { useFormController } from 'src/components/Form/Form/useFormController';

import { userApiService, USER_ROLE } from 'src/services/api';
import type { ApiCreateUserForm } from 'src/services/api';

import { LoadingButton } from 'src/components/Button/LoadingButton';
import { StyledSectionHeader } from 'src/components/Styled/Forms/StyledSectionHeader';
import { UserEmailFormItem } from 'src/containers/User/UserEmailFormItem';

import { useUserEmailValidation } from 'src/containers/User/UserEmailFormItem/useUserEmailValidation';
import { UserLoginFormItem } from 'src/containers/User/UserLoginFormItem';
import { UserNameFormItem } from 'src/containers/User/UserNameFormItem';
import { useUserNameValidation } from 'src/containers/User/UserNameFormItem/useUserNameValidation';
import { UserAvatarFormItem } from 'src/containers/User/UserAvatarFormItem';
import { useUserAvatarValidation } from 'src/containers/User/UserAvatarFormItem/useUserAvatartValidation';
import { useUserRoleValidation } from 'src/containers/User/UserRoleFormItem/useUserRoleValidation';
import { UserRoleFormItem } from 'src/containers/User/UserRoleFormItem';
import { isValidFunction } from 'src/utils';
import { useUserLoginValidation } from 'src/containers/User/UserLoginFormItem/useUserLoginValidation';
import { encodeImage } from 'src/containers/AvatarUpload/functions';
import { maybeGetErrorDataFromBackend } from 'src/services/api/functions';
import { defaultErrorToast } from 'src/components/Toast/functions';

import type * as T from './types';
import linkClasses from 'src/styles/common/link.module.css';
import classes from './CreateUserForm.module.css';
import { errorDataToDisplayErrorData, getFirstInvalidField } from './functions';

const DEFAULT_FORM_DATA: Required<T.CreateUserFormData> = {
  avatar: null,
  email: '',
  login: '',
  name: '',
  role: USER_ROLE.USER,
};

const FORM_FIELD_LIST: Array<FieldPath<T.CreateUserFormData>> = [
  'email',
  'login',
  'name',
  'role',
  'avatar',
];

const CreateUserForm: FC<T.CreateUserFormProps> = (props) => {
  const { className, onSubmitSuccess } = props;
  const { t } = useTranslation();

  const controller = useFormController<T.CreateUserFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues: DEFAULT_FORM_DATA,
  });

  const { handleSubmit, setFocus, setValue, trigger, watch } = controller;

  // email
  const {
    validate: validateEmail,
    validation: emailValidation,
    validationState: emailValidationState,
  } = useUserEmailValidation(controller, 'email');

  // login
  const {
    validate: validateLogin,
    validation: loginValidation,
    validationState: loginValidationState,
  } = useUserLoginValidation(controller, 'login');

  // name
  const { validate: validateName, validation: nameValidation } =
    useUserNameValidation(controller, 'name');

  // avatar
  const { validate: validateAvatar, validation: avatartValidation } =
    useUserAvatarValidation(controller, 'avatar');

  // role
  const { validate: validateRole, validation: roleValidation } =
    useUserRoleValidation(controller, 'role');

  // submit
  const [
    disabled,
    setDisabled,
  ] = useState<boolean>(false);

  const [
    locked,
    setLocked,
  ] = useState<boolean>(false);
  const disabledOrLocked = disabled || locked;

  const [
    firstInvaliField,
    setFirstInvaliField,
  ] = useState<FieldPath<T.CreateUserFormData> | undefined>();

  // валидация ручная, поэтому submit формы всегда будет успешным
  const onSubmitValid: FormSubmitHandler<T.CreateUserFormData> = async (
    data,
    event
  ) => {
    const { avatar, email, login, name, role } = data;
    let localFirstInvaliField: FieldPath<T.CreateUserFormData> | undefined;

    try {
      setDisabled(true);
      setFirstInvaliField(undefined);
      // валидация
      // отдельные вызовы для валидации МОГУТ выбрасывать исключения,
      // поэтому используется Promise.all, в случае исключения
      // процесс остановится, API-вызов не будет сделан
      const validList = await Promise.all([
        validateEmail(email),
        validateLogin(login),
        validateName(name),
        validateRole(role),
        validateAvatar(avatar),
      ]);
      console.log(validList);

      if (validList.every(Boolean)) {
        const avatarEncodedValue =
          (avatar && avatar.file && (await encodeImage(avatar.file))) ||
          undefined;

        const form: ApiCreateUserForm = {
          avatar: avatarEncodedValue,
          email: email.trim(),
          login: login.trim(),
          name: name.trim(),
          role: { id: role },
        };

        console.log('form', form);

        const result = await userApiService.addUser(form);
        isValidFunction(onSubmitSuccess) && onSubmitSuccess(result.data, event);
        setLocked(true);
        localFirstInvaliField = undefined;
      } else {
        // для фокуса первого инвалидного элемента
        localFirstInvaliField = getFirstInvalidField<T.CreateUserFormData>(
          validList,
          FORM_FIELD_LIST
        );
      }
    } catch (e) {
      const errorData = maybeGetErrorDataFromBackend(e);
      if (errorData.status !== 401) {
        defaultErrorToast(errorDataToDisplayErrorData(errorData));
      }
    } finally {
      setDisabled(false);
      setFirstInvaliField(localFirstInvaliField);
    }
  };

  useEffect(() => {
    if (firstInvaliField) {
      setFocus(firstInvaliField);
    }
  }, [
    firstInvaliField,
    setFocus,
  ]);

  return (
    <div className={classNames(classes.root, className)}>
      <Form<T.CreateUserFormData>
        controller={controller}
        onSubmit={handleSubmit(onSubmitValid)}
      >
        <StyledSection>
          {/*
          <StyledSectionHeader
            colorVariant="secondary"
            icon={IconAccount}
            text={t('user.create.form.userSectionHeader')}
          />
          */}

          <UserEmailFormItem<T.CreateUserFormData>
            disabled={disabledOrLocked}
            hintVisible
            validation={emailValidation}
            validationState={emailValidationState}
          />

          <UserLoginFormItem<T.CreateUserFormData>
            disabled={disabledOrLocked}
            hintVisible
            validation={loginValidation}
            validationState={loginValidationState}
          />

          <UserNameFormItem<T.CreateUserFormData>
            disabled={disabledOrLocked}
            validation={nameValidation}
          />

          <UserRoleFormItem<T.CreateUserFormData>
            disabled={disabledOrLocked}
            validation={roleValidation}
          />

          <UserAvatarFormItem<T.CreateUserFormData>
            disabled={disabledOrLocked}
            validation={avatartValidation}
          />
        </StyledSection>

        <div className={classes.buttons}>
          <LoadingButton
            className={classNames(linkClasses.createButtonLink, classes.button)}
            colorVariant="secondary"
            disabled={disabledOrLocked}
            loading={disabled}
            type="submit"
            variant="contained"
          >
            <div className={linkClasses.createButtonLinkContent}>
              {t('user.create.title')}
            </div>
          </LoadingButton>
        </div>
      </Form>
    </div>
  );
};

export default CreateUserForm;
