import { useCallback, useState } from 'react';

export function useDropdown(): readonly [
  open: () => void,
  close: () => void,
  opened: boolean
] {
  const [
    opened,
    setOpened,
  ] = useState<boolean>(false);

  const open = useCallback(() => {
    setOpened(true);
  }, []);

  const close = useCallback(() => {
    setOpened(false);
  }, []);

  return [
    open,
    close,
    opened,
  ] as const;
}
