import {
  AuthApiService,
  RoomApiService,
  UserApiService,
} from 'src/services/api/modules';
import { ApiBackend } from './ApiBackend';

export const baseUrl: string = '/api';

export const apiBackend = new ApiBackend({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
  timeout: 10000,
});

export const authApiService = new AuthApiService(apiBackend);
export const roomApiService = new RoomApiService(apiBackend);
export const userApiService = new UserApiService(apiBackend);
