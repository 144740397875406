import classNames from 'classnames';
import type { MouseEvent, MouseEventHandler } from 'react';
import { useState } from 'react';
import { useMemo, useRef, type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconDelete } from 'src/assets/svg';

import { Avatar } from 'src/components/Avatar';
import { Button } from 'src/components/Button/Button2';
import type { FileDropContainerColorVariant } from 'src/containers/FileDropContainer';
import {
  FileDropContainer,
  FILE_DROP_CONTAINER_COLOR_VARIANT,
  type FileDropContainerChangeHandler,
} from 'src/containers/FileDropContainer';
import { isValidFunction } from 'src/utils';
import { defaultImageCompressor } from './functions';
import { type AvatarUploadData } from './types';

import classes from './AvatarUpload.module.css';
import { type ValueOf } from 'src/types/utils';

type ValueType = AvatarUploadData | null;
type InternalValueType = File | null;
export type AvatarUploadChangeHandler = (file?: ValueType) => void;
export type AvatarCompressor = (file: File) => Promise<File>;

export const AVATAR_UPLOAD_COLOR_VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
  danger: 'danger',
} as const;

export type AvatarUploadColorVariant = ValueOf<
  typeof AVATAR_UPLOAD_COLOR_VARIANT
>;

export interface AvatarUploadProps {
  className?: string;
  colorVariant?: AvatarUploadColorVariant;
  compressor?: AvatarCompressor;
  disabled?: boolean;
  onChange?: AvatarUploadChangeHandler;
  value?: ValueType;
}

const AvatarUpload: FC<AvatarUploadProps> = (props) => {
  const {
    className,
    colorVariant = AVATAR_UPLOAD_COLOR_VARIANT.primary,
    compressor = defaultImageCompressor,
    disabled,
    onChange: onChangeProp,
    value,
  } = props;
  const { t } = useTranslation();

  const avatarURL: string | undefined = value ? value.url : undefined;
  const currentRef = useRef<ValueType>(null);

  const [
    processing,
    setProcessing,
  ] = useState<boolean>(false);

  const onChange: FileDropContainerChangeHandler = (files) => {
    if (isValidFunction(onChangeProp)) {
      const asyncHandler = async (
        file: InternalValueType
      ): Promise<InternalValueType> => {
        let processedFile: InternalValueType = file;

        if (file && isValidFunction(compressor)) {
          setProcessing(true);
          try {
            processedFile = await compressor(file);
          } catch (e) {
          } finally {
            setProcessing(false);
          }
        }

        const next: ValueType = processedFile
          ? {
              url: URL.createObjectURL(processedFile),
              file: processedFile,
            }
          : null;
        if (currentRef.current) {
          URL.revokeObjectURL(currentRef.current.url);
        }
        currentRef.current = next;
        onChangeProp(next);

        return processedFile;
      };

      asyncHandler(files && files.length > 0 ? files[0] : null);
    }
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  const onRemoveClick: MouseEventHandler<HTMLButtonElement> = (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    isValidFunction(onChangeProp) && onChangeProp(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const fileDropContainerColorVariant:
    | FileDropContainerColorVariant
    | undefined =
    colorVariant === AVATAR_UPLOAD_COLOR_VARIANT.danger
      ? FILE_DROP_CONTAINER_COLOR_VARIANT.danger
      : undefined;

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.uploadContainer}>
        <FileDropContainer
          disabled={disabled}
          colorVariant={fileDropContainerColorVariant}
          inputRef={inputRef}
          onChange={onChange}
        />
      </div>
      <div className={classes.avatarContainer}>
        <Avatar
          className={classes.avatar}
          sizeVariant="xlarge"
          src={avatarURL}
          variant="rounded"
        />
        {value && (
          <div className={classes.buttons}>
            <Button
              colorVariant="secondary"
              disabled={disabled}
              onClick={onRemoveClick}
              prefixIcon={IconDelete}
              sizeVariant="small"
              type="button"
              variant="text"
            >
              {t('form.file.remove')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AvatarUpload;
