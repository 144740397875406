import classNames from 'classnames';
import { type FC } from 'react';

import { Attribute, type AttributeProps } from 'src/components/AttributeList';

import classes from './TextAttribute.module.css';

export interface TextAttributeProps extends Omit<AttributeProps, 'children'> {
  className?: string;
  noWrap?: boolean;
  value?: string;
}

const TextAttribute: FC<TextAttributeProps> = (props) => {
  const { className, noWrap, value, ...rest } = props;

  return value ? (
    <Attribute
      className={classNames(noWrap && classes.rootNoWrap, className)}
      {...rest}
    >
      <span className={classes.value}>{value}</span>
    </Attribute>
  ) : null;
};

export default TextAttribute;
