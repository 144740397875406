import classNames from 'classnames';
import {
  type AnchorHTMLAttributes,
  type DetailedHTMLProps,
  type FC,
} from 'react';

import classes from './SimpleLink.module.css';
import { type BaseSimpleProps, SIMPLE_LINK_SIZE_VARIANT } from './SimpleLink';

export interface SimpleExternalLinkProps
  extends DetailedHTMLProps<
      AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    BaseSimpleProps {}

const SimpleLink: FC<SimpleExternalLinkProps> = (props) => {
  const { className, noWrap, sizeVariant, ...rest } = props;

  const sizeVariantClassName =
    sizeVariant === SIMPLE_LINK_SIZE_VARIANT.small
      ? classes.small
      : sizeVariant === 'smaller'
      ? classes.smaller
      : null;

  return (
    <span
      className={classNames(
        classes.root,
        sizeVariantClassName,
        noWrap && classes.noWrap,
        className
      )}
    >
      <a
        className={classes.link}
        {...rest}
      />
    </span>
  );
};

export default SimpleLink;
