import { type ReactNode, type ReactElement, type FC } from 'react';
import classNames from 'classnames';

import {
  Pagination,
  type PaginationColorVariant,
  type PaginationOnPageHandler,
  type PaginationOnPageSizeHandler,
} from 'src/components/Pagination';

import classes from './AsyncPageableContainer.module.css';
import {
  AsyncContainer,
  type AsyncContainerClasses,
} from 'src/components/AsyncContainer/AsyncContainer';

export interface AsyncPageableContainerClasses
  extends Omit<AsyncContainerClasses, 'root'> {
  contentRoot: string;
  paginator: string;
  root: string;
}

export interface AsyncPageableContainerSlots {
  errorPlaceholder: ReactNode;
  content: ReactNode;
  loadingPlaceholder: ReactNode;
  noDataPlaceholder: ReactNode;
}

export interface AsyncPageableContainerProps {
  children?: ReactNode;
  className?: string;
  classes?: Partial<AsyncPageableContainerClasses>;
  dataLength?: number | null;
  isError?: boolean;

  /**
   * Важно: запрос может не начаться с первым рендером (чаще всего, начнется после первого
   * рендера через эффект), данные могу быть переданы уже на первый рендер без запроса
   */
  isRequesting?: boolean;

  /**
   * Используются в том числе для того, чтобы определить, есть ли данные по запросу
   */
  lastOkTotalPages?: number;

  onPage: PaginationOnPageHandler;
  onPageSize?: PaginationOnPageSizeHandler;

  /**
   * запрошенная страница
   */
  page: number;

  pageSize: number;
  pageSizeVariants?: number[];
  paginationColorVariant?: PaginationColorVariant;

  slots?: Partial<AsyncPageableContainerSlots>;
  totalElements?: number;
}

const AsyncPageableContainer: FC<AsyncPageableContainerProps> = (props) => {
  const {
    children: childrenProp,
    className,
    classes: classesProp = {},
    dataLength,
    isError,
    isRequesting,
    lastOkTotalPages,
    onPage,
    onPageSize,
    page,
    pageSize,
    pageSizeVariants,
    paginationColorVariant,

    slots = {},
    totalElements,
  } = props;

  const {
    errorPlaceholder: errorPlaceholderSlot,
    content: contentSlot,
    loadingPlaceholder: loadingPlaceholderSlot,
    noDataPlaceholder: noDataPlaceholderSlot,
  } = slots;

  const hadData = typeof lastOkTotalPages === 'number' && lastOkTotalPages > 0;
  const hasNonEmptyData = typeof dataLength === 'number' && dataLength > 0;

  return (
    <div className={classNames(classes.root, className)}>
      <AsyncContainer
        className={classNames(classes.container, classesProp.contentRoot)}
        classes={{
          content: classesProp.content,
        }}
        hasData={hasNonEmptyData}
        isError={isError}
        isRequesting={isRequesting}
        slots={{
          content: contentSlot || childrenProp,
          errorPlaceholder: errorPlaceholderSlot,
          loadingPlaceholder: loadingPlaceholderSlot,
          noDataPlaceholder: noDataPlaceholderSlot,
        }}
      ></AsyncContainer>
      {hadData && (
        <Pagination
          className={classNames(classes.pagination, classesProp.paginator)}
          colorVariant={paginationColorVariant}
          onPage={onPage}
          onPageSize={onPageSize}
          page={page}
          pageSize={pageSize}
          pageSizeVariants={pageSizeVariants}
          totalElements={totalElements}
          totalPages={lastOkTotalPages}
        />
      )}
    </div>
  );
};

export default AsyncPageableContainer;
