import type { RoomListSort } from 'src/constants/rooms';
import { ROOM_LIST_SORT_LIST } from 'src/constants/rooms';
import type { PageRequest } from 'src/services/api';
import type { Mapper } from 'src/utils/mappers/types';
import type { InternalPageRequest } from 'src/utils/pageable';

export const roomListInternalPageRequestToPageRequestMapper: Mapper<
  InternalPageRequest<RoomListSort>,
  PageRequest
> = (input) => {
  const { page, pageSize, sort: internalSort } = input;

  let sort: any;
  if (internalSort) {
    switch (internalSort) {
      case ROOM_LIST_SORT_LIST.ALPHABETICAL_ASC: {
        sort = 'title,asc';
        break;
      }

      case ROOM_LIST_SORT_LIST.ALPHABETICAL_DESC: {
        sort = 'title,desc';
        break;
      }

      case ROOM_LIST_SORT_LIST.DATE_ASC: {
        sort = 'created,asc';
        break;
      }

      case ROOM_LIST_SORT_LIST.DATE_DESC: {
        sort = 'created,desc';
        break;
      }
    }
  }

  return {
    page,
    size: pageSize,
    sort,
  };
};
