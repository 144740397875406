import { type OnlyLastExecutor } from 'src/utils/executors/OnlyLastExecutor';
import {
  type ExecutorEntry,
  type ExecutorJob,
} from 'src/utils/executors/types';
import { debounce } from 'throttle-debounce';

export type OnlyLastExecutorDebouncerCallback<T> = (
  entry: ExecutorEntry<T>
) => Promise<void>;

export class OnlyLastExecutorDebouncer<T> {
  private _executor: OnlyLastExecutor;
  private _callback: OnlyLastExecutorDebouncerCallback<T> | null = null;
  private _lastJob: ExecutorJob<T> | null = null;
  private _enabled: boolean = true;

  private _entry: ExecutorEntry<T> | null = null;
  private _debouncedExecute: any;

  constructor(
    executor: OnlyLastExecutor,
    debounceDelayMs: number = 500,
    callback?: OnlyLastExecutorDebouncerCallback<T>
  ) {
    this._executor = executor;
    this._callback = callback || null;

    this._debouncedExecute = debounce(debounceDelayMs, this._execute);
  }

  private async _execute() {
    if (this._entry && this._enabled) {
      if (this._callback) {
        this._callback(this._entry);
      } else {
        this._entry.execute();
      }
      this._entry = null;
    }
  }

  private async _jobWrapper(abortController: AbortController) {
    const { _lastJob } = this;
    return await _lastJob!(abortController);
  }

  execute(job: ExecutorJob<T>) {
    if (!this._enabled) {
      return;
    }

    this._lastJob = job;

    if (!this._entry) {
      this._entry = this._executor.queue((abortController) =>
        this._jobWrapper(abortController)
      );
    }
    this._debouncedExecute();
  }

  enable(enabled: boolean = true) {
    this._enabled = enabled;
  }

  invalidateAll() {
    this._executor.invalidateAll();
  }
}
