import {
  type ReactNode,
  type FC,
  type DragEventHandler,
  useMemo,
  type DragEvent,
  useState,
  useRef,
  useCallback,
} from 'react';
import classNames from 'classnames';

import classes from './Droppable.module.css';

import { checkTransferTypes } from 'src/components/Droppable/functions';
import { isValidFunction } from 'src/utils';

export interface DroppableRenderProps {
  active: boolean;
  disabled: boolean;
}
export type DroppableRender = (props: DroppableRenderProps) => ReactNode;

export type DroppableDropHandler = DragEventHandler<HTMLDivElement>;

export interface DroppableProps {
  acceptedTransferTypes?: string[];
  className?: string;
  disabled?: boolean;
  onDrop?: DroppableDropHandler;
  render?: DroppableRender;
}

const Droppable: FC<DroppableProps> = (props) => {
  const {
    acceptedTransferTypes,
    className,
    disabled,
    onDrop: onDropProp,
    render: renderProp,
  } = props;

  const [
    active,
    setActive,
  ] = useState<boolean>(false);

  const hasAcceptedTransferTypes: boolean =
    Array.isArray(acceptedTransferTypes) && acceptedTransferTypes.length > 0;

  // target - the EventTarget the pointing device entered to,
  // relatedTarget - the EventTarget the pointing device exited from
  const onDragEnter: DragEventHandler<HTMLDivElement> = (
    event: DragEvent<HTMLDivElement>
  ) => {
    if (disabled || !hasAcceptedTransferTypes) {
      return;
    }

    const { dataTransfer } = event;
    if (
      dataTransfer &&
      checkTransferTypes(dataTransfer.types, acceptedTransferTypes!)
    ) {
      event.preventDefault();
      event.stopPropagation();

      setActive(true);
    }
  };

  // target - the EventTarget the pointing device exited from
  // relatedTarget - the EventTarget the pointing device entered to,
  const onDragLeave: DragEventHandler<HTMLDivElement> = (
    event: DragEvent<HTMLDivElement>
  ) => {
    if (disabled || !hasAcceptedTransferTypes || !active) {
      return;
    }

    // не проверяем никакие типы, есть ли такая ситуация, когда
    // во время текущей операции поменяются типы dataTransfer
    if (event.currentTarget.contains(event.relatedTarget as Node | null)) {
      event.stopPropagation();
      return;
    } else {
      setActive(false);
    }
  };

  const onDragOver: DragEventHandler<HTMLDivElement> = (
    event: DragEvent<HTMLDivElement>
  ) => {
    if (disabled || !hasAcceptedTransferTypes) {
      return;
    }

    const { dataTransfer } = event;
    if (
      dataTransfer &&
      checkTransferTypes(dataTransfer.types, acceptedTransferTypes!)
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onDrop: DragEventHandler<HTMLDivElement> = (
    event: DragEvent<HTMLDivElement>
  ) => {
    if (disabled || !hasAcceptedTransferTypes) {
      return;
    }

    const { dataTransfer } = event;

    if (
      dataTransfer &&
      checkTransferTypes(dataTransfer.types, acceptedTransferTypes!)
    ) {
      event.preventDefault();
      event.stopPropagation();

      setActive(false);

      isValidFunction(onDropProp) && onDropProp(event);
    }
  };

  const children =
    isValidFunction(renderProp) &&
    renderProp({ active, disabled: Boolean(disabled) });

  return (
    <div
      className={classNames(classes.root, className)}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      {children}
    </div>
  );
};

export default Droppable;
