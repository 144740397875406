import type { FC } from 'react';

import { ErrorPlaceholder } from 'src/containers/Placeholder/ErrorPlaceholder';

import type * as T from './types';

const RoomErrorPlaceholder: FC<T.RoomErrorPlaceholderProps> = ({ onRetry }) => {
  return <ErrorPlaceholder onRetryClick={onRetry} />;
};

export default RoomErrorPlaceholder;
