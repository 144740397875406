import { type FC } from 'react';

import { Spinner, type SpinnerProps } from 'src/components/Spinner';

import classes from './FullscreenSpinner.module.css';

export interface FullscreenSpinner {
  spinnerProps?: SpinnerProps;
}

const FullscreenSpinner: FC<FullscreenSpinner> = (props) => {
  const { spinnerProps } = props;

  return (
    <div className={classes.root}>
      <Spinner
        className={classes.spinner}
        size={80}
        color="#b6dbff"
        {...spinnerProps}
      />
    </div>
  );
};

export default FullscreenSpinner;
