import { ApiService } from '../ApiService';
import { type ApiBackendResponse } from '../ApiBackend';
import type {
  ApiSendMailResult,
  ApiUpdateUserForm,
  ApiUserAdminView,
  InternalCreateUserResult,
  InternalUserAdminView,
  InternalUserView,
} from 'src/services/api/types';
import {
  type UserId,
  type UserListRequestFilterData,
  type Pageable,
  type PageRequest,
  type ApiUserView,
  type CheckResultView,
  type ApiCreateUserForm,
  type ApiCreateUserResult,
} from 'src/services/api/types';

import { USER_LIST_DEFAULT_PAGE_SIZE } from 'src/constants/users';
import {
  mapToInternalType,
  mapPageableToInternalType,
} from 'src/services/api/functions';
import {
  createUserResultToInternalTypeMapper,
  userViewToInternalTypeMapper,
} from 'src/services/api/mappers';

export class UserApiService extends ApiService {
  async addUser(
    form: ApiCreateUserForm
  ): Promise<ApiBackendResponse<InternalCreateUserResult>> {
    const response = await this.backend.post<ApiCreateUserResult>(
      '/user',
      form
    );

    return mapToInternalType<ApiCreateUserResult, InternalCreateUserResult>(
      response,
      createUserResultToInternalTypeMapper
    );
  }

  async blockUser(
    id: UserId
  ): Promise<ApiBackendResponse<InternalUserAdminView>> {
    const response = await this.backend.post<ApiUserAdminView>(
      `/user/${id}/block`
    );

    return mapToInternalType<ApiUserAdminView, InternalUserAdminView>(
      response,
      userViewToInternalTypeMapper
    );
  }

  async checkEmail(
    email: string
  ): Promise<ApiBackendResponse<CheckResultView>> {
    return this.backend.get<CheckResultView>(`check/email/${email}`);
  }

  async checkLogin(
    login: string
  ): Promise<ApiBackendResponse<CheckResultView>> {
    return this.backend.get<CheckResultView>(`check/login/${login}`);
  }

  async editUser(
    id: UserId,
    form: ApiUpdateUserForm
  ): Promise<ApiBackendResponse<InternalUserAdminView>> {
    const response = await this.backend.put<ApiUserAdminView>(
      `/user/${id}`,
      form
    );

    return mapToInternalType<ApiUserAdminView, InternalUserAdminView>(
      response,
      userViewToInternalTypeMapper
    );
  }

  async getUserList(
    pageRequest: PageRequest,
    filter?: UserListRequestFilterData
  ): Promise<ApiBackendResponse<Pageable<InternalUserView>>> {
    const { page, size = USER_LIST_DEFAULT_PAGE_SIZE, sort } = pageRequest;

    const response = await this.backend.get<Pageable<ApiUserView>>('/user', {
      params: { page, size, sort, ...filter },
    });

    return mapPageableToInternalType<ApiUserView, InternalUserView>(
      response,
      userViewToInternalTypeMapper
    );
    /*
    return new Promise<ApiBackendResponse<Pageable<UserView>>>(
      (resolve, reject) => {
        setTimeout(() => {
          reject('kek');
        }, 2000);
      }
    );
    */
  }

  async resendTokenMail(
    id: UserId
  ): Promise<ApiBackendResponse<ApiSendMailResult>> {
    return this.backend.post<ApiSendMailResult>(
      `/user/${id}/resendPasswordMail`
    );
  }

  async unblockUser(
    id: UserId
  ): Promise<ApiBackendResponse<InternalUserAdminView>> {
    const response = await this.backend.post<ApiUserAdminView>(
      `/user/${id}/unblock`
    );

    return mapToInternalType<ApiUserAdminView, InternalUserAdminView>(
      response,
      userViewToInternalTypeMapper
    );
  }

  async getUser(id: UserId): Promise<ApiBackendResponse<InternalUserView>> {
    const response = await this.backend.get<ApiUserView>(`/user/${id}`);
    return mapToInternalType<ApiUserView, InternalUserView>(
      response,
      userViewToInternalTypeMapper
    );
  }
}
