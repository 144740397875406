import { ALL_USER_ROLES, type ApiUserView } from 'src/services/api/types';

export function userViewValidator(data?: ApiUserView): boolean {
  if (data) {
    const { role: { id: roleId } = {} } = data;

    return Boolean(
      data.id && roleId && ALL_USER_ROLES.includes(roleId) && data.name
    );
  }

  return false;
}
