import { type FC, memo } from 'react';

import {
  PlaceholderImage,
  type PlaceholderImageProps,
} from 'src/containers/Placeholder/PlaceholderImage';

import imageURL from 'src/assets/images/empty.png';

export interface EmptyPlaceholderImageProps
  extends Omit<PlaceholderImageProps, 'src' | 'srcSet'> {}

const EmptyPlaceholderImage: FC<EmptyPlaceholderImageProps> = (props) => {
  return (
    <PlaceholderImage
      {...props}
      src={imageURL}
    />
  );
};

export default memo(EmptyPlaceholderImage);
