import { type FC } from 'react';
import classNames from 'classnames';

import { type InternalPersonalView } from 'src/services/api';
import { Avatar } from 'src/components/Avatar';
import { UserRoleChip } from 'src/components/RoleChip/UserRoleChip';

import classes from './HeaderProfileCard.module.css';

export interface HeaderProfileCardProps {
  className?: string;
  user: InternalPersonalView;
}

const HeaderProfileCard: FC<HeaderProfileCardProps> = (props) => {
  const {
    className,
    user: { avatar, name, role },
  } = props;

  const nameLabel: string | string = name;

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.avatarContainer}>
        <Avatar
          alt={nameLabel}
          className={classes.avatar}
          fallbackLabelSource={nameLabel}
          sizeVariant="large"
          src={avatar}
        />
      </div>
      <div className={classes.name}>{nameLabel}</div>
      <div className={classes.roleContainer}>
        <UserRoleChip role={role} />
      </div>
    </div>
  );
};

export default HeaderProfileCard;
