import classNames, { type Argument } from 'classnames';

type ClassNamesObject = Record<string, Argument>;

const hasOwnProperty = Object.prototype.hasOwnProperty;

export function mergeClasses(
  obj1: ClassNamesObject | undefined | null,
  obj2: ClassNamesObject | undefined | null
): ClassNamesObject {
  if (!obj1 && !obj2) {
    return {};
  }

  if (!obj1) {
    return obj2!;
  }

  if (!obj2) {
    return obj1!;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const keySet = new Set(keys1.concat(keys2));
  const result: ClassNamesObject = {};
  keySet.forEach((key) => {
    const has1 = hasOwnProperty.call(obj1, key);
    const has2 = hasOwnProperty.call(obj2, key);
    if (has1 && has2) {
      result[key] = classNames(obj1[key], obj2[key]);
    } else if (has1) {
      result[key] = obj1[key];
    } else if (has2) {
      result[key] = obj2[key];
    }
  });

  return result;
}
