import { useState } from 'react';
import type { ReactNode, FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CreateUserForm } from './components/CreateUserForm';
import { Typography, TYPOGRAPHY_VARIANT } from 'src/components/Typography';
import { ContentWrapper } from 'src/components/ContentWrapper';
import classes from './CreateUserPage.module.css';
import { StyledSection } from 'src/components/Styled/Forms/StyledSection';
import { IconCheckCircle, IconAlertCircle } from 'src/assets/svg';
import type { CreateUserFormSuccessHandler } from 'src/pages/CreateUserPage/components/CreateUserForm/types';
import type { InternalUserAdminView } from 'src/services/api';
import { SimpleLink } from 'src/components/Link/SimpleLink';
import { PATHS } from 'src/constants/paths';

const CreateUserPage: FC = () => {
  const { t } = useTranslation();

  const [
    sent,
    setSent,
  ] = useState<boolean>(false);
  const [
    user,
    setUser,
  ] = useState<InternalUserAdminView | null>(null);
  const onFormSuccess: CreateUserFormSuccessHandler = (data) => {
    setSent(data.sendMail);
    setUser(data.obj);
  };

  let resultBlock: ReactNode | undefined;
  if (user) {
    const IconComponent = sent ? IconCheckCircle : IconAlertCircle;
    const text = sent ? 'user.create.resultOk' : 'user.create.resultSendError';

    resultBlock = (
      <ContentWrapper maxWidth="500px">
        <StyledSection className={classes.resultSection}>
          <div className={classes.iconBlock}>
            <IconComponent className={classes.resultIcon} />
          </div>
          <div className={classes.resultText}>
            <Trans
              i18nKey={text}
              t={t}
              values={{ email: user.email }}
            >
              <div className={classes.resultEmail}>0</div>
            </Trans>
          </div>
          <div className={classes.linkBlock}>
            <SimpleLink
              className={classes.link}
              sizeVariant="small"
              to={PATHS.user.format(user.id)}
            >
              {t('links.toUserPage')}
            </SimpleLink>
          </div>
        </StyledSection>
      </ContentWrapper>
    );
  }

  return (
    <div className={classes.root}>
      <ContentWrapper maxWidth="500px">
        <Typography
          sizeVariant="small"
          variant={TYPOGRAPHY_VARIANT.h1}
        >
          {t('user.create.title')}
        </Typography>

        <CreateUserForm
          className={classes.form}
          onSubmitSuccess={onFormSuccess}
        />
      </ContentWrapper>

      {resultBlock}
    </div>
  );
};

export default CreateUserPage;
