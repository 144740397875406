import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconPen } from 'src/assets/svg';

import { Button } from 'src/components/Button/Button2';
import { useDropdown } from 'src/components/Dropdown/useDropdown';
import { UserEditDialog } from 'src/containers/UserView/components/UserEditDialog';

import type * as T from './types';

const UserBlockButton: FC<T.UserEditButtonProps> = (props) => {
  const { t } = useTranslation();
  const { className, initialData, onEdit } = props;

  const [
    open,
    close,
    opened,
  ] = useDropdown();

  return (
    <>
      <Button
        className={className}
        colorVariant="secondary"
        onClick={open}
        prefixIcon={IconPen}
        sizeVariant="small"
        variant="contained"
      >
        {t('user.actions.edit')}
      </Button>

      <UserEditDialog
        initialData={initialData}
        onEdit={onEdit}
        onCloseRequested={close}
        open={opened}
      />
    </>
  );
};

export default UserBlockButton;
