import type { TFunction } from 'react-i18next';

import type { RequestDisplayErrorData } from 'src/types/common';
import { maybeGetErrorDataFromBackend } from 'src/services/api/functions';

export function loginPageFormErrorParser(
  error: any,
  t: TFunction
): RequestDisplayErrorData {
  const { status, message: parsedMessage } =
    maybeGetErrorDataFromBackend(error);

  let title: string | undefined;
  let message: string | undefined;
  if (status === 401) {
    title = t('loginPage.errorUnauthorized');
  } else {
    message = parsedMessage;
  }

  return {
    message,
    status,
    title,
  };
}
