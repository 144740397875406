import { type FC, useMemo, useEffect, useCallback } from 'react';

import {
  ROOM_LIST_DEFAULT_DISPLAY_PAGE,
  ROOM_LIST_DEFAULT_PAGE,
  ROOM_LIST_DEFAULT_PAGE_SIZE,
  ROOM_LIST_PAGE_SIZE_VALID_LIST,
  type RoomListSort,
} from 'src/constants/rooms';
import {
  PageableContentContainer,
  type PageableContentContainerItemRender,
} from 'src/containers/PageableContentContainer';
import { roomApiService, type ApiRoomPreviewView } from 'src/services/api';
import { InternalPageRequest } from 'src/utils/pageable';
import { RoomListLoadingPlaceholder } from 'src/containers/RoomList/RoomListLoadingPlaceholder';
import { RoomListItem } from 'src/containers/RoomListItem';
import { useAuthState } from 'src/contexts/auth';

import {
  useSinglePagePageableRequest,
  type UsePageableRequestDataSupplier,
} from 'src/hooks/request';
import { RoomListNoDataPlaceholder } from 'src/containers/RoomList/RoomListNoDataPlaceholder';
import { RoomListErrorPlaceholder } from 'src/containers/RoomList/RoomListErrorPlaceholder';

import type * as T from './types';
import type * as I from './internal/types';
import { roomListInternalPageRequestToPageRequestMapper } from './internal/functions';
import classes from './RoomList.module.css';

import type { PaginationOnPageHandler } from 'src/components/Pagination';
import { StyledBlock } from 'src/components/Styled/Block/StyledBlock';
import { AsyncPageableContainer } from 'src/components/AsyncContainer/AsyncPageableContainer';
import { Grid } from 'src/components/Grid';

const dataSupplier: UsePageableRequestDataSupplier<
  ApiRoomPreviewView,
  I.RoomListDataSupplierContext,
  RoomListSort
> = async (pageRequest, context) => {
  const result = await roomApiService.getRoomList(
    roomListInternalPageRequestToPageRequestMapper(pageRequest),
    context
  );
  return result.data;
};

const DISPLAY_PAGE_DELTA =
  ROOM_LIST_DEFAULT_DISPLAY_PAGE - ROOM_LIST_DEFAULT_PAGE;

const RoomList: FC<T.RoomListProps> = (props) => {
  const { user } = useAuthState();
  const {
    filter,
    onPage,
    onPageSize,
    page: displayPage,
    pageSize = ROOM_LIST_DEFAULT_PAGE_SIZE,
    query,
    sort,
  } = props;

  const page = displayPage - DISPLAY_PAGE_DELTA;

  const pageRequest: InternalPageRequest<RoomListSort> = useMemo(
    () => new InternalPageRequest(page, pageSize, sort),
    [
      page,
      pageSize,
      sort,
    ]
  );

  const context: I.RoomListDataSupplierContext = useMemo(
    () => ({ filter, query }),
    [
      filter,
      query,
    ]
  );

  const { data, pageData, lastOkPageData, isLoading, isError, getData } =
    useSinglePagePageableRequest<
      ApiRoomPreviewView,
      I.RoomListDataSupplierContext,
      RoomListSort
    >({ dataSupplier });

  useEffect(() => {
    getData(pageRequest, context);
  }, [
    pageRequest,
    context,
    getData,
  ]);

  const retry = () => {
    getData(pageRequest, context);
  };

  const renderItem: PageableContentContainerItemRender<ApiRoomPreviewView> = (
    item
  ) => {
    return {
      element: (
        <RoomListItem
          currentUser={user}
          data={item}
        />
      ),
      key: item.id,
    };
  };

  const { totalPages } = lastOkPageData || {};
  const { totalElements } = pageData || {};
  const dataLenght = Array.isArray(data) ? data.length : null;

  return (
    <StyledBlock className={classes.root}>
      <AsyncPageableContainer
        dataLength={dataLenght}
        isError={isError}
        isRequesting={isLoading}
        lastOkTotalPages={totalPages}
        onPage={onPage}
        onPageSize={onPageSize}
        page={displayPage}
        pageSize={pageSize}
        pageSizeVariants={ROOM_LIST_PAGE_SIZE_VALID_LIST}
        paginationColorVariant="primary"
        slots={{
          errorPlaceholder: <RoomListErrorPlaceholder onRetryClick={retry} />,
          content: data ? (
            <Grid>
              {data.map((room) => (
                <RoomListItem
                  checkable={true}
                  data={room}
                  key={room.id}
                />
              ))}
            </Grid>
          ) : null,
          loadingPlaceholder: <RoomListLoadingPlaceholder />,
          noDataPlaceholder: <RoomListNoDataPlaceholder />,
        }}
        totalElements={totalElements}
      />
    </StyledBlock>
  );
};

export default RoomList;
