import {
  type FC,
  type ButtonHTMLAttributes,
  forwardRef,
  type PropsWithRef,
  type DetailedHTMLProps,
} from 'react';
import classNames from 'classnames';

import classes from './Button.module.css';
import { type ReactSVGComponent } from 'src/types/svg';
import { type ValueOf } from 'src/types/utils';

export const BUTTON_VARIANT = {
  contained: 'contained',
  outlined: 'outlined',
  text: 'text',
  blank: 'blank',
} as const;

export type ButtonVariant = ValueOf<typeof BUTTON_VARIANT>;

export const BUTTON_COLOR_VARIANT = {
  danger: 'danger',
  primary: 'primary',
} as const;

export type ButtonColorVariant = ValueOf<typeof BUTTON_COLOR_VARIANT>;

export const BUTTON_SIZE_VARIANT = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export type ButtonSizeVariant = ValueOf<typeof BUTTON_SIZE_VARIANT>;

export interface ButtonProps
  extends PropsWithRef<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  > {
  /**
   * Цветовая тема, определяет цвет фона, заливки и шрифта.
   */
  colorVariant?: ButtonColorVariant;

  /**
   * Компонент иконки.
   * Если указан, использует спецальные стили для кнопок с иконками.
   * Рендерит children после иконки, так что не рекомендуется
   * указывать эту опцию вместе с children.
   */
  icon?: ReactSVGComponent;

  sizeVariant?: ButtonSizeVariant;

  uppercased?: boolean;

  /**
   * Тема внешнего вида
   */
  variant?: ButtonVariant;
}

const Button: FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      children,
      className: classNameProp,
      colorVariant = BUTTON_COLOR_VARIANT.primary,
      disabled,
      icon: IconComponent,
      sizeVariant = BUTTON_SIZE_VARIANT.medium,
      variant = BUTTON_VARIANT.contained,
      uppercased = false,
      ...rest
    } = props;

    const colorVariantClassName =
      colorVariant === BUTTON_COLOR_VARIANT.danger
        ? classes.danger
        : classes.primary;

    const sizeVariantClassName =
      sizeVariant === BUTTON_SIZE_VARIANT.small
        ? classes.small
        : sizeVariant === BUTTON_SIZE_VARIANT.large
        ? classes.large
        : classes.medium;

    const variantClassName =
      variant === BUTTON_VARIANT.blank
        ? null
        : variant === BUTTON_VARIANT.outlined
        ? classes.outlined
        : variant === BUTTON_VARIANT.text
        ? classes.text
        : classes.contained;

    const className = classNames(
      classes.root,
      colorVariantClassName,
      sizeVariantClassName,
      variantClassName,
      Boolean(IconComponent) && classes.iconed,
      disabled && classes.disabled,
      uppercased && classes.uppercased,
      classNameProp
    );

    return (
      <button
        className={className}
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        <div className={classes.wrapper}>
          {IconComponent && <IconComponent className={classes.icon} />}
          {children}
        </div>
      </button>
    );
  }
);

export default Button;
