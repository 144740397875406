import { type FC, type ReactNode } from 'react';
import classNames from 'classnames';

import classes from './FlexContainer.module.css';

export interface FlexContainerClasses {
  container: string;
}

export interface FlexContainerProps {
  children?: ReactNode;
  className?: string;
  classes?: Partial<FlexContainerClasses>;
}

const FlexContainer: FC<FlexContainerProps> = (props) => {
  const { children, className, classes: classesProp = {} } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <div
        className={classNames(classes.containerWrapper, classesProp.container)}
      >
        <div className={classes.container}>{children}</div>
      </div>
    </div>
  );
};

export default FlexContainer;
