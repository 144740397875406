import { type FC, type ReactNode } from 'react';
import { default as MUIDialogContent } from '@mui/material/DialogContent';

import classes from './DialogContent.module.css';

import { useDialogContext } from 'src/components/Modal/useDialogContext';

export interface DialogContentProps {
  children?: ReactNode;
  className?: string;
}

const DialogContent: FC<DialogContentProps> = (props) => {
  const { children, className } = props;

  const context = useDialogContext();

  return (
    <MUIDialogContent className={classes.root}>{children}</MUIDialogContent>
  );
};

export default DialogContent;
