import classNames from 'classnames';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledBlock } from 'src/components/Styled/Block/StyledBlock';

import { type UserListSort } from 'src/constants/users';

import toolbarClasses from 'src/containers/Layout/ListContentLayout/ListContentLayoutToolbar.module.css';
import {
  SearchWidget,
  type SearchWidgetFormSubmit,
  SEARCH_WIDGET_COLOR_VARIANT,
} from 'src/containers/SearchWidget';
import {
  UserListFilterWidget,
  type UserListFilterWidgetFormSubmitHandler,
} from 'src/containers/UserList/UserListFilterWidget';
import type { UserListSortWidgetSelectHandler } from 'src/containers/UserList/UserListSortWidget';
import {
  UserListSortWidget,
  type UserListSortWidgetLinkPathComposer,
} from 'src/containers/UserList/UserListSortWidget';
import { type InternalUserListFilterDataType } from 'src/types/common';

export interface UserListToolbarProps {
  className?: string;
  currentFilterData: InternalUserListFilterDataType;
  defaultFilterData: Required<InternalUserListFilterDataType>;
  initialQuery?: string;
  onFilterSubmit: UserListFilterWidgetFormSubmitHandler;
  onSearchSubmit?: SearchWidgetFormSubmit;
  onSortSelect?: UserListSortWidgetSelectHandler;
  sortLinkPathComposer: UserListSortWidgetLinkPathComposer;
  sortValue: UserListSort;
}

const UserListToolbar: FC<UserListToolbarProps> = (props) => {
  const {
    className,
    currentFilterData,
    defaultFilterData,
    initialQuery,
    onFilterSubmit,
    onSearchSubmit,
    onSortSelect,
    sortLinkPathComposer,
    sortValue,
  } = props;
  const { t } = useTranslation();

  const inputHint = t('userList.search.placeholder');

  return (
    <StyledBlock className={className}>
      <div className={classNames(toolbarClasses.toolbar)}>
        <div
          className={classNames(
            toolbarClasses.toolbarBlock,
            toolbarClasses.flex
          )}
        >
          <SearchWidget
            colorVariant={SEARCH_WIDGET_COLOR_VARIANT.secondary}
            initialQuery={initialQuery}
            inputProps={{ placeholder: inputHint }}
            onSubmit={onSearchSubmit}
          />
        </div>
        <UserListSortWidget
          className={toolbarClasses.toolbarBlock}
          linkPathComposer={sortLinkPathComposer}
          onSelect={onSortSelect}
          value={sortValue}
        />
        <UserListFilterWidget
          className={toolbarClasses.toolbarBlock}
          currentData={currentFilterData}
          defaultData={defaultFilterData}
          onSubmit={onFilterSubmit}
        />
      </div>
    </StyledBlock>
  );
};

export default UserListToolbar;
