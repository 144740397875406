import type { FC } from 'react';
import classNames from 'classnames';

import { DEFAULT_ICON_SIZE } from 'src/components/Icon';
import classes from './Spinner.module.css';

export interface SpinnerProps {
  className?: string;
  color?: string;
  size?: number;
  strokeWidth?: number;
}

const DEFAULT_SPINNER_SIZE = DEFAULT_ICON_SIZE;
const DEFAULT_SPINNER_STROKE_WIDTH = 6;

const Spinner: FC<SpinnerProps> = (props) => {
  const { className, color, size, strokeWidth } = props;

  const calculatedSize = size ?? DEFAULT_SPINNER_SIZE;
  const calculatedStrokeWidth = strokeWidth ?? DEFAULT_SPINNER_STROKE_WIDTH;

  return (
    <span className={classNames(classes.root, className)}>
      <svg
        className={classes.svg}
        height={calculatedSize}
        viewBox="0 0 50 50"
        width={calculatedSize}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={classes.path}
          cx="25"
          cy="25"
          fill="none"
          r="20"
          strokeWidth={calculatedStrokeWidth}
          style={{ stroke: color }}
        ></circle>
      </svg>
    </span>
  );
};

export default Spinner;
