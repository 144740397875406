import { ALL_USER_PERMISSIONS } from 'src/services/api/constants';
import type {
  Pageable,
  InternalUserPermission,
  InternalUserView,
  ApiUserView,
  ApiPersonalView,
  InternalPersonalView,
  ApiCreateUserResult,
  ApiUserAdminView,
  InternalCreateUserResult,
  InternalUserAdminView,
} from 'src/services/api/types';
import { userViewValidator } from 'src/services/api/validators';

export function userViewToInternalTypeMapper(
  data: ApiUserAdminView
): InternalUserAdminView;
export function userViewToInternalTypeMapper(
  data: ApiPersonalView
): InternalPersonalView;
export function userViewToInternalTypeMapper(
  data: ApiUserView
): InternalUserView;

/**
 * Общая функция для преобразования ApiUserView с перегрузкой типов
 */
export function userViewToInternalTypeMapper(
  data: ApiUserView
): InternalUserView {
  if (userViewValidator(data)) {
    const {
      role: { id: roleId },
    } = data;

    // permissions
    const permissions: InternalUserPermission[] = ALL_USER_PERMISSIONS.slice();
    /*
    const permissions: InternalUserPermission[] = Array.isArray(
      data.permissions
    )
      ? (data.permissions
          .map((permission) => {
            return ALL_USER_PERMISSIONS.includes(
              permission.id as InternalUserPermission
            )
              ? permission.id
              : null;
          })
          .filter(Boolean) as InternalUserPermission[])
      : [];
      */

    const result: InternalUserView = {
      avatar: data.avatar,
      created: data.created,
      email: data.email,
      id: data.id,
      login: data.login,
      locked: Boolean(data.locked),
      name: data.name,
      role: roleId,
      permissions,
    };

    return result;
  }

  throw new Error('Mapper error - invalid abstract ApiUserView format');
}

export function createUserResultToInternalTypeMapper(
  data: ApiCreateUserResult
): InternalCreateUserResult {
  return {
    sendMail: data.sendMail,
    obj: userViewToInternalTypeMapper(data.obj),
  };
}
