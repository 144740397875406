import classNames from 'classnames';
import { useMemo, type FC, type ReactNode } from 'react';
import {
  Button,
  type ButtonProps,
  BUTTON_VARIANT,
} from 'src/components/Button/Button2';
import type { ButtonLikeClasses } from 'src/components/Button/ButtonLike';

import classes from '../Pagination.module.css';

export interface PaginationPageButtonProps extends ButtonProps {
  active?: boolean;
}

const CLASSES_PROP: Partial<ButtonLikeClasses> = {
  icon: classes.layoutChevronIcon,
};

const PaginationPageButton: FC<PaginationPageButtonProps> = (props) => {
  const {
    active,
    className,
    disabled,
    classes: classesProp = {},
    ...rest
  } = props;

  const resolvedClasses = useMemo(
    () => ({ ...CLASSES_PROP, ...classesProp }),
    [classesProp]
  );

  return (
    <Button
      className={classNames(
        classes.layoutButton,
        active && classes.layoutButtonActive,
        disabled && classes.layoutButtonDisabled,
        className
      )}
      classes={resolvedClasses}
      disabled={disabled}
      variant={BUTTON_VARIANT.blank}
      {...rest}
    ></Button>
  );
};

export default PaginationPageButton;
