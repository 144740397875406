import { type FC } from 'react';
import { InternalHeaderProfileButton } from './InternalHeaderProfileButton';

import { useAuthState } from 'src/contexts/auth';

export interface HeaderProfileButtonProps {
  className?: string;
}

const HeaderProfileButton: FC<HeaderProfileButtonProps> = (props) => {
  const { authenticated, user } = useAuthState();
  const { className } = props;

  return user && authenticated ? (
    <InternalHeaderProfileButton
      className={className}
      user={user}
    />
  ) : null;
};

export default HeaderProfileButton;
