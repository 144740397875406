import { useCallback, useEffect, useState, type FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { PATHS } from 'src/constants/paths';
import { NonAuthorizedHeader } from 'src/containers/Header/NonAuthorizedHeader';
import { ContentWrapper } from 'src/components/ContentWrapper';
import {
  SetPasswordForm,
  type SetPasswordFormSuccessHandler,
  type SetPasswordFromSubmitHandler,
} from 'src/containers/SetPasswordForm';
import { type ApiSetPasswordForm, authApiService } from 'src/services/api';
import { FullscreenLayout } from 'src/containers/FullscreenLayout';
import { FullscreenSpinner } from 'src/components/Spinner/FullscreenSpinner';
import {
  usePasswordTokenVerifier,
  type UsePasswordTokenVerifierHandler,
  type UsePasswordTokenVerifierResult,
} from 'src/hooks/usePasswordTokenVerifier';
import { SimpleLink } from 'src/components/Link/SimpleLink';

import classes from './SetPasswordPage.module.css';
import passwordFormsClasses from 'src/styles/common/passwordForms.module.css';

const STAGE_START = 7;
const STAGE_VERIFIYNG_TOKEN = 1;
const STAGE_INVAILD_TOKEN = 2;
const STAGE_ERROR_VERIFYING_TOKEN = 5;
const STAGE_FORM = 3;
const STAGE_SUCCESS = 20;

const SEARCH_PARAM_TOKEN = 'token';

export type SetPasswordPageSourceType = 'set' | 'reset';
export interface SetPasswordPageProps {
  sourceType?: SetPasswordPageSourceType;
}

const SetPasswordPage: FC<SetPasswordPageProps> = (props) => {
  const { sourceType = 'set' } = props;
  const isResetSourceType = sourceType === 'reset';
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const token = searchParams.get(SEARCH_PARAM_TOKEN);

  const [
    stage,
    setStage,
  ] = useState<number>(STAGE_VERIFIYNG_TOKEN);

  useEffect(() => {
    setStage(token ? STAGE_VERIFIYNG_TOKEN : STAGE_INVAILD_TOKEN);
  }, [token]);

  const onVerifyToken: UsePasswordTokenVerifierHandler = useCallback(
    (result: UsePasswordTokenVerifierResult | null) => {
      console.log('token verifier', result);
      setStage(
        !result || result === 'error'
          ? STAGE_ERROR_VERIFYING_TOKEN
          : result === 'invalid'
          ? STAGE_INVAILD_TOKEN
          : STAGE_FORM
      );
    },
    []
  );

  // триггерится при изменении token
  usePasswordTokenVerifier(token, onVerifyToken);

  const descriptionText =
    stage === STAGE_INVAILD_TOKEN
      ? isResetSourceType
        ? t('resetPasswordPage.descriptionInvalidToken')
        : t('setPasswordPage.descriptionInvalidToken')
      : stage === STAGE_ERROR_VERIFYING_TOKEN
      ? isResetSourceType
        ? t('resetPasswordPage.descriptionErrorToken')
        : t('setPasswordPage.descriptionErrorToken')
      : stage === STAGE_FORM
      ? isResetSourceType
        ? t('resetPasswordPage.descriptionForm')
        : t('setPasswordPage.descriptionForm')
      : stage === STAGE_SUCCESS
      ? isResetSourceType
        ? t('resetPasswordPage.descriptionSuccess')
        : t('setPasswordPage.descriptionSuccess')
      : null;

  const description = descriptionText ? (
    <p className={passwordFormsClasses.descriptionBlock}>{descriptionText}</p>
  ) : null;

  const link =
    stage === STAGE_ERROR_VERIFYING_TOKEN ||
    stage === STAGE_INVAILD_TOKEN ||
    stage === STAGE_SUCCESS ? (
      <div className={classes.linkBlock}>
        <SimpleLink
          className={passwordFormsClasses.link}
          sizeVariant="small"
          to={PATHS.login.path}
        >
          {t('links.toLoginPage')}
        </SimpleLink>
      </div>
    ) : null;

  const onSubmit: SetPasswordFromSubmitHandler = async (form) => {
    if (token) {
      const apiForm: ApiSetPasswordForm = {
        oldPassword: form.password,
        newPassword: form.passwordConfirm,
      };

      await authApiService.setPassword(token, apiForm);
    }
  };

  const onSuccess: SetPasswordFormSuccessHandler = () => {
    setStage(STAGE_SUCCESS);
  };

  return stage === STAGE_START || stage === STAGE_VERIFIYNG_TOKEN ? (
    <FullscreenSpinner />
  ) : (
    <FullscreenLayout
      className={classes.root}
      classes={{ content: classes.mainLayoutContent }}
      header={<NonAuthorizedHeader loginButtonHidden={true} />}
    >
      <div className={classes.content}>
        <ContentWrapper className={classes.contentInnerWrapper}>
          <>
            {description}
            {stage === STAGE_FORM && (
              <div className={classes.formBlock}>
                <div className={classes.formBlockInnerWrapper}>
                  <SetPasswordForm
                    onSubmit={onSubmit}
                    onSuccess={onSuccess}
                  />
                </div>
              </div>
            )}
            {link}
          </>
        </ContentWrapper>
      </div>
    </FullscreenLayout>
  );
};

export default SetPasswordPage;
