import { type UserListDataSupplierContext } from './internalTypes';
import { type UserListRequestFilterData } from 'src/services/api';

export function convertDataSupplierContextToFilterDataType(
  context?: UserListDataSupplierContext
): UserListRequestFilterData | undefined {
  if (context) {
    const { query, filter: { roleList, blocked, unblocked } = {} } = context;

    return {
      query,
      roles: roleList,
      blocked,
      unblocked,
    };
  }
}
