import {
  ToastContainer as ToastContainerComponent,
  Slide,
} from 'react-toastify';
import type { FC } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import type { ToastContainerProps } from 'react-toastify';

const ToastContainer: FC<ToastContainerProps> = ({
  position = 'top-right',
  transition = Slide,
  autoClose = 5000,
  hideProgressBar = true,
  ...rest
}) => {
  return (
    <ToastContainerComponent
      position={position}
      transition={transition}
      autoClose={autoClose}
      hideProgressBar={hideProgressBar}
      draggable={true}
      {...rest}
    />
  );
};

export default ToastContainer;
