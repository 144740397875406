import { memo, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Placeholder } from 'src/components/Placeholder';
import { EmptyPlaceholderImage } from 'src/containers/Placeholder/EmptyPlaceholderImage';

export interface RoomListNoDataPlaceholderProps {
  className?: string;
}

const RoomListNoDataPlaceholder: FC<RoomListNoDataPlaceholderProps> = (
  props
) => {
  const { className } = props;
  const { t } = useTranslation();

  const icon = <EmptyPlaceholderImage alt={t('search.noResultPlaceholder')} />;

  return (
    <Placeholder
      className={className}
      description={t('search.noResultsDescription')}
      slots={{ icon }}
      title={t('search.noResultsTitle')}
    />
  );
};

export default memo(RoomListNoDataPlaceholder);
