import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from 'src/components/Form/Form';
import type { FormSubmitHandler } from 'src/components/Form/Form';
import { FormItemWithTypedError } from 'src/components/Form/FormItemWithTypedError';
import type { FormItemWithTypedErrorPayload } from 'src/components/Form/FormItemWithTypedError/types';
import type { FormItemValidationRule } from 'src/components/Form/FormItem';
import { Input } from 'src/components/Input';
import { useAuthActions } from 'src/contexts/auth';
import { PATHS } from 'src/constants/paths';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { IconLock, IconUser } from 'src/assets/svg';
import { useFormController } from 'src/components/Form/Form/useFormController';
import { SimpleLink } from 'src/components/Link/SimpleLink';
import { isValidFunction } from 'src/utils';
import { stringRequiredValidatorFactory } from 'src/components/Form/validation/validators';
import { LoadingButton } from 'src/components/Button/LoadingButton';
import { FormErrorBlock } from 'src/components/Form/FormErrorBlock';

import type * as T from './types';
import classes from './LoginPageForm.module.css';
import passwordFormsClasses from 'src/styles/common/passwordForms.module.css';
import { loginPageFormErrorParser } from './functions';

const DEFAULT_FORM_DATA: T.LoginPageFormData = {
  username: '',
  password: '',
};

const FORM_USERNAME_INPUT_ID = 'login-page-username';
const FORM_PASSWORD_INPUT_ID = 'login-page-password';

const formItemRequiredValidationRule: FormItemValidationRule<
  string,
  FormItemWithTypedErrorPayload
> = {
  validator: stringRequiredValidatorFactory(),
  payload: {
    tKey: 'form.validation.required',
  },
};

const LoginPageForm: FC<T.LoginPageFormProps> = (props) => {
  const { onSubmitSuccess } = props;
  const { authenticate } = useAuthActions();
  const { t } = useTranslation();

  // form
  const controller = useFormController({
    defaultValues: DEFAULT_FORM_DATA,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const [
    isDisabled,
    setDisabled,
  ] = useState(false);
  const [
    isError,
    setError,
  ] = useState(false);
  const [
    error,
    setErrorData,
  ] = useState<any>(null);

  const onSubmitValid: FormSubmitHandler<T.LoginPageFormData> = async (
    data,
    event
  ) => {
    setError(false);
    setErrorData(null);
    setDisabled(true);

    try {
      await authenticate(data.username.trim(), data.password.trim());
      isValidFunction(onSubmitSuccess) && onSubmitSuccess(data, event);
    } catch (e) {
      setError(true);
      setErrorData(e);
    } finally {
      setDisabled(false);
    }
  };

  const { handleSubmit } = controller;
  const onSubmit = handleSubmit(onSubmitValid);

  return (
    <div className={classes.formContainerLayout}>
      <ContentWrapper className={classes.formContainerLayoutWrapper}>
        <div className={classes.formContainerWrapper}>
          <div className={classes.formContainer}>
            <Form<T.LoginPageFormData>
              controller={controller}
              onSubmit={onSubmit}
            >
              <FormItemWithTypedError<
                HTMLInputElement,
                string,
                T.LoginPageFormData
              >
                name="username"
                labelFor={FORM_USERNAME_INPUT_ID}
                slots={{
                  input: ({
                    field: { onChange, onBlur, value, ref },
                    valid,
                  }) => {
                    return (
                      <Input
                        autoComplete="username"
                        classes={{
                          input: passwordFormsClasses.input,
                          prefixIcon: passwordFormsClasses.inputIcon,
                          root: passwordFormsClasses.inputRoot,
                        }}
                        colorVariant={valid ? 'primary' : 'danger'}
                        disabled={isDisabled}
                        id={FORM_USERNAME_INPUT_ID}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={t('loginPage.loginLabel')}
                        prefixIcon={IconUser}
                        ref={ref}
                        value={value}
                      />
                    );
                  },
                }}
                validation={{
                  required: formItemRequiredValidationRule,
                }}
              />

              <FormItemWithTypedError<
                HTMLInputElement,
                string,
                T.LoginPageFormData
              >
                name="password"
                labelFor={FORM_PASSWORD_INPUT_ID}
                slots={{
                  input: ({
                    field: { onChange, onBlur, value, ref },
                    valid,
                  }) => {
                    return (
                      <Input
                        autoComplete="password"
                        classes={{
                          input: passwordFormsClasses.input,
                          prefixIcon: passwordFormsClasses.inputIcon,
                          root: passwordFormsClasses.inputRoot,
                        }}
                        colorVariant={valid ? 'primary' : 'danger'}
                        disabled={isDisabled}
                        id={FORM_PASSWORD_INPUT_ID}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={t('loginPage.passwordLabel')}
                        prefixIcon={IconLock}
                        ref={ref}
                        type="password"
                        value={value}
                      />
                    );
                  },
                }}
                validation={{
                  required: formItemRequiredValidationRule,
                }}
              />

              <div className={classes.buttons}>
                <LoadingButton
                  className={passwordFormsClasses.submitButton}
                  colorVariant="primary"
                  disabled={isDisabled}
                  loading={isDisabled}
                  variant="contained"
                  type="submit"
                  uppercased
                >
                  {t('loginPage.submit')}
                </LoadingButton>
              </div>
              {isError && (
                <FormErrorBlock
                  className={classes.errorBlock}
                  {...loginPageFormErrorParser(error, t)}
                />
              )}
            </Form>

            <div className={classes.forgotPasswordContainer}>
              <SimpleLink
                className={classes.forgotPasswordLink}
                sizeVariant="small"
                to={PATHS.forgotPassword.path}
              >
                {t('loginPage.forgotPassword')}
              </SimpleLink>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};

export default LoginPageForm;
