import classNames from 'classnames';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from 'src/components/Button/ButtonLink';
import { PATHS } from 'src/constants/paths';

import linkClasses from 'src/styles/common/link.module.css';
import classes from './CreateRoomButton.module.css';

export interface CreateRoomButtonProps {
  className?: string;
}

const CreateRoomButton: FC<CreateRoomButtonProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();

  return (
    <ButtonLink
      className={classNames(
        linkClasses.createButtonLink,
        classes.root,
        className
      )}
      colorVariant={'primary'}
      rel="noopener"
      to={PATHS.newRoom.path}
      variant="contained"
    >
      <div className={linkClasses.createButtonLinkContent}>
        {t('rooms.add')}
      </div>
    </ButtonLink>
  );
};

export default CreateRoomButton;
