import classNames from 'classnames';
import { type FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { RoleChip, type RoleChipProps } from 'src/components/RoleChip/RoleChip';
import { type UserRole, USER_ROLE } from 'src/services/api';
import classes from './UserRoleChip.module.css';

export interface UserRoleChipProps extends Omit<RoleChipProps, 'classes'> {
  className?: string;
  role: UserRole;
}

interface UserRoleChipMapEntry {
  className: string;
  label: string;
}

const USER_ROLE_CHIP_MAP: Record<UserRole, UserRoleChipMapEntry> = {
  [USER_ROLE.ADMIN]: {
    className: classes.admin,
    label: 'user.roles.admin',
  },
  [USER_ROLE.USER]: {
    className: classes.user,
    label: 'user.roles.user',
  },
} as const;

const UserRoleChip: FC<UserRoleChipProps> = (props) => {
  const { className, role, ...rest } = props;
  const { t } = useTranslation();

  const entry: UserRoleChipMapEntry =
    USER_ROLE_CHIP_MAP[role] || USER_ROLE_CHIP_MAP[USER_ROLE.USER];

  return (
    <RoleChip
      className={classNames(entry.className, className)}
      classes={{ dot: classes.dot }}
      {...rest}
    >
      {t(entry.label)}
    </RoleChip>
  );
};

export default memo(UserRoleChip);
