import type { FC } from 'react';

import { ErrorPlaceholder } from 'src/containers/Placeholder/ErrorPlaceholder';

import type * as T from './types';

const UserErrorPlaceholder: FC<T.UserErrorPlaceholderProps> = ({ onRetry }) => {
  return <ErrorPlaceholder onRetryClick={onRetry} />;
};

export default UserErrorPlaceholder;
