import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { type To } from 'react-router-dom';

import { SortWidget } from 'src/components/SortWdget/SortWidget2';
import { SORT_WIDGET_COLOR_VARIANT } from 'src/components/SortWdget';

import { StyledLink, STYLED_LINK_VARIANT } from 'src/components/StyledLink';
import { SortWidgetEntry } from 'src/components/SortWdget/SortWidgetEntry';
import { USER_LIST_SORT_LIST, type UserListSort } from 'src/constants/users';

import classes from './RoomListSortWidget.module.css';

export type UserListSortWidgetLinkPathComposer = (value: UserListSort) => To;

export type UserListSortWidgetSelectHandler = (value: UserListSort) => void;

export interface UserListSortWidgetProps {
  className?: string;
  linkPathComposer: UserListSortWidgetLinkPathComposer;
  onSelect?: UserListSortWidgetSelectHandler;
  value: UserListSort;
}

const UserListSortWidget: FC<UserListSortWidgetProps> = (props) => {
  const { className, onSelect, value } = props;

  const { t } = useTranslation();
  /*
  const itemRenderer: SortWidgetItemRender = (item, active, defaultRender) => {
    const to: To = linkPathComposer(item.id);

    return (
      <StyledLink
        to={to}
        variant={STYLED_LINK_VARIANT.menuItem}
      >
        {defaultRender}
      </StyledLink>
    );
  };
*/

  return (
    <SortWidget
      className={className}
      colorVariant={SORT_WIDGET_COLOR_VARIANT.secondary}
      onSelect={onSelect}
      value={value}
    >
      <SortWidgetEntry
        direction="asc"
        value={USER_LIST_SORT_LIST.ALPHABETICAL_ASC}
      >
        {t('userList.sort.alphabeticalAsc')}
      </SortWidgetEntry>
      <SortWidgetEntry
        direction="desc"
        value={USER_LIST_SORT_LIST.ALPHABETICAL_DESC}
      >
        {t('userList.sort.alphabeticalDesc')}
      </SortWidgetEntry>
    </SortWidget>
  );
};

export default UserListSortWidget;
