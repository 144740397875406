import {
  type FC,
  type ReactNode,
  useMemo,
  useEffect,
  useCallback,
  useReducer,
} from 'react';

import type { InternalUserView } from 'src/services/api';
import { userApiService, type ApiUserView } from 'src/services/api';
import { InternalPageRequest } from 'src/utils/pageable';
import { useAuthState } from 'src/contexts/auth';

import {
  useSinglePagePageableRequest,
  type UsePageableRequestDataSupplier,
} from 'src/hooks/request';

import type {
  PaginationOnPageHandler,
  PaginationOnPageSizeHandler,
} from 'src/components/Pagination';

import classes from './UserList.module.css';
import {
  USER_LIST_DEFAULT_DISPLAY_PAGE,
  USER_LIST_DEFAULT_PAGE,
  USER_LIST_DEFAULT_PAGE_SIZE,
  USER_LIST_PAGE_SIZE_VALID_LIST,
  type UserListSort,
} from 'src/constants/users';
import { userListInternalPageRequestToPageRequestMapper } from './mappers/pageRequest';
import type { InternalUserListFilterDataType } from 'src/types/common';
import { type UserListDataSupplierContext } from './internalTypes';
import { convertDataSupplierContextToFilterDataType } from 'src/containers/UserList/UserList/function';
import { type UserListItemBlockHandler } from 'src/containers/UserListItem/UserListItem';
import { StyledBlock } from 'src/components/Styled/Block/StyledBlock';
import { AsyncPageableContainer } from 'src/components/AsyncContainer/AsyncPageableContainer';
import { UserListContent } from 'src/containers/UserList/UserListContent';
import { isValidFunction } from 'src/utils';
import { NoDataPlaceholder } from 'src/containers/Placeholder/NoDataPlaceholder';
import { ErrorPlaceholder } from 'src/containers/Placeholder/ErrorPlaceholder';
import { UserListLoadingPlaceholder } from 'src/containers/UserList/UserListLoadingPlaceholder';

export interface UserListProps {
  filter?: InternalUserListFilterDataType;
  onPage: PaginationOnPageHandler;
  onPageSize?: PaginationOnPageSizeHandler;
  page: number;
  pageSize?: number;
  query?: string;
  sort?: UserListSort;
}

const dataSupplier: UsePageableRequestDataSupplier<
  InternalUserView,
  UserListDataSupplierContext,
  UserListSort
> = async (pageRequest, context) => {
  const result = await userApiService.getUserList(
    userListInternalPageRequestToPageRequestMapper(pageRequest),
    convertDataSupplierContextToFilterDataType(context)
  );
  return result.data;
};

const DISPLAY_PAGE_DELTA =
  USER_LIST_DEFAULT_DISPLAY_PAGE - USER_LIST_DEFAULT_PAGE;

const UserList: FC<UserListProps> = (props) => {
  const { user } = useAuthState();
  const {
    filter,
    onPage,
    onPageSize,
    page: displayPage,
    pageSize = USER_LIST_DEFAULT_PAGE_SIZE,
    query,
    sort,
  } = props;

  const page = displayPage - DISPLAY_PAGE_DELTA;

  const pageRequest: InternalPageRequest<UserListSort> = useMemo(
    () => new InternalPageRequest(page, pageSize, sort),
    [
      page,
      pageSize,
      sort,
    ]
  );

  const context: UserListDataSupplierContext = useMemo(
    () => ({ filter, query }),
    [
      filter,
      query,
    ]
  );

  const { data, pageData, lastOkPageData, isLoading, isError, getData } =
    useSinglePagePageableRequest<
      InternalUserView,
      UserListDataSupplierContext,
      UserListSort
    >({ dataSupplier });

  useEffect(() => {
    getData(pageRequest, context);
  }, [
    pageRequest,
    context,
    getData,
  ]);

  const retry = () => {
    getData(pageRequest, context);
  };

  // actions
  const onBlockUser: UserListItemBlockHandler = useCallback(
    async (data: InternalUserView) => {
      try {
        await userApiService.blockUser(data.id);
        getData(pageRequest, context);
      } catch (e) {
        throw e;
      }
    },
    [
      pageRequest,
      context,
      getData,
    ]
  );

  const onUnblockUser: UserListItemBlockHandler = useCallback(
    async (data: InternalUserView) => {
      try {
        await userApiService.unblockUser(data.id);
        getData(pageRequest, context);
      } catch (e) {
        throw e;
      }
    },
    [
      pageRequest,
      context,
      getData,
    ]
  );

  const { totalPages } = lastOkPageData || {};
  const { totalElements } = pageData || {};
  const dataLenght = Array.isArray(data) ? data.length : null;

  const content: ReactNode = data && (
    <UserListContent
      data={data}
      onBlockUser={onBlockUser}
      onUnblockUser={onUnblockUser}
    />
  );

  return (
    <StyledBlock className={classes.root}>
      <AsyncPageableContainer
        dataLength={dataLenght}
        isError={isError}
        isRequesting={isLoading}
        lastOkTotalPages={totalPages}
        onPage={onPage}
        onPageSize={onPageSize}
        page={displayPage}
        pageSize={pageSize}
        pageSizeVariants={USER_LIST_PAGE_SIZE_VALID_LIST}
        paginationColorVariant="secondary"
        slots={{
          errorPlaceholder: (
            <ErrorPlaceholder
              colorVariant="secondary"
              onRetryClick={retry}
            />
          ),
          content,
          loadingPlaceholder: <UserListLoadingPlaceholder />,
          noDataPlaceholder: <NoDataPlaceholder />,
        }}
        totalElements={totalElements}
      />
    </StyledBlock>
  );
};

export default UserList;
