import { useCallback, useEffect } from 'react';
import type { FieldValues } from 'react-hook-form';

import type { UseFormControllerReturn } from 'src/components/Form/Form';
import type { FormItemValidation } from 'src/components/Form/FormItem';
import type { FormItemWithTypedErrorPayload } from 'src/components/Form/FormItemWithTypedError';
import { maxFileSizeSyntheticValidatorFactory } from 'src/components/Form/validation/syntheticValidators';
import type {
  SyntheticValidatorResult,
  KeyType,
} from 'src/components/Form/validation/types';
import { useManualValidationResultSetter } from 'src/components/Form/validation/useManualValidationResultSetter';
import {
  USER_AVATAR_MAX_SIZE_BYTES,
  USER_AVATAR_MAX_SIZE_MBYTES,
} from 'src/constants/users';

import type * as T from './types';

// внутренние правила валидации
const validationRules: FormItemValidation<
  T.UserAvatarFormItemValue,
  FormItemWithTypedErrorPayload
> = {
  maxSize: {
    payload: {
      tKey: 'form.validation.maxFileSize',
      tOptions: {
        maxSize: USER_AVATAR_MAX_SIZE_MBYTES,
        units: '$t(unit.mbytes)',
      },
    },
  },
};

// валидаторы
const maxFileSizeValidator = maxFileSizeSyntheticValidatorFactory(
  USER_AVATAR_MAX_SIZE_BYTES,
  'maxSize'
);

export function useUserAvatarValidation<F extends FieldValues = FieldValues>(
  controller: UseFormControllerReturn<F>,
  key: KeyType<F>
): T.UseUserAvatarValidationReturn {
  const { watch } = controller;
  // ручной setter
  const validationResultSetter = useManualValidationResultSetter(
    controller,
    key
  );

  const validateHandler = useCallback(
    (value: T.UserAvatarFormItemValue) => {
      const result: SyntheticValidatorResult = maxFileSizeValidator(value);
      validationResultSetter(result);
    },
    [
      maxFileSizeValidator,
      validationResultSetter,
    ]
  );

  // надо валидировать постоянно
  useEffect(() => {
    const subscription = watch((data, info) => {
      switch (info.name) {
        case key: {
          validateHandler(data[key]);
          break;
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    key,
    watch,
    validateHandler,
  ]);

  // полная валидация для итогового результата
  const validate: T.UseUserAvatarValidationValdateHandler = (
    value: T.UserAvatarFormItemValue
  ) => {
    const result: SyntheticValidatorResult = maxFileSizeValidator(value);
    validationResultSetter(result);
    return result.result;
  };

  return {
    validate,
    validation: validationRules,
  };
}
