import type { MouseEventHandler, FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from 'src/components/Button/LoadingButton';
import { isValidFunction } from 'src/utils';

import type * as T from './types';

const UserResendButton: FC<T.UserResendButtonProps> = (props) => {
  const { t } = useTranslation();
  const { className, onResend: onResendProp } = props;

  const [
    disabled,
    setDisabled,
  ] = useState<boolean>(false);

  const onResend: MouseEventHandler<HTMLButtonElement> = async (event) => {
    try {
      setDisabled(true);
      isValidFunction(onResendProp) && (await onResendProp(event));
    } catch (e) {
    } finally {
      setDisabled(false);
    }
  };

  return (
    <LoadingButton
      className={className}
      colorVariant="secondary"
      disabled={disabled}
      loading={disabled}
      onClick={onResend}
      sizeVariant="small"
      variant="outlined"
    >
      {t('user.actions.resend')}
    </LoadingButton>
  );
};

export default UserResendButton;
