import { memo, type FC } from 'react';

import classes from './Background.module.css';
import { ReactComponent as BackgroundSvg } from 'src/assets/svg/background.svg';

const Background: FC = () => {
  return <div className={classes.root}>{<BackgroundSvg width="100%" />}</div>;
};

export default memo(Background);
