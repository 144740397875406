import { type ReactNode, type FC } from 'react';
import classNames from 'classnames';

import MUIPopper, {
  type PopperProps as MUIPopperProps,
} from '@mui/material/Popper';
import { type PopperUnstyledProps as MUIPopperUnstyledProps } from '@mui/base';

import classes from './Popper.module.css';

export interface PopperVirtualElement {
  getBoundingClientRect: Element['getBoundingClientRect'];
  contextElement?: Element;
}

export interface PopperPopperJSOptions
  extends Pick<
    NonNullable<MUIPopperProps['popperOptions']>,
    'modifiers' | 'strategy'
  > {}

export type PopperRender = MUIPopperUnstyledProps['children'];

export interface PopperProps
  extends Omit<MUIPopperProps, 'components | componentProps | modifiers'> {
  /**
   * HTML-элемент или виртуальный элемент PopperVirtualElement (см. popperjs),
   * на основе которого будет устанавливаться позиция поппера
   */
  anchorEl?: HTMLElement | PopperVirtualElement | null;

  children?: PopperRender;

  /**
   * HTML-элемент, внутрь которого будет рендериться контент
   * поппера через портал (см. disablePortal)
   */
  container?: HTMLElement;

  /**
   * Содержимое поппера будет рендериться без использования портала,
   * в DOM родительского компонента.
   */
  disablePortal?: boolean;

  /**
   * Не удалять элементы из DOM при закрытии поппера.
   * В mui работает с багами
   */
  // keepMounted?: boolean;

  open: boolean;
  placement?: MUIPopperProps['placement'];
  popperJSOptions?: Partial<PopperPopperJSOptions>;

  /**
   * Помогает лучше обрабатывать анимации
   */
  transition?: boolean;
}

const Popper: FC<PopperProps> = (props) => {
  const { className, ...rest } = props;

  return (
    <MUIPopper
      className={classNames(classes.root, className)}
      {...rest}
    />
  );
};

export default Popper;
