import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type * as T from './types';

export function useRedirect() {
  const navigate = useNavigate();

  const redirect = useCallback(
    (props: T.RedirectProps) => {
      const { externalRedirect, redirect: redirectProp, replace } = props;

      if (externalRedirect) {
        if (replace) {
          window.location.replace(externalRedirect);
        } else {
          window.location.assign(externalRedirect);
        }
      } else if (redirectProp) {
        navigate(redirectProp, { replace });
      }
    },
    [navigate]
  );

  return redirect;
}
