import { useState, useCallback, useRef } from 'react';
import { type UseRequestMerger, type UseRequestPerformer } from './types';
import { EXECUTOR_RESULT_STATUS } from 'src/utils/executors';
import { isValidFunction } from 'src/utils';

export type UseRequestDataSupplier<T, C = void> = (context: C) => Promise<T>;

export type UseRequestDataSetter<T> = (value: T | null) => void;

export interface UseRequestProps<T, C = void> {
  dataSetter?: UseRequestDataSetter<T>;
  dataSupplier: UseRequestDataSupplier<T, C>;
}

export type UseRequestGetData<T, C = void> = (
  performer: UseRequestPerformer<T>,
  merger: UseRequestMerger<T | null>,
  context: C
) => Promise<void>;

export interface UseRequestReturn<T, C = void> {
  data: T | null;
  isLoading: boolean;
  isError: boolean;
  errorData?: any;
  getData: UseRequestGetData<T, C>;
}

export const useRequest = <T, C>(
  props: UseRequestProps<T, C>
): Readonly<UseRequestReturn<T, C>> => {
  const { dataSetter, dataSupplier } = props;

  const [
    data,
    setData,
  ] = useState<T | null>(null);
  // чтобы не ререндерить лишний раз из-за getData
  const dataRef = useRef<T | null>(null);

  const [
    isLoading,
    setLoading,
  ] = useState<boolean>(false);
  const [
    isError,
    setError,
  ] = useState<boolean>(false);
  const [
    errorData,
    setErrorData,
  ] = useState<any>(null);

  const fetchData = useCallback(
    async (performer: UseRequestPerformer<T>, context: C) => {
      const entry = performer(dataSupplier(context));
      console.log('request', 'id', entry.id, 'context', context);
      const result = await entry.execute();
      console.log('result', result);
      return result;
    },
    [
      dataSupplier,
    ]
  );

  const _setData = useCallback(
    (nextData: T | null) => {
      setData(nextData);
      isValidFunction(dataSetter) && dataSetter(nextData);
      dataRef.current = nextData;
    },
    [
      dataSetter,
      dataRef,
    ]
  );

  const getData: UseRequestGetData<T, C> = useCallback(
    async (
      performer: UseRequestPerformer<T>,
      merger: UseRequestMerger<T | null>,
      context: C
    ) => {
      // сброс флагов
      setLoading(true);
      setError(false);

      const result = await fetchData(performer, context);

      switch (result.status) {
        case EXECUTOR_RESULT_STATUS.ok: {
          const { data } = result;
          const nextData = merger(dataRef.current, data);
          _setData(nextData);

          setError(false);
          setErrorData(null);
          setLoading(false);
          break;
        }

        case EXECUTOR_RESULT_STATUS.invalid: {
          break;
        }

        case EXECUTOR_RESULT_STATUS.error:
        default: {
          _setData(null);
          setError(true);
          setErrorData(result.error);
          setLoading(false);
        }
      }
    },
    [
      fetchData,
      _setData,
    ]
  );

  return {
    data,
    isLoading,
    isError,
    errorData,
    getData,
  } as const;
};
