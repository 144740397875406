import classNames from 'classnames';
import { memo, type FC, type ReactNode } from 'react';
import { type ValueOf } from 'src/types/utils';

import classes from './Placeholder.module.css';

export const PLACEHOLDER_COLOR_VARIANT = {
  // error
  danger: 'danger',
  // no results
  primary: 'primary',
} as const;

export type PlaceholderColorVariant = ValueOf<typeof PLACEHOLDER_COLOR_VARIANT>;

export interface PlaceholderClasses {
  root: string;
  icon: string;
}

export interface PlaceholderSlots {
  appendix: ReactNode;
  icon: ReactNode;
}

export interface PlaceholderProps {
  className?: string;
  colorVariant?: PlaceholderColorVariant;
  description?: string;
  slots?: Partial<PlaceholderSlots>;
  title: string;
}

const Placeholder: FC<PlaceholderProps> = (props) => {
  const {
    className,
    colorVariant = PLACEHOLDER_COLOR_VARIANT.primary,
    description,
    slots: { appendix: appendixSlot, icon: iconSlot } = {},
    title,
  } = props;

  const colorVariantClassName =
    colorVariant === PLACEHOLDER_COLOR_VARIANT.danger ? classes.danger : null;

  return (
    <div className={classNames(classes.root, colorVariantClassName, className)}>
      <div className={classes.content}>
        {iconSlot && <div className={classes.iconWrapper}>{iconSlot}</div>}
        <div className={classes.title}>{title}</div>
        {description && (
          <div className={classes.description}>{description}</div>
        )}
        {appendixSlot && <div className={classes.appendix}>{appendixSlot}</div>}
      </div>
    </div>
  );
};

export default memo(Placeholder);
