import classNames from 'classnames';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, type ButtonColorVariant } from 'src/components/Button/Button2';
import { Placeholder } from 'src/components/Placeholder';
import { ErrorPlaceholderImage } from 'src/containers/Placeholder/ErrorPlaceholderImage';

import classes from './ErrorPlaceholder.module.css';
import type * as T from './types';
import { ERROR_PLACEHOLDER_COLOR_VARIANT } from './constants';

const ErrorPlaceholder: FC<T.ErrorPlaceholderProps> = (props) => {
  const {
    className,
    colorVariant = ERROR_PLACEHOLDER_COLOR_VARIANT.primary,
    description,
    iconAltText: iconAltTextProp,
    onRetryClick,
    retryText: retryTextProp,
    titleText: titleTextProp,
  } = props;

  const { t } = useTranslation();

  let buttonColorVariant: ButtonColorVariant;
  switch (colorVariant) {
    case ERROR_PLACEHOLDER_COLOR_VARIANT.secondary: {
      buttonColorVariant = 'secondary';
      break;
    }

    case ERROR_PLACEHOLDER_COLOR_VARIANT.primary:
    default: {
      buttonColorVariant = 'primary';
      break;
    }
  }

  const retryText = retryTextProp || t('placeholder.common.errorRetry');
  const titleText: string = titleTextProp || t('placeholder.common.errorTitle');
  const iconAltText = iconAltTextProp || t('placeholder.common.errorAlt');

  const icon = <ErrorPlaceholderImage alt={iconAltText} />;

  const appendix = onRetryClick ? (
    <div className={classes.appendix}>
      <Button
        colorVariant={buttonColorVariant}
        onClick={onRetryClick}
        sizeVariant="small"
        variant="outlined"
      >
        {retryText}
      </Button>
    </div>
  ) : null;

  return (
    <Placeholder
      className={className}
      colorVariant="danger"
      description={description}
      slots={{ appendix, icon }}
      title={titleText}
    />
  );
};

export default ErrorPlaceholder;
