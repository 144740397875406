import type { ReactNode, ReactElement, Ref } from 'react';
import { useRef } from 'react';
import type { FieldValues } from 'react-hook-form';
import classNames from 'classnames';

import { SlideCssTransition } from 'src/components/Transition/SlideCssTransition';
import { isValidFunction } from 'src/utils';

import * as T from './types';
import classes from './FormItemErrorBlock.module.css';

/**
 * @template E Тип инпута
 * @template V Тип значения
 * @template T Тип значений всей формы
 * @template P Полезная нагрузка валидаторов, позволяет с учетом типов передавать
 * дополнительную информацию с результатами валидации в рендер
 */
const FormItemErrorBlock = <
  E extends Element,
  V,
  T extends FieldValues = FieldValues,
  P = never
>(
  props: T.FormItemErrorBlockProps<E, V, T, P>
): ReactElement<any, any> | null => {
  const { className, renderProps, slots } = props;
  const { error: errorSlotProp } = slots || {};

  const lastError = useRef<ReactNode | null>(null);
  const { valid, validation } = renderProps;

  if (validation) {
    lastError.current = isValidFunction(errorSlotProp)
      ? errorSlotProp(renderProps)
      : validation.type;
  }

  return (
    <SlideCssTransition<HTMLDivElement>
      appear
      classes={{ exitDone: classes.slideExitDone }}
      in={!valid}
      render={(ref: Ref<HTMLDivElement>) => (
        <div
          className={classNames(classes.root, className)}
          ref={ref}
        >
          {lastError.current}
        </div>
      )}
    ></SlideCssTransition>
  );
};

export default FormItemErrorBlock;
