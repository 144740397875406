import type { FieldValues } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { FormItemWithTypedError } from 'src/components/Form/FormItemWithTypedError';
import { StyledFormItemHint } from 'src/components/Styled/Forms/StyledFormItemHint';
import {
  USER_AVATAR_DISPLAY_ACCEPTED,
  USER_AVATAR_MAX_SIZE_MBYTES,
} from 'src/constants/users';
import { AvatarUpload } from 'src/containers/AvatarUpload';

import * as T from './types';

const DEFAULT_INPUT_ID = 'user_form_avatar_input';
const DEFAULT_INPUT_NAME = 'avatar';

const UserAvatarFormItem = <T extends FieldValues = FieldValues>(
  props: T.UserAvatarFormItemProps<T>
) => {
  const {
    className,
    defaultValue,
    disabled,
    hintVisible,
    id = DEFAULT_INPUT_ID,
    name = DEFAULT_INPUT_NAME,
  } = props;

  const { t } = useTranslation();

  return (
    <FormItemWithTypedError<HTMLInputElement, T.UserAvatarFormItemValue, T>
      className={className}
      defaultValue={defaultValue}
      name={name}
      label={t('user.form.labelAvatar')}
      labelFor={id}
      slots={{
        hint: () =>
          hintVisible ? (
            <StyledFormItemHint>
              <Trans
                i18nKey="user.form.hintAvatar"
                t={t}
                values={{
                  accepted: USER_AVATAR_DISPLAY_ACCEPTED,
                  maxSize: USER_AVATAR_MAX_SIZE_MBYTES,
                  unit: t('unit.mbytes'),
                }}
              >
                <strong>0</strong>
                <strong>1</strong>
              </Trans>
            </StyledFormItemHint>
          ) : null,

        input: ({ field: { onChange, onBlur, value, ref }, valid }) => {
          return (
            <div>
              <AvatarUpload
                disabled={disabled}
                colorVariant={valid ? 'secondary' : 'danger'}
                onChange={(value) => onChange(value || null)}
                value={value}
              />
            </div>
          );
        },
      }}
    />
  );
};

export default UserAvatarFormItem;
