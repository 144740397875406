import type { FC, ReactNode, ReactElement } from 'react';
import { useEffect } from 'react';

import { useAuth } from 'src/contexts/auth';

export interface AuthAwaitedProps {
  /**
   * Будут отрисованы, когда компонент точно будет знать,
   * что аутентификационные данные получены
   */
  children?: ReactNode;
  fallback?: ReactNode;
}

const AuthAwaited: FC<AuthAwaitedProps> = (props) => {
  const { children, fallback } = props;

  const [
    { verified },
    { verify },
  ] = useAuth();

  useEffect(() => {
    if (!verified) {
      const asyncEffect = async () => {
        try {
          await verify();
        } catch (e) {}
      };

      asyncEffect();
    }
  }, [
    verified,
    verify,
  ]);

  return verified
    ? (children as ReactElement<any, any>)
    : (fallback as ReactElement<any, any>) || null;
};

export default AuthAwaited;
