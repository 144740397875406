import { type FC, type ReactNode } from 'react';
import { default as MUIDialogTitle } from '@mui/material/DialogTitle';

import classes from './DialogTitle.module.css';

import { useDialogContext } from 'src/components/Modal/useDialogContext';

export interface DialogTitleProps {
  children?: ReactNode;
  className?: string;
}

const DialogTitle: FC<DialogTitleProps> = (props) => {
  const { children, className } = props;

  const context = useDialogContext();

  return <MUIDialogTitle className={classes.root}>{children}</MUIDialogTitle>;
};

export default DialogTitle;
