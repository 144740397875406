import { type FC, type ReactNode } from 'react';
import classNames from 'classnames';

import classes from './StyledSection.module.css';

export interface StyledSectionProps {
  children?: ReactNode;
  className?: string;
}

const StyledSection: FC<StyledSectionProps> = (props) => {
  const { children, className } = props;

  return <div className={classNames(classes.root, className)}>{children}</div>;
};

export default StyledSection;
