import classNames from 'classnames';
import type { Ref } from 'react';
import { type ChangeEvent, useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IconImage, IconUpload } from 'src/assets/svg';
import { ButtonLikeLabel } from 'src/components/Button/ButtonLikeLabel';
import {
  FileInput,
  type FileInputChangeHandler,
} from 'src/components/Input/FileInput';
import { USER_AVATAR_ACCEPTED } from 'src/constants/users';
import {
  Droppable,
  type DroppableDropHandler,
  type DroppableRender,
} from 'src/components/Droppable';
import { isValidFunction } from 'src/utils';

import classes from './FileDropContainer.module.css';
import { type ValueOf } from 'src/types/utils';

export const FILE_DROP_CONTAINER_COLOR_VARIANT = {
  primary: 'primary',
  danger: 'danger',
} as const;

export type FileDropContainerColorVariant = ValueOf<
  typeof FILE_DROP_CONTAINER_COLOR_VARIANT
>;

export interface FileDropContainerClasses {
  droppableContent: string;
}

export type FileDropContainerChangeHandler = (files?: FileList) => void;

export interface FileDropContainerProps {
  className?: string;
  classes?: Partial<FileDropContainerClasses>;
  colorVariant?: FileDropContainerColorVariant;
  disabled?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  onChange?: FileDropContainerChangeHandler;
}

const ACCEPTED_TRANSFER_TYPES = [
  'Files',
  'text/plain',
];

const INPUT_ID = 'input_avatar';

const FileDropContainer: FC<FileDropContainerProps> = (props) => {
  const {
    className,
    classes: classesProp = {},
    colorVariant = FILE_DROP_CONTAINER_COLOR_VARIANT.primary,
    disabled,
    inputRef,
    onChange: onChangeProp,
  } = props;

  const { t } = useTranslation();

  const onInputChange: FileInputChangeHandler = (
    files: FileList | null,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    isValidFunction(onChangeProp) && onChangeProp(files || undefined);
  };

  const colorVariantClassName =
    colorVariant === FILE_DROP_CONTAINER_COLOR_VARIANT.danger
      ? classes.internaalRootDanger
      : null;

  const droppableRender: DroppableRender = ({ active, disabled }) => {
    return (
      <div
        className={classNames(
          classes.internalRoot,
          colorVariantClassName,
          classesProp.droppableContent,
          active && classes.internalRootActive
        )}
      >
        <div
          className={classNames(
            classes.overlay,
            active && classes.overlayActive
          )}
        >
          <div className={classes.overlayWrapper}>
            <div className={classes.iconWrapper}>
              <IconUpload className={classes.icon} />
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.iconWrapper}>
            <IconImage className={classes.icon} />
          </div>
          <div className={classes.hint}>
            <div>{t('form.file.dnd')}</div>
            <div className={classes.hintDelimiter}>{t('form.file.or')}</div>
          </div>
          <div>
            <FileInput
              disabled={disabled}
              id={INPUT_ID}
              inputProps={{
                accept: USER_AVATAR_ACCEPTED,
                multiple: false,
              }}
              ref={inputRef}
              onChange={onInputChange}
            >
              <ButtonLikeLabel
                className={classes.buttonBrowse}
                colorVariant="primary"
                htmlFor={INPUT_ID}
                sizeVariant="small"
                syntheticDisabled={disabled}
                variant="text"
              >
                {t('form.file.browse')}
              </ButtonLikeLabel>
            </FileInput>
          </div>
          <div></div>
        </div>
      </div>
    );
  };

  const onDrop: DroppableDropHandler | undefined = useMemo(
    () =>
      isValidFunction(onChangeProp)
        ? (event) => {
            const { dataTransfer } = event;
            if (dataTransfer) {
              const { files } = dataTransfer;
              onChangeProp(files);
            }
          }
        : undefined,
    [onChangeProp]
  );

  return (
    <div
      className={classNames(
        classes.root,
        className,
        disabled && classes.rootDisabled
      )}
    >
      <Droppable
        acceptedTransferTypes={ACCEPTED_TRANSFER_TYPES}
        className={classes.droppable}
        disabled={disabled}
        onDrop={onDrop}
        render={droppableRender}
      ></Droppable>
    </div>
  );
};

export default FileDropContainer;
