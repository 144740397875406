import type { FieldPath, FieldValues } from 'react-hook-form';
import type { TFunction } from 'react-i18next';

import type { ErrorData } from 'src/services/api/functions';
import type { RequestDisplayErrorData } from 'src/types/common';

export function errorDataToDisplayErrorData(
  error: ErrorData,
  t: TFunction
): RequestDisplayErrorData {
  const { status, message } = error;
  return { message, status, title: t('error.common') };
}

export function getFirstInvalidField<T extends FieldValues = FieldValues>(
  validList: boolean[],
  fieldList: Array<FieldPath<T>>
): FieldPath<T> | undefined {
  const position = validList.indexOf(false);
  return position !== -1 && position < fieldList.length
    ? fieldList[position]
    : undefined;
}
