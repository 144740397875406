import { type ReactNode, type FC, useRef } from 'react';
import classNames from 'classnames';

import classes from './AsyncContainer.module.css';

export interface AsyncContainerClasses {
  content: string;
  root: string;
}

export interface AsyncContainerSlots {
  content: ReactNode;
  errorPlaceholder: ReactNode;
  loadingPlaceholder: ReactNode;
  noDataPlaceholder: ReactNode;
}

export interface AsyncContainerProps {
  children?: ReactNode;
  className?: string;
  classes?: Partial<AsyncContainerClasses>;
  hasData?: boolean;
  isError?: boolean;

  /**
   * Важно: запрос может не начаться с первым рендером (чаще всего, начнется после первого
   * рендера через эффект), данные могу быть переданы уже на первый рендер без запроса
   */
  isRequesting?: boolean;

  slots?: Partial<AsyncContainerSlots>;
}

const AsyncContainer: FC<AsyncContainerProps> = (props) => {
  const {
    children: childrenProp,
    className,
    classes: classesProp = {},
    hasData: hasDataProp,
    isError,
    isRequesting,
    slots = {},
  } = props;
  const {
    errorPlaceholder: errorPlaceholderSlot,
    content: contentSlot,
    loadingPlaceholder: loadingPlaceholderSlot,
    noDataPlaceholder: noDataPlaceholderSlot,
  } = slots;
  const content = contentSlot || childrenProp;

  const hasData = !isRequesting && !isError && hasDataProp;

  // TODO
  // smart delay
  const isSensitiveToRequestingProp = useRef<boolean>(false);
  if (!isSensitiveToRequestingProp.current) {
    isSensitiveToRequestingProp.current = Boolean(
      hasData || isError || isRequesting
    );
  }

  const isLoading = isSensitiveToRequestingProp.current
    ? isRequesting
    : !hasData;

  const children = hasData
    ? content
    : isLoading
    ? loadingPlaceholderSlot
    : isError
    ? errorPlaceholderSlot
    : noDataPlaceholderSlot;

  console.log(
    'hasDataProp:',
    hasDataProp,
    'hasData:',
    hasData,
    'isLoading:',
    isLoading,
    'isRequesting:',
    isRequesting,
    isSensitiveToRequestingProp,
    'isError:',
    isError
  );

  return (
    <div className={classNames(classes.root, classesProp.root, className)}>
      {children}
    </div>
  );
};

export default AsyncContainer;
