import { type FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { LanguageSelector } from 'src/containers/LanguageSelector';
import { HeaderWrapper } from 'src/containers/Header/HeaderWrapper';

import classes from './NonAuthorizedHeader.module.css';
import { ButtonLink } from 'src/components/Button/ButtonLink';
import { PATHS } from 'src/constants/paths';

export interface HeaderProps {
  className?: string;
  loginButtonHidden?: boolean;
}

const NonAuthorizedHeader: FC<HeaderProps> = (props) => {
  const { t } = useTranslation();
  const { className, loginButtonHidden } = props;

  return (
    <HeaderWrapper
      classes={{
        header: classes.header,
        root: classNames(
          className,
          classes.root,
          loginButtonHidden && classes.loginButtonHidden
        ),
      }}
    >
      <div className={classes.locale}>
        <LanguageSelector />
      </div>
      {!loginButtonHidden && (
        <div className={classes.buttonContainer}>
          <ButtonLink
            className={classes.loginButton}
            colorVariant="primary"
            to={PATHS.login.path}
            sizeVariant="small"
            variant="contained"
          >
            {t('header.login')}
          </ButtonLink>
        </div>
      )}
    </HeaderWrapper>
  );
};

export default NonAuthorizedHeader;
